import { Fragment } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { processingStepStyle } from './processing-step-style';

function ProcessingStep({ style, steps, currentStep = 1 }) {
  const classes = processingStepStyle();

  return (
    <div className={classes.claimStepWrapper} style={style}>
      {steps.map((step, idx) => {
        const adjustedIdx = idx + 1;
        const isCurrentOrCompleteStep = currentStep >= adjustedIdx;
        const isLastStep = adjustedIdx === steps.length;

        return (
          <Fragment key={step}>
            <div
              className={classes.numberIcon}
              data-testid={`step-${adjustedIdx}`}
              style={{
                backgroundColor: isCurrentOrCompleteStep
                  ? '#5368F5'
                  : 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <Typography className={classes.numberDetail}>{adjustedIdx}</Typography>
            </div>
            <Typography variant='subtitle2'>{step}</Typography>

            {!isLastStep ? (
              <div
                className={classes.horizontalLine}
                data-testid='divider'
                style={{
                  backgroundColor: '#BDBDBD',
                }}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}

ProcessingStep.defaultProps = {
  currentStep: 1,
  style: {},
};

ProcessingStep.propTypes = {
  style: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number,
};

export default ProcessingStep;
