import makeStyles from '@mui/styles/makeStyles';

export const OrganizationsCreateStyles = makeStyles(
  {
    defaultBg: {
      background: '#2196F320',
    },
    defaultContainer: {
      height: '100%',
      padding: '1.5rem 1.5rem 0',
      marginRight: '-2rem',
      marginBottom: '-1rem',
      '& > *': {
        marginTop: 0,
      },
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '1.5rem',
      paddingBottom: 0,
      margin: 0,
      marginRight: '-2rem',
    },
    defaultHeight: {
      '& input, & > div': { minHeight: '59px', boxSizing: 'border-box' },
    },
    fieldsetLabel: { fontWeight: 'bold', fontSize: '14px', marginBottom: '16px' },
    mt2: { marginTop: '16px' },
    hidden: { height: 0, position: 'fixed', top: '-9999px', left: '-9999px' },
    redText: {
      color: 'red',
    },
  },
  { name: 'organizations-create' }
);
