import {
  Create,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useRedirect,
} from 'react-admin';
import moment from 'moment';
import PropTypes from 'prop-types';

const NoteCreate = () => {
  const redirect = useRedirect();

  const today = moment().format('MM/DD/YYYY, h:mm A');

  const { identity, isLoading: identityLoading } = useGetIdentity();

  function onSuccess(data) {
    redirect(`/customers/${data.user_id}/show/notes`);
  }

  return identityLoading ? null : (
    <Create de title='Add Customer Notes' mutationOptions={{ onSuccess }}>
      <SimpleForm
        defaultValues={{
          created_at: today,
          agent_email: identity.email,
          agent_id: identity.id,
          note: '',
        }}
      >
        <TextInput disabled source='created_at' />
        <TextInput disabled source='agent_email' />
        <TextInput multiline source='note' />
      </SimpleForm>
    </Create>
  );
};

NoteCreate.propTypes = {
  location: PropTypes.object,
};

export default NoteCreate;
