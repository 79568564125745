import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  useListContext,
  WithListContext,
} from 'react-admin';
import { CircularProgress, Typography } from '@mui/material';

import { PERMISSION_EDIT_QUOTE } from '../../../../auth/permissions';
import EditButton from '../../../../components/EditButton';
import RestrictedAction from '../../../../components/security/RestrictedAction';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import CreateQuoteModal from './create-quote-modal';
import QuoteListCustomActions from './quote-list-custom-actions';

function DatagridWrapper({ ...props }) {
  const { isLoading } = useListContext();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props}>
      <TextField label='FIRST NAME' source='first_name' />
      <TextField label='LAST NAME' source='last_name' />
      <TextField label='EMAIL' source='email' />
      <ArrayField label='PETS' source='quote_pets' sortable={false} link={false}>
        <WithListContext
          render={({ data }) => {
            return (
              <ul style={{ padding: 0 }}>
                {data.map(quote_pet => (
                  <div
                    key={quote_pet.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      marginBottom: '10px',
                    }}
                  >
                    <ChipField record={quote_pet} source='name' key={quote_pet.id} />
                    <Typography style={{ fontSize: '12px', alignContent: 'center' }}>
                      {quote_pet.product}
                    </Typography>
                  </div>
                ))}
              </ul>
            );
          }}
        />
      </ArrayField>
      <DateField
        options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        label='CREATED ON'
        source='created_at'
      />
      <FunctionField
        label='CREATED BY'
        render={(record = {}) => {
          return record.created_by ? (
            <>
              <Typography style={{ fontSize: '10px' }}>AGENT</Typography>
              <Typography>{record.created_by}</Typography>
            </>
          ) : (
            <Typography>Owner</Typography>
          );
        }}
      />
      <FunctionField
        label='EDITED BY'
        render={(record = {}) => {
          return record.updated_by ? (
            <>
              <Typography style={{ fontSize: '10px' }}>AGENT</Typography>
              <Typography>{record.updated_by}</Typography>
            </>
          ) : (
            <Typography>Owner</Typography>
          );
        }}
      />
      <FunctionField
        label='CONVERSION'
        render={(record = {}) => {
          return record.converted === true ? (
            <Typography style={{ color: '#028E10', textAlign: 'center' }}>
              YES
            </Typography>
          ) : (
            <Typography style={{ color: '#B53F3F', textAlign: 'center' }}>
              NO
            </Typography>
          );
        }}
      />
      <RestrictedAction requiredPermissions={PERMISSION_EDIT_QUOTE}>
        <FunctionField
          label=''
          render={(record = {}) => {
            return record.product === 'insurance' ? (
              <EditButton customPath={`/${RoutePaths.quotes}/${record.id}`} />
            ) : null;
          }}
        />
      </RestrictedAction>
    </Datagrid>
  );
}

function QuoteList() {
  const [isCreateModalOpen, toggleIsCreateModalOpen] = useBooleanInput(false);

  return (
    <List
      actions={<QuoteListCustomActions onClick={toggleIsCreateModalOpen} />}
      exporter={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <>
        <DatagridWrapper />
        <CreateQuoteModal
          open={isCreateModalOpen}
          onClose={toggleIsCreateModalOpen}
        />
      </>
    </List>
  );
}

export default QuoteList;
