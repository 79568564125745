import { useGetOne } from 'react-admin';

import { STATES_API } from '@pumpkincare/config';

export function useProductsByState(state) {
  return useGetOne(
    '',
    { id: state, meta: { api: 'UTILS', custom_url: STATES_API } },
    { enabled: !!state }
  );
}
