import { Fragment, useState } from 'react';
import { useRedirect, useResourceDefinitions } from 'react-admin';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QueueIcon from '@mui/icons-material/Queue';
import { ListItemButton, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import RoutePaths from '../routes';

const theme = createTheme();

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    listItemRoot: {
      paddingBottom: '0px',
      paddingTop: '0px',
      paddingLeft: '6px',
      paddingRight: '6px',
      '&.Mui-selected': {
        color: 'rgba(0, 125, 255, 0.5)',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
    },
    selected: {},
    menuText: {
      color: '#1976d2',
      fontSize: '10px',
      lineHeight: '24px',
      fontWeight: '700',
    },
  },
  { name: 'mga-menu' }
);

export default function MgaMenu() {
  const redirect = useRedirect();
  const classes = useStyles();

  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    name => resourcesDefinitions[name]
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const menuList = resources.filter(
    resource => resource.hasList && resource.name !== RoutePaths.claimsLibrary
  );

  function handleListItemClick(index, name) {
    setSelectedIndex(index);
    redirect(`/${name}`);
  }

  return (
    <List component='nav' className={classes.root}>
      {menuList.map((resource, index) => {
        return resource.name === RoutePaths.claims ? (
          <Fragment key={resource.name}>
            <ListItemButton
              selected={selectedIndex === index}
              onClick={() => handleListItemClick(index, resource.name)}
              classes={{ root: classes.listItemRoot, selected: classes.selected }}
            >
              <div
                style={{
                  display: 'inline-grid',
                  width: '100%',
                  height: '50px',
                  textAlign: 'center',
                }}
              >
                <ListItemIcon style={{ minWidth: '0px', margin: 'auto' }}>
                  <QueueIcon
                    style={{
                      color: '#1976D2',
                      width: '18px',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ textAlign: 'center', marginTop: '0px' }}
                  primaryTypographyProps={{
                    className: classes.menuText,
                    style: { textTransform: 'capitalize' },
                  }}
                  primary='Claims Queue'
                  data-testid='menu-icon-claims-queue'
                />
              </div>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === RoutePaths.claimsLibrary}
              onClick={() =>
                handleListItemClick(
                  RoutePaths.claimsLibrary,
                  RoutePaths.claimsLibrary
                )
              }
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected,
              }}
            >
              <div
                style={{
                  display: 'inline-grid',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <ListItemIcon style={{ minWidth: '0px', margin: 'auto' }}>
                  <AssignmentTurnedInIcon
                    style={{
                      color: '#1976D2',
                      width: '18px',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ textAlign: 'center', marginTop: '0px' }}
                  primaryTypographyProps={{
                    className: classes.menuText,
                    style: { textTransform: 'capitalize' },
                  }}
                  primary='Claims Library'
                  data-testid='menu-icon-claims-lib'
                />
              </div>
            </ListItemButton>
          </Fragment>
        ) : (
          <ListItemButton
            selected={selectedIndex === index}
            classes={{ root: classes.listItemRoot, selected: classes.selected }}
            onClick={() => handleListItemClick(index, resource.name)}
            key={resource.name}
          >
            <div
              style={{
                display: 'inline-grid',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <ListItemIcon style={{ minWidth: '0px', margin: 'auto' }}>
                {resource.icon}
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: 'center', marginTop: '0px' }}
                disableTypography
                primary={
                  <Typography
                    style={{
                      color: '#1976d2',
                      fontSize: '10px',
                      lineHeight: '24px',
                      fontWeight: '700',
                      margin: '0px',
                    }}
                  >
                    {resource.options?.label || resource.name}
                  </Typography>
                }
              />
            </div>
          </ListItemButton>
        );
      })}
    </List>
  );
}
