import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

function ArrayTextField({ source }) {
  const record = useRecordContext();

  const addonsIncluded = record.items.some(item => item.type === 'addon') ? (
    <span style={{ fontSize: '11px', color: 'rgba(0,0,0,0.38)' }}>
      <br />
      ADD-ONS INCLUDED*
    </span>
  ) : null;

  return (
    <span>
      {record[source] ? record[source].join(', ') : ''}
      {addonsIncluded}
    </span>
  );
}
ArrayTextField.propTypes = { source: PropTypes.string };
ArrayTextField.defaultProps = { source: 'pet_names' };

export default ArrayTextField;
