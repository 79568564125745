import { TextInput } from 'react-admin';
import { Grid, InputLabel } from '@mui/material';

import { OrganizationsCreateStyles } from '../../organizations-create/organizations-create.styles';

export function VetAssociationField() {
  const classes = OrganizationsCreateStyles();
  return (
    <Grid container spacing={4} fullWidth>
      <Grid item xs={12} md={6}>
        <InputLabel shrink>Vet Association</InputLabel>
        <TextInput
          variant='outlined'
          source='association'
          label={false}
          fullWidth
          className={classes.defaultHeight}
        />
      </Grid>
    </Grid>
  );
}
