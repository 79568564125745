import { useGetList } from 'react-admin';

export function useDiscounts() {
  const { data, ...query } = useGetList(`organizations/discounts`, {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'percentage_off', order: 'ASC' },
  });

  return {
    ...query,
    data: filterMultipetDiscount(data),
  };
}

function filterMultipetDiscount(data) {
  return data
    ? Object.values(data).reduce((result, item) => {
        if (item?.code !== 'MultipetDiscount') {
          result[item.id] = item;
        }

        return result;
      }, {})
    : data;
}
