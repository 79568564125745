import { useRef, useState } from 'react';
import { useNotify, useUpdate } from 'react-admin';
import { Button, MenuItem, Select, Typography } from '@mui/material';

import {
  usePetByUser,
  usePetPlans,
  usePetPlanUtilization,
} from '@pumpkincare/users';

import NumericField from '../../../../lib/shared/ui/numeric-field';
import UtilizationMga from '../../../../shared/components/utilization-mga';

function PlanSelectInput({ petId, onChange, planValue }) {
  const { data: petPlanData } = usePetPlans(petId);
  return petPlanData?.length > 0 ? (
    <Select
      style={{ width: '335px', height: '40px', marginBottom: '32px' }}
      variant='outlined'
      value={planValue}
      onChange={props => onChange(props.target.value)}
    >
      {petPlanData.map(petPlan => {
        return (
          <MenuItem
            key={petPlan.id}
            value={petPlan.id}
          >{`${petPlan.plan.description}`}</MenuItem>
        );
      })}
    </Select>
  ) : null;
}

function UtilizationMgaWrapper({ petPlanUtilizationData }) {
  return petPlanUtilizationData ? (
    <UtilizationMga
      renderCloseIcon={false}
      petPlanData={petPlanUtilizationData}
      style={{ border: '1px solid' }}
    />
  ) : null;
}

export default function CustomerShipment({ id }) {
  const [updateOne] = useUpdate();
  const notify = useNotify();
  const [petId, setPetId] = useState('');
  const quantity = useRef(0);
  const [planId, setPlanId] = useState('');
  const { data: petPlanUtilizationData, refetch } = usePetPlanUtilization(planId);

  const { data: petData = [], isLoading } = usePetByUser(id);

  function update() {
    if (!planId) {
      notify(`Please select a plan`, {
        type: 'warning',
      });
      return;
    }

    if (!quantity.current.value) {
      notify(`Please inform the quantity`, {
        type: 'warning',
      });
      return;
    }

    updateOne(
      'pet-plans',
      {
        id: `${planId}/utilization`,
        data: {
          quantity: quantity.current.value,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: error =>
          notify(`There was an error while updating utilization: ${error.message}`, {
            type: 'error',
          }),
      }
    );
  }

  return isLoading ? null : (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%', display: 'block' }}>
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '500',
            color: '#007DFF',
            marginBottom: '32px',
          }}
        >
          Update Medication Utilization:
        </Typography>
        <Typography>Pet</Typography>
        <Select
          style={{ width: '335px', height: '40px', marginBottom: '32px' }}
          variant='outlined'
          value={petId}
          onChange={props => {
            setPetId(props.target.value);
          }}
        >
          {petData.map(pet => (
            <MenuItem key={pet.id} value={pet.id}>
              {pet.name}
            </MenuItem>
          ))}
        </Select>

        {petId ? (
          <>
            <Typography>Plan</Typography>
            <PlanSelectInput
              petId={petId}
              planValue={planId}
              onChange={value => setPlanId(value)}
            />
          </>
        ) : null}

        <Typography>Dosage Quantity</Typography>

        <NumericField
          style={{ width: '335px', marginBottom: '48px' }}
          size='small'
          inputRef={quantity}
          allowDecimalValue
        />

        <div style={{ width: '335px', textAlign: 'right', marginBottom: '48px' }}>
          <Button
            style={{ color: '#007DFF', border: '1px solid' }}
            onClick={() => update()}
          >
            UPDATE
          </Button>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <UtilizationMgaWrapper petPlanUtilizationData={petPlanUtilizationData} />
      </div>
    </div>
  );
}
