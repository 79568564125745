import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

import { useClaimReimbursement } from '@pumpkincare/claims';
import { BankWithdrawal, TabPanel } from '@pumpkincare/shared';

import RoutePaths from '../../../routes';
import { claimReimbursementTabStyle } from './claim-reimbursement-tab-style';

export default function ClaimReimbursementTab({ tabValue }) {
  const classes = claimReimbursementTabStyle();

  const claimRecord = useRecordContext();
  const { data: reimbursementData = [], isLoading } = useClaimReimbursement(
    claimRecord?.id
  );

  return (
    <TabPanel value={tabValue} index={2}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {reimbursementData?.list?.map((reimbursement, index) => {
            return (
              <div key={index} className={classes.reimbursementContainer}>
                <BankWithdrawal reimbursement={reimbursement} />
              </div>
            );
          })}
        </>
      )}
      {reimbursementData?.list?.length > 0 ? (
        <div style={{ marginTop: '68px' }}>
          <Button
            variant='outlined'
            fullWidth
            style={{
              textTransform: 'none',
              backgroundColor: '#007DFF',
              color: '#FFFFFF',
            }}
            component={Link}
            to={{
              pathname: `/${RoutePaths.reimbursements}/${claimRecord.id}/show`,
            }}
          >
            REIMBURSEMENT DETAILS
          </Button>
        </div>
      ) : null}
    </TabPanel>
  );
}
