import makeStyles from '@mui/styles/makeStyles';

const claimVetReimbursementModalStyles = makeStyles(
  {
    modalTitle: {
      padding: '32px',
    },
    modalContent: { width: '500px', padding: '32px' },
    modalActions: { padding: '0 32px 32px', justifyContent: 'space-between' },
    cancelButton: {
      width: '100%',
    },
    reimburseButton: {
      width: '100%',
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
      marginBottom: '16px',
    },
    content: {
      fontWeight: '500',
      marginBottom: '16px',
    },
    amountTitle: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      marginBottom: '8px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    amountValue: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    directDeposit: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      margin: '16px 0 32px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    error: {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '13px',
      color: '#EE0004',
      marginTop: '4px',
    },

    bankAccount: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: '20px',
      marginBottom: '20px',
    },

    bankAccountHeader: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      marginBottom: '8px',
      letterSpacing: '1px',
    },

    bankAccountTitle: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      marginBottom: '8px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.87)',
    },

    bankAccountColLeft: {
      minWidth: '30%',
    },

    bankAccountColRight: {
      minWidth: '70%',
    },
  },
  { name: 'claim-vet-reimbursement-modal' }
);

export { claimVetReimbursementModalStyles };
