import { Children, cloneElement } from 'react';
import { WithPermissions } from 'react-admin';

import { permissionsByGroup } from '../../auth/groups';

const checkRequiredPermissions = (requiredPermissions, userGroup) => {
  const grantedPermissions = permissionsByGroup[userGroup?.split('_').pop()];
  if (!grantedPermissions) {
    console.warn("Didn't find any granted permissions for the current user group.");

    return false;
  }

  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions.every(requiredPermission => {
      return grantedPermissions[requiredPermission] === true;
    });
  }

  const requiredPermission = requiredPermissions;
  return grantedPermissions[requiredPermission] === true;
};

function RestrictedAction(props) {
  const { basePath, children, record, resource, requiredPermissions } = props;

  return (
    <WithPermissions
      render={({ permissions: userGroup }) => {
        if (checkRequiredPermissions(requiredPermissions, userGroup)) {
          return Children.map(children, child => {
            // respect childResource and childBasePath properties already set on the child
            const childResource = child.props.resource || resource;
            const childBasePath = child.props.basePath || basePath;

            const newProps = { record };
            if (childBasePath) newProps.basePath = childBasePath;
            if (childResource) newProps.resource = childResource;

            return cloneElement(child, newProps);
          });
        }

        return null;
      }}
    />
  );
}

RestrictedAction.propTypes = {
  requiredPermissions: (props, propName) => {
    const propValue = props[propName];
    const isValidType = typeof propValue === 'string' || Array.isArray(propValue);

    const errorMessage = `Failed prop type: The prop '${propName}' in 'RestrictedAction' \
       is expected to be a non-empty string or non-empty array.`;

    if (!isValidType || propValue.length === 0) {
      return new Error(errorMessage);
    }

    return null;
  },
};

export default RestrictedAction;
