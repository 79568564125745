import makeStyles from '@mui/styles/makeStyles';

const claimBulkEditSection = makeStyles(
  {
    insuranceFieldContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    preventFieldContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '900px',
    },
    updateLineItemButton: {
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      borderRadius: '4px',
      color: 'white',
      marginLeft: '12px',
    },
    insuranceButtonContainer: { textAlign: 'right', marginTop: '16px' },
    preventButtonContainer: {
      textAlign: 'right',
      marginTop: '16px',
      width: '900px',
    },
    cancelUpdateLineItemButton: {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      color: '#007DFF',
      marginLeft: '12px',
    },
  },
  {
    name: 'claim-bulk-edit-section',
  }
);

export { claimBulkEditSection };
