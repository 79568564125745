import { useMemo } from 'react';
import { ListContextProvider, useList, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

function InvoiceItems(props) {
  const record = useRecordContext();

  const { children } = props;
  const { items = [] } = record;

  if (items.length === 0) {
    return null;
  }

  const sorted = useMemo(() => {
    return items
      .sort((a, b) => String(a.pet_name).localeCompare(b.pet_name))
      .sort((a, b) => {
        const order = { policy: 999, plan: 888, addon: 777, fee: 666 };
        return order[b.type] - order[a.type];
      });
  }, [items]);

  const list = useList({
    perPage: sorted.length,
    data: sorted,
    ids: sorted.map(({ id }) => id),
    total: sorted.length,
  });

  return <ListContextProvider value={list}>{children}</ListContextProvider>;
}

InvoiceItems.defaultProps = {
  children: null,
};

InvoiceItems.propTypes = {
  children: PropTypes.node,
};

export default InvoiceItems;
