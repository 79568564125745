import makeStyles from '@mui/styles/makeStyles';

const planModalStyle = makeStyles(
  {
    textFontSize: {
      fontSize: '12px',
    },
    label: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    petSpeciesGender: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase',
      marginTop: '12px',
    },
    petName: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    petDetails: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '14px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    productsDescription: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    productHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12px',
      marginBottom: '4px',
    },
    productName: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.84)',
      textTransform: 'uppercase',
    },
    productPrice: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    productDetails: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '14px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    vetName: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase',
      marginBottom: '8px',
    },
    discountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '16px 24px',
    },
    discountName: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '14px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.84)',
    },
    discountAmount: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      color: '#D4424E',
    },
    totalLabel: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      color: 'black',
    },
    totalPrice: {
      fontSize: '24px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '4px',
    },
  },
  { name: 'plan-modal' }
);

export { planModalStyle };
