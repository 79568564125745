import { useState } from 'react';
import { useNotify } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import { postSendQuoteByEmail } from '../service/quoteFlowService';

function SendQuoteByEmailModal(props) {
  const { showDialog, setShowDialog, quote } = props;
  const notify = useNotify();

  const [email, setEmail] = useState('');

  function handleSendEmailClick() {
    postSendQuoteByEmail([email], quote.id)
      .then(() => {
        setShowDialog(false);
        setEmail('');
        notify(`Email sent.`, 'info');
      })
      .catch(e => {
        notify(`There was an error when sending the email: ${e.message}`, 'warning');
      });
  }

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      aria-label='Non Conversin Quote'
    >
      <div
        style={{
          width: '500px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '500',
            color: 'black',
          }}
        >
          SEND QUOTE TO
        </Typography>
      </div>
      <DialogContent style={{ display: 'inline-grid' }}>
        <div style={{ margin: '16px 0px' }}>
          <TextField
            label='Email Address'
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={() => setShowDialog(false)} style={{ color: '#D4424E' }}>
          CANCEL
        </Button>
        <IconButton
          style={{ padding: '0px 8px', color: '#3F51B5' }}
          onClick={() => handleSendEmailClick()}
          size='large'
        >
          <SendIcon style={{ paddingRight: '3px' }} />
          <Typography style={{ fontSize: '14px' }}>SEND</Typography>
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default SendQuoteByEmailModal;
