import { useRecordContext } from 'react-admin';
import { Card, Divider, Typography } from '@mui/material';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useNextCharges } from '@pumpkincare/users';

import CircularProgress from '../../../../shared/components/SanitizedComponents/CircularProgress';
import CardDetails from './CardDetails';
import NextCharges from './NextCharges';

function RenderNextChargesSection({ error, isLoading, nextCharges, classes }) {
  if (error) {
    return <div className={classes.redText}>Error getting next charges date</div>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <NextCharges nextCharges={nextCharges} />
      <Divider sx={{ marginTop: '24px' }} />
    </>
  );
}

RenderNextChargesSection.propTypes = {
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  nextCharges: PropTypes.array,
  classes: PropTypes.shape({
    redText: PropTypes.string,
  }),
};

export default function CustomerBillingDetails({ classes }) {
  const userRecord = useRecordContext();
  const { punks1457AdminCustomerBilling } = useFlags();
  const { data: nextCharges, isLoading, error } = useNextCharges(userRecord.id);

  const titleClassName = classNames(classes.title, {
    [classes.titleBilling]: punks1457AdminCustomerBilling,
  });
  return (
    <div className={classes.billingDetails}>
      <Typography className={titleClassName}>
        {punks1457AdminCustomerBilling ? 'Billing Information' : 'Billing Details'}
      </Typography>
      <div className={classes.billingDetailsRow}>
        <CardDetails paymentDetails={userRecord.payment_details} />

        {punks1457AdminCustomerBilling ? (
          <>
            <Card
              variant=''
              style={{ marginRight: '24px', padding: '4px 16px 14px 16px' }}
            >
              <Typography
                className={classes.label}
                style={{ paddingBottom: '12px', fontSize: '12px' }}
              >
                Billing Address
              </Typography>

              <Typography variant='body2'>
                {userRecord.billing_address.street_1}
              </Typography>

              {userRecord.billing_address.street_2 ? (
                <Typography variant='body2'>
                  {userRecord.billing_address.street_2}
                </Typography>
              ) : null}

              <Typography variant='body2'>
                {userRecord.billing_address.city},{' '}
                {userRecord.billing_address.state_province}{' '}
                {userRecord.billing_address.zipcode}{' '}
                {userRecord.billing_address.country}
              </Typography>
            </Card>

            <Card variant='' style={{ marginRight: '24px', padding: '4px 0 14px' }}>
              <Typography
                className={classes.label}
                style={{ paddingBottom: '12px', fontSize: '12px' }}
              >
                Billing Frequency
              </Typography>

              <Typography variant='body2'>
                {userRecord.is_charged_annually ? 'Annually' : 'Montly'}
              </Typography>
            </Card>
          </>
        ) : (
          <>
            <Card
              variant={punks1457AdminCustomerBilling ? '' : 'outlined'}
              style={{ marginRight: '24px', padding: '6px 16px 14px 16px' }}
            >
              <Typography
                className={classes.label}
                style={{ paddingBottom: '12px' }}
              >
                Billing Address
              </Typography>

              <Typography variant='body2'>
                {userRecord.billing_address.street_1}
              </Typography>

              {userRecord.billing_address.street_2 ? (
                <Typography variant='body2'>
                  {userRecord.billing_address.street_2}
                </Typography>
              ) : null}

              <Typography variant='body2'>
                {userRecord.billing_address.city},{' '}
                {userRecord.billing_address.state_province}{' '}
                {userRecord.billing_address.zipcode}{' '}
                {userRecord.billing_address.country}
              </Typography>
            </Card>
            <RenderNextChargesSection
              isLoading={isLoading}
              nextCharges={nextCharges}
              classes={classes}
              error={error}
            />
          </>
        )}
      </div>
      {punks1457AdminCustomerBilling ? (
        <RenderNextChargesSection
          isLoading={isLoading}
          nextCharges={nextCharges}
          classes={classes}
          error={error}
        />
      ) : null}
    </div>
  );
}

CustomerBillingDetails.propTypes = {
  classes: PropTypes.shape({
    billingDetails: PropTypes.string,
    billingDetailsRow: PropTypes.string,
    billingDetailsCard: PropTypes.string,
    title: PropTypes.string,
    titleBilling: PropTypes.string,
    redText: PropTypes.string,
    label: PropTypes.string,
  }),
};
