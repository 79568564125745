import { useEffect, useState } from 'react';
import { Create, TabbedForm, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { CustomTabbedForm } from '@pumpkincare/shared';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import CircularProgress from '../../../../shared/components/SanitizedComponents/CircularProgress';
import { getQuoteDetail, putCustomerQuote } from '../service/quoteFlowService';
import { getFullQuote } from '../utils/getFullQuote';
import CheckoutTab from './CheckoutTab';
import CustomerTab from './CustomerTab';
import NonConversionModal from './NonConversionModal';
import PetTab from './PetTab';
import QuoteTab from './QuoteTab';

const QuoteEdit = props => {
  const notify = useNotify();
  const { id } = useParams();

  const [discount, setDiscount] = useState();
  const [quote, setQuote] = useState({});
  const [pets, setPets] = useState([]);
  const [user, setUser] = useState({});
  const [quoteUrl, setQuoteUrl] = useState();
  const [showNonConversionModal, setShowNonConversionModal] = useState();
  const [isModalOpen, toggleModalOpen] = useBooleanInput(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (showNonConversionModal?.quote.id) {
      getQuoteDetail(showNonConversionModal.quote.id).then(response => {
        if (response.data.converted === true) {
          if (showNonConversionModal.event === 'SAVE') {
            putCustomerQuote(showNonConversionModal.quote)
              .then(() => {
                navigate('/quotes');
              })
              .catch(e => {
                notify(e.message, 'warning');
              });
          } else {
            navigate('/quotes');
          }
        } else {
          toggleModalOpen();
        }
      });
    }
  }, [showNonConversionModal]);

  useEffect(() => {
    getFullQuote(id).then(({ quote, quoteUrl }) => {
      setQuote(quote);
      setPets(quote.quote_pets);
      setQuoteUrl(quoteUrl);
      setDiscount(quote.organization);

      setInitialValues({
        first_name: quote.first_name,
        last_name: quote.last_name,
        policy_zipcode: quote.policy_zipcode,
        email: quote.email,
        has_vet: quote.vet ? 'YES' : 'NO',
        partner: quote.partner ? 'YES' : 'NO',
        partner_code: quote.partner_code ? 'YES' : 'NO',
        vet: quote.vet,
        pets:
          quote.quote_pets.length > 0
            ? quote.quote_pets.map(pet => ({
                pet_name: pet.name,
                pet_gender: pet?.gender,
                species: pet.species.toLowerCase(),
                pet_age: pet?.age,
                breed_code: pet?.breed_code,
              }))
            : [{ pet_name: '' }],
      });
    });
  }, [id]);

  return (
    <Create {...props}>
      <CustomTabbedForm toolbar={null} defaultValues={initialValues}>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              1
            </div>
          }
          label='User'
        >
          {!quote.id ? (
            <CircularProgress />
          ) : (
            <CustomerTab
              setQuote={setQuote}
              quote={quote}
              setDiscount={setDiscount}
              setShowNonConversionModal={setShowNonConversionModal}
              edit={true}
              setUser={setUser}
            />
          )}
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              2
            </div>
          }
          label='Pet'
        >
          {!quote.id ? (
            <CircularProgress />
          ) : (
            <PetTab
              quote={quote}
              setQuote={setQuote}
              setPets={setPets}
              setQuoteUrl={setQuoteUrl}
              pets={pets}
              discount={discount}
              setShowNonConversionModal={setShowNonConversionModal}
              edit={true}
              user={user}
            />
          )}
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              3
            </div>
          }
          label='Quote'
        >
          {!quote.id ? (
            <CircularProgress />
          ) : (
            <QuoteTab
              quote={quote}
              setQuote={setQuote}
              quoteUrl={quoteUrl}
              discount={discount}
              setShowNonConversionModal={setShowNonConversionModal}
              edit={true}
            />
          )}
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              4
            </div>
          }
          label='Checkout'
        >
          <CheckoutTab quote={quote} edit={true} />
        </TabbedForm.Tab>
        <NonConversionModal
          showDialog={isModalOpen}
          setShowDialog={toggleModalOpen}
          quote={quote}
        />
      </CustomTabbedForm>
    </Create>
  );
};

export default QuoteEdit;
