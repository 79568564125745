import { useEffect, useState } from 'react';
import { useNotify, useRefresh, useUnselectAll, useUpdateMany } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { cancellationReasons } from '../../constants/cancellationReasons';
import { cancellationStatuses } from '../../constants/cancellationStatuses';
import { getOpsUsers } from '../../shared/services/netServices';
import useStyles from './CancellationStyles';

function getStatusesOptions(showCompleted) {
  return cancellationStatuses.reduce(function (filtered, option) {
    if (option.id !== 'cancellation_completed' || showCompleted) {
      const item = (
        <MenuItem key={option.id} value={option.name}>
          {option.name}
        </MenuItem>
      );
      filtered.push(item);
    }
    return filtered;
  }, []);
}

const reasonsOptions = cancellationReasons.map(option => (
  <MenuItem key={option.id} value={option.name} disabled={option.disabled}>
    {option.name}
  </MenuItem>
));

export default function CancellationUpdateFormDialog({
  selectedIds,
  open,
  setOpen,
  showCompleted,
  showReason,
}) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [opsList, setOpsList] = useState([]);
  useEffect(() => {
    getOpsUsers().then(response => {
      const opsItems = response.map((item, i) => (
        <MenuItem key={i} value={item.id}>
          {item.email}
        </MenuItem>
      ));
      setOpsList(opsItems);
    });
  }, []);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany] = useUpdateMany();
  function handleDialogClose() {
    setData({});
    setOpen(false);
  }

  function handleConfirm() {
    updateMany(
      'cancellation-assignments/user',
      {
        ids: selectedIds,
        data: data,
      },
      {
        onSuccess: () => {
          refresh();
          notify('Cancellations updated', {});
          unselectAll('cancellation-assignments');
        },
        onError: () =>
          notify('Error: cancellations not updated', { type: 'warning' }),
      }
    );
    setOpen(false);
  }

  function handleChange(event) {
    const name = event.target.name;
    setData({
      ...data,
      [name]: event.target.value,
    });
  }
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle id='update-dialog'>Update cancellation</DialogTitle>
      <DialogContent>
        <form>
          <FormControl className={classes.formControl}>
            <InputLabel id={'status-select-label'}>Status</InputLabel>
            <Select
              labelId={'status-select-label'}
              id='status-select'
              name={'internal_cancellation_status'}
              value={
                data.internal_cancellation_status
                  ? data.internal_cancellation_status
                  : ''
              }
              onChange={handleChange}
            >
              <MenuItem key={'none'} value={''}>
                &nbsp;
              </MenuItem>
              {getStatusesOptions(showCompleted)}
            </Select>
          </FormControl>
          {showReason ? (
            <FormControl className={classes.formControl}>
              <InputLabel id={'reason-select-label'}>Reason</InputLabel>
              <Select
                labelId={'reason-select-label'}
                id='reason-select'
                name={'cancellation_reason'}
                value={data.cancellation_reason ? data.cancellation_reason : ''}
                onChange={handleChange}
              >
                <MenuItem key={'none'} value={''}>
                  &nbsp;
                </MenuItem>
                {reasonsOptions}
              </Select>
            </FormControl>
          ) : null}
          <FormControl className={classes.formControl}>
            <InputLabel id={'assigned-to-select-label'}>Assigned To</InputLabel>
            <Select
              labelId={'assigned-to-select-label'}
              id='assigned-to-select'
              name={'ops_user_id'}
              value={data.ops_user_id ? data.ops_user_id : ''}
              onChange={handleChange}
            >
              <MenuItem key={'none'} value={''}>
                &nbsp;
              </MenuItem>
              {opsList}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant='contained'
          style={{
            color: 'white',
            backgroundColor: '#3F51B5',
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
