import {
  Button,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import Typography from '@mui/material/Typography';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import RoutePaths from '../../../../routes';
import { AuditLogsLink } from '../../../audit-logs';
import FieldStatusChip from '../field-status-chip';
import petShowStyle from '../pet-show-style';

function PetPlansDatagrid(props) {
  const classes = petShowStyle();

  const { onPetCancelClick, ...rest } = props;

  const PetPlanTitle = () => {
    return (
      <div className={classes.spacing}>
        <Typography className={classes.title}>Plans</Typography>
      </div>
    );
  };

  return (
    <>
      <PetPlanTitle />

      <SimpleShowLayout className={classes.root} {...rest}>
        <ReferenceManyField
          label={''}
          reference={RoutePaths.petPlans}
          target='pet_id'
          sort={{ field: 'created_at', order: 'DESC' }}
        >
          <Datagrid
            sx={{
              '& .RaDatagrid-headerCell': {
                color: '#2D2D2D',
                backgroundColor: '#D9D9D9',
                textTransform: 'uppercase',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '16px',
                letterSpacing: '1.5px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              },
            }}
            bulkActionButtons={false}
            style={{ maxWidth: '830px' }}
          >
            <FieldStatusChip label='Status' />

            <TextField label='Cost' source='plan.cost' />

            <TextField label='Description' source='plan.description' />

            <FunctionField
              label='Effective Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone
                    source='plan_effective_date'
                    record={record}
                  />
                );
              }}
            />

            <FunctionField
              label='End Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone source='plan_end_date' record={record} />
                );
              }}
            />

            <TextField label='Is annual?' source='is_annualized_pet_plan' />

            <FunctionField
              label=''
              render={(record = {}) => {
                return <AuditLogsLink filter={{ pet_plan_id: record.id }} />;
              }}
            />

            <FunctionField
              label=''
              render={(record = {}) => {
                if (record.status === 'renew_pending') {
                  return (
                    <Button
                      style={{
                        color: '#D4424E',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                      variant='outlined'
                      onClick={() => onPetCancelClick(record)}
                      label='DECLINE'
                    />
                  );
                } else {
                  return null;
                }
              }}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
}
export default PetPlansDatagrid;
