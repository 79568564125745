import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { GENDER_MAP, ProcessingStep, SPECIES_MAP } from '@pumpkincare/shared';
import { useWellnessProgram } from '@pumpkincare/wellness';

import { appendBreedName } from '../../../../../shared/utils/petUtils';
import WellnessPlansOptions from '../../wellness-plans-options';
import {
  wellnessQuoteTabPlanSelectionStyles,
  wellnessQuoteTabPlanSelectionSx,
} from './wellness-quote-tab-plan-selection-styles';

function WellnessQuoteTabPlanSelection({ quote, onSelectPlan, onEditClick }) {
  const {
    data: { program_options: programOptions },
  } = useWellnessProgram({
    program_id: 'saw-pumpkin-0',
    state: 'NY', // hardcoded rn
    species: 'dog', // returns both rn
    age: '1', // age does not matter rn
  });

  function onChange(pet, option) {
    onSelectPlan(prevState => ({
      ...prevState,
      [pet.id]: option,
    }));
  }

  function onRemove(pet) {
    onSelectPlan(prevState => {
      const newState = { ...prevState };
      delete newState[pet.id];
      return newState;
    });
  }

  return (
    <div style={wellnessQuoteTabPlanSelectionStyles.root}>
      <div style={wellnessQuoteTabPlanSelectionStyles.content}>
        <ProcessingStep
          steps={['Details', 'Choose Plan', 'Checkout']}
          currentStep={2}
          style={wellnessQuoteTabPlanSelectionStyles.processingStep}
        />

        <Typography variant='subtitle2'>Customer Information</Typography>
        <Typography
          variant='body2'
          sx={wellnessQuoteTabPlanSelectionSx.customerInfo}
        >
          <span>
            {quote.first_name} {quote.last_name}
          </span>
          <span>{quote.email}</span>

          <IconButton
            aria-label='edit'
            sx={{ padding: 0, marginLeft: '-16px' }}
            onClick={onEditClick}
          >
            <EditIcon sx={{ color: '#356DED', width: '18px', height: '18px' }} />
          </IconButton>
        </Typography>

        {quote.quote_pets?.map(pet => (
          <Accordion
            key={pet.id}
            square
            defaultExpanded
            sx={wellnessQuoteTabPlanSelectionSx.accordion}
          >
            <AccordionSummary
              aria-controls={`Pet ${pet.name}`}
              id={`${pet.id}`}
              disableRipple
              expandIcon={<ExpandMoreIcon sx={{ fill: '#000000' }} />}
            >
              <div>
                <Typography variant='h5' sx={{ marginBottom: '8px' }}>
                  {pet.name}
                </Typography>
                <Typography variant='body1'>
                  {SPECIES_MAP[pet.species]} • {appendBreedName(pet)?.breed_name} •
                  Age {pet?.age} • {GENDER_MAP[pet?.gender] || 'N/A'}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant='h6'
                sx={wellnessQuoteTabPlanSelectionSx.plansTitle}
              >
                Pumpkin Wellness Club Plans
              </Typography>

              <WellnessPlansOptions
                programOptions={programOptions?.[pet.species]}
                wellnessPlans={{ options: pet.prices }}
                species={pet.species}
                isLoading={false}
                onChange={option => onChange(pet, option)}
                onRemove={() => onRemove(pet)}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

WellnessQuoteTabPlanSelection.defaultProps = {
  quote: {},
};

WellnessQuoteTabPlanSelection.propTypes = {
  quote: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    quote_pets: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.string,
        breed_name: PropTypes.string,
        gender: PropTypes.oneOf(['M', 'F']),
        name: PropTypes.string,
        species: PropTypes.oneOf(['dog', 'cat']),
        prices: PropTypes.object,
      })
    ),
  }),
  onSelectPlan: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default WellnessQuoteTabPlanSelection;
