import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function ACHType(props) {
  const { fundingSource, ...rest } = props;

  return (
    <Typography {...rest}>
      {`${
        fundingSource.account_type.charAt(0).toUpperCase() +
        fundingSource.account_type.slice(1)
      } ending in ${fundingSource.account_number}`}
    </Typography>
  );
}

ACHType.defaultProps = {
  fundingSource: {
    account_type: '',
    account_number: '',
  },
};

ACHType.propTypes = {
  fundingSource: PropTypes.shape({
    account_type: PropTypes.string,
    account_number: PropTypes.string,
  }),
};

export default ACHType;
