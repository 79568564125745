import { Typography } from '@mui/material';

import { REIMBURSEMENT_CANCELLATION_REASON } from '@pumpkincare/reimbursements';

import { parseAndFormatDate } from '../../../shared/utils';
import { reimbursementCancellationDetailStyle } from './reimbursement-cancellation-detail-style';

export default function ReimbursementCancellationDetail({ cancellations = [] }) {
  const classes = reimbursementCancellationDetailStyle();

  return cancellations.map(cancellation => (
    <div key={cancellation.created_at} className={classes.cancellationDiv}>
      <Typography className={classes.information}>
        {`CANCELLATION REQUESTED BY: ${cancellation.requestor_ops_user_name}`}
      </Typography>
      <Typography className={classes.information}>
        {`REQUEST DATE: ${parseAndFormatDate(cancellation.created_at)}`}
      </Typography>
      <Typography className={classes.information}>
        {`REASON: ${
          REIMBURSEMENT_CANCELLATION_REASON.find(
            reason => reason.id === cancellation.reason
          )?.name
        }`}
      </Typography>
      {cancellation.status === 'closed' ? (
        <Typography className={classes.cancellationDecision}>
          {`CANCELLATION ${cancellation.is_approved ? 'APPROVED' : 'DENIED'}`}
        </Typography>
      ) : null}

      {cancellation.status === 'closed' && !cancellation.is_approved ? (
        <Typography className={classes.information}>
          {`NOTES: ${cancellation.decision_note}`}
        </Typography>
      ) : null}
    </div>
  ));
}
