const ACTIVE = 'active';
const CANCELLED = 'cancelled';
const RENEW_PENDING = 'renew_pending';
const RENEW_PENDING_LABEL = 'renew pending';
const DECLINED = 'declined';

export const getPetPlanStatus = plan_status => {
  if (plan_status === ACTIVE) {
    return [
      { id: ACTIVE, name: ACTIVE },
      { id: CANCELLED, name: CANCELLED },
    ];
  } else if (plan_status === RENEW_PENDING) {
    return [
      { id: RENEW_PENDING, name: RENEW_PENDING_LABEL },
      { id: DECLINED, name: DECLINED },
    ];
  }
};
