import makeStyles from '@mui/styles/makeStyles';

const policyExpandableDatagridStyle = makeStyles(
  {
    colHeader: {
      color: '#2D2D2D',
      backgroundColor: '#EBECEE',
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  { name: 'policy-expandable-datagrid' }
);

export { policyExpandableDatagridStyle };
