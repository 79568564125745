import makeStyles from '@mui/styles/makeStyles';

const petShowStyle = makeStyles(
  {
    root: {
      padding: '0px !important',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },

    title: {
      color: '#007DFF',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },

    colHeader: {
      color: '#2D2D2D',
      backgroundColor: '#D9D9D9',
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },

    spacing: {
      marginTop: '20px',
      marginBottom: '20px',
    },

    petDetailContainer: {
      display: 'grid',
      gap: '20px 28px',
      gridTemplateColumns: '1fr 1fr',
      width: '25vw',
      minWidth: '240px',
      paddingTop: '16px',
    },

    petDetailTitle: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginBottom: '4px',
    },

    petDetailSubtitle: {
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      fontSize: '14px',
      letterSpacing: '0.25px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'capitalize',
      color: '#000000',
    },

    returnToShowCustomerLink: {
      textDecoration: 'none',
      display: 'flex',
      marginRight: '24px',
      marginBottom: '40px',
    },

    returnText: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
    },

    arrowBackIcon: {
      color: '#007DFF',
      width: '16px',
    },

    petPhoto: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  { name: 'pet-show' }
);

export default petShowStyle;
