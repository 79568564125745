import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import {
  formatAuditObject,
  formatRequestedRecordsText,
  MEDICAL_RECORD_REQUEST_OPEN,
  useMedicalRecordsRequest,
} from '@pumpkincare/medical-records';
import { AuditLog } from '@pumpkincare/shared';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import MarkReceivedModal from '../mark-received-modal';
import { viewMedicalRecordsRequestStyle } from './view-medical-records-request-style.js';

function ViewMedicalRecordsRequest({ requestId, onClose, onSuccess }) {
  const classes = viewMedicalRecordsRequestStyle();
  const {
    data: {
      vet_practice: vetPractice,
      clinic_name: clinicName,
      records,
      additional_owner: additionalOwner,
      additional_notes: additionalNotes,
      audit,
      status,
      contact_email: contactEmail,
    },
    isLoading,
  } = useMedicalRecordsRequest(requestId);

  const [isMarkReceivedModalOpen, toggleMarkReceivedModal] = useBooleanInput(false);

  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle variant='h5' className={classes.modalTitle}>
        Request Details (ID: {requestId})
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <div>
          <div className={classes.section}>
            <Typography variant='body2'>
              <b>Vet Clinic</b>
            </Typography>
            <div>
              <Typography variant='body2'>{clinicName}</Typography>
              {vetPractice ? (
                <Typography variant='body2'>
                  <span>{vetPractice.normalized_addr}</span>
                </Typography>
              ) : null}
              <Typography variant='body2'>
                <span>{contactEmail}</span>
              </Typography>
            </div>
          </div>

          {additionalOwner ? (
            <div className={classes.section}>
              <Typography variant='body2'>
                <b>Additional Owner</b>
              </Typography>
              <div>
                <Typography variant='body2'>{additionalOwner}</Typography>
              </div>
            </div>
          ) : null}

          <div className={classes.section}>
            <Typography variant='body2'>
              <b>Requested Records</b>
            </Typography>
            <div>
              {formatRequestedRecordsText(records).map(record => (
                <Typography key={record.description || record.type} variant='body2'>
                  {record.text}
                </Typography>
              ))}
            </div>
          </div>

          {additionalNotes ? (
            <div className={classes.section}>
              <Typography variant='body2'>
                <b>Additional Note</b>
              </Typography>
              <div>
                <Typography variant='body2'>{additionalNotes}</Typography>
              </div>
            </div>
          ) : null}
        </div>

        <div className={classes.audit}>
          {isLoading ? (
            <CircularProgress size={16} className={classes.loader} />
          ) : (
            <AuditLog
              auditLog={audit.map(auditItem =>
                formatAuditObject(auditItem, {
                  isDescription: true,
                  dateFormat: 'MMMM Do YYYY, h:mm a (Z)',
                })
              )}
            />
          )}
        </div>
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.modalActions,
          spacing: classes.unsetSpacing,
        }}
      >
        <Button
          onClick={onClose}
          className={classes.cancelButton}
          variant='outlined'
          color='primary'
        >
          Close
        </Button>
        <Button
          className={classes.confirmButton}
          variant='contained'
          color='primary'
          disabled={status !== MEDICAL_RECORD_REQUEST_OPEN}
          onClick={toggleMarkReceivedModal}
        >
          Mark Received
        </Button>
      </DialogActions>

      {isMarkReceivedModalOpen ? (
        <MarkReceivedModal
          requestId={requestId}
          onClose={toggleMarkReceivedModal}
          onSuccess={onSuccess}
        />
      ) : null}
    </Dialog>
  );
}

ViewMedicalRecordsRequest.propTypes = {
  requestId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ViewMedicalRecordsRequest;
