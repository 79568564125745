import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTimeout } from 'ra-core';

import { renderFakeLines } from '@pumpkincare/claims';

import Placeholder from '../place-holder';

const theme = createTheme();

const useStyles = makeStyles(
  {
    primary: {
      width: '30vw',
      display: 'inline-block',
      marginBottom: theme.spacing(),
    },
    tertiary: { float: 'right', opacity: 0.541176, minWidth: '10vw' },
  },
  { name: 'RaSimpleListLoading' }
);

function SimpleListLoading(props) {
  const {
    className,
    hasSecondaryText,
    hasTertiaryText,
    nbFakeLines = 5,
    ...rest
  } = props;
  const classes = useStyles(props);
  const oneSecondHasPassed = useTimeout(1000);

  return oneSecondHasPassed ? (
    <List className={className} {...rest}>
      {renderFakeLines(nbFakeLines, key => (
        <ListItem key={key}>
          <ListItemText
            primary={
              <div>
                <Placeholder className={classes.primary} />
                {hasTertiaryText ? (
                  <span className={classes.tertiary}>
                    <Placeholder />
                  </span>
                ) : null}
              </div>
            }
            secondary={hasSecondaryText ? <Placeholder /> : null}
          />
        </ListItem>
      ))}
    </List>
  ) : null;
}

SimpleListLoading.propTypes = {
  className: PropTypes.string,
  hasSecondaryText: PropTypes.bool,
  hasTertiaryText: PropTypes.bool,
  nbFakeLines: PropTypes.number,
};

export default SimpleListLoading;
