import {
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from 'react-admin';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  {
    margin: {
      margin: '10px auto',
      display: 'block',
    },
  },
  { name: 'login-form' }
);

export function buildAuthProvider(functionMap) {
  return {
    login: params => functionMap[AUTH_LOGIN](params),
    logout: params => functionMap[AUTH_LOGOUT](params),
    checkAuth: params => functionMap[AUTH_CHECK](params),
    checkError: () => Promise.resolve(),
    getPermissions: () => functionMap[AUTH_GET_PERMISSIONS](),
    getIdentity: () => functionMap['AUTH_GET_IDENTITY'](),
  };
}

export function buildLoginForm(onClickHandler) {
  function LoginForm() {
    const classes = useStyles();
    const handleClick = () => {
      return (() => {
        onClickHandler();
      })();
    };
    return (
      <Button
        variant='contained'
        color='primary'
        size='large'
        className={classes.margin}
        onClick={handleClick}
      >
        Login
      </Button>
    );
  }
  return LoginForm;
}
