import { useState } from 'react';
import { Create, Labeled, required, SelectInput, SimpleForm } from 'react-admin';
import { useLocation } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import {
  useCustomer,
  usePetAvailablePlans,
  usePolicyByPetId,
} from '@pumpkincare/users';

import RoutePaths from '../../routes';
import Tile from '../../shared/components/Tile';
import { formatAgeRange, parseAndFormatDate } from '../../shared/utils';
import PetVet from './PetVet';

const useStyles = makeStyles(
  {
    planItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      whiteSpace: 'normal',
      paddingTop: '18px',
      paddingBottom: '18px',
    },
    planVersion: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    planTitle: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '4px',
    },
    planDetails: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
      marginTop: '8px',
    },
    menu: {
      padding: '0px !important',
      maxWidth: '472px',
    },
  },
  { name: 'mga-pet-plan-create' }
);

function MgaPetPlanCreate() {
  const location = useLocation();
  const classes = useStyles();
  const { pet } = location.state;
  const [plan, setPlan] = useState({});

  const redirect = `/${RoutePaths.customers}/${pet?.user?.id}/show`;
  const { data: userData } = useCustomer(pet?.user_id);
  const {
    data: availablePlans = [],
    isLoading,
    refetch,
  } = usePetAvailablePlans(pet?.id);

  const { data = [] } = usePolicyByPetId(pet?.id);

  const policies = data
    .filter(policy => policy.status === 'active')
    .map(policy => ({
      id: policy.policy_effective_date,
      name: `${parseAndFormatDate(policy.policy_effective_date)} - ${policy.status}`,
    }));

  const address = userData ? userData.rating_address : {};

  function loadPlans() {
    refetch();
  }

  function createItem(plan) {
    return (
      <MenuItem key={plan.id} value={plan} className={classes.planItem}>
        <Typography className={classes.planVersion}>
          PREVENT {plan.version_number.toFixed(1)}
        </Typography>
        <Typography className={classes.planTitle}>
          {plan.name} {formatAgeRange(plan.min_age_months, plan.max_age_months)}
        </Typography>
        <Typography className={classes.planDetails}>
          {plan.plan_definitions
            ?.map(def => `${def.quantity} ${def.details}`)
            .join(', ')}
        </Typography>
        <Typography className={classes.planDetails}>
          Cost: ${plan.cost?.toFixed(2)}
        </Typography>
      </MenuItem>
    );
  }

  return (
    <Create
      redirect={redirect}
      transform={data => {
        return {
          ...data,
          plan_id: plan.id,
          pet_id: pet?.id,
        };
      }}
    >
      <SimpleForm validateOnBlur={true}>
        <Tile
          title={'Customer Name'}
          content={`${userData?.first_name} ${userData?.last_name}`}
          style={{ width: '472px' }}
        />

        <Tile
          title={'Pet Name'}
          content={pet?.name}
          style={{ width: '472px', marginTop: '36px' }}
        />

        <div style={{ width: '472px', marginTop: '36px' }}>
          {address ? (
            <PetVet petId={pet?.id} onChange={loadPlans} address={address} />
          ) : null}
        </div>

        <div
          style={{
            width: '472px',
            minHeight: '84px',
          }}
        >
          {isLoading === true ? (
            <CircularProgress />
          ) : (
            <Labeled label='PREVENT PLAN'>
              <Select
                source='plan_id'
                onChange={event => setPlan(event.target.value)}
              >
                {availablePlans.map(createItem)}
              </Select>
            </Labeled>
          )}
        </div>

        <Tile
          title={'Effective Date'}
          content={
            <SelectInput
              variant='outlined'
              label=''
              source='effective_date'
              choices={policies}
              validate={required()}
              required={true}
            />
          }
          style={{ width: '472px', marginTop: '24px', marginBottom: '18px' }}
        />

        <div style={{ display: 'flex', color: 'red', width: '750px' }}>
          <InfoOutlinedIcon style={{ marginRight: '4px' }} />
          <Typography>
            <b>Reminder</b>: Monthly fees for pet plans will automatically be charged
            going forward on the next billing date. You must manually charge any pet
            plan fees due to Pumpkin for past billing cycles separately.
          </Typography>
        </div>
      </SimpleForm>
    </Create>
  );
}

export default MgaPetPlanCreate;
