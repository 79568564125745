import makeStyles from '@mui/styles/makeStyles';

const claimSubmissionInformationStyle = makeStyles(
  {
    submissionDetail: { marginBottom: '24px', width: '93%' },
    header: {
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: '500',
      color: '#2196F3',
      marginTop: '48px',
      marginBottom: '24px',
    },
    titleWrapper: { display: 'flex', justifyContent: 'space-between' },
    title: {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
      fontWeight: 'normal',
      color: '#2D2D2D',
    },
    detail: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  { name: 'claim-submission-information' }
);

export { claimSubmissionInformationStyle };
