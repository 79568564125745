import { getObjectByStatus } from './petUtils.js';

export const STATUSES = {
  ACTIVE: 'active',
  RENEW_PENDING: 'renew_pending',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  VOIDED: 'voided',
  DECLINED: 'declined',
  PENDING: 'pending',
  LAPSED: 'lapsed',
  CANCEL_PENDING: 'cancel_pending',
};

export function getPetPlansStatusOptions(pet) {
  const pet_plans_option = createOptions(pet);
  return pet_plans_option.sort((a, b) => a.name.localeCompare(b.name));
}

export function isPetPlanInCancelledStatus(pet_plan_status) {
  return [
    STATUSES.CANCELLED,
    STATUSES.EXPIRED,
    STATUSES.VOIDED,
    STATUSES.DECLINED,
  ].some(plan_status => plan_status === pet_plan_status);
}

function createOptions(pet) {
  return pet.plans.reduce((acc, plan) => {
    if (plan.status === STATUSES.RENEW_PENDING) {
      acc.push({ id: plan.status, name: 'Renewal Prevent Plan' });
    } else if (plan.status === STATUSES.ACTIVE) {
      acc.push({ id: plan.status, name: 'Active Prevent Plan' });
    }
    return acc;
  }, []);
}

export function getPetPlanByStatus(pet, status) {
  return pet.plans.filter(plan => plan.status === status)[0];
}

/*
 * These is a specific PetPlanEdit page function.
 * When editing the petPlan, dates for PEP should be associated
 * with a policy effective dates but still editable fields.
 * The fallback in case the policy does not exists, is to set
 * the original PetPlan effective_date.
 */
export function getDefaultPetPlanEffectiveDate(pet, record) {
  const policy = getObjectByStatus(pet, 'policies', record.status);
  return policy.policy_effective_date || record.plan_effective_date;
}

/*
 * These is a specific PetPlanEdit page function.
 * When editing the petPlan, dates for PEP should be associated
 * with a policy effective dates but still editable fields.
 * The fallback in case the policy does not exists, is to set
 * the original PetPlan end_date.
 */
export function getDefaultPetPlanEndDate(pet, record) {
  const policy = getObjectByStatus(pet, 'policies', record.status);
  return policy.policy_end_date || record.plan_end_date;
}
