import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import { getCancellationByUserID } from '../../services/netServices';
import { formatCurrency } from '../../utils/currencyUtils';
import { useStyles } from './CancellationRefund.styles';

function CancellationRefund({ userId }) {
  const [cancellations, setCancellations] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getCancellationByUserID(userId).then(response => {
      if (response?.cancellation_assignments) {
        setCancellations(response.cancellation_assignments);
      }
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      {cancellations.map((cancellation, i) => (
        <div key={String(i)} className={classes.item}>
          <Typography component='h6' className={classes.name}>
            {cancellation.pet.name}:
          </Typography>
          <div style={{ display: 'flex' }}>
            {cancellation.insurance_refund ? (
              <div style={{ marginRight: '20px' }}>
                <Typography className={classes.title}>INSURANCE REFUND:</Typography>
                <Typography className={classes.price}>
                  {cancellation.insurance_refund > 0
                    ? formatCurrency(cancellation.insurance_refund)
                    : formatCurrency(0)}
                </Typography>
              </div>
            ) : null}

            {cancellation.outstanding_balance ? (
              <div>
                <Typography className={classes.title}>
                  PREVENT{' '}
                  {cancellation.outstanding_balance < 0
                    ? 'REFUND'
                    : 'OUTSTANDING BALANCE'}
                  :
                </Typography>
                <Typography className={classes.price}>
                  {formatCurrency(Math.abs(cancellation.outstanding_balance))}
                </Typography>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CancellationRefund;
