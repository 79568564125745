import makeStyles from '@mui/styles/makeStyles';

const entitlementTableStyle = makeStyles(
  {
    table: {
      width: 'calc(100% - 16px)',
      margin: '16px auto 2px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
    },

    cell: {
      textAlign: 'center',
      minWidth: '44px',
      paddingBottom: '10px',
      paddingTop: '10px',
      '&:first-child': {
        textAlign: 'left',
      },
    },

    row: {
      height: '34px',
      borderTop: '1px solid #B9B9B9',
      borderBottom: '1px solid #B9B9B9',
      '&:last-child': {
        borderBottom: '0px',
      },
    },

    entitlementHeader: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#007DFF',
    },

    entitlementValue: {
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '12px',
      color: '#000000',
    },
  },
  { name: 'entitlement-table' }
);

export { entitlementTableStyle };
