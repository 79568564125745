import { useEffect, useState } from 'react';
import { Create, TabbedForm, useNotify } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomTabbedForm } from '@pumpkincare/shared';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import { getQuoteDetail, putCustomerQuote } from '../service/quoteFlowService';
import CheckoutTab from './CheckoutTab';
import CustomerTab from './CustomerTab';
import NonConversionModal from './NonConversionModal';
import PetTab from './PetTab';
import QuoteTab from './QuoteTab';

const QuoteCreate = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const { quote: routerQuote } = routerState || {};

  const [discount, setDiscount] = useState();
  const [quote, setQuote] = useState({});
  const [pets, setPets] = useState([]);
  const [user, setUser] = useState({});
  const [quoteUrl, setQuoteUrl] = useState();
  const [showNonConversionModal, setShowNonConversionModal] = useState();
  const [isModalOpen, toggleModalOpen] = useBooleanInput(false);

  useEffect(() => {
    if (showNonConversionModal?.quote.id) {
      getQuoteDetail(showNonConversionModal.quote.id).then(response => {
        if (response.data.converted === true) {
          if (showNonConversionModal.event === 'SAVE') {
            putCustomerQuote(showNonConversionModal.quote)
              .then(() => {
                navigate('/quotes');
              })
              .catch(e => {
                notify(e.message, 'warning');
              });
          } else {
            navigate('/quotes');
          }
        } else {
          toggleModalOpen();
        }
      });
    }
  }, [showNonConversionModal]);

  /*
  if a user is coming to INS quote from SAW quote ExistingCustomerModal, setQuote
  this mimics the setQuote in CustomerTab handleAddPetClick
   */
  useEffect(() => {
    if (routerQuote && !quote.id) setQuote(routerQuote);
  }, [routerQuote, quote]);

  return (
    <Create>
      <CustomTabbedForm
        toolbar={null}
        defaultValues={{
          pets: [{ pet_name: '' }],
          partner: 'NO',
          partner_code: 'NO',
        }}
      >
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              1
            </div>
          }
          label='User'
        >
          <CustomerTab
            setQuote={setQuote}
            quote={quote}
            setDiscount={setDiscount}
            setShowNonConversionModal={setShowNonConversionModal}
            setUser={setUser}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              2
            </div>
          }
          label='Pet'
        >
          <PetTab
            quote={quote}
            setQuote={setQuote}
            setPets={setPets}
            setQuoteUrl={setQuoteUrl}
            pets={pets}
            discount={discount}
            setShowNonConversionModal={setShowNonConversionModal}
            user={user}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              3
            </div>
          }
          label='Quote'
        >
          <QuoteTab
            quote={quote}
            setQuote={setQuote}
            quoteUrl={quoteUrl}
            discount={discount}
            setShowNonConversionModal={setShowNonConversionModal}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          icon={
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#5C6BC0',
                color: 'white',
                width: '18px',
              }}
            >
              4
            </div>
          }
          label='Checkout'
        >
          <CheckoutTab quote={quote} />
        </TabbedForm.Tab>
        <NonConversionModal
          showDialog={isModalOpen}
          setShowDialog={toggleModalOpen}
          quote={quote}
        />
      </CustomTabbedForm>
    </Create>
  );
};

export default QuoteCreate;
