import { useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { nonConversionReasons } from '../../../../constants/nonConversionReason';
import { putCustomerQuote } from '../service/quoteFlowService';

function NonConversionModal(props) {
  const { showDialog, setShowDialog, quote } = props;
  const notify = useNotify();
  const navigate = useNavigate();

  const [reasonForNonConversion, setReasonForNonConversion] = useState('');

  const [notes, setNotes] = useState('');

  const reasonForNonConversionOptions = nonConversionReasons.map(reason => (
    <MenuItem key={reason.id} value={reason.id}>
      {reason.name}
    </MenuItem>
  ));

  function handleReasonForNonConversionChange(event) {
    setReasonForNonConversion(event.target.value);
  }

  function onSaveButtonClick() {
    const quoteAux = {
      ...quote,
      comment: notes,
      converted: false,
      non_conversion_reason: reasonForNonConversion,
    };

    putCustomerQuote(quoteAux)
      .then(() => {
        navigate('/quotes');
      })
      .catch(e => {
        setShowDialog(false);
        notify(e.message, 'warning');
      });
  }

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      aria-label='Non Conversion Quote'
    >
      <div
        style={{
          width: '500px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '500',
            color: 'black',
          }}
        >
          Conversion
        </Typography>
        <IconButton
          style={{
            color: 'black',
          }}
          onClick={() => setShowDialog(false)}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ display: 'inline-grid' }}>
        <Typography>Please choose a reason for non-conversion.</Typography>
        <Select
          label='Select Reason'
          value={reasonForNonConversion}
          onChange={handleReasonForNonConversionChange}
          fullWidth={true}
        >
          {reasonForNonConversionOptions}
        </Select>

        <div style={{ margin: '16px 0px' }}>
          <TextField
            label='Notes (Optional) '
            multiline
            fullWidth
            variant={'outlined'}
            rows={8}
            inputProps={{ maxLength: 500 }}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onSaveButtonClick} variant='contained' color='primary'>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NonConversionModal;
