import { useRecordContext } from 'react-admin';
import { CircularProgress, Typography } from '@mui/material';

import { getModifiedBy } from '@pumpkincare/claims';
import { useClaimAuditLogV2 } from '@pumpkincare/claims';
import { TabPanel } from '@pumpkincare/shared';

import { getDateAsFormattedLocalTZ } from '../../../shared/utils';
import { claimAuditLogTabStyle } from './claim-audit-log-tab-style';

export default function ClaimAuditLogTab({ tabValue, opsUserList }) {
  const classes = claimAuditLogTabStyle();
  const claimRecord = useRecordContext();

  const { data: auditLogData = [], isLoading } = useClaimAuditLogV2(claimRecord?.id);

  return (
    <TabPanel value={tabValue} index={1}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ position: 'relative' }}>
          {(!Array.isArray(auditLogData) && auditLogData?.addedFakeId
            ? []
            : auditLogData
          ).map((auditLog, index) => {
            return (
              <div key={index} className={classes.auditLog}>
                {index + 1 !== auditLogData.length ? (
                  <div className={classes.dashLine}></div>
                ) : null}

                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.circle}
                    style={{
                      background:
                        index + 1 !== auditLogData.length ? '#C4C4C4' : '#2196F3',
                    }}
                  ></div>
                  <Typography className={classes.auditLogEvent}>
                    {auditLog.context}
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: '28px',
                    marginBottom: '12px',
                  }}
                >
                  <Typography className={classes.auditLogDetail}>
                    {auditLog.description}
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: '28px',
                    marginBottom: '20px',
                  }}
                >
                  <Typography className={classes.auditLogDetail}>
                    {`${getModifiedBy(
                      auditLog.modified_by,
                      opsUserList
                    )} - ${getDateAsFormattedLocalTZ(
                      auditLog.created_at,
                      'MMMM Do YYYY, h:mm a (Z)'
                    )}`}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </TabPanel>
  );
}
