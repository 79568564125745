import { CreateButton, useRecordContext } from 'react-admin';

export default function AddClaimAttachmentButton({ routes, disableButton }) {
  const record = useRecordContext();

  const claimRecord = {
    claim_id: record.id,
    user_id: record.customer_id,
    pet_id: record.pet_id,
    vet_id: record.vet_id,
  };

  return (
    <CreateButton
      disabled={disableButton}
      label='ADD CLAIM ATTACHMENTS'
      resource={`/${routes.documentsV2}/create`}
      state={{ record: claimRecord }}
    />
  );
}
