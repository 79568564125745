export const PRODUCT_STATUS_COLORS = {
  ACTIVE: {
    name: 'Active',
    color: '#3EC28F',
    textColor: '#000000',
  },
  LAPSED: {
    name: 'Lapsed',
    color: '#F87D23',
    textColor: '#000000',
  },
  PENDING: {
    name: 'Pending',
    color: '#AEE5D0',
    textColor: '#000000',
  },
  RENEW_PENDING: {
    name: 'Renew Pending',
    color: '#AEE5D0',
    textColor: '#000000',
  },
  CANCEL_PENDING: {
    name: 'Cancel Pending',
    color: '#DC9399',
    textColor: '#000000',
  },
  CANCELLED: {
    name: 'Cancelled',
    color: '#D4424E',
    textColor: '#000000',
  },
  EXPIRED: {
    name: 'Expired',
    color: '#00000061',
    textColor: '#000000',
  },
  DECLINED: {
    name: 'Declined',
    color: '#00000061',
    textColor: '#000000',
  },
  VOIDED: {
    name: 'Voided',
    color: '#00000061',
    textColor: '#000000',
  },
  UPDATED: {
    name: 'Updated',
    color: '#AEE5D0',
    textColor: '#000000',
  },
};
