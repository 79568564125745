import { useState } from 'react';
import { Create, SimpleForm, useCreate, useNotify, useRedirect } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Add from '@mui/icons-material/Add';
import { Button, CircularProgress, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import { slugify } from '@pumpkincare/shared';
import { useDiscounts } from '@pumpkincare/users/discounts-query';

import RoutePaths from '../../../routes';
import { OrganizationsConfirmModal } from '../organizations-confirm-modal';
import { CreateTitle, OrganizationsForm } from '../organizations-form';
import { OrganizationsCreateStyles } from './organizations-create.styles';

function OrganizationsCreateToolbar({ resource, ...props }) {
  const [create, { isLoading }] = useCreate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { getValues } = useFormContext();

  function handleOrgSave(formData) {
    const data = {};
    Object.keys(formData).forEach(key => {
      if (formData && typeof formData[key] !== 'undefined') {
        data[key] = formData[key];
      }
    });

    create(
      resource,
      { data: { ...data } },
      {
        onSuccess: () => {
          setOpen(false);
          notify('ra.notification.created', { type: 'success' });
          redirectTo('list', RoutePaths.organizations);
        },
        onError: error => {
          const errorTxt =
            typeof error === 'string'
              ? error
              : error?.message || 'ra.notification.http_error';
          setOpen(false);
          notify(errorTxt, { type: 'warning' });
        },
      }
    );
  }

  function handleDialogClick() {
    setOpen(true);
  }

  function handleDialogClose(e) {
    setOpen(false);
    e.stopPropagation();
  }

  const orgData = getValues();

  if (orgData.name) {
    orgData.pass_code = slugify(orgData.name);
  }

  return (
    <Toolbar {...props}>
      <Button variant='contained' color='primary' onClick={handleDialogClick}>
        <Add /> Add Discount
      </Button>
      <OrganizationsConfirmModal
        isOpen={open}
        loading={isLoading}
        handleOnClose={handleDialogClose}
        handleOnConfirm={() => handleOrgSave(orgData)}
        formData={orgData}
      />
    </Toolbar>
  );
}

export function OrganizationsCreate() {
  const classes = OrganizationsCreateStyles();
  const { data: discounts, isLoading, error } = useDiscounts();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography className={classes.redText} component='p'>
        Error fetching discounts data
      </Typography>
    );
  }

  return (
    <>
      <CreateTitle className={classes.defaultBg} />
      <Create className={classnames(classes.defaultBg, classes.defaultContainer)}>
        <SimpleForm
          redirect='list'
          toolbar={
            <OrganizationsCreateToolbar resource={RoutePaths.organizations} />
          }
        >
          <OrganizationsForm discounts={discounts} />
        </SimpleForm>
      </Create>
    </>
  );
}
