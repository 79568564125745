import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';

import { COUNTRIES_LIST, stateList } from '@pumpkincare/shared';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import { countryToFlag } from '../utils/quoteUtils';
import { paymentFormStyle } from './PaymentFormStyle';

function AddressInput(props) {
  const {
    className,
    isShipping = false,
    onChange,
    style,
    quote,
    defaultAddress = {},
  } = props;

  const styles = paymentFormStyle();
  const [isUsAddress, setIsUsAddress] = useBooleanInput(
    defaultAddress?.country === 'US'
  );
  const addressInputClassName = classNames(
    styles.inputGroup,
    styles.border,
    className
  );
  const textInputWithBottomBorderClassName = classNames(
    styles.textInput,
    styles.borderBottom
  );

  function handleFieldChangeFactory(field) {
    return (e, newValue) =>
      onChange({ field, value: field === 'country' ? newValue.id : e.target.value });
  }

  function handleCountryChangeFactory(field, newValue) {
    setIsUsAddress(newValue.id === 'US');
    onChange({ field, value: newValue.id });
  }

  return (
    <>
      {!isShipping ? (
        <Autocomplete
          id='billing-country'
          disableClearable
          style={{ width: 300, padding: '24px 0px 8px 0px' }}
          options={COUNTRIES_LIST}
          autoHighlight
          getOptionLabel={option => option.name}
          renderOption={(props, option) => (
            <div {...props} key={option.id}>
              <span>{countryToFlag(option.id)}</span>
              {option.name}
            </div>
          )}
          onChange={(e, newValue) => handleCountryChangeFactory('country', newValue)}
          defaultValue={COUNTRIES_LIST.find(
            country => country.id === defaultAddress.country
          )}
          renderInput={params => (
            <TextField
              {...params}
              label='Country'
              variant='outlined'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      ) : null}
      <div className={addressInputClassName} style={style}>
        <input
          type={'text'}
          className={textInputWithBottomBorderClassName}
          onChange={handleFieldChangeFactory('address1')}
          placeholder={'Address'}
          defaultValue={defaultAddress.street_1}
        />

        <input
          type={'text'}
          className={textInputWithBottomBorderClassName}
          onChange={handleFieldChangeFactory('address2')}
          placeholder={'Address line 2'}
          defaultValue={defaultAddress.street_2}
        />

        <div className={styles.borderBottom}>
          <input
            type={'text'}
            className={classNames(styles.textInput, styles.borderRight)}
            onChange={handleFieldChangeFactory('city')}
            placeholder={'City'}
            style={{ width: '50%' }}
            defaultValue={defaultAddress.city}
          />

          <input
            type={'text'}
            className={styles.textInput}
            disabled={isShipping}
            onChange={handleFieldChangeFactory('zipcode')}
            style={
              isShipping
                ? { width: '50%', border: 'none', backgroundColor: '#EEEFF1' }
                : { width: '50%', border: 'none' }
            }
            placeholder={'Zip'}
            defaultValue={quote.policy_zipcode}
          />
        </div>
        {isUsAddress && !isShipping ? (
          <select
            data-testid='select-option'
            onChange={handleFieldChangeFactory('state')}
            className={styles.selectInput}
            defaultValue={quote.policy_state}
          >
            <option value='' disabled selected>
              State
            </option>
            {stateList.map(state => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={'text'}
            className={styles.textInput}
            disabled={isShipping}
            style={
              isShipping
                ? { border: 'none', backgroundColor: '#EEEFF1' }
                : { border: 'none' }
            }
            onChange={handleFieldChangeFactory('state')}
            placeholder={'State'}
            defaultValue={quote.policy_state}
          />
        )}
      </div>
    </>
  );
}

export default AddressInput;
