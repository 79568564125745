import { FormDataConsumer, SelectInput, TextInput } from 'react-admin';
import { Grid, InputLabel } from '@mui/material';

import {
  orgTypeChoices,
  vetAssociationStates,
} from '../../../constants/organizations-constants';
import { OrganizationsCreateStyles } from '../organizations-create/organizations-create.styles';
import {
  NumberOfEmployeesField,
  OrganizationAddress,
  SalesforceIdField,
  VetAssociationField,
} from './components';

export function OrganizationsForm({ discounts }) {
  const classes = OrganizationsCreateStyles();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const isVetDiscount = formData?.org_type === orgTypeChoices[0].id;

        return (
          <>
            <div className={classes.mt2}>
              <InputLabel shrink>Discount Type</InputLabel>
              <SelectInput
                variant='outlined'
                source='org_type'
                choices={orgTypeChoices}
                label={false}
                className={classes.defaultHeight}
              />
            </div>
            <div className={classes.mt2}>
              <InputLabel shrink>Discount</InputLabel>
              <SelectInput
                variant='outlined'
                source='discount_code'
                choices={Object.values(discounts)}
                optionText='label'
                optionValue='code'
                label={false}
                className={classes.defaultHeight}
              />
            </div>
            <Grid container spacing={4} fullWidth>
              <Grid item xs={12} md={5}>
                <InputLabel shrink>
                  {isVetDiscount ? 'Vet Clinic' : 'Organization'} Name
                </InputLabel>
                <TextInput
                  variant='outlined'
                  source='name'
                  label={false}
                  fullWidth
                  className={classes.defaultHeight}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <InputLabel shrink>
                  {isVetDiscount ? 'Vet Clinic' : 'Organization'} Website
                </InputLabel>
                <TextInput
                  variant='outlined'
                  source='website'
                  label={false}
                  fullWidth
                  className={classes.defaultHeight}
                />
              </Grid>
              {isVetDiscount ? (
                <>
                  <SalesforceIdField />
                  {(formData.num_of_employees = undefined)}
                </>
              ) : (
                <>
                  <NumberOfEmployeesField />
                  {(formData.salesforceid = undefined)}
                </>
              )}
            </Grid>
            <OrganizationAddress
              name={isVetDiscount ? 'Vet Clinic Address' : 'Organization Address'}
            />
            {isVetDiscount &&
            formData?.state &&
            vetAssociationStates.includes(formData?.state) ? (
              <VetAssociationField />
            ) : (
              (formData.association = undefined)
            )}
          </>
        );
      }}
    </FormDataConsumer>
  );
}
