import { Amplify, Auth, Hub } from 'aws-amplify';

import { AUTH_SETTINGS, BLOCKED_PAGES } from '@pumpkincare/config';

function clearLocalStorage() {
  localStorage.removeItem('@pumpkincare/auth/groups');
  localStorage.removeItem('@pumpkincare/auth/user');
}

function cognitoLogin() {
  Auth.federatedSignIn();
}

function cognitoAuthCheck() {
  return Auth.currentSession()
    .then(() => {
      return Promise.resolve();
    })
    .catch(() => {
      clearLocalStorage();
      if (BLOCKED_PAGES.some(page => window.location.pathname.indexOf(page) > -1)) {
        window.history.pushState(null, null, '/login');
        window.location.reload();
      }
      return Promise.reject({ message: false });
    });
}

function cognitoGetIdentity() {
  const { fullName, cognitoId, email } =
    JSON.parse(localStorage.getItem('@pumpkincare/auth/user')) || {};
  return Promise.resolve({ id: cognitoId, fullName: fullName, email: email });
}

function cognitoUserPoolGroups() {
  const { cognitoGroups } =
    JSON.parse(localStorage.getItem('@pumpkincare/auth/groups')) || {};
  return cognitoGroups || [];
}

function cognitoGetCurrentUser() {
  const { email } = JSON.parse(localStorage.getItem('@pumpkincare/auth/user')) || {};
  return { email: email };
}

function cognitoLogout() {
  clearLocalStorage();
  return Auth.signOut().then(() => {
    return Promise.resolve();
  });
}

function cognitoGetPermissions() {
  const { currentCognitoGroup } =
    JSON.parse(localStorage.getItem('@pumpkincare/auth/groups')) || {};
  return Promise.resolve(currentCognitoGroup);
}

//TODO - Wrap this whole file in a function

if (!AUTH_SETTINGS.useOidcLib) {
  Amplify.configure({
    Auth: AUTH_SETTINGS,
  });

  Hub.listen('auth', data => {
    if (data.channel === 'auth' && data.payload.event === 'signIn') {
      Auth.currentAuthenticatedUser().then(authenticatedUser => {
        const { payload } = authenticatedUser.signInUserSession.idToken;
        localStorage.setItem(
          '@pumpkincare/auth/user',
          JSON.stringify({
            cognitoId: payload.sub,
            email: payload.email,
            fullName: `${payload.name} ${payload.family_name}`,
          })
        );

        const groupsArray = payload['cognito:groups'];
        if (groupsArray.length > 0) {
          const { currentCognitoGroup: currentGroup } =
            JSON.parse(localStorage.getItem('@pumpkincare/auth/groups')) || {};
          let currentCognitoGroup = groupsArray[0];
          if (currentGroup && groupsArray.find(group => group === currentGroup)) {
            currentCognitoGroup = currentGroup;
          }

          localStorage.setItem(
            '@pumpkincare/auth/groups',
            JSON.stringify({
              cognitoGroups: groupsArray,
              currentCognitoGroup: currentCognitoGroup,
            })
          );
        }

        window.history.pushState(
          null,
          null,
          localStorage.getItem('@pumpkincare/auth/admin-redirect') || '/'
        );
        window.location.reload();
      });
    }
  });

  if (window.location.pathname.indexOf('/login') === -1) {
    const item = localStorage.getItem('@pumpkincare/auth/admin-redirect');

    if (item === window.location.pathname) {
      localStorage.removeItem('@pumpkincare/auth/admin-redirect');
    } else {
      localStorage.setItem(
        '@pumpkincare/auth/admin-redirect',
        window.location.pathname
      );
    }
  }
}

const cognitoAuthProviderFunctionMap = {
  AUTH_LOGIN: cognitoLogin,
  AUTH_LOGOUT: cognitoLogout,
  AUTH_CHECK: cognitoAuthCheck,
  AUTH_GET_PERMISSIONS: cognitoGetPermissions,
  AUTH_GET_IDENTITY: cognitoGetIdentity,
};

export {
  cognitoLogin,
  cognitoAuthProviderFunctionMap,
  cognitoUserPoolGroups,
  cognitoGetCurrentUser,
};
