import makeStyles from '@mui/styles/makeStyles';

const planPolicyDetailCardStyle = makeStyles(
  {
    card: { display: 'flex', alignItems: 'center', marginRight: '24px' },
    titleWrapper: { display: 'flex', alignItems: 'center', marginBottom: '5px' },
    title: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0.15px',
      color: '#000000',
      textTransform: 'capitalize',
    },
    detail: {
      fontSize: '9px',
      fontWeight: '400',
      lineHeight: '12px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    status: {
      fontSize: '10px',
      height: '20px',
      marginLeft: '8px',
      color: '#000000',
      fontWeight: '700',
      letterSpacing: '0.1em',
    },
    dateWrapper: {
      display: 'flex',
      width: '268px',
      marginBottom: '4px',
    },
    utilizationWrapper: {
      display: 'flex',
      width: '268px',
    },
  },
  { name: 'plan-policy-detail-card' }
);

export { planPolicyDetailCardStyle };
