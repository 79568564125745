import { List, SimpleList } from 'react-admin';

import { useOpsList } from '@pumpkincare/users';

import { orderUsersByEmail } from '../../../../../shared/utils/orderUsersByEmail';
import ClaimListActions from '../ClaimListActions';
import ClaimPrimarySession from '../ClaimPrimarySession/ClaimPrimarySession';
import ClaimSecondarySession from '../ClaimSecondarySession';
import ClaimTertiarySession from '../ClaimTertiarySession';

function ClaimsLibrary() {
  const { data: opsData, isLoading } = useOpsList();

  function rowStyle() {
    return {
      backgroundColor: 'white',
      marginTop: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
    };
  }

  return (
    <List
      sort={{ field: 'created_at', order: 'DESC' }}
      exporter={false}
      hasCreate={false}
      actions={
        <ClaimListActions
          title={'Claims Library'}
          showAssignee={true}
          showStatus={true}
          opsList={isLoading && !opsData ? [] : orderUsersByEmail(opsData)}
          filterOnClick={true}
          filterClaimsOn={['created_at']}
        />
      }
    >
      <SimpleList
        linkType={false}
        primaryText={record => <ClaimPrimarySession record={record} />}
        secondaryText={record => <ClaimSecondarySession record={record} />}
        tertiaryText={record => <ClaimTertiarySession record={record} />}
        style={{ backgroundColor: 'rgb(0, 125, 255, 0.08)' }}
        rowSx={rowStyle}
      />
    </List>
  );
}

export default ClaimsLibrary;
