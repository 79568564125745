import { Link } from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import RoutePaths from '../../routes';

function AuditLogsLink({ buttonName = 'Logs', filter }) {
  return (
    <Button
      color='secondary'
      variant='outlined'
      style={{
        textTransform: 'none',
        border: '0px',
        padding: '0px 0px 0px 6px',
        fontSize: '14px',
        color: '#0B33E3',
      }}
      startIcon={<Search />}
      component={Link}
      to={{
        pathname: `/${RoutePaths.auditLogs}`,
        search: `filter=${JSON.stringify(filter)}`,
      }}
      onClick={e => e.stopPropagation()}
    >
      {buttonName}
    </Button>
  );
}

AuditLogsLink.propTypes = {
  label: PropTypes.string,
  filter: PropTypes.object.isRequired,
};

export default AuditLogsLink;
