import makeStyles from '@mui/styles/makeStyles';

const policyChangeRadioButtonStyle = makeStyles(
  {
    currentPolicyChip: {
      borderRadius: '100px',
      padding: '4px',
      marginLeft: '4px',
      width: '75px',
      height: '18px',
      color: 'white',
      backgroundColor: '#007DFF',
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '12px',
    },
    radioButtonLabelContainer: { display: 'grid' },
    rawRateContainer: { display: 'flex', alignItems: 'center' },
    rawRate: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    deductible: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    cappedRate: {
      cursor: 'help',
    },
  },
  { name: 'policy-change-radio-button' }
);

export { policyChangeRadioButtonStyle };
