import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Tile from '../../../../shared/components/Tile';
import { formatCurrency } from '../../../../shared/utils/currencyUtils';

const useStyles = makeStyles(
  {
    root: {
      padding: '16px 16px 24px 16px',
    },
    planDetails: {
      display: 'flex',
      alignItems: 'end',
      '& > *': {
        marginRight: '100px',
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'black',
    },
    plan: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: 'black',
      marginTop: '8px',
    },
    planDetail: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '14px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    utilizationContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
      marginTop: '40px',
      '& > *': {
        marginRight: '40px',
      },
    },
  },
  { name: 'prevent-summary' }
);

function EntitlementsUtilizationSummary({ utilization }) {
  return utilization.map((item, index) => {
    const total = item.available + item.pending + item.utilized;
    return (
      <Tile
        key={index}
        title={item.details}
        content={`${item.utilized} of ${total}`}
      />
    );
  });
}

function AnnualCapUtilizationSummary({ annual_cap }) {
  const { available, pending, utilized } = annual_cap;

  return (
    <>
      <Tile
        title='ENTITLEMENT'
        content={formatCurrency(available + pending + utilized, { areCents: true })}
      />
      <Tile
        title='AVAILABLE'
        content={formatCurrency(annual_cap.available, { areCents: true })}
      />
      <Tile
        title='PENDING'
        content={formatCurrency(annual_cap.pending, { areCents: true })}
      />
      <Tile
        title='UTILIZED'
        content={formatCurrency(annual_cap.utilized, { areCents: true })}
      />
    </>
  );
}

export default function PreventSummary(props) {
  const {
    petPlan: { annual_cap, plan, stats, utilization },
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Prevent Claim Summary</Typography>

      <div className={classes.planDetails}>
        <div>
          <Typography className={classes.plan}>
            Prevent {plan.version_number.toFixed(1)} - {plan.name}
          </Typography>

          {plan.planDetails.map((item, index) => {
            return (
              <Typography className={classes.planDetail} key={index}>
                {item}
              </Typography>
            );
          })}
        </div>

        <Tile
          title={'Total claims'}
          content={`${stats ? stats.number_of_completed_claims : ''} completed / ${
            stats ? stats.number_of_pending_claims : ''
          } pending`}
        />

        <Tile
          title={'Total paid'}
          content={
            stats
              ? formatCurrency(stats.total_paid, {
                  areCents: true,
                  hideZero: true,
                })
              : ''
          }
        />

        <Tile
          title={'Utilization Type'}
          content={
            stats
              ? stats.annual_cap_active
                ? 'Annual Cap'
                : 'Item Entitlement'
              : ''
          }
        />
      </div>

      <div className={classes.utilizationContainer}>
        {stats ? (
          stats.annual_cap_active ? (
            <AnnualCapUtilizationSummary annual_cap={annual_cap} />
          ) : (
            <EntitlementsUtilizationSummary utilization={utilization} />
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
