import { Typography } from '@mui/material';

import { GENDER_MAP } from '@pumpkincare/shared';

import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../../../constants/quoteFlow';
import { petDetailHeaderStyle } from './pet-detail-header-style';

function PetDetailHeader(props) {
  const classes = petDetailHeaderStyle();

  const { petData = {}, showLine = false } = props;
  function petAgePlusOne(pet) {
    if (pet.renewal_pending_policy?.id) {
      if (pet.age == UP_TO_5_MONTHS || pet.age == UP_TO_11_MONTHS) {
        return 1;
      } else {
        return parseInt(pet.age) + 1;
      }
    }
    return pet.age;
  }

  return (
    <>
      <div className={classes.petHeader}>
        <Typography className={classes.petHeaderText}>
          {`Species: ${petData.species || ''} | Breed: ${petData.breed || ''} | 
        Age: ${(petData.age && petAgePlusOne(petData)) || ''} | Gender: ${
          GENDER_MAP[petData?.gender] || 'N/A'
        }`}
        </Typography>
      </div>
      {showLine ? <hr className={classes.line} /> : null}
    </>
  );
}

export default PetDetailHeader;
