import makeStyles from '@mui/styles/makeStyles';

const midtermSummaryStyle = makeStyles(
  {
    petPlanTitle: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#007DFF',
    },
    petPlanHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    planHeader: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'black',
      marginTop: '24px',
    },
    planBenefitsContainer: { display: 'flex', justifyContent: 'space-between' },
    planBenefitsList: {
      paddingLeft: '16px',
      marginBottom: '6px',
      marginTop: '8px',
      color: 'rgba(0, 0, 0, 0.54);',
    },
    planBenefits: {
      fontSize: '12px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.54);',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      paddingLeft: '0px',
    },
    planDiscount: {
      fontSize: '12px',
      fontWeight: '600',
      color: '#C21B44',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      paddingLeft: '0px',
    },
    saveButton: {
      color: 'white',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      margin: '16px 0px',
    },
    resetButton: {
      color: 'white',
      backgroundColor: '#D4424E',
      '&:hover': {
        backgroundColor: '#D4424E',
      },
    },
    totalValues: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      letterSpacing: '0.25px',
      color: 'black',
    },
    discountColor: {
      color: '#C21B44',
    },
    planBenefitsWithoutDiscount: {
      fontSize: '12px',
      fontWeight: '600',
      color: '#000000',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      textDecoration: 'line-through',
    },
  },
  { name: 'midterm-summary' }
);

export { midtermSummaryStyle };
