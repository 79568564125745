import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';

import { formatCurrency, parseAndFormatDate } from '../../../../shared/utils';
import { CLAIM_REIMBURSEMENT_STATUS_COLOR } from '../../constants/claim-reimbursements-status-colors';
import { useReimbursementCardDetailsStyles } from './reimbursement-card-details.styles';

const REIMBURSEMENT_MAP = {
  insurance: 'INSURANCE',
  prevent: 'PREVENTIVE ESSENTIALS',
  wellness: 'PUMPKIN WELLNESS CLUB',
};

function ReimbursementCardDetails({ reimbursement, bankWithdrawal = {} }) {
  const classes = useReimbursementCardDetailsStyles();

  const reimbursementSubtitle = `Payment to ${
    reimbursement.is_vet_reimbursement ? 'Vet Clinic' : 'Customer'
  }: ${formatCurrency(reimbursement.total_reimbursement_amount, {
    areCents: true,
  })}`;

  function renderBankAccount() {
    let bankAccount = null;
    if (reimbursement.reimbursement_type === 'insurance') {
      bankAccount = reimbursement.program_namespace === 'ipg' ? 'IPG' : 'C&F';
    } else if (reimbursement.reimbursement_type === 'wellness') {
      bankAccount = 'Wellness';
    }

    return (
      <Typography className={classes.reimbursementSectionTitle}>
        Bank Account: Pumpkin {bankAccount} Account
      </Typography>
    );
  }

  function renderBankWithdrawal(bankWithdrawal) {
    if (!Object.keys(bankWithdrawal)?.length) {
      return renderBankAccount();
    }

    return (
      <>
        {renderBankAccount()}
        <Typography className={classes.reimbursementDetail}>
          Initiated on: {parseAndFormatDate(bankWithdrawal.created_at)}
        </Typography>
        <Typography className={classes.reimbursementDetail}>
          {`Completed on: ${
            bankWithdrawal.completed_at
              ? parseAndFormatDate(bankWithdrawal.completed_at)
              : ''
          }`}
        </Typography>
        <Typography className={classes.reimbursementDetail}>
          Dwolla External ID: {bankWithdrawal.dwolla_external_id}
        </Typography>
        <Typography className={classes.reimbursementDetail}>
          ACH Trace ID: {bankWithdrawal.ach_trace_id}
        </Typography>
      </>
    );
  }

  return (
    <div>
      {reimbursement.cancellations?.some(
        cancellation => cancellation.status === 'open'
      ) ? (
        <div className={classes.financialReviewContainer}>
          <FlagOutlinedIcon className={classes.financialReviewIcon} />
          <Typography className={classes.financialReviewText}>
            UNDER FINANCIAL REVIEW
          </Typography>
        </div>
      ) : null}
      <Typography className={classes.reimbursementTitle}>
        {`${REIMBURSEMENT_MAP[reimbursement.reimbursement_type]} REIMBURSEMENTS`}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography component='strong' className={classes.reimbursementSubtitle}>
          {reimbursementSubtitle}
        </Typography>

        <Chip
          style={{
            fontSize: '10px',
            height: '20px',
            marginLeft: '8px',
            color: '#000000',
            backgroundColor:
              CLAIM_REIMBURSEMENT_STATUS_COLOR[reimbursement.status] || '#FFFFFF',
          }}
          label={reimbursement.status?.toUpperCase()}
        />
      </div>
      <Typography component='strong' className={classes.reimbursementSubtitle}>
        {reimbursement.reimbursement_method === 'ACH'
          ? `Mode: ACH to Customer - ${
              reimbursement.funding_source
                ? `${reimbursement.funding_source.account_type}
                       ending in ${reimbursement.funding_source.account_number}`
                : ''
            }`
          : 'Mode: Physical Check'}
      </Typography>
      {renderBankWithdrawal(bankWithdrawal)}
      {reimbursement.reimbursement_method === 'ACH' ? (
        <>
          <Typography className={classes.reimbursementSectionTitle}>
            Dwolla to Customer
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Initiated on: ${parseAndFormatDate(reimbursement.created_at)}`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Completed on: ${
              reimbursement.reimbursement_date
                ? parseAndFormatDate(reimbursement.reimbursement_date)
                : ''
            }`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Dwolla Transaction ID: ${reimbursement?.dwolla_external_id ?? ''}`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`ACH Trace ID: ${reimbursement?.ach_trace_id ?? ''}`}
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.reimbursementDetail}>
            {`Created on: ${parseAndFormatDate(reimbursement.created_at)}`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Send Date: ${
              reimbursement.send_date
                ? parseAndFormatDate(reimbursement.send_date)
                : ''
            }`}
          </Typography>

          <Typography className={classes.reimbursementDetail}>
            {`Check Number: ${
              reimbursement.lob_check_number ? reimbursement.lob_check_number : ''
            }`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Transaction Id: ${
              reimbursement.check_id
                ? reimbursement.check_id
                : reimbursement.whitelabel_e_check_id || ''
            }`}
          </Typography>
        </>
      )}
      <Typography className={classes.reimbursementSectionTitle}>
        Payment Details
      </Typography>
      <Typography className={classes.reimbursementDetail}>
        {`Amount Claimed: ${formatCurrency(reimbursement.claimed_amount, {
          areCents: true,
        })}`}
      </Typography>
      <Typography className={classes.reimbursementDetail}>
        {`Applied to Deductible: ${formatCurrency(
          reimbursement.applied_to_deductible,
          { areCents: true }
        )}`}
      </Typography>
      <Typography className={classes.reimbursementDetail}>
        {`Allowed Amount: ${formatCurrency(reimbursement.allowed_amount, {
          areCents: true,
        })}`}
      </Typography>
      <Typography className={classes.reimbursementDetailAmountPaid}>
        {`Amount Paid: ${formatCurrency(reimbursement.total_reimbursement_amount, {
          areCents: true,
        })}`}
      </Typography>
      {reimbursement.cancellations?.some(
        cancellation => cancellation.is_approved === true
      ) ? (
        <>
          <Typography
            className={classes.reimbursementDetail}
          >{`Cancellation Date: ${parseAndFormatDate(
            reimbursement.cancellations.find(
              cancellation => cancellation.is_approved === true
            )?.decision_made_at
          )}`}</Typography>
          <Typography
            className={classes.reimbursementDetail}
          >{`Cancellation Request Date: ${parseAndFormatDate(
            reimbursement.cancellations.find(
              cancellation => cancellation.is_approved === true
            ).created_at
          )}`}</Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Requested By: ${
              reimbursement.cancellations.find(
                cancellation => cancellation.is_approved === true
              ).requestor_ops_user_name
            }`}
          </Typography>
        </>
      ) : null}
      {reimbursement.cancellations?.some(
        cancellation => cancellation.status === 'open'
      ) ? (
        <>
          <Typography className={classes.reimbursementDetail}>
            {`Cancellation Request Date: ${parseAndFormatDate(
              reimbursement.cancellations.find(
                cancellation => cancellation.status === 'open'
              ).created_at
            )}`}
          </Typography>
          <Typography className={classes.reimbursementDetail}>
            {`Requested By: ${
              reimbursement.cancellations.find(
                cancellation => cancellation.status === 'open'
              ).requestor_ops_user_name
            }`}
          </Typography>
        </>
      ) : null}
    </div>
  );
}

ReimbursementCardDetails.propTypes = {
  reimbursement: PropTypes.shape({
    ach_trace_id: PropTypes.string,
    allowed_amount: PropTypes.number,
    applied_to_deductible: PropTypes.number,
    cancellations: PropTypes.array,
    check_id: PropTypes.string,
    claim_submission_id: PropTypes.string,
    claimed_amount: PropTypes.number,
    created_at: PropTypes.string,
    dwolla_external_id: PropTypes.string,
    dwolla_transfer_status: PropTypes.string,
    funding_source: PropTypes.object,
    id: PropTypes.string,
    latest_error: PropTypes.string,
    lob_check_number: PropTypes.string,
    lob_check_status: PropTypes.string,
    reimbursement_date: PropTypes.string,
    reimbursement_method: PropTypes.string,
    reimbursement_type: PropTypes.string,
    send_date: PropTypes.string,
    status: PropTypes.string,
    subclaim_id: PropTypes.string,
    total_reimbursement_amount: PropTypes.number,
    whitelabel_e_check_id: PropTypes.number,
    program_namespace: PropTypes.string,
  }),
  bankWithdrawal: PropTypes.shape({
    ach_trace_id: PropTypes.string,
    completed_at: PropTypes.string,
    created_at: PropTypes.string,
    dwolla_external_id: PropTypes.string,
    dwolla_transfer_status: PropTypes.string,
    id: PropTypes.string,
    reimbursement_id: PropTypes.string,
  }),
};

export default ReimbursementCardDetails;
