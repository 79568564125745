export const cancellationReasons = [
  { id: 'cost', name: 'Cost', disabled: true },
  { id: 'rate_increase', name: 'Rate Increase' },
  { id: 'general_price', name: 'General Price' },
  { id: 'unemployment_hardship', name: 'Unemployment/Hardship' },
  { id: 'switching-cheaper', name: 'Switching to a cheaper company' },
  { id: 'cost_other', name: 'Cost - Other' },
  { id: 'pre_existing_condition', name: 'Pre-existing Condition', disabled: true },
  { id: 'incident_occurred', name: 'Incident occurred during waiting period' },
  { id: 'misunderstood_coverage', name: 'Misunderstood pre-existing coverage' },
  {
    id: 'didnt_believe_condition',
    name: "Didn't believe condition would be pre-existing",
  },
  { id: 'pre_existing_other', name: 'Pre-existing Condition - Other' },
  { id: 'other_reasons', name: 'Other Reasons', disabled: true },
  { id: 'general_coverage', name: 'General Coverage' },
  { id: 'service', name: 'Service' },
  { id: 'duplicate', name: 'Duplicate' },
  { id: 'deceased', name: 'Deceased' },
  { id: 'owner_change', name: 'Owner Change' },
  { id: 'non_payment', name: 'Non-Payment' },
  { id: 'switching_provider', name: 'Switching to another provider' },
  { id: 'other', name: 'Other' },
  { id: 'unknown', name: 'Unknown' },
];

export const mgaCancellationReasons = [
  { id: 'cost', name: 'Cost', disabled: true },
  { id: 'rate_increase', name: 'Rate Increase' },
  { id: 'general_price', name: 'General Price' },
  { id: 'unemployment_hardship', name: 'Unemployment/Hardship' },
  { id: 'switching-cheaper', name: 'Switching to a cheaper company' },
  { id: 'cost_other', name: 'Cost - Other' },
  { id: 'pre_existing_condition', name: 'Pre-existing Condition', disabled: true },
  { id: 'incident_occurred', name: 'Incident occurred during waiting period' },
  { id: 'misunderstood_coverage', name: 'Misunderstood pre-existing coverage' },
  {
    id: 'didnt_believe_condition',
    name: "Didn't believe condition would be pre-existing",
  },
  { id: 'pre_existing_other', name: 'Pre-existing Condition - Other' },
  { id: 'other_reasons', name: 'Other Reasons', disabled: true },
  { id: 'general_coverage', name: 'General Coverage' },
  { id: 'service', name: 'Service' },
  { id: 'duplicate', name: 'Duplicate' },
  { id: 'deceased', name: 'Deceased' },
  { id: 'owner_change', name: 'Owner Change' },
  { id: 'switching_provider', name: 'Switching to another provider' },
  { id: 'other', name: 'Other' },
  { id: 'unknown', name: 'Unknown' },
];
