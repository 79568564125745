import { useCallback, useEffect, useState } from 'react';
import {
  FormDataConsumer,
  minValue,
  number,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  INVOICE_ITEM_DESCRIPTION_OB_ADDON,
  INVOICE_ITEM_DESCRIPTION_OB_PEP,
  INVOICE_ITEM_DESCRIPTION_OB_WELLNESS,
  invoiceItemDescriptionsByType,
} from '../../constants/invoiceItemDescriptions';
import {
  INVOICE_ITEM_TYPE_ADDON,
  INVOICE_ITEM_TYPE_FEE,
  INVOICE_ITEM_TYPE_OB,
  INVOICE_ITEM_TYPE_PLAN,
  INVOICE_ITEM_TYPE_POLICY,
  INVOICE_ITEM_TYPE_WELLNESS,
  invoiceItemTypes,
} from '../../constants/invoiceItemTypes';
import { INTEGER_VALIDATION_REGEX } from '../../constants/validation';
import NumericInput from '../../lib/shared/ui/numeric-input';
import Tile from '../../shared/components/Tile';
import {
  convertDollarsToCents,
  formatCurrency,
} from '../../shared/utils/currencyUtils';
import { getPets } from './invoiceService';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label='Add Items' icon={<AddIcon />} />
  </Toolbar>
);

const validateQuantity = [
  required(),
  number(),
  minValue(1),
  regex(INTEGER_VALIDATION_REGEX, 'Quantity must be a positive integer or zero.'),
];

const validatePricePerUnit = [required(), number(), minValue(0.01)];

function doesTypeRequirePetToBeSelected(itemType) {
  return [
    INVOICE_ITEM_TYPE_ADDON,
    INVOICE_ITEM_TYPE_OB,
    INVOICE_ITEM_TYPE_PLAN,
    INVOICE_ITEM_TYPE_POLICY,
    INVOICE_ITEM_TYPE_WELLNESS,
  ].includes(itemType);
}

function InvoiceItemsForm({ customerId, onAddItems }) {
  const notify = useNotify();
  const [pets, setPets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [subtotal, setSubtotal] = useState(0);

  const loadPets = useCallback(() => {
    if (customerId) {
      setIsLoading(true);

      getPets(customerId)
        .then(setPets)
        .catch(e => {
          setPets(null);
          notify(`Error: Unable to load Pets. ${e}`, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  }, [customerId]);

  useEffect(() => {
    loadPets();
  }, [loadPets]);

  function addonsFor(petId) {
    if (!petId) return [];
    return pets.find(pet => pet.id === petId).addons;
  }

  function plansFor(petId) {
    if (!petId) return [];
    return pets.find(pet => pet.id === petId).plans;
  }

  function policiesFor(petId) {
    if (!petId) return [];
    return pets.find(pet => pet.id === petId).policies;
  }

  function wellnessFor(petId) {
    if (!petId) return [];
    return pets.find(pet => pet.id === petId).wellness;
  }

  function handleOnSave(data) {
    const pet = pets.find(pet => pet.id === data.pet_id);
    const addon = pet
      ? pet.addons.find(addon => addon.id === data.pet_addon_id)
      : null;
    const plan = pet ? pet.plans.find(plan => plan.id === data.pet_plan_id) : null;
    const policy = pet
      ? pet.policies.find(policy => policy.id === data.policy_id)
      : null;

    const wellness = pet
      ? pet.wellness.find(wellness => wellness.id === data.wellness_id)
      : null;

    const itemsToAdd = [];
    for (let i = 0; i < parseInt(data.quantity); i++) {
      const item = {
        id: _.uniqueId(),
        pet_id: data.pet_id,
        type: data.type,
        description: data.description_type,
        price_per_unit: convertDollarsToCents(parseFloat(data.price_per_unit)),
      };

      if (data.type !== INVOICE_ITEM_TYPE_FEE) {
        item.description = `${item.description} - ${pet.name}`;
      }

      if (data.type === INVOICE_ITEM_TYPE_PLAN) {
        item.pet_plan_id = plan.id;
        item.effective_date = plan.formattedEffectiveDate;
      }

      if (data.type === INVOICE_ITEM_TYPE_ADDON) {
        item.pet_addon_id = addon.id;
      }

      if (data.type === INVOICE_ITEM_TYPE_OB) {
        if (data.description_type === INVOICE_ITEM_DESCRIPTION_OB_PEP) {
          item.pet_plan_id = plan.id;
          item.effective_date = plan.formattedEffectiveDate;
        }
        if (data.description_type === INVOICE_ITEM_DESCRIPTION_OB_ADDON) {
          item.pet_addon_id = addon.id;
        }
        if (data.description_type === INVOICE_ITEM_DESCRIPTION_OB_WELLNESS) {
          item.wellness_id = wellness.id;
          item.effective_date = wellness.formattedEffectiveDate;
        }
      }

      if (data.type === INVOICE_ITEM_TYPE_POLICY) {
        item.policy_id = policy.id;
        item.effective_date = policy.formattedEffectiveDate;
      }

      if (data.type === INVOICE_ITEM_TYPE_WELLNESS) {
        item.wellness_id = wellness.id;
        item.effective_date = wellness.formattedEffectiveDate;
      }

      itemsToAdd.push(item);
    }

    onAddItems(itemsToAdd);
  }

  function validateOutstandingBalance(values, errors) {
    if (values.type === INVOICE_ITEM_TYPE_OB) {
      if (
        values.description_type === INVOICE_ITEM_DESCRIPTION_OB_PEP &&
        !values.pet_plan_id
      ) {
        errors.pet_plan_id = ['A plan is required.'];
      }
      if (
        values.description_type === INVOICE_ITEM_DESCRIPTION_OB_ADDON &&
        !values.pet_addon_id
      ) {
        errors.pet_addon_id = ['An addon is required.'];
      }
      if (
        values.description_type === INVOICE_ITEM_DESCRIPTION_OB_WELLNESS &&
        !values.wellness_id
      ) {
        errors.wellness_id = ['A wellness plan is required.'];
      }
    }
  }

  function handleValidate(values) {
    const errors = {};

    if (doesTypeRequirePetToBeSelected(values.type) && !values.pet_id) {
      errors.pet_id = ['A pet is required."'];
    }

    validateOutstandingBalance(values, errors);

    if (values.type === INVOICE_ITEM_TYPE_PLAN && !values.pet_plan_id) {
      errors.pet_plan_id = ['A plan is required.'];
    }

    if (values.type === INVOICE_ITEM_TYPE_ADDON && !values.pet_addon_id) {
      errors.pet_addon_id = ['A pet addon is required.'];
    }

    if (values.type === INVOICE_ITEM_TYPE_POLICY && !values.policy_id) {
      errors.policy_id = ['A policy is required.'];
    }

    if (values.type === INVOICE_ITEM_TYPE_WELLNESS && !values.wellness_id) {
      errors.wellness_id = ['A wellness plan is required.'];
    }

    return errors;
  }

  function calculateSubtotal({ quantity, price_per_unit }) {
    const parsedQuantity = INTEGER_VALIDATION_REGEX.test(quantity)
      ? parseInt(quantity)
      : 0;

    const floatPricePerUnit = parseFloat(price_per_unit) * 100;
    const parsedPricePerUnit = floatPricePerUnit > 0 ? floatPricePerUnit : 0;
    setSubtotal(parsedQuantity * parsedPricePerUnit);
  }

  return isLoading ? (
    <Typography style={{ padding: '16px', color: 'rgba(0, 0, 0, 0.6)' }}>
      Loading...
    </Typography>
  ) : (
    <SimpleForm
      onSubmit={handleOnSave}
      validate={handleValidate}
      toolbar={<CustomToolbar />}
    >
      <SelectInput
        label='Type'
        source='type'
        choices={invoiceItemTypes}
        validate={required()}
        fullWidth
      />

      <FormDataConsumer>
        {formDataProps =>
          doesTypeRequirePetToBeSelected(formDataProps.formData.type) ? (
            <SelectInput
              label='Pet'
              source='pet_id'
              choices={pets}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.type ? (
            <SelectInput
              label='Description Type'
              source='description_type'
              choices={invoiceItemDescriptionsByType[formDataProps.formData.type]}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.type === INVOICE_ITEM_TYPE_ADDON ||
          (formDataProps.formData.type === INVOICE_ITEM_TYPE_OB &&
            formDataProps.formData.description_type ===
              INVOICE_ITEM_DESCRIPTION_OB_ADDON) ? (
            <SelectInput
              label='Addon'
              source='pet_addon_id'
              choices={addonsFor(formDataProps.formData?.pet_id)}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.type === INVOICE_ITEM_TYPE_PLAN ||
          (formDataProps.formData.type === INVOICE_ITEM_TYPE_OB &&
            formDataProps.formData.description_type ===
              INVOICE_ITEM_DESCRIPTION_OB_PEP) ? (
            <SelectInput
              label='Plan'
              source='pet_plan_id'
              choices={plansFor(formDataProps.formData?.pet_id)}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.type === INVOICE_ITEM_TYPE_POLICY ? (
            <SelectInput
              label='Policy'
              source='policy_id'
              choices={policiesFor(formDataProps.formData?.pet_id)}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.type === INVOICE_ITEM_TYPE_WELLNESS ||
          (formDataProps.formData.type === INVOICE_ITEM_TYPE_OB &&
            formDataProps.formData.description_type ===
              INVOICE_ITEM_DESCRIPTION_OB_WELLNESS) ? (
            <SelectInput
              label='Wellness'
              source='wellness_id'
              choices={wellnessFor(formDataProps.formData?.pet_id)}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps => (
          <NumericInput
            label='Quantity'
            source='quantity'
            validate={validateQuantity}
            onBlur={() => calculateSubtotal({ ...formDataProps.formData })}
            fullWidth
            variant='filled'
          />
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {formDataProps => (
          <NumericInput
            source='price_per_unit'
            label='Price per Unit'
            validate={validatePricePerUnit}
            onBlur={() => calculateSubtotal({ ...formDataProps.formData })}
            fullWidth
            variant='filled'
            allowDecimalValue
            precision={2}
          />
        )}
      </FormDataConsumer>

      <Tile
        title={'Subtotal'}
        content={formatCurrency(subtotal, { areCents: true })}
        style={{ marginTop: '32px' }}
      />
    </SimpleForm>
  );
}

InvoiceItemsForm.propTypes = {
  customerId: PropTypes.string.isRequired,
  onAddItems: PropTypes.func.isRequired,
};

export default InvoiceItemsForm;
