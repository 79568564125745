import { useState } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  useGetRecordId,
  useRedirect,
} from 'react-admin';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PetsIcon from '@mui/icons-material/Pets';
import { Chip, CircularProgress, IconButton, Typography } from '@mui/material';

import {
  CLAIM_REIMBURSEMENT_STATUS_COLOR,
  useClaimById,
  useClaimReimbursement,
} from '@pumpkincare/claims';
import {
  ClaimCustomerInformation,
  ClaimDetailCard,
  GENDER_MAP,
  SPECIES_MAP,
} from '@pumpkincare/shared';
import { useCustomer, usePet } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { formatCurrency } from '../../../shared/utils/currencyUtils';
import { appendBreedName } from '../../../shared/utils/petUtils';
import CancellationConfirmModal from '../cancellation-confirm-modal';
import ReimbursementActionButton from '../reimbursement-action-button';
import ReimbursementCancellationDetail from '../reimbursement-cancellation-detail';
import ReimbursementCancellationModal from '../reimbursement-cancellation-modal';
import ReissueReimbursementModal from '../reissue-reimbursement-modal';
import { reimbursementDetailStyle } from './reimbursement-detail-style';

const REIMBURSEMENT_MAP = {
  insurance: 'Insurance',
  prevent: 'Preventive Essentials',
  wellness: 'Pumpkin Wellness',
};

export default function ReimbursementDetail() {
  const redirect = useRedirect();
  const classes = reimbursementDetailStyle();
  const claimId = useGetRecordId();

  const [isCancellationModalOpen, toggleCancellationModal] = useBooleanInput();
  const [isConfirmCancellationModalOpen, toggleConfirmCancellationModal] =
    useBooleanInput();
  const [isReissueReimbursementOpen, toggleReissueReimbursementModal] =
    useBooleanInput();
  const [reimbursement, setReimbursement] = useState();
  const { data: claimData } = useClaimById(claimId);
  const { data: reimbursementData = [] } = useClaimReimbursement(claimId);
  const { data: petData, isLoading: isPetDataLoading } = usePet(claimData?.pet_id);
  const { data: customerData, isLoading: isCustomerLoading } = useCustomer(
    claimData?.customer_id
  );

  const mailingAddress = customerData ? customerData.mailing_address : {};

  function handleCancelClick(reimbursementClicked) {
    setReimbursement(reimbursementClicked);
    toggleCancellationModal();
  }

  function handleConfirmClick(reimbursementClicked) {
    setReimbursement(reimbursementClicked);
    toggleConfirmCancellationModal();
  }

  function handleReissueClick(reimbursementClicked) {
    setReimbursement(reimbursementClicked);
    toggleReissueReimbursementModal();
  }

  return (
    <div className={classes.mainDiv}>
      <Link to={`/claims/${claimId}/show`} className={classes.link}>
        <ArrowBackIosIcon className={classes.arrowBackIcon} />
        <Typography
          className={classes.claimNumber}
        >{`RETURN TO CLAIM ${claimId}`}</Typography>
      </Link>
      <div className={classes.headerWrapper}>
        {isCustomerLoading ? (
          <CircularProgress />
        ) : (
          <ClaimCustomerInformation customerData={customerData} />
        )}

        {!isPetDataLoading && petData ? (
          <ClaimDetailCard
            icon={
              <IconButton
                onClick={() => redirect(`/pets/${petData.id}/show`)}
                style={{ padding: '0px' }}
                size='large'
              >
                <PetsIcon
                  style={{ width: '35px', height: '35px', color: '#007DFF' }}
                />
              </IconButton>
            }
            title={`${petData.name}`}
            detail={`${SPECIES_MAP[petData.species]}, ${
              appendBreedName(petData, 'breed')?.breed
            }, Age ${petData?.age}, ${GENDER_MAP[petData?.gender] || 'N/A'}`}
          />
        ) : (
          <CircularProgress />
        )}
      </div>
      <ArrayField
        source='reimbursements'
        record={{
          reimbursements: reimbursementData?.list || reimbursementData,
        }}
      >
        <Datagrid
          classes={{
            headerCell: classes.headerCell,
            table: classes.table,
          }}
          rowSx={() => ({
            backgroundColor: 'white',
          })}
          bulkActionButtons={false}
          isRowExpandable={record => record.cancellations?.length > 0}
          expand={({ record }) => (
            <ReimbursementCancellationDetail cancellations={record.cancellations} />
          )}
        >
          <FunctionField
            label='STATUS'
            render={(record = {}) => (
              <Chip
                style={{
                  fontSize: '10px',
                  height: '20px',
                  color: '#000000',
                  backgroundColor:
                    CLAIM_REIMBURSEMENT_STATUS_COLOR[record.status] || '#FFFFFF',
                }}
                label={record.status?.toUpperCase()}
              />
            )}
          />

          <FunctionField
            label='TYPE'
            render={(record = {}) => (
              <Typography className={classes.reimbursementCell}>
                {REIMBURSEMENT_MAP[record.reimbursement_type]}
              </Typography>
            )}
          />

          <FunctionField
            label='AMOUNT PAID'
            render={(record = {}) => (
              <Typography className={classes.reimbursementCell}>
                {formatCurrency(record.total_reimbursement_amount, {
                  areCents: true,
                })}
              </Typography>
            )}
          />
          <FunctionField
            label='METHOD'
            render={(record = {}) => (
              <Typography className={classes.reimbursementCell}>
                {record.reimbursement_method === 'ACH'
                  ? `ACH ending in ${
                      record.funding_source
                        ? record.funding_source.account_number
                        : ''
                    }`
                  : `Physical Check - ${record.lob_check_number}`}
              </Typography>
            )}
          />
          <FunctionField
            label='ID'
            render={(record = {}) => (
              <Typography className={classes.reimbursementCell}>
                {record.reimbursement_method === 'ACH'
                  ? record.ach_trace_id
                  : record.check_id || record.whitelabel_e_check_id}
              </Typography>
            )}
          />
          <TextField
            className={classes.reimbursementCell}
            source='dwolla_external_id'
            label='DWOLLA TRANSACTION ID'
          />
          <DateField
            className={classes.reimbursementCell}
            label='CREATED'
            source='created_at'
          />
          <FunctionField
            label='CANCEL DATE'
            render={(record = {}) => (
              <DateField
                className={classes.reimbursementCell}
                record={{
                  cancellation_date: record.cancellations?.find(
                    cancellation => cancellation.is_approved === true
                  )?.decision_made_at,
                }}
                source='cancellation_date'
              />
            )}
          />
          <FunctionField
            label=''
            render={(record = {}) => (
              <ReimbursementActionButton
                reimbursement={record}
                claimStatus={claimData?.status}
                onConfirmCancellationClick={handleConfirmClick}
                onReissueClick={handleReissueClick}
                onRequestCancellationClick={handleCancelClick}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
      <ReimbursementCancellationModal
        reimbursement={reimbursement}
        isOpen={isCancellationModalOpen}
        onClose={toggleCancellationModal}
      />
      <CancellationConfirmModal
        reimbursement={reimbursement}
        isOpen={isConfirmCancellationModalOpen}
        onClose={toggleConfirmCancellationModal}
      />
      <ReissueReimbursementModal
        reimbursement={reimbursement}
        isOpen={isReissueReimbursementOpen}
        onClose={toggleReissueReimbursementModal}
        mailingAddress={mailingAddress}
        claimId={claimId}
      />
    </div>
  );
}
