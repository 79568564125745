export function getRecordValue(record, source) {
  let recordAux = record[source];
  if (source.split('.').length > 1) {
    recordAux = record;
    let aux = source.split('.');
    for (let i = 0; i < aux.length; i++) {
      if (recordAux && recordAux[aux[i]] !== undefined) {
        recordAux = recordAux[aux[i]];
      }
    }
  }
  return recordAux;
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const testEmail = re.test(String(email).toLowerCase());

  return testEmail;
}
