import { FunctionField, ReferenceField } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import { formatISODate } from '../../../../shared/utils';
import {
  getCurrentPolicy,
  getCurrentWellness,
  getLatestPlan,
} from '../../../../shared/utils/productUtils';
import { petListStyle } from '../pet-list/pet-list-style';
import PlanCostField from '../plan-cost-field';
import PlanEffectiveDateField from '../plan-effective-date-field';
import PlanStatusField from '../plan-status-field';

function PetPlanItem({ petRecord }) {
  const classes = petListStyle();

  const policy = getCurrentPolicy(petRecord?.policies);
  const wellness = getCurrentWellness(petRecord);

  const isWellnessActiveOrPending =
    wellness?.status === 'active' || wellness?.status === 'pending';

  const updatedPlan = isWellnessActiveOrPending
    ? null
    : getLatestPlan(policy, petRecord);

  function renewalStatus(record) {
    const textStyle = classnames({
      [classes.renewalToday]: record.renew_status === 'Renewal Today',
      [classes.renewalUpcoming]: record.renew_status === 'Upcoming Renewal',
    });

    return <span className={textStyle}>{record.renew_status}</span>;
  }

  return updatedPlan ? (
    <Grid container direction='row' alignItems='center'>
      <Grid xs={0.9} item>
        <Typography
          sx={{
            color: '#000000',
          }}
          variant='subtitle2'
        >
          Prevent
        </Typography>
      </Grid>

      <Grid xs={1.5} item>
        <PlanStatusField petRecord={petRecord} />
      </Grid>

      <Grid xs={1.2} item>
        <PlanCostField petRecord={petRecord} />
      </Grid>

      <Grid xs={1.6} item>
        <ReferenceField
          record={updatedPlan}
          source='plan_id'
          reference='plans'
          label=''
        >
          <FunctionField
            render={(record = {}) => {
              return (
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {`Pumpkin Prevent ${record.version_number}`}
                </Typography>
              );
            }}
          />
        </ReferenceField>
      </Grid>

      <Grid xs={1.8} item>
        <PlanEffectiveDateField petRecord={petRecord} />
      </Grid>

      <Grid xs={1.8} item>
        <Typography
          sx={{
            color: '#000000',
          }}
          variant='subtitle2'
        >
          <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }}>
            {updatedPlan.status === 'cancelled' ||
            updatedPlan.status === 'cancel_pending' ||
            !petRecord.next_charge_date
              ? ''
              : formatISODate(petRecord.next_charge_date, { isGenerated: true })}
          </Typography>
        </Typography>
      </Grid>

      <Grid xs={1.2} item>
        <Typography
          sx={{
            color: '#000000',
          }}
          variant='subtitle2'
        >
          {policy.lapsed_since ? (
            <DateFieldIgnoringZone
              source='lapsed_since'
              record={policy}
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '22px',
                letterspacing: '0.1px',
              }}
            />
          ) : (
            ''
          )}
        </Typography>
      </Grid>

      <Grid xs={2} item>
        <Typography
          sx={{
            color: '#000000',
          }}
          variant='subtitle2'
        >
          {renewalStatus(petRecord)}
        </Typography>
      </Grid>
    </Grid>
  ) : null;
}

PetPlanItem.propTypes = {
  petRecord: PropTypes.shape({
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        cost: PropTypes.number,
        plan_id: PropTypes.string,
        plan_effective_date: PropTypes.string,
      })
    ),
    next_charge_date: PropTypes.string,
    policies: PropTypes.arrayOf(PropTypes.object),
  }),
};
export default PetPlanItem;
