import makeStyles from '@mui/styles/makeStyles';

const policyChangeRequestStyle = makeStyles(
  {
    ratesRadioGroupButton: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    ratesCard: {
      backgroundColor: '#FAFAFA',
      marginTop: '10px',
      padding: '20px',
      borderRadius: '14px',
    },
    deductibleTitle: {
      fontWeight: '500',
      lineHeight: '30px',
      color: '#373F51',
      fontSize: '20px',
      marginBottom: '5px',
    },
    radioButton: {
      '& svg': {
        width: '28px',
        height: '28px',
      },
      padding: '8px 0px',
      marginRight: '7%',
    },
    ratesDescription: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.15px',
    },
    ratesLabel: {
      fontSize: '24px',
      fontWeight: '800',
      lineHeight: '18px',
      letterSpacing: '0.15px',
      paddingBottom: '8px',
    },
    displayFlex: {
      display: 'flex',
    },
    weight500: {
      fontWeight: '500',
    },
  },
  { name: 'policy-change-request' }
);

export { policyChangeRequestStyle };
