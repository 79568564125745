import { useRecordContext } from 'react-admin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  DownloadClaimDocumentSummaryLink,
  ProcessingStep,
} from '@pumpkincare/shared';

function StepResolver(props) {
  const {
    handleNextButtonClick,
    handleBackButtonClick,
    classes,
    toggleReimbursementModal,
    disableButton,
  } = props;
  const record = useRecordContext();
  const { fer4153BackButtonAfterRunTheRules } = useFlags();

  const step = record.step || 1;

  const buttonLabel = step <= 1 ? 'NEXT' : 'RUN RULES';

  if (step === 5) {
    return (
      <>
        <CheckCircleIcon fontSize='large' className={classes.checkCircle} />
        <DownloadClaimDocumentSummaryLink
          preventClaimId={record.id}
          linkLabel='Claim Summary Document'
          {...props}
        />
      </>
    );
  }

  if (step === 4) {
    return (
      <Button
        disabled
        onClick={toggleReimbursementModal}
        className={classes.nextButton}
        variant='contained'
      >
        REIMBURSE
      </Button>
    );
  }

  if (step === 3) {
    return (
      <>
        {fer4153BackButtonAfterRunTheRules ? (
          <>
            <Button
              onClick={() => handleBackButtonClick(record)}
              className={classes.backButton}
              color='primary'
              size='medium'
              startIcon={<ChevronLeftIcon />}
            >
              BACK
            </Button>
            <ProcessingStep
              currentStep={step}
              steps={['Items Review', 'Adjudication']}
              style={{ width: '270px' }}
            />
          </>
        ) : null}
        <Button
          onClick={() => handleNextButtonClick({ ...record, step: 2 })}
          className={classes.nextButton}
          disabled={disableButton}
          variant='contained'
        >
          {buttonLabel}
        </Button>
        <Button
          disabled={disableButton}
          onClick={toggleReimbursementModal}
          className={classes.nextButton}
          variant='contained'
        >
          REIMBURSE
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        onClick={() => handleBackButtonClick(record)}
        className={classes.backButton}
        color='primary'
        size='medium'
        disabled={step !== 2}
        startIcon={<ChevronLeftIcon />}
      >
        BACK
      </Button>
      <ProcessingStep
        currentStep={step}
        steps={['Items Review', 'Adjudication']}
        style={{ width: '270px' }}
      />

      <Button
        onClick={() => handleNextButtonClick(record)}
        className={step !== 2 ? classes.newNextButton : classes.nextButton}
        disabled={disableButton}
        variant='contained'
      >
        {buttonLabel}
      </Button>
    </>
  );
}

StepResolver.propTypes = {
  classes: PropTypes.shape({
    backButton: PropTypes.string,
    nextButton: PropTypes.string,
    checkCircle: PropTypes.string,
    newNextButton: PropTypes.string,
  }),
  handleNextButtonClick: PropTypes.func,
  handleBackButtonClick: PropTypes.func,
  toggleReimbursementModal: PropTypes.func,
  disableButton: PropTypes.bool,
};

export default StepResolver;
