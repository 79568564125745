import { useGetList, useGetOne } from 'react-admin';

const WELLNESS_QUERY = 'wellness';

export function useWellnessUtilization(wellnessId) {
  return useGetOne(
    WELLNESS_QUERY,
    { id: `${wellnessId}/utilization` },
    {
      enabled: !!wellnessId,
    }
  );
}

export function useWellnessByPetId(petId, options = {}) {
  return useGetList(
    WELLNESS_QUERY,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'start_date', order: 'ASC' },
      filter: { pet_id: petId },
    },
    { enabled: !!petId, ...options }
  );
}
