export const {
  TEST,
  ENVIRONMENT,
  VET_LOOKUP,
  GOOGLE_TAG_MANAGER_ID,
  LAUNCH_DARKLY_KEY,
  STRIPE_API_KEY,
  PUMPKIN_CLIENT_BASE_URL,
  POLICY_BASE_API,
  CUSTOMER_POLICY_BASE_API,
  PERSONALIZATION_BASE_API,
  CLOUDFLARE_WORKER_API,
  STATES_API,
  SEGMENT_KEY,
  SEGMENT_SRC_BASE,
  SAW_PROGRAM_WORKER_API,
  SENTRY_DSN,
  SENTRY_DISABLE_SOURCEMAP,
  SENTRY_RELEASE,
  PK_SENTRY_SDK_ENABLE_TRACING,
  PK_SENTRY_SDK_TRACES_SAMPLE_RATE,
  PK_SENTRY_SDK_PROFILES_SAMPLE_RATE,
  PK_SENTRY_SDK_REPLAYS_SAMPLE_RATE,
  PK_SENTRY_SDK_ENABLE_REPLAYS,
  PK_SENTRY_SDK_ENABLE_PROFILING,
} = process.env;

export const AUTH_SETTINGS = {
  userPoolWebClientId: process.env.CLIENT_ID,
  IdentityProvider: process.env.IDENTITY_PROVIDER,
  identityPoolId: process.env.IDENTITY_POOL_ID,
  region: process.env.REGION,
  userPoolId: process.env.USER_POOL_ID,
  useOidcLib: process.env.USE_OIDC_LIB === 'true', // string to boolean
  oauth: {
    domain: process.env.APP_WEB_DOMAIN,
    scope: [process.env.TOKEN_SCOPE],
    redirectSignIn: process.env.REDIRECT_URI_SIGN_IN,
    redirectSignOut: process.env.REDIRECT_URI_SIGN_OUT,
    responseType: 'token',
  },
};
