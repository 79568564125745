import { FormDataConsumer } from 'react-admin';
import { MenuItem, Select, Typography } from '@mui/material';

import VetInput from '../../../../shared/components/VetInput';
import { HDYHAU_OPTIONS, MY_VET_CLINIC } from '../constants/HDYHAUQuestions';

function ReferralForm({ HDYHAUAnswer, onHDYHAUChange, onHDYHAUVetChange, quote }) {
  const hdyhauOptions = HDYHAU_OPTIONS.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <div>
      <Typography
        style={{
          margin: '60px 0 0',
          fontSize: '20px',
          lineHeight: '24px',
          fontWeight: '500',
        }}
      >
        Referral
      </Typography>
      <Typography style={{ margin: '24px 0' }}>
        How did you hear about Pumpkin?<sup style={{ color: '#B00020' }}>*</sup>
      </Typography>
      <Select
        onChange={onHDYHAUChange}
        fullWidth={true}
        defaultValue=''
        style={{ marginBottom: '24px' }}
      >
        {hdyhauOptions}
      </Select>
      <FormDataConsumer>
        {({ formData }) => {
          return HDYHAUAnswer === MY_VET_CLINIC ? (
            <VetInput
              formData={{ ...formData, vet_hdyhau: quote.vet }}
              onChange={onHDYHAUVetChange}
              source='vet_hdyhau'
              zipcodeSource={'policy_zipcode'}
            />
          ) : null;
        }}
      </FormDataConsumer>
    </div>
  );
}

export default ReferralForm;
