import { CREATE, GET_LIST, GET_ONE, UPDATE } from 'react-admin';

import { dataProvider } from '../../lib/dataProvider/provider';
import RoutePaths from '../../routes';
import { orderUsersByEmail } from '../utils/orderUsersByEmail';

export function checkExistingEmail(email) {
  return dataProvider(CREATE, `users/check_email`, {
    data: { email: email },
    api: 'CUSTOMER',
    returnJson: 'BODY',
  }).then(response => response.data);
}

export function getClaimNotes(claimId) {
  return dataProvider(GET_ONE, RoutePaths.claims, {
    id: `${claimId}/${RoutePaths.notes}`,
  }).then(response => response.data);
}

export function getCancellationNotes(userId) {
  return dataProvider(GET_ONE, RoutePaths.cancellationDetails, {
    id: `${userId}/${RoutePaths.notes}`,
  }).then(response => response.data);
}

export function getClaimHistory(claimId) {
  return dataProvider(GET_ONE, RoutePaths.claims, {
    id: `${claimId}/history`,
  }).then(response => response.data);
}

export function getPet(petId) {
  return dataProvider(GET_ONE, RoutePaths.pets, {
    id: petId,
  }).then(response => response.data);
}

export function getOpsUsers() {
  return dataProvider(GET_LIST, RoutePaths.opsUsers, {
    pagination: { page: 1, perPage: 1000 },
    sort: {
      field: 'id',
      order: 'ASC',
    },
  }).then(response => orderUsersByEmail(response.data));
}

export function getCurrentOpsUser() {
  return dataProvider('GET', `${RoutePaths.opsUsers}/current-user`, {}).then(
    response => response.data
  );
}

export function postClaimNotes(note, claimId) {
  return dataProvider(CREATE, `${RoutePaths.claims}/${claimId}/notes`, {
    data: note,
  });
}

export function postCancellationNotes(note, userId) {
  return dataProvider(CREATE, `${RoutePaths.cancellationDetails}/${userId}/notes`, {
    data: note,
  });
}

export function updateCancellations(data) {
  return dataProvider(UPDATE, `${RoutePaths.cancellationsDashboard}`, {
    data: data,
    returnJson: 'RAW',
  });
}

export function getCancellationByUserID(userId) {
  return dataProvider('GET', `${RoutePaths.cancellationDetails}/${userId}`, {}).then(
    response => response.data
  );
}
