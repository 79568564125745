import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useListContext,
  useNotify,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CircularProgress } from '@mui/material';

import { formatCurrency } from '../../shared/utils/currencyUtils';
import { formatAgeRange, getPlanDetails } from '../../shared/utils/planUtils';
import PlanFilter from './plan-filter';

function DatagridWrapper(props) {
  const { isLoading } = useListContext();
  const notify = useNotify();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props}>
      <FunctionField
        label='Version'
        sortBy='version_number'
        render={(record = {}) => record.version_number.toFixed(1)}
      />
      <TextField label='Name' source='name' />
      <FunctionField
        label='Species'
        sortBy='target'
        render={(record = {}) => record.target}
        style={{ textTransform: 'capitalize' }}
      />
      <FunctionField
        label='Age'
        sortBy='min_age_months'
        render={(record = {}) =>
          formatAgeRange(record.min_age_months, record.max_age_months).replaceAll(
            ' ',
            `\u00A0`
          )
        }
      />
      <FunctionField
        label='Cost Monthly'
        sortBy='cost'
        render={(record = {}) => formatCurrency(record.cost)}
      />
      <FunctionField
        label='Benefits'
        render={(record = {}) =>
          getPlanDetails(record).map((item, index) => <div key={index}>{item}</div>)
        }
      />
      <FunctionField
        label='Vet Clinic'
        render={(record = {}) => (
          <>
            <div>{record.vet_name}</div>
            <div>{record.vet_address}</div>
          </>
        )}
      />
      <TextField label='Status' source='status' />
      <FunctionField
        label='ID'
        render={(record = {}) => {
          return (
            <CopyToClipboard
              text={record.id}
              onCopy={() => {
                notify(`${record.id} copied`, {});
              }}
            >
              <span>{record.id}</span>
            </CopyToClipboard>
          );
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </Datagrid>
  );
}

function PlanList(props) {
  return (
    <List
      {...props}
      actions={null}
      exporter={false}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<PlanFilter />}
    >
      <DatagridWrapper />
    </List>
  );
}

export default PlanList;
