const wellnessQuoteTabPlanSelectionStyles = {
  root: {
    background: 'white',
    padding: '40px 8px',
    width: '100%',
  },
  content: {
    width: '63vw',
    minWidth: '500px',
    margin: '0 auto ',
  },
  processingStep: {
    marginBottom: '68px',
  },
};

const wellnessQuoteTabPlanSelectionSx = {
  customerInfo: {
    display: 'flex',
    gap: '32px',
    paddingBottom: '16px',
    margin: '2px 0 16px',
    borderBottom: '1px solid #DEDEDE',
  },
  accordion: {
    boxShadow: 'none',
    background: '#F8F7F3',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  plansTitle: {
    textAlign: 'center',
    borderTop: '1px solid rgba(0,0,0, 0.12)',
    paddingTop: '16px',
  },
};

export { wellnessQuoteTabPlanSelectionStyles, wellnessQuoteTabPlanSelectionSx };
