export function flattenObj(currentNode, flattenedKey) {
  let target = {};
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;

      if (typeof flattenedKey === 'undefined') {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }

      var value = currentNode[key];

      if (typeof value === 'object') {
        target = { ...target, ...flattenObj(value, newKey) };
      } else {
        target[newKey] = value;
      }

      const innerKey = newKey.split('.');

      if (innerKey?.length > 1) {
        target[innerKey[1]] = target[newKey];
        delete target[newKey];
      }
    }
  }

  return target;
}
