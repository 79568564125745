export const INVOICE_ITEM_TYPE_PLAN = 'plan';
export const INVOICE_ITEM_TYPE_POLICY = 'policy';
export const INVOICE_ITEM_TYPE_WELLNESS = 'wellness';
export const INVOICE_ITEM_TYPE_FEE = 'fee';
export const INVOICE_ITEM_TYPE_OB = 'outstanding balance';
export const INVOICE_ITEM_TYPE_ADDON = 'addon';

export const invoiceItemTypes = [
  { id: INVOICE_ITEM_TYPE_PLAN, name: 'Plan' },
  { id: INVOICE_ITEM_TYPE_POLICY, name: 'Policy' },
  { id: INVOICE_ITEM_TYPE_WELLNESS, name: 'Wellness' },
  { id: INVOICE_ITEM_TYPE_FEE, name: 'Fee' },
  { id: INVOICE_ITEM_TYPE_OB, name: 'Outstanding Balance' },
  { id: INVOICE_ITEM_TYPE_ADDON, name: 'Addon' },
];
