import {
  AutocompleteInput,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';

import { claimMGAStatusesFilter } from '@pumpkincare/claims';
import { stateList } from '@pumpkincare/shared';

function ClaimLibraryFilter(props) {
  const { opsList } = props;

  const { filterValues, setFilters, hideFilter } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  function handleSubmit(values) {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter('main');
    }
  }

  function onClearAll() {
    form.reset();
    setFilters({}, []);
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Box display='flex' mb={1} style={{ display: 'inline-block' }}>
            <Box component='span' mr={1}>
              <SelectInput
                variant='outlined'
                source='status'
                style={{ width: '200px' }}
                choices={claimMGAStatusesFilter}
                label='Status'
                helperText={false}
                resettable
                alwaysOn
              />
            </Box>
            <Box component='span' mr={1}>
              <AutocompleteInput
                label='Assignee'
                style={{ width: '200px', display: 'inline-block' }}
                source='current_owner_email'
                choices={opsList}
                helperText={false}
                optionText='email'
                optionValue='email'
                resettable
                variant='outlined'
              />
            </Box>

            <Box component='span' mr={1}>
              <SelectInput
                source='state'
                style={{ width: '200px' }}
                variant='outlined'
                helperText={false}
                choices={stateList}
                label='State'
                resettable
                alwaysOn
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                label='Claim Number'
                style={{ width: '200px' }}
                source='claim_number'
                helperText={false}
                variant='outlined'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                label='INS Number'
                helperText={false}
                style={{ width: '200px' }}
                source='insurance_number'
                variant='outlined'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                style={{ width: '200px' }}
                label='PEP Number'
                source='pep_number'
                helperText={false}
                variant='outlined'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                style={{ width: '200px' }}
                label='Wellness Number'
                source='wellness_number'
                helperText={false}
                variant='outlined'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                style={{ width: '200px' }}
                label={`C&F Claim Id`}
                source='cnf_claim_id'
                variant='outlined'
                helperText={false}
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                style={{ width: '200px' }}
                label='PKN Id'
                source='customer_external_id'
                variant='outlined'
                helperText={false}
              />
            </Box>

            <Box component='span' mr={1}>
              <SelectInput
                style={{ width: '200px' }}
                variant='outlined'
                helperText={false}
                source='is_under_financial_review'
                choices={[
                  { id: 'open', name: 'Yes' },
                  { id: 'closed', name: 'No' },
                ]}
                label='Is under Financial Review'
                resettable
                alwaysOn
              />
            </Box>

            <Box component='span' mr={1}>
              <SelectInput
                style={{ width: '200px' }}
                variant='outlined'
                helperText={false}
                source='claim_type'
                choices={[
                  { id: 'INSURANCE', name: 'Insurance' },
                  { id: 'PREVENT', name: 'Prevent' },
                  { id: 'WELLNESS', name: 'Wellness' },
                ]}
                label='Contains Subclaim Type'
                resettable
                alwaysOn
              />
            </Box>

            <Box component='span' mr={1}>
              <SelectInput
                style={{ width: '200px' }}
                variant='outlined'
                helperText={false}
                source='claim_type_informed_by_user'
                choices={[
                  { id: 'INSURANCE', name: 'Insurance' },
                  { id: 'PREVENT', name: 'Prevent' },
                  { id: 'WELLNESS', name: 'Wellness' },
                ]}
                label='Claim Type'
                resettable
                alwaysOn
              />
            </Box>

            <div style={{ display: 'flex', marginBottom: '16px', marginTop: '8px' }}>
              <Button
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#007DFF',
                  color: 'white',
                  width: '120px',
                  marginRight: '8px',
                }}
                type='submit'
              >
                Filter
              </Button>
              <Button
                variant='outlined'
                color='primary'
                type='button'
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#007DFF',
                  color: 'white',
                  width: '120px',
                }}
                onClick={() => onClearAll()}
              >
                Clear All
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
}
export default ClaimLibraryFilter;
