import CloseIcon from '@mui/icons-material/Close';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Card } from '@mui/material';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { AnnualCapUtilization, EntitlementTable } from '@pumpkincare/shared';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { transformUtilizationMga } from '../../utils/utilization-utils';
import CircularProgress from '../SanitizedComponents/CircularProgress';
import { utilizationMgaStyle } from './utilization-mga-style';

export default function UtilizationMga({
  petPlanData,
  style,
  onClose = () => {},
  renderCloseIcon = true,
}) {
  const classes = utilizationMgaStyle();

  const [isAnnualCap, toggleAnnualCap] = useBooleanInput(false);

  const utilization = petPlanData ? transformUtilizationMga(petPlanData) : null;

  return (
    <Card className={classes.card} style={style}>
      <div className={classes.cardHeader}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className={classes.cardTitle}>Prevent Utilization</Typography>
          {renderCloseIcon ? (
            <IconButton
              style={{ padding: '12px 12px 0px 12px' }}
              onClick={onClose}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>

        <Typography className={classes.typeInfo}>
          TYPE: {isAnnualCap ? 'ANNUAL CAP ' : 'ENTITLEMENT'}
          <CompareArrowsIcon
            className={classes.toggleView}
            onClick={toggleAnnualCap}
          />
        </Typography>
      </div>

      {!utilization ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.infoContainer}>
            <div>
              <Typography className={classes.typeInfo}>PET</Typography>
              <Typography className={classes.typeDetail}>
                {utilization.pet.name}
              </Typography>
            </div>
            <div>
              <Typography className={classes.typeInfo}>CUSTOMER</Typography>
              <Typography className={classes.typeDetail}>
                {utilization.user.name}
              </Typography>
            </div>
            <div>
              <Typography className={classes.typeInfo}>PREVENT TYPE</Typography>
              <Typography className={classes.typeDetail}>
                {utilization.plan.description}
              </Typography>
              <Typography className={classes.typeDetail}>
                {utilization.plan.dateRange}
              </Typography>
              {utilization.plan.reachedLimit ? (
                <Typography className={classes.reachedLimit}>
                  REACHED LIMIT
                </Typography>
              ) : null}
            </div>
          </div>

          {isAnnualCap ? (
            <AnnualCapUtilization data={utilization.plan.annualCap} />
          ) : (
            <EntitlementTable data={utilization.plan.entitlements} />
          )}
        </>
      )}
    </Card>
  );
}

UtilizationMga.propTypes = {
  petPlanData: PropTypes.object,
  style: PropTypes.object,
  onClose: PropTypes.func,
  renderCloseIcon: PropTypes.bool,
};
