import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

function CancelAndReturnModal({ open, cancelTo, onReturn, title, subtitle, sx }) {
  return (
    <Dialog open={open} onClose={onReturn} sx={sx}>
      <DialogTitle sx={{ padding: '24px 32px 8px' }}>
        <Typography
          sx={{ fontSize: '24px', lineHeight: '32px', textAlign: 'center' }}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ padding: '0 32px' }}>
        {subtitle ? (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#595959',
              letterSpacing: '0.15px',
            }}
          >
            {subtitle}
          </Typography>
        ) : null}
      </DialogContent>

      <DialogActions
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '0 16px',
          padding: '24px 32px',
        }}
      >
        <Button
          onClick={onReturn}
          variant='outlined'
          color='secondary'
          type='button'
        >
          Return to Request
        </Button>
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to={cancelTo}
          type='button'
        >
          Confirm Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelAndReturnModal.defaultProps = {
  sx: {},
  subtitle: '',
};

CancelAndReturnModal.propTypes = {
  sx: PropTypes.object,
  open: PropTypes.bool.isRequired,
  cancelTo: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }).isRequired,
  onReturn: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default CancelAndReturnModal;
