import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import { paperlessSettingsModalStyle } from './paperless-settings-modal-style';

function PaperlessSettingsModal(props) {
  const {
    onClose,
    onSaveButtonClick,
    isSaving,
    saveButtonLabel,
    isPaperless,
    emailAddress,
  } = props;
  const [isPaperlessSet, togglePaperless] = useBooleanInput(isPaperless);
  const [isDisclaimerRead, toggleDisclaimerRead] = useBooleanInput(false);

  const classes = paperlessSettingsModalStyle();

  return (
    <Dialog open classes={{ paper: classes.paper }} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.modalTitle}>Paperless Setting</Typography>
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <div className={classes.switchContainer}>
          <Switch
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            disabled={isSaving}
            checked={isPaperlessSet}
            onChange={togglePaperless}
          />

          <Typography>Switch on to enable paperless.</Typography>
        </div>
        {isPaperlessSet ? (
          <>
            <Typography className={classes.textContent}>
              Enabling paperless means that documents will no longer be sent to their
              mailing address. Read the following disclaimer to the customer in order
              to verify their paperless settings.
            </Typography>
            <div className={classes.disclaimerContainer}>
              <Typography className={classes.textContent}>
                {`When your plan documents are available we'll send an email to ${emailAddress} with a 
            link to view your plan in the Member Center which should be within 24-48 hours. 
            This coverage contains an automatic renewal provision, which means we will 
            automatically issue you  a new plan each year using the payment method you have on file,
             unless you contact us to cancel. You will receive an email notifying you that
            your new policy is available in the Member Center, please look it over.
            It will include advance notice of any changes to your premium or
            coverage, prior to your next renewal.`}
              </Typography>
            </div>
            <div className={classes.textContent}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSaving}
                    onClick={() => toggleDisclaimerRead()}
                  />
                }
                label='I have read the disclaimer to the customer.'
              />
            </div>
          </>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.dialogButtonContainer}>
        <Button
          disabled={isSaving}
          onClick={onClose}
          className={classes.cancelButton}
        >
          CANCEL
        </Button>
        <Button
          disabled={isSaving || (isPaperlessSet && !isDisclaimerRead)}
          onClick={() => onSaveButtonClick(isPaperlessSet)}
          className={classes.addButton}
        >
          {saveButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PaperlessSettingsModal.defaultProps = {
  isSaving: false,
  saveButtonLabel: 'SAVE SETTINGS',
  isPaperless: false,
};

PaperlessSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  saveButtonLabel: PropTypes.string,
  isPaperless: PropTypes.bool,
  emailAddress: PropTypes.string.isRequired,
};

export default PaperlessSettingsModal;
