import { useMemo } from 'react';
import { MenuItem, Typography } from '@mui/material';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import { formatAgeRange } from '../../../../shared/utils/planUtils';
import { petQuoteCardStyle } from './PetQuoteCardStyle';

function PlanMenuItem({ plan, ...rest }) {
  const classes = petQuoteCardStyle();

  const description = useMemo(
    () => JSON.parse(plan.web_description).pickerModule.planDetails.join(', '),
    [plan.web_description]
  );

  return (
    <MenuItem {...rest} className={classes.planItem}>
      <Typography className={classes.planVersion}>
        PREVENT {plan.version_number.toFixed(1)}
      </Typography>

      <Typography className={classes.planTitle}>
        {plan.name} {formatAgeRange(plan.min_age_months, plan.max_age_months)}
      </Typography>

      <Typography className={classes.planDetails}>{description}</Typography>
      <Typography className={classes.planDetails}>
        Monthly Total: {formatCurrency(plan.cost)}
      </Typography>
    </MenuItem>
  );
}

export default PlanMenuItem;
