import { Show, TabbedShowLayout, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';

import { useFundingSource, usePetByUser } from '@pumpkincare/users';

import {
  CustomerAuditLogs,
  CustomerBilling,
  CustomerClaims,
  CustomerDetail,
  CustomerDocumentsV2,
  CustomerNotes,
  CustomerPetVets,
  CustomerShipment,
} from './view';

function UserTitle() {
  const record = useRecordContext();
  return (
    <span>
      Customer:{' '}
      {record
        ? `${record.first_name} ${record.last_name} - ${record.pumpkin_id}`
        : ''}
    </span>
  );
}

function CustomerShow() {
  const { id } = useParams();
  const { data: petData, isLoading: isPetDataLoading } = usePetByUser(id);
  const { data: fundingSourceData = [] } = useFundingSource(id);

  return (
    <Show resource='customers' actions={false} title={<UserTitle />}>
      <TabbedShowLayout
        sx={{
          '& .RaTabbedShowLayout-content': {
            padding: ' 22px 24px',
          },
        }}
      >
        <TabbedShowLayout.Tab label='Details'>
          {petData && petData.length ? (
            <CustomerDetail
              id={id}
              petListData={petData}
              isPetDataLoading={isPetDataLoading}
              fundingSourceData={fundingSourceData}
            />
          ) : null}
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='claims' label='Claims'>
          <CustomerClaims id={id} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='shipments' label='Shipments'>
          <CustomerShipment
            id={id}
            reference='shipments'
            target='user_id'
            title='Shipment'
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='billing' label='Billing'>
          <CustomerBilling title='Billing' />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='vets' label='Vets'>
          <CustomerPetVets title='Vets' />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='notes' label='Notes'>
          <CustomerNotes />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='documents' label='Documents'>
          <CustomerDocumentsV2 />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path='audit-logs' label='Audit Logs'>
          <CustomerAuditLogs />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default CustomerShow;
