import {
  Form,
  required,
  SelectInput,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { usePetPlans } from '@pumpkincare/users';

import { cancellationReasons } from '../../../constants/cancellationReasons';
import { parseAndFormatDate } from '../../../shared/utils';
import { petCancelModalStyle } from './pet-cancel-modal-style';

function PetCancelModal(props) {
  const { onClose, isOpen, policy_plan = {} } = props;
  const classes = petCancelModalStyle();
  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const { data: petPlanData = [] } = usePetPlans(policy_plan.pet_id);

  const petPlan = petPlanData.find(plan => plan.status === 'renew_pending');

  const hasPolicy = !!policy_plan.policy_end_date;
  const endDate = policy_plan.policy_effective_date || petPlan?.plan_effective_date;

  function handleSubmitCancellationReason({ cancellation_reason }) {
    create(
      `cancellations/renewals`,
      {
        data: {
          reason: cancellation_reason,
          pet_id: policy_plan.pet_id,
          type: hasPolicy ? 'policy' : 'plan',
        },
      },
      {
        onSuccess: () => {
          onClose();
          refresh();
        },
        onError: error => {
          notify(
            `There was an error while cancelling the renewal plan/policy: ${error.message}`,
            {
              type: 'warning',
            }
          );
        },
      }
    );
  }

  return (
    <Dialog maxWidth='md' open={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmitCancellationReason}>
        <DialogTitle>
          <Typography className={classes.modalTitle}>Confirm Non-Renewal</Typography>
          <Typography className={classes.modalSubTitle}>
            {`This ${
              hasPolicy ? 'policy' : 'plan'
            } will not be activated. This pet will no longer have
                coverage as of ${parseAndFormatDate(endDate)}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.informationDiv}>
            <Typography className={classes.information}>PET:</Typography>
            <Typography className={classes.information}>
              {policy_plan.pet_name}
            </Typography>
          </div>

          {policy_plan.policy_effective_date ? (
            <div className={classes.informationDiv}>
              <Typography className={classes.information}>POLICY PERIOD:</Typography>
              <Typography className={classes.information}>
                {`${parseAndFormatDate(
                  policy_plan.policy_effective_date
                )} - ${parseAndFormatDate(policy_plan.policy_end_date)}`}
              </Typography>
            </div>
          ) : null}
          {petPlan ? (
            <div className={classes.informationDiv}>
              <Typography className={classes.information}>
                PREVENTIVE ESSENTIALS PLAN PERIOD:
              </Typography>
              <Typography className={classes.information}>
                {`${parseAndFormatDate(
                  petPlan.plan_effective_date
                )} - ${parseAndFormatDate(petPlan.plan_end_date)}`}
              </Typography>
            </div>
          ) : null}

          <SelectInput
            fullWidth
            validate={required()}
            source='cancellation_reason'
            choices={cancellationReasons}
            label='Cancellation Reason'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={isLoading}
            type='submit'
            className={classes.updateButton}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

PetCancelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  policy_plan: PropTypes.object,
};

export default PetCancelModal;
