import makeStyles from '@mui/styles/makeStyles';

const claimBulkEditDeleteSection = makeStyles(
  {
    updateLineItemButton: {
      backgroundColor: '#5368F5',
      '&:hover': {
        backgroundColor: '#2A41DE',
      },
      borderRadius: '4px',
      color: 'white',
    },
    cancelUpdateLineItemButton: {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#5368F50A',
      },
      border: '1px solid #5368F580',
      borderRadius: '4px',
      color: '#5368F5',
    },
    toggleButtonSelected: {
      '&&': {
        backgroundColor: '#5368F5',
        color: 'white',
        '&:hover': {
          backgroundColor: '#5368F5',
          color: 'white',
        },
      },
    },
    toggleButtonRoot: {
      '&:hover': {
        backgroundColor: '#fff',
        color: '#red',
      },
    },
  },
  {
    name: 'claim-bulk-edit-delete-section',
  }
);

export { claimBulkEditDeleteSection };
