import { useRedirect } from 'react-admin';
import { Avatar, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import {
  ClaimDetailCard,
  GENDER_MAP,
  PlanPolicyDetailCard,
  SPECIES_MAP,
} from '@pumpkincare/shared';

import { parseAndFormatDate } from '../../../shared/utils';

const AVATAR_PLACEHOLDERS = {
  dog: '/assets/images/dog_placeholder.svg',
  cat: '/assets/images/cat_placeholder.svg',
};

function ClaimPolicyPlanInformation({
  petData = {},
  policyData = {},
  planData,
  wellnessData,
}) {
  const redirect = useRedirect();

  const policyStartDate = parseAndFormatDate(policyData?.start_date) || 'N/A';
  const policyEndDate = parseAndFormatDate(policyData?.end_date) || 'N/A';
  const policyDates = `Policy Effective Dates: ${policyStartDate} - ${policyEndDate}`;

  const planStartDate = parseAndFormatDate(planData?.start_date) || 'N/A';
  const planEndDate = parseAndFormatDate(planData?.end_date) || 'N/A';

  const wellnessStartDate = parseAndFormatDate(wellnessData?.start_date) || 'N/A';
  const wellnessEndDate = parseAndFormatDate(wellnessData?.end_date) || 'N/A';

  const planDates = `Plan Effective Dates: ${planStartDate} - ${planEndDate}`;
  const wellnessDates = `Effective Dates: ${wellnessStartDate} - ${wellnessEndDate}`;

  return (
    <div style={{ display: 'flex' }}>
      <ClaimDetailCard
        icon={
          <IconButton
            onClick={() => redirect(`/pets/${petData.id}/show`)}
            style={{ padding: '0px' }}
            size='large'
          >
            <Avatar
              sx={{
                width: '45px',
                height: '45px',
              }}
              alt={petData?.pet_photo?.location.split('/')[-1]}
              src={
                petData?.pet_photo?.location || AVATAR_PLACEHOLDERS[petData?.species]
              }
            />
          </IconButton>
        }
        title={petData.name}
        detail={`${SPECIES_MAP[petData.species]}, ${petData.breed}, Age ${
          petData.age
        }, ${GENDER_MAP[petData.gender]}`}
      />

      {policyData?.id && !policyData?.addedFakeId ? (
        <PlanPolicyDetailCard
          title={`INSURANCE ${
            policyData.state
          } (${policyData.namespace?.toUpperCase()}-${policyData.version})`}
          status={policyData.status}
          effectiveDatePeriod={policyDates}
          enrollmentDate={parseAndFormatDate(policyData?.enrollment_date) || 'N/A'}
          coverageStartDate={
            parseAndFormatDate(policyData?.coverage_start_date) || 'N/A'
          }
          accidentStartDate={
            parseAndFormatDate(policyData?.accident_coverage_start_date) || 'N/A'
          }
          isPolicy
        />
      ) : null}

      {planData && !planData?.addedFakeId ? (
        <PlanPolicyDetailCard
          title={`PREVENTIVE ESSENTIALS (${planData.version})`}
          status={planData.status}
          effectiveDatePeriod={planDates}
          enrollmentDate={parseAndFormatDate(planData?.enrollment_date) || 'N/A'}
          coverageStartDate={
            parseAndFormatDate(planData?.coverage_start_date) || 'N/A'
          }
          isPolicy={false}
        />
      ) : null}

      {wellnessData && !wellnessData?.addedFakeId ? (
        <PlanPolicyDetailCard
          title={`PUMPKIN WELLNESS CLUB (${petData?.species?.toUpperCase()} ${wellnessData.tier
            .split('.')[0]
            .toUpperCase()} PLAN)`}
          status={wellnessData.status}
          effectiveDatePeriod={wellnessDates}
          enrollmentDate={parseAndFormatDate(wellnessData?.start_date) || 'N/A'}
          isPolicy={false}
        />
      ) : null}
    </div>
  );
}
ClaimPolicyPlanInformation.propTypes = {
  petData: PropTypes.object,
  policyData: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    state: PropTypes.string,
    namespace: PropTypes.string,
    version: PropTypes.string,
    status: PropTypes.string,
    enrollment_date: PropTypes.string,
    coverage_start_date: PropTypes.string,
    accident_coverage_start_date: PropTypes.string,
    addedFakeId: PropTypes.bool,
  }),
  planData: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    addedFakeId: PropTypes.bool,
    version: PropTypes.string,
    status: PropTypes.string,
    enrollment_date: PropTypes.string,
    coverage_start_date: PropTypes.string,
  }),
  wellnessData: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    tier: PropTypes.string,
    status: PropTypes.string,
    addedFakeId: PropTypes.bool,
    coverage_start_date: PropTypes.string,
  }),
};

export default ClaimPolicyPlanInformation;
