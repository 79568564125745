import { CUSTOMER_POLICY_BASE_API } from '@pumpkincare/config';

export function searchVets(vet_name, zipcode = '00000') {
  return fetch(`${CUSTOMER_POLICY_BASE_API}/vet-practices/zipcode-suggestions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ vet_name, zipcode }),
  })
    .then(response => response.json())
    .then(response => response.body);
}
