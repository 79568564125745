import { Button } from '@mui/material';

import useBooleanInput from '../../hooks/useBooleanInput';
import CancellationUpdateFormDialog from './CancellationUpdateFormDialog';

export default function CancellationUpdateButton({ selectedIds }) {
  const [open, setOpen] = useBooleanInput(false);
  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <Button
        variant='contained'
        style={{
          color: 'white',
          backgroundColor: '#3F51B5',
        }}
        onClick={handleClick}
      >
        Update
      </Button>
      <CancellationUpdateFormDialog
        selectedIds={selectedIds}
        open={open}
        setOpen={setOpen}
        showCompleted={false}
        showReason={true}
      />
    </>
  );
}
