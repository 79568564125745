import { ArrayField, Datagrid, FunctionField, TextField } from 'react-admin';
import { Card, Typography } from '@mui/material';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import ResourceGrid from '../../../../components/ResourceGrid';
import { formatCurrency } from '../../../../shared/utils';
import { customerBillingStyle } from './CustomerBillingStyle';

export default function NextCharges({ nextCharges }) {
  const { punks1457AdminCustomerBilling } = useFlags();
  const classes = customerBillingStyle();
  const record = {
    next_charges: nextCharges,
  };

  const labelClassName = classNames(classes.label, {
    [classes.labelBilling]: punks1457AdminCustomerBilling,
  });

  return nextCharges.length ? (
    <>
      {punks1457AdminCustomerBilling ? (
        <Typography variant='subtitle2' sx={{ margin: '4px 0' }}>
          Upcoming Payments
        </Typography>
      ) : null}

      <Card
        className={classes.billingDetailsCard}
        variant={punks1457AdminCustomerBilling ? '' : 'outlined'}
      >
        <ResourceGrid record={record}>
          <ArrayField source='next_charges' label=''>
            <Datagrid
              bulkActionButtons={false}
              sx={{
                '& .RaDatagrid-headerCell': {
                  borderBottom: '0px',
                  ...(punks1457AdminCustomerBilling
                    ? { paddingLeft: '0', paddingRight: '64px' }
                    : {}),
                },
                '& .RaDatagrid-table': {
                  borderBottom: '0px',
                  ...(punks1457AdminCustomerBilling ? { width: 'unset' } : {}),
                },
                '& .RaDatagrid-rowCell': {
                  borderBottom: '0px',
                  ...(punks1457AdminCustomerBilling
                    ? { paddingLeft: '0', paddingRight: '64px', paddingBottom: '0' }
                    : {}),
                },
              }}
            >
              <FunctionField
                label={<Typography className={labelClassName}>PETS</Typography>}
                render={nextCharge => `${nextCharge.pet_names.join(', ')}`}
              />
              <TextField
                label={<Typography className={labelClassName}>STATUS</Typography>}
                source='status'
              />
              {punks1457AdminCustomerBilling ? (
                <TextField
                  sx={{ textTransform: 'capitalize' }}
                  label={<Typography className={labelClassName}>TYPE</Typography>}
                  source='product'
                />
              ) : null}

              <DateFieldIgnoringZone
                label={
                  <Typography className={labelClassName}>
                    NEXT CHARGE DATE
                  </Typography>
                }
                source='next_charge_date'
              />
              <FunctionField
                label={
                  <Typography className={labelClassName}>
                    {punks1457AdminCustomerBilling ? '' : 'CHARGE '}AMOUNT
                  </Typography>
                }
                render={nextCharge =>
                  `${formatCurrency(nextCharge.amount, { areCents: true })}`
                }
              />
            </Datagrid>
          </ArrayField>
        </ResourceGrid>
      </Card>
    </>
  ) : null;
}
