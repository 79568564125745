import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { MGA_APPROVED } from '../../../lib/claims/claim-status';

function ReimbursementActionButton(props) {
  const {
    reimbursement = {},
    claimStatus,
    onReissueClick,
    onConfirmCancellationClick,
    onRequestCancellationClick,
  } = props;
  let buttonLabel;
  let onButtonClick;
  if (reimbursement.status === 'cancelled' || reimbursement.status === 'failed') {
    buttonLabel = 'REISSUE';
    onButtonClick = onReissueClick;
  } else if (reimbursement.reimbursement_method === 'Check') {
    if (
      reimbursement.cancellations?.some(
        cancellation => cancellation.status === 'open'
      )
    ) {
      buttonLabel = 'CONFIRM';
      onButtonClick = onConfirmCancellationClick;
    } else if (claimStatus == MGA_APPROVED) {
      buttonLabel = 'CANCEL';
      onButtonClick = onRequestCancellationClick;
    }
  }

  return (
    <Button
      onClick={() => onButtonClick(reimbursement)}
      variant='contained'
      disabled={
        !buttonLabel || (buttonLabel == 'REISSUE' && !!reimbursement.reissued)
      }
    >
      {buttonLabel || 'CANCEL'}
    </Button>
  );
}

ReimbursementActionButton.propTypes = {
  reimbursement: PropTypes.object,
  onReissueClick: PropTypes.func.isRequired,
  onConfirmCancellationClick: PropTypes.func.isRequired,
  onRequestCancellationClick: PropTypes.func.isRequired,
};

export default ReimbursementActionButton;
