import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  ALL_MEDICAL_RECORDS_REQUEST,
  CLAIM_INVOICE_REQUEST,
  MEDICAL_RECORD_REQUEST,
} from '@pumpkincare/medical-records';

import { parseAndFormatDate } from '../../../../shared/utils';
import { requestRecordsPreviewStyle } from './request-records-preview-style';

function RequestRecordsPreview(props) {
  const {
    vetName,
    petName,
    firstName,
    lastName,
    phone,
    additionalOwner,
    documents,
    claimId,
    additionalNotes,
  } = props;
  const classes = requestRecordsPreviewStyle();
  const allMedicalRecords = documents.find(
    document => document.type === ALL_MEDICAL_RECORDS_REQUEST
  );
  const records = documents.filter(
    document => document.type === MEDICAL_RECORD_REQUEST
  );
  const invoice = documents.find(
    document => document.type === CLAIM_INVOICE_REQUEST
  );

  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.title}>
        Request Email Preview
      </Typography>

      <div className={classes.card}>
        <Typography variant='body1' className={classes.hi}>
          Hi, {vetName}!
        </Typography>
        <Typography variant='h5' className={classes.subtitle}>
          We need medical records to help process your client's pet insurance claim.
        </Typography>

        <div className={classes.divider} />

        <div className={classes.subsection}>
          <div className={classes.grid}>
            <Typography variant='body1'>Patient Name</Typography>
            <Typography variant='body1'>{petName}</Typography>

            <Typography variant='body1'>Owner Name</Typography>
            <div>
              <Typography variant='body1'>
                {firstName} {lastName}
              </Typography>
              <Typography variant='body1'>{phone}</Typography>
            </div>

            {additionalOwner?.trim() ? (
              <>
                <Typography variant='body1'>Additional Owner</Typography>
                <Typography variant='body1'>{additionalOwner}</Typography>
              </>
            ) : null}

            <Typography variant='body1'>Documents Requested</Typography>
            <div className={classes.documents}>
              {allMedicalRecords ? (
                <div>
                  <Typography variant='body1'>
                    All records from{' '}
                    {allMedicalRecords.date
                      ? parseAndFormatDate(allMedicalRecords.date, 'M/D/YYYY')
                      : 'first visit'}{' '}
                    to present day, including:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant='body1'>
                        Medical (SOAP/Doctor's) notes
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body1'>
                        Diagnostic Test Results
                      </Typography>
                    </li>
                  </ul>
                </div>
              ) : null}

              {records.length ? (
                <div>
                  {records.map(record => (
                    <Typography variant='body1' key={record.description}>
                      {parseAndFormatDate(record.date, 'M/D/YYYY')}:{' '}
                      {record.description}
                    </Typography>
                  ))}
                </div>
              ) : null}

              {invoice ? (
                <Typography variant='body1'>
                  A paid invoice from {parseAndFormatDate(invoice.date, 'M/D/YYYY')}
                </Typography>
              ) : null}
            </div>

            {claimId ? (
              <>
                <Typography variant='body1'>Claim ID</Typography>
                <Typography variant='body1'>{claimId}</Typography>
              </>
            ) : null}
          </div>

          {additionalNotes ? (
            <div className={classes.notesContainer}>
              <Typography variant='body1'>
                <b>Note from Pumpkin Claims Team</b>
              </Typography>
              <div className={classes.notes}>
                <Typography variant='body1'>{additionalNotes}</Typography>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* to deal with overflow issues */}
      <div className={classes.spacer} />
    </div>
  );
}

RequestRecordsPreview.propTypes = {
  vetName: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  additionalOwner: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        ALL_MEDICAL_RECORDS_REQUEST,
        MEDICAL_RECORD_REQUEST,
        CLAIM_INVOICE_REQUEST,
      ]),
      date: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  claimId: PropTypes.string,
  additionalNotes: PropTypes.string,
};

export default RequestRecordsPreview;
