import { CREATE, GET_MANY, GET_ONE, UPDATE } from 'react-admin';

import { CLOUDFLARE_WORKER_API } from '@pumpkincare/config';

import {
  CUSTOMER_POLICY_BASE_API,
  PERSONALIZATION_BASE_API,
} from '../../../../lib/config';
import { dataProvider } from '../../../../lib/dataProvider/provider';
import RoutePaths from '../../../../routes';

export function validateGroupDiscountPasscode(passcode) {
  return dataProvider(CREATE, RoutePaths.discounts, {
    data: { passcode: passcode },
    api: 'CUSTOMER',
    returnJson: 'BODY',
  }).then(response => response.data);
}

export function postCustomerQuote(customer) {
  return dataProvider(CREATE, RoutePaths.quotes, {
    data: customer,
    returnJson: 'DATA',
  });
}

export function putCustomerQuote(quote) {
  return dataProvider(UPDATE, RoutePaths.quotes, {
    data: quote,
    id: quote.id,
    returnJson: 'DATA',
  });
}

export function postPetQuote(pet, quoteId) {
  return dataProvider(CREATE, `${RoutePaths.quotes}/${quoteId}/${RoutePaths.pets}`, {
    data: pet,
    returnJson: 'DATA',
  });
}

export function postRegisterQuote(quote, quoteId) {
  return dataProvider(CREATE, `${RoutePaths.quotes}/${quoteId}/register-quote`, {
    data: quote,
    returnJson: 'DATA',
  });
}

export function getPlan(species, ageInMonths, vet) {
  return dataProvider(GET_ONE, RoutePaths.plans, {
    id: `${species}/${ageInMonths}`,
    api: 'CUSTOMER',
    returnJson: 'BODY',
    query: vet ? { vet_id: vet.id } : null,
  }).then(response => {
    return response.data;
  });
}

export function getPolicyOptions(quoteId, petId) {
  return dataProvider(
    GET_ONE,
    `${RoutePaths.quotes}/${quoteId}/${RoutePaths.pets}`,
    {
      id: `${petId}/policy-options`,
      returnJson: 'RAW',
    }
  )
    .then(response => response.data)
    .then(data => {
      return {
        deductibles: data.deductibles,
        limits: data.limits,
        prices: data.prices,
      };
    })
    .catch(() => {
      return {
        deductibles: [],
        limits: [],
        prices: {},
      };
    });
}

export function putPetQuoteDetail(quotes, quoteId) {
  return dataProvider(UPDATE, `${RoutePaths.quotes}`, {
    data: quotes,
    id: `${quoteId}/${RoutePaths.pets}`,
  });
}

export function patchPetQuote(pet, quoteId) {
  return dataProvider('PATCH', `${RoutePaths.quotes}`, {
    data: pet,
    id: `${quoteId}/${RoutePaths.pets}`,
  });
}

export function getTransactionFee(quoteId) {
  return dataProvider(CREATE, `quotes-v3/${quoteId}/transaction-fee`, {
    returnJson: 'BODY',
    api: 'CUSTOMER',
  });
}

export function getDiscountDetail(quotePetId) {
  return dataProvider(GET_ONE, `${RoutePaths.quotes}/multipet`, {
    id: `${quotePetId}/getdiscounts`,
    returnJson: 'BODY',
    api: 'CUSTOMER',
  })
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getQuoteLink(quote) {
  return dataProvider(GET_ONE, RoutePaths.quotes, {
    id: `${quote.id}/deep-link`,
  }).then(response => {
    return response.data.url;
  });
}

export function postSendQuoteByEmail(emails, quoteId) {
  return dataProvider(CREATE, `${RoutePaths.quotes}/${quoteId}/deep-link`, {
    data: { emails: emails },
  });
}

export function getQuoteDetail(quoteId) {
  return dataProvider(GET_ONE, RoutePaths.quotes, {
    id: quoteId,
  });
}

export function getQuotesByEmail(quoteEmail) {
  return dataProvider(GET_MANY, RoutePaths.quotes, {
    email: quoteEmail,
    isQuote: true,
  });
}

export function getUserDetail(userId) {
  return dataProvider(GET_ONE, 'users', {
    id: userId,
  });
}

export function getPetsByUserId(userId) {
  return dataProvider(GET_MANY, RoutePaths.pets, {
    userId: userId,
    isPet: true,
  });
}

export function getEmbarkDiscountByState(state) {
  return fetch(`${CLOUDFLARE_WORKER_API}/v1/embark-state-discount/${state}`, {
    method: 'GET',
  }).then(response => response.json());
}

export function getPetBreedList(species) {
  return fetch(`${CLOUDFLARE_WORKER_API}/v1/breeds/${species}`, {
    method: 'GET',
  }).then(response => response.json());
}

export function postFinalizeQuote(
  quoteId,
  shippingAddress,
  billingAddress,
  phoneNumber,
  stripeToken,
  billedAnnually,
  isPaperless
) {
  return dataProvider(CREATE, `${RoutePaths.quotes}/${quoteId}/finalize`, {
    data: {
      billing_information: billingAddress,
      phone: phoneNumber,
      shipping_information: shippingAddress,
      stripeToken: stripeToken,
      billed_annually: billedAnnually,
      is_paperless: isPaperless,
    },
    returnJson: 'DATA',
  });
}

export function postFinalizeAddAPetQuote(quoteId, payload = {}) {
  return dataProvider(CREATE, `${RoutePaths.quotes}/${quoteId}/finalize/add-a-pet`, {
    data: payload,
    returnJson: 'DATA',
  });
}

export function createIdentity() {
  return fetch(`${PERSONALIZATION_BASE_API}/identities`, {
    method: 'POST',
  }).then(response => response.json());
}

export function updateIdentity(identity, quote) {
  return fetch(`${PERSONALIZATION_BASE_API}/identities/${identity}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: quote.email,
      first_name: quote.first_name,
      last_name: quote.last_name,
      city: quote.city,
      state: quote.policy_state,
      zipcode: quote.policy_zipcode,
    }),
  });
}

export function postIdentityHdyhau(id, payload) {
  return fetch(`${PERSONALIZATION_BASE_API}/identities/${id}/hdyhau`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export function getAddonList(species, age, partner) {
  const partnerProp = partner ? `&partner=${partner}` : '';
  return fetch(
    `${CUSTOMER_POLICY_BASE_API}/${RoutePaths.addons}?species=${species}&age=${age}${partnerProp}`,
    {
      method: 'GET',
    }
  ).then(response => response.json());
}

export function putPetAddon(quoteId, petId, payload) {
  return dataProvider(
    UPDATE,
    `${RoutePaths.quotes}/${quoteId}/pets/${petId}/addons`,
    {
      data: payload,
      returnJson: 'DATA',
    }
  );
}
