import { useState } from 'react';
import { useNotify } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { usePet } from '@pumpkincare/users';

import Tile from '../../shared/components/Tile';
import VetInput from '../../shared/components/VetInput';
import { linkVet } from './vetService';

function PetVet(props) {
  const { petId, onChange, showTile, address } = props;
  const notify = useNotify();

  const { data: petData = {} } = usePet(petId);

  const data = { vet: petData.latest_vet, customerZipcode: address.zipcode };
  const [vet, setVet] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const currentVet = isEditMode
    ? ''
    : data?.vet?.vet_name || <i style={{ color: 'rgba(0, 0, 0, 0.6)' }}>none</i>;

  function handleUpdateClick() {
    if (!vet || vet?.id === data?.vet?.id) {
      setEditMode(false);
      return;
    }

    setIsUpdating(true);

    linkVet(petId, vet.id)
      .then(() => {
        onChange(vet);
        setVet(null);
      })
      .catch(error => {
        notify(error.message, 'error');
      })
      .finally(() => {
        setIsUpdating(false);
        setEditMode(false);
      });
  }

  function handleCancelClick() {
    setEditMode(false);
    setVet(null);
  }

  return (
    <div
      style={
        !isEditMode
          ? { display: 'flex', alignItems: showTile ? 'flex-end' : 'center' }
          : {}
      }
    >
      {showTile ? <Tile title={'Vet Clinic'} content={currentVet} /> : currentVet}

      {isEditMode ? (
        <>
          <VetInput
            source='vet'
            zipcode={address.zipcode}
            formData={{ ...data }}
            onChange={setVet}
            disabled={isUpdating}
          />

          <div>
            <Button
              style={{ color: '#3700B3' }}
              onClick={handleUpdateClick}
              disabled={isUpdating}
            >
              {isUpdating ? 'UPDATING…' : 'UPDATE'}
            </Button>
            <Button
              color='primary'
              onClick={handleCancelClick}
              disabled={isUpdating}
              style={{ color: '#3700B3' }}
            >
              CANCEL
            </Button>
          </div>
        </>
      ) : (
        <>
          <Button
            color='primary'
            startIcon={data?.vet ? <EditIcon /> : <AddIcon />}
            onClick={() => setEditMode(true)}
            style={{ marginBottom: showTile ? '-6px' : '0', marginLeft: '16px' }}
          >
            {data?.vet ? 'EDIT' : 'ADD'}
          </Button>
        </>
      )}
    </div>
  );
}

PetVet.propTypes = {
  petId: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showTile: PropTypes.bool,
};

PetVet.defaultProps = {
  showTile: true,
};

export default PetVet;
