import { LoadingIndicator } from 'react-admin';
import { AppBar, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ENVIRONMENT } from '@pumpkincare/config';

import PumpkinUserMenu from './PumpkinUserMenu';

function MgaAppBar(props) {
  const APP_COLORS = {
    local: '#373F51',
    dev: '#FFA4AC',
    staging: '#FEC600',
    production: '#007DFF',
    review: '#00FF2A',
    sandbox: '#B237CD',
    training: '#127E79',
  };
  // do not pass alwaysOn to AppBar
  // eslint-disable-next-line no-unused-vars
  const { alwaysOn, ...rest } = props;

  return (
    <AppBar
      style={{ backgroundColor: APP_COLORS[ENVIRONMENT] || '#007DFF' }}
      {...rest}
    >
      <Toolbar style={{ justifyContent: 'space-between', minHeight: '60px' }}>
        <div style={{ display: 'flex' }}>
          <img
            style={{ width: '111px' }}
            src='/assets/images/Pumpkin_Logo_White.svg'
          />
          {ENVIRONMENT !== 'production' ? (
            <sup
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {ENVIRONMENT === 'staging' ? 'QA' : ENVIRONMENT}
            </sup>
          ) : null}
        </div>

        <Typography
          style={{
            fontSize: '24px',
            fontWeight: '400',
            lineHeight: '24px',
            width: '70%',
            marginLeft: '48px',
          }}
          id='react-admin-title'
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '280px',
            justifyContent: 'flex-end',
          }}
        >
          <LoadingIndicator />
          <PumpkinUserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}

MgaAppBar.defaultProps = {
  alwaysOn: false,
};

MgaAppBar.propTypes = {
  alwaysOn: PropTypes.bool,
};

export default MgaAppBar;
