export const CANCELLATION_STATUS_COLORS = {
  cancellation_received: {
    name: 'TO DO',
    color: '#00000014',
    textColor: '#000000',
  },
  cancellation_pending: {
    name: 'TO DO',
    color: '#00000014',
    textColor: '#000000',
  },
  cancellation_completed: {
    name: 'Cancelled',
    color: '#D50000',
    textColor: '#FFFFFF',
  },
};
