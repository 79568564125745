import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';

import { MGA_BUTTONS } from '../constants/CustomerButtons';

function CustomerNavBar({ userId }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {MGA_BUTTONS.map(item => {
          return (
            <Button
              key={item.link}
              component={Link}
              to={{
                pathname: `/customers/${userId}/${item.link}`,
              }}
            >
              {item.text}
            </Button>
          );
        })}
      </div>
      <Divider style={{ marginTop: '0px', marginLeft: '-24px', width: '105%' }} />
    </div>
  );
}

CustomerNavBar.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default CustomerNavBar;
