import {
  INVOICE_ITEM_TYPE_ADDON,
  INVOICE_ITEM_TYPE_FEE,
  INVOICE_ITEM_TYPE_OB,
  INVOICE_ITEM_TYPE_PLAN,
  INVOICE_ITEM_TYPE_POLICY,
  INVOICE_ITEM_TYPE_WELLNESS,
} from './invoiceItemTypes';

export const INVOICE_ITEM_DESCRIPTION_EMBARK = 'Embark DNA Kit';
export const INVOICE_ITEM_DESCRIPTION_FEE = 'Installment Fee';
export const INVOICE_ITEM_DESCRIPTION_OB_PEP =
  'Preventive Essentials Outstanding Balance';
export const INVOICE_ITEM_DESCRIPTION_OB_ADDON = 'Addon';
export const INVOICE_ITEM_DESCRIPTION_OB_WELLNESS = 'Wellness Outstanding Balance';
export const INVOICE_ITEM_DESCRIPTION_PEP = 'Preventive Essentials';
export const INVOICE_ITEM_DESCRIPTION_POLICY = 'Pumpkin Pet Insurance';
export const INVOICE_ITEM_DESCRIPTION_WELLNESS = 'Wellness';
export const INVOICE_ITEM_DESCRIPTION_OTHER = 'Other';

const invoiceItemTypeAddonDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_EMBARK,
    name: INVOICE_ITEM_DESCRIPTION_EMBARK,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

const invoiceItemTypeFeeDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_FEE,
    name: INVOICE_ITEM_DESCRIPTION_FEE,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

const invoiceItemTypeOBDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_OB_PEP,
    name: INVOICE_ITEM_DESCRIPTION_OB_PEP,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OB_ADDON,
    name: INVOICE_ITEM_DESCRIPTION_OB_ADDON,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OB_WELLNESS,
    name: INVOICE_ITEM_DESCRIPTION_OB_WELLNESS,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

const invoiceItemTypePlanDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_OB_PEP,
    name: INVOICE_ITEM_DESCRIPTION_OB_PEP,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_PEP,
    name: INVOICE_ITEM_DESCRIPTION_PEP,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

const invoiceItemTypePolicyDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_POLICY,
    name: INVOICE_ITEM_DESCRIPTION_POLICY,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

const invoiceItemTypeWellnessDescriptions = [
  {
    id: INVOICE_ITEM_DESCRIPTION_WELLNESS,
    name: INVOICE_ITEM_DESCRIPTION_WELLNESS,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OB_WELLNESS,
    name: INVOICE_ITEM_DESCRIPTION_OB_WELLNESS,
  },
  {
    id: INVOICE_ITEM_DESCRIPTION_OTHER,
    name: INVOICE_ITEM_DESCRIPTION_OTHER,
  },
];

export const invoiceItemDescriptionsByType = {
  [INVOICE_ITEM_TYPE_ADDON]: invoiceItemTypeAddonDescriptions,
  [INVOICE_ITEM_TYPE_FEE]: invoiceItemTypeFeeDescriptions,
  [INVOICE_ITEM_TYPE_OB]: invoiceItemTypeOBDescriptions,
  [INVOICE_ITEM_TYPE_PLAN]: invoiceItemTypePlanDescriptions,
  [INVOICE_ITEM_TYPE_POLICY]: invoiceItemTypePolicyDescriptions,
  [INVOICE_ITEM_TYPE_WELLNESS]: invoiceItemTypeWellnessDescriptions,
};
