import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  useListContext,
  useNotify,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from '@mui/material/CircularProgress';

import AuditLogDetails from './audit-log-details';
import AuditLogsFilter from './audit-logs-filter';
function CopiableField({ label, source }) {
  const notify = useNotify();

  return (
    <FunctionField
      label={label}
      render={(record = {}) => {
        return (
          <CopyToClipboard
            text={record[source]}
            onCopy={() => {
              notify(`${record[source]} copied`, {});
            }}
          >
            <span>{record[source]}</span>
          </CopyToClipboard>
        );
      }}
      onClick={e => {
        e.stopPropagation();
      }}
      sortable={false}
    />
  );
}

function DatagridWrapper(props) {
  const { isLoading } = useListContext();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid
      {...props}
      bulkActionButtons={false}
      rowClick='expand'
      expand={<AuditLogDetails />}
    >
      <DateField showTime={true} label='Date' source='created_at' />
      <TextField label='Context' source='context' sortable={false} />
      <TextField label='Description' source='description' sortable={false} />
      <TextField label='Modified by' source='modified_by' sortable={false} />
      <CopiableField label='User ID' source='user_id' />
      <CopiableField label='Pet ID' source='pet_id' />
      <CopiableField label='Policy ID' source='policy_id' />
      <CopiableField label='Pet Plan ID' source='pet_plan_id' />
      <CopiableField label='Wellness ID' source='wellness_id' />
      <CopiableField label='Claim ID' source='claim_id' />
      <CopiableField label='Invoice ID' source='invoice_id' />
      <CopiableField label='Quote ID' source='quote_id' />
      <TextField label='Function' source='function' />
    </Datagrid>
  );
}

function AuditLogsList() {
  return (
    <List
      exporter={false}
      filters={<AuditLogsFilter />}
      actions={null}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
      perPage={25}
    >
      <DatagridWrapper />
    </List>
  );
}

export default AuditLogsList;
