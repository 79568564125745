import makeStyles from '@mui/styles/makeStyles';

const petDetailHeaderStyle = makeStyles(
  {
    petHeader: {
      display: 'flex',
    },
    petHeaderText: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.54);',
      textTransform: 'capitalize',
    },
    line: {
      borderTop: '1px solid #000000',
    },
  },
  { name: 'pet-detail-header' }
);

export { petDetailHeaderStyle };
