import PropTypes from 'prop-types';

import { getFullPercentage } from '../../../../shared/utils/petUtils';

function PendingPolicyChangeDetail({ pendingPolicyChange }) {
  if (!pendingPolicyChange) {
    return null;
  }

  const reimbursementRate = getFullPercentage(pendingPolicyChange.args?.coinsurance);

  const premium =
    pendingPolicyChange.args?.premium ||
    pendingPolicyChange.args?.filed_rate ||
    '0.00';

  const capped_rate = pendingPolicyChange.args?.capped_rate || '0.00';

  return (
    <div>
      <div>
        Pending Change Request: <b>{pendingPolicyChange.change_type}</b>
      </div>
      <div>
        <span>Requested Changes:</span>&nbsp;
        <span>Deductible = {pendingPolicyChange.args.deductible},</span>&nbsp;
        <span>Annual Limit = {pendingPolicyChange.args.annual_limit},</span>&nbsp;
        <span>Raw Rate = {pendingPolicyChange.args.raw_rate},</span>&nbsp;
        <span>Filed Rate = {pendingPolicyChange.args.filed_rate},</span>&nbsp;
        <span>Capped Rate = {capped_rate},</span>&nbsp;
        <span>Reimbursement Rate = {reimbursementRate},</span>&nbsp;
        <span>Premium = {premium}</span>&nbsp;
      </div>
    </div>
  );
}
PendingPolicyChangeDetail.propTypes = {
  pendingPolicyChange: PropTypes.object,
};

export default PendingPolicyChangeDetail;
