import makeStyles from '@mui/styles/makeStyles';

const requestRecordsPreviewStyle = makeStyles(
  {
    root: { maxWidth: '696px', width: '100%', margin: '0 auto' },
    title: { textAlign: 'center', marginBottom: '16px' },

    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '2px',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    hi: { textAlign: 'center', marginBottom: '24px' },
    subtitle: { textAlign: 'center', maxWidth: '512px', margin: '0 auto' },
    divider: {
      width: '100%',
      height: '1px',
      background: '#CDCFD3',
      margin: '16px auto',
    },

    subsection: {
      maxWidth: '435px',
      width: '100%',
      margin: '0 auto',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      gap: '16px 0',
      margin: '16px auto 8px',
      '& > *:nth-child(2n + 1)': { fontWeight: 'bold' },
      '& li': { marginTop: '8px' },
    },
    documents: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px 0',
      '& ul': { margin: '0', paddingInlineStart: '20px' },
    },
    notesContainer: { marginTop: '24px' },
    notes: {
      background: '#EBECEE',
      padding: '16px 24px',
      margin: '8px auto',
    },
    spacer: { width: '100%', height: '1px' },
  },
  {
    name: 'request-records-preview',
  }
);

export { requestRecordsPreviewStyle };
