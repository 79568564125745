import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { useBadgeStyles } from './custom-badge.styles';

function CustomBadge({
  badgeContent,
  bg = 'grey',
  children,
  color = 'black',
  show = true,
}) {
  const classes = useBadgeStyles();

  return (
    <div className={classes.wrapper}>
      {children}
      <Box
        sx={{
          padding: '0px 10px',
          borderRadius: '.25rem',
          background: bg,
          color: color,
          marginLeft: '.5rem',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '12px',
          letterSpacing: '-0.02em',
          visibility: show ? 'visible' : 'hidden',
          position: show ? 'static' : 'absolute',
          top: show ? 'initial' : '-9999',
          left: show ? 'initial' : '-9999',
          height: show ? 'auto' : '0',
        }}
      >
        {badgeContent}
      </Box>
    </div>
  );
}

CustomBadge.propTypes = {
  show: PropTypes.bool,
  badgeContent: PropTypes.elementType,
  children: PropTypes.elementType,
  bg: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};

CustomBadge.defaultProps = {
  show: true,
};

export default CustomBadge;
