const metadata = {
  user: {
    entity: 'user',
    templates: [
      { name: 'forgot_password', display_name: 'Forgot Password' },
      { name: 'statement', display_name: 'Latest Statement' },
    ],
  },
  pet: {
    entity: 'pet',
    templates: [
      { name: 'insurance_documents', display_name: 'Insurance Documents' },
      { name: 'prevent_documents', display_name: 'Prevent Documents' },
    ],
  },
  shipment: {
    entity: 'shipment',
    templates: [
      {
        name: 'simparica_out_the_door',
        display_name: 'Simparica Out The Door',
      },
      {
        name: 'revolutionplus_out_the_door',
        display_name: 'RevolutionPlus Out The Door',
      },
      {
        name: 'trio-out-the-door',
        display_name: 'Trio Out The Door',
      },
    ],
  },
  invoice: {
    entity: 'invoice',
    templates: [{ name: 'statement', display_name: 'Statement' }],
  },
};

export default metadata;
