import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

import {
  AUTH_SETTINGS,
  CUSTOMER_POLICY_BASE_API,
  POLICY_BASE_API,
} from '@pumpkincare/config';

import RoutePaths from '../../routes';
import { getInvoiceCreationPostBody } from './adapters/invoices';
import { getRefundsCreationPostBody } from './adapters/refunds';

const httpClient = fetchUtils.fetchJson;

function getAccessToken(authData) {
  const lastAuthUserTokenName = `CognitoIdentityServiceProvider.${authData.userPoolWebClientId}.LastAuthUser`;

  const lastAuthUser = localStorage.getItem(lastAuthUserTokenName);

  const accessTokenName = `CognitoIdentityServiceProvider.${authData.userPoolWebClientId}.${lastAuthUser}.accessToken`;

  return localStorage.getItem(accessTokenName);
}

function oidcGetAccessToken(authData) {
  const accessTokenName = `oidc.user:${authData.oauth.domain}:${authData.userPoolWebClientId}`;
  return JSON.parse(localStorage.getItem(accessTokenName)).access_token;
}

function configureOptions() {
  const options = {};
  options.headers = new Headers({ Accept: 'application/json' });
  options.mode = 'cors';
  let accessToken;
  if (AUTH_SETTINGS.useOidcLib) {
    accessToken = oidcGetAccessToken(AUTH_SETTINGS);
  } else {
    accessToken = getAccessToken(AUTH_SETTINGS);
  }
  options.headers.set('Authorization', `Bearer ${accessToken}`);

  return options;
}

function getApiBaseUrl(params) {
  const { api, custom_url } = params.meta || {};
  const options = {};
  let apiUrl = POLICY_BASE_API;

  if (api === 'CUSTOMER') {
    apiUrl = CUSTOMER_POLICY_BASE_API;
  } else if (['UTILS', 'WORKER'].includes(api)) {
    apiUrl = custom_url;
    options.api = api;
  }
  return apiUrl;
}

function addFakeId(data, resource, id) {
  if (!isNaN(data)) {
    return { data: { id: 1, addedFakeId: true } };
  }
  if (id === 'incident') {
    return { data: { id: 1, list: data } };
  }
  if (String(id).includes('reimbursements') || String(id).includes('notes')) {
    return { data: { id: 1, list: data } };
  }
  if (!data.id) {
    data.id = Math.random().toString(36);
    data.addedFakeId = true;
  }
  return { data: data };
}

export const providerV2 = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    if (resource === RoutePaths.claims && params.filter) {
      let customer;

      if (
        params.filter['first_name'] ||
        params.filter['last_name'] ||
        params.filter['pkn_number']
      ) {
        customer = {
          first_name: params.filter['first_name'],
          last_name: params.filter['last_name'],
          external_id: params.filter['pkn_number'],
        };
      }

      const assignedIdFilter =
        params.filter['assigned_id'] === 'null'
          ? null
          : params.filter['assigned_id'];

      query.filter = JSON.stringify({
        claim_number: params.filter['claim_number'],
        claim_type: params.filter['claim_type'],
        status: params.filter['status'],
        is_unassigned: params.filter['is_unassigned'],
        assigned_id: assignedIdFilter,
        is_active: params.filter['is_active'],
        external_claim_id: params.filter['external_claim_id'],
        pet_name: params.filter['pet_name'],
        current_owner_email: params.filter['current_owner_email'],
        state: params.filter['state'],
        customer,
      });
    }

    if (resource === RoutePaths.invoices && params.filter) {
      const filters = {};
      if (params.filter['created_at_gte']) {
        filters.created_at_gte = params.filter['created_at_gte'];
        delete params.filter['created_at_gte'];
      }

      if (params.filter['pet_names']) {
        filters.pet_names = params.filter['pet_names'];
        delete params.filter['pet_names'];
      }

      query.filters = JSON.stringify(filters);
      query.filter = JSON.stringify(params.filter);
    }

    if (resource === 'dashboard') {
      resource = 'claims';
      if (params.filter) {
        const filters = {};
        if (params.filter['only_uncompleted']) {
          filters.only_uncompleted = params.filter['only_uncompleted'];
          delete params.filter['only_uncompleted'];
        }

        query.filters = JSON.stringify(filters);
        query.filter = JSON.stringify(params.filter);
      }
    }

    if (
      resource === RoutePaths.claimsLibrary &&
      Object.keys(params.filter).length > 0
    ) {
      resource = 'claims';
    }

    if (resource === RoutePaths.customers) {
      resource = 'users';
    }

    const options = configureOptions();

    if (
      resource === RoutePaths.claimsLibrary &&
      Object.keys(params.filter).length === 0
    ) {
      return { data: [{ id: 0 }], total: 0 };
    }

    const url = `${getApiBaseUrl(params)}/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url, options);

    return resource === 'funding-sources'
      ? {
          data: json.body?.data
            ? json.body.data
                .map(fundingSource => {
                  fundingSource.id = fundingSource.user_id;
                  return fundingSource;
                })
                .filter(d => d)
            : [],
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }
      : {
          data: json.body?.data ? json.body.data.filter(d => d) : [],
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
  },

  getOne: async (resource, params) => {
    let resourceFormatted = resource;
    if (resource === RoutePaths.customers) {
      resourceFormatted = 'users';
    }
    let url = resourceFormatted
      ? `${getApiBaseUrl(params)}/${resourceFormatted}/${params.id}`
      : `${getApiBaseUrl(params)}/${params.id}`;

    if (params.query) {
      url += `?${stringify(params.query)}`;
    }

    const options = configureOptions();

    if (params.meta?.api === 'WORKER') {
      options.headers.set('access-control-allow-origin', '*');
      options.headers.delete('Authorization');
    }

    const { json } =
      params?.meta?.api === 'UTILS'
        ? await httpClient(url)
        : await httpClient(url, options);
    return addFakeId(json.body?.data || json.body || json, resource, params.id);
  },

  getMany: async (resource, params) => {
    let resourceFormatted = resource;
    if (resource === RoutePaths.customers) {
      resourceFormatted = 'users';
    }
    let query;
    if (params.isQuote) {
      query = {
        filter: JSON.stringify({ email: params.email }),
      };
    } else if (params.isPet) {
      query = {
        filter: JSON.stringify({ user_id: params.userId }),
      };
    } else {
      query = { filter: JSON.stringify({ id: params.ids }) };
    }
    const options = configureOptions();

    const url = `${getApiBaseUrl(params)}/${resourceFormatted}?${stringify(query)}`;
    const { json } = await httpClient(url, options);
    return { data: json.body?.data || json.body || json };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const options = configureOptions();

    // Replace the resource is to align with our notes endpoint path
    if (resource === RoutePaths.notes && params.target === 'user_id') {
      resource = `users/${params.id}/notes`;
    }

    const url = `${getApiBaseUrl(params)}/${resource}?${stringify(query)}`;

    const { json, headers } = await httpClient(url, options);

    return {
      data: json.body?.data || json.body || json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    };
  },

  create: async (resource, params) => {
    let convertedResource = resource;
    let postBody = params.data;
    if (resource === RoutePaths.notes) {
      convertedResource = `users/${params.data.user_id}/notes`;
    }

    if (resource === RoutePaths.refunds) {
      convertedResource = `invoices/${params.data.id}/refunds`;
      postBody = getRefundsCreationPostBody(params);
    }

    if (resource === RoutePaths.invoices) {
      postBody = getInvoiceCreationPostBody(params);
    }

    const options = configureOptions();

    const { json } = await httpClient(
      `${getApiBaseUrl(params)}/${convertedResource}`,
      {
        ...options,
        method: 'POST',
        body: JSON.stringify(postBody),
      }
    );
    return addFakeId(json?.body?.data || json?.body || json || { id: 0 });
  },

  update: async (resource, params) => {
    let url = `${getApiBaseUrl(params)}/${resource}`;
    let method = 'PUT';
    let body = params.data;

    if (params.meta?.method) {
      method = params.meta.method;
    }

    if (params.id) {
      url += `/${params.id}`;
    }

    if (resource === RoutePaths.addresses) {
      body.state_province = body.state;
    }

    if (
      [
        RoutePaths.invoices,
        RoutePaths.medicalRecordsRequests,
        RoutePaths.wellnessQuote,
      ].some(path => path === resource)
    ) {
      method = 'PATCH';
    }

    const options = configureOptions();

    const { json } = await httpClient(url, {
      ...options,
      method: method,
      body: JSON.stringify(body),
    });
    return addFakeId(json?.body?.data || json?.body || json);
  },

  updateMany: async (resource, params) => {
    const options = configureOptions();

    return Promise.all(
      params.ids.map(id =>
        httpClient(`${getApiBaseUrl(params)}/${resource}/${id}`, {
          ...options,
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({
      data: responses.map(
        response => response.body?.data || response.body || response
      ),
    }));
  },

  delete: async (resource, params) => {
    const options = configureOptions();
    let body = params.meta?.data || {};
    const url = `${getApiBaseUrl(params)}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      ...options,
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    return { data: json };
  },

  deleteMany: async (resource, params) => {
    const options = configureOptions();
    const url = `${getApiBaseUrl(params)}/${resource}/${params.ids}`;
    const { json } = await httpClient(url, {
      ...options,
      method: 'DELETE',
      body: JSON.stringify(params.meta),
    });
    return { data: [json] };
  },
};
