import { SvgIcon } from '@mui/material';

function ChainIcon() {
  return (
    <SvgIcon>
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5753 14.9897C10.8389 14.8654 9.24207 13.993 8.19933 12.5989C7.86852 12.1567 7.95886 11.53 8.40111 11.1992C8.84336 10.8684 9.47005 10.9587 9.80086 11.401C10.496 12.3303 11.5606 12.9119 12.7182 12.9949C13.8758 13.0778 15.0124 12.6538 15.833 11.8329L18.8208 8.84525C20.3367 7.27574 20.315 4.78092 18.7721 3.23798C17.2291 1.69505 14.7343 1.67337 13.1751 3.17913L11.4551 4.88913C11.0635 5.27852 10.4303 5.27667 10.0409 4.88501C9.65154 4.49335 9.65339 3.86019 10.0451 3.4708L11.7754 1.75068C14.1297 -0.523155 17.8719 -0.490636 20.1863 1.82377C22.5007 4.13817 22.5332 7.8804 20.2472 10.2471L17.2473 13.2469C16.0166 14.4782 14.3117 15.1141 12.5753 14.9897ZM9.42488 7.01019C11.1613 7.13455 12.7581 8.00696 13.8009 9.40099C14.1317 9.84324 14.0413 10.4699 13.5991 10.8007C13.1568 11.1315 12.5301 11.0412 12.1993 10.5989C11.5042 9.66959 10.4396 9.08799 9.28201 9.00508C8.12439 8.92217 6.98784 9.34614 6.1672 10.1671L3.17938 13.1547C1.66349 14.7242 1.68517 17.219 3.22811 18.762C4.77104 20.3049 7.26587 20.3266 8.82299 18.8229L10.533 17.1129C10.9235 16.7223 11.5567 16.7223 11.9472 17.1129C12.3377 17.5034 12.3377 18.1365 11.9472 18.5271L10.2248 20.2493C7.87053 22.5231 4.1283 22.4906 1.81389 20.1762C-0.500509 17.8618 -0.533028 14.1195 1.75299 11.7529L4.75286 8.75299C5.98362 7.52178 7.68845 6.88583 9.42488 7.01019Z'
          fill='#5368F5'
        />
      </svg>
    </SvgIcon>
  );
}

export default ChainIcon;
