import { useRecordContext } from 'react-admin';
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone';
import { Typography } from '@mui/material';

import { claimDiagnosisStyle } from './claim-diagnosis-style';

export default function ClaimDiagnosis() {
  const record = useRecordContext();
  const classes = claimDiagnosisStyle();

  return (
    <div style={{ marginBottom: '42px' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '530px', marginRight: '56px' }}>
          <Typography className={classes.title}>
            OCCURRENCE / DIAGNOSIS STORY
          </Typography>
          <Typography className={classes.detail}>
            {record.diagnosis_story}
          </Typography>
        </div>
        <div>
          <Typography className={classes.title}>REIMBURSEMENT TO</Typography>
          {record.send_reimbursement_to_vet ? (
            <div style={{ display: 'flex' }}>
              <OutlinedFlagTwoToneIcon className={classes.flag} />
              <Typography className={classes.vetText}>VET REIMBURSEMENT</Typography>
            </div>
          ) : (
            <Typography className={classes.detail}>Customer</Typography>
          )}
        </div>
      </div>
    </div>
  );
}
