import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { searchVets } from '../../services/vetServices';

const useStyles = makeStyles(
  {
    // Autocomplete classes
    root: {
      paddingTop: '4px',
    },
    inputRoot: {
      paddingLeft: '12px',
    },
    endAdornment: {
      right: '8px',
      top: '-8px',
    },

    // renderOption classes
    option: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 16px',
      cursor: 'pointer',
      '&:hover': {
        background: '#EEF0FE',
      },
    },
    optionName: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    optionAddress: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    // renderInput classes
    textLabel: {
      margin: 0,
      width: '100%',
      alignItems: 'flex-start',
      '& span': { fontSize: '14px' },
      '& > div > div': { background: 'white' }, // input only
    },
    required: { '& span': { color: '#C21B44', fontSize: '16px' } },
    searchAdornment: {
      position: 'absolute',
      right: '8px',
    },
    clearButton: { padding: '0px', color: '#2A41DE' },
    formHelperText: { marginLeft: 0 },

    // pep4 classes
    pep4Container: {
      marginTop: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    pep4: {
      marginLeft: '4px',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#2196F3',
    },
  },
  { name: 'vet-input' }
);

function OutlinedVetInput(props) {
  const { formData, label, source, onChange, disabled } = props;
  const { setValue } = useFormContext() || {};

  const classes = useStyles();

  const [vetValue, setVetValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!vetValue && formData[source]) {
      setVetValue(formData[source]);
    }
  }, [formData, source, vetValue]);

  useEffect(() => {
    let active = true;

    const zipcode = props.zipcode ? props.zipcode : formData[props.zipcodeSource];

    // if input is empty but zipcode is known, call the api and it will return some
    // vets from the region. but if we dont have both info, it does not make sense
    // to search.
    if (inputValue === '' && !zipcode) {
      setOptions(vetValue ? [vetValue] : []);
      setLoading(false);
      return;
    }

    // when we select an option, inputValue will change, triggering this code
    // if inputValue (which is vet_name) is equal to current vetValue.vet_name, then
    // we do not have to search again.
    if (vetValue && inputValue === vetValue.vet_name) {
      setOptions(vetValue ? [vetValue] : []);
      setLoading(false);
      return;
    }

    setLoading(true);
    setOptions([]);

    const timer = setTimeout(() => {
      searchVets(inputValue, zipcode)
        .then(results => {
          if (active) {
            setOptions(results);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);

    return () => {
      active = false;
      clearTimeout(timer);
    };
  }, [formData, inputValue, props, vetValue]);

  function handleOnChange(event, newVet) {
    setVetValue(newVet);
    formData[source] = newVet;
    setValue?.(source, newVet);

    onChange(newVet);
  }

  function handleOnInputChange(event, newInputValue) {
    setInputValue(newInputValue);
  }

  function handleClearButtonClick() {
    setVetValue(null);
    setInputValue('');
    formData[source] = null;
    onChange(null);
  }

  function highlight(vetName) {
    if (!inputValue) {
      return vetName;
    }

    const startIndex = vetName.search(new RegExp(inputValue, 'i'));
    if (startIndex === -1) {
      return vetName;
    }

    const endIndex = startIndex + inputValue.length;

    return (
      <>
        {startIndex > 0 ? vetName.substring(0, startIndex) : null}
        <b>{vetName.substring(startIndex, endIndex)}</b>
        {endIndex < vetName.length ? vetName.substring(endIndex) : null}
      </>
    );
  }

  return (
    <>
      <Autocomplete
        options={options}
        autoComplete
        value={vetValue}
        inputValue={inputValue}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => `${option.vet_name} - ${option.normalized_addr}`}
        noOptionsText={
          inputValue === '' ? 'Start typing to search' : 'No matching vet found'
        }
        loadingText={'Searching...'}
        loading={isLoading}
        fullWidth
        popupIcon={null}
        clearOnBlur={false}
        classes={{
          root: classes.root,
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
        }}
        renderOption={(props, option) => (
          <div {...props} key={option.id} className={classes.option}>
            <Typography className={classes.optionName}>
              {highlight(option.vet_name)}
            </Typography>
            <Typography className={classes.optionAddress}>
              {option.normalized_addr}
            </Typography>
          </div>
        )}
        renderInput={params => (
          <FormControlLabel
            classes={{ root: classes.textLabel }}
            labelPlacement='top'
            label={label}
            control={
              <TextField
                {...params}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                FormHelperTextProps={{ classes: { root: classes.formHelperText } }}
                variant='outlined'
                size='medium'
                InputLabelProps={{
                  ...props.InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      className={classes.searchAdornment}
                    >
                      {vetValue || inputValue ? (
                        <IconButton
                          className={classes.clearButton}
                          onClick={handleClearButtonClick}
                          size='large'
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        )}
        PopperComponent={params => (
          <Popper
            {...params}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
          />
        )}
        disabled={disabled}
      />

      {vetValue && vetValue.has_pep4 === true ? (
        <div className={classes.pep4Container}>
          <FlagOutlined style={{ color: '#2196F3' }} />
          <Typography className={classes.pep4}>
            Prevent 4.0: Vet Plan Available
          </Typography>
        </div>
      ) : null}
    </>
  );
}

// https://github.com/facebook/prop-types?tab=readme-ov-file#usage (customProp)
function zipcodePropType(props) {
  return (
    !props.hasOwnProperty('zipcode') &&
    !props.hasOwnProperty('zipcodeSource') &&
    new Error(`Either "zipcode" or "zipcodeSource" is required`)
  );
}

OutlinedVetInput.propTypes = {
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  zipcode: zipcodePropType,
  zipcodeSource: zipcodePropType,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

OutlinedVetInput.defaultProps = {
  label: 'Search Vet Clinics',
  onChange: () => {},
  disabled: false,
  InputLabelProps: {},
};

export default OutlinedVetInput;
