import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  {
    cancelButton: {
      color: '#D5424E',
      borderStyle: 'solid',
      borderWidth: '1px',
      height: '38px',
      width: '100%',
    },
    container: {
      backgroundColor: 'rgba(115,121,133, 0.35)',
    },
    question: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#373F51',
      '& > span': { color: '#C21B44' },
      '&.Mui-focused': { color: '#373F51' },
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px',
      textAlign: 'center',
      marginTop: '48px',
    },
    close: {
      color: 'black',
      position: 'absolute',
      top: '6px',
      right: '12px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      maxWidth: '472px',
    },
    fileDropZone: {
      height: '64px',
      border: '1px dashed #979797',
      borderRadius: '0',
      boxSizing: 'border-box',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '16px',
      color: '#606060',
      letterSpacing: '0.25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actions: {
      paddingRight: '32px',
      paddingBottom: '40px',
    },
    saveButton: {
      width: '112px',
      height: '36px',
    },
    saveLabel: {
      padding: '0',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
  },
  { name: 'document-upload-v2' }
);

export default useStyles;
