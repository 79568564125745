import { useGetOne } from 'react-admin';

import { SAW_PROGRAM_WORKER_API } from '@pumpkincare/config';

import { parseWellnessPrograms } from './wellness-program-utils';

export function useWellnessProgram({ program_id, state, species, age }) {
  const url = `${SAW_PROGRAM_WORKER_API}/v1/programs/${program_id}/${state}/${species}/${age}`;

  return useGetOne(
    '',
    {
      id: '',
      meta: {
        api: 'WORKER',
        custom_url: url,
      },
    },
    {
      enabled: !!species,
      placeholderData: [],
      select: response => {
        return parseWellnessPrograms(response);
      },
    }
  );
}
