import {
  Form,
  required,
  SelectInput,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { CLAIM_REIMBURSEMENT_STATUS_COLOR } from '@pumpkincare/claims';
import {
  REIMBURSEMENT_CANCELLATION_REASON,
  useReimbursementById,
} from '@pumpkincare/reimbursements';

import { formatCurrency, parseAndFormatDate } from '../../../shared/utils';
import { reimbursementCancellationStyle } from './reimbursement-cancellation-modal-style';

const REIMBURSEMENT_MAP = {
  insurance: 'Insurance',
  prevent: 'Preventive Essentials',
  wellness: 'Wellness',
};

function ReimbursementCancellationModal(props) {
  const { onClose, isOpen, reimbursement = {} } = props;
  const classes = reimbursementCancellationStyle();
  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const { data: reimbursementData = {} } = useReimbursementById(reimbursement.id);

  function handleSubmitCancellationReason({ reimbursement_cancellation_reason }) {
    create(
      `reimbursements/${reimbursement.id}/cancel`,
      {
        data: {
          reason: reimbursement_cancellation_reason,
        },
      },
      {
        onSuccess: () => {
          onClose();
          refresh();
        },
        onError: error => {
          notify(
            `There was an error while requesting the reimbursement cancellation: ${error.message}`,
            {
              type: 'warning',
            }
          );
        },
      }
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmitCancellationReason}>
        <DialogTitle variant='h6' className={classes.modalTitle}>
          Reimbursement Cancellation
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Typography className={classes.modalSubTitle}>
            Are you sure you want to cancel the following reimbursement?
          </Typography>
          <div className={classes.informationDiv}>
            <Typography className={classes.totalReimbursement}>
              {`${
                REIMBURSEMENT_MAP[reimbursementData.reimbursement_type]
              } Reimbursement: ${formatCurrency(
                reimbursementData.total_reimbursement_amount,
                { areCents: true }
              )}`}
            </Typography>
            <div className={classes.statusDiv}>
              <Typography className={classes.initiatedOn}>
                {`Initiated on ${parseAndFormatDate(reimbursementData.created_at)}`}
              </Typography>
              <Chip
                style={{
                  fontSize: '10px',
                  height: '20px',
                  color: '#000000',
                  backgroundColor:
                    CLAIM_REIMBURSEMENT_STATUS_COLOR[reimbursementData.status] ||
                    '#FFFFFF',
                }}
                label={reimbursementData.status?.toUpperCase()}
              />
            </div>
          </div>
          <SelectInput
            fullWidth
            validate={required()}
            source='reimbursement_cancellation_reason'
            choices={REIMBURSEMENT_CANCELLATION_REASON}
            label='Cancellation Reason'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={isLoading}
            type='submit'
            className={classes.updateButton}
          >
            SUBMIT REQUEST
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

ReimbursementCancellationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  reimbursement: PropTypes.object,
};

export default ReimbursementCancellationModal;
