import { CreateButton, useRecordContext } from 'react-admin';
import withStyles from '@mui/styles/withStyles';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const AddNoteButton = () => {
  const record = useRecordContext();
  return (
    <CreateButton
      label='Create New Note'
      resource='notes'
      state={{ record: { user_id: record.id } }}
    />
  );
};

export default withStyles(styles)(AddNoteButton);
