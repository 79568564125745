import { useGetOne } from 'react-admin';

export function usePolicyByClaimId(claimId) {
  return useGetOne('claims', { id: `${claimId}/policy` }, { enabled: !!claimId });
}

export function usePlanByClaimId(claimId) {
  return useGetOne('claims', { id: `${claimId}/plan` }, { enabled: !!claimId });
}

export function useWellnessByClaimId(claimId, options = {}) {
  return useGetOne(
    'claims',
    { id: `${claimId}/wellness` },
    { enabled: !!claimId, ...options }
  );
}
