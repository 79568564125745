import { useNotify } from 'react-admin';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { useBankWithdrawal } from '../../../users/bank-withdrawal-query';
import ReimbursementCardDetails from '../reimbursement-card-details';

function BankWithdrawal({ reimbursement }) {
  const notify = useNotify();

  const {
    isLoading,
    error,
    data: bankWithdrawal,
  } = useBankWithdrawal(reimbursement.id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error?.body?.message) {
    notify(`Error: ${error?.body?.message}`, 'error');
    return null;
  }

  return (
    <ReimbursementCardDetails
      reimbursement={reimbursement}
      bankWithdrawal={bankWithdrawal?.[0]}
    />
  );
}

BankWithdrawal.propTypes = {
  reimbursement: PropTypes.shape({
    ach_trace_id: PropTypes.string,
    allowed_amount: PropTypes.number,
    applied_to_deductible: PropTypes.number,
    cancellations: PropTypes.array,
    check_id: PropTypes.string,
    claim_submission_id: PropTypes.string,
    claimed_amount: PropTypes.number,
    created_at: PropTypes.string,
    dwolla_external_id: PropTypes.string,
    dwolla_transfer_status: PropTypes.string,
    funding_source: PropTypes.object,
    id: PropTypes.string,
    latest_error: PropTypes.string,
    lob_check_number: PropTypes.string,
    lob_check_status: PropTypes.string,
    reimbursement_date: PropTypes.string,
    reimbursement_method: PropTypes.string,
    reimbursement_type: PropTypes.string,
    send_date: PropTypes.string,
    status: PropTypes.string,
    subclaim_id: PropTypes.string,
    total_reimbursement_amount: PropTypes.number,
    whitelabel_e_check_id: PropTypes.number,
  }),
};

export default BankWithdrawal;
