import makeStyles from '@mui/styles/makeStyles';

const claimNotesTabStyle = makeStyles(
  {
    noteDate: {
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    noteAuthorName: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    notesDescription: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
  { name: 'claim-notes-tab' }
);

export { claimNotesTabStyle };
