import makeStyles from '@mui/styles/makeStyles';

const recordsDocumentsSelectorStyle = makeStyles(
  {
    checkboxLabel: {
      marginLeft: 0,
      display: 'block',
    },
    subCheckbox: {
      margin: '8px 0 8px 42px',
    },
    extraInfo: {
      color: '#595959',
      fontSize: '11px',
      lineHeight: '13px',
    },
    specificRecord: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '0 8px',
      '&:not(:first-of-type)': { marginTop: '24px' },
    },
    recordDate: { '&:first-of-type': { width: '160px' } },
    recordDescription: {
      '&:first-of-type': { flex: '1' }, // fun with specificity
    },
    buttonClear: {
      marginTop: '40px',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      minWidth: 'unset',
    },
    iconClear: {
      color: '#2A41DE',
      width: '18px',
    },
    addRecordButton: {
      cursor: 'pointer',
      color: '#2A41DE',
      display: 'flex',
      gap: '0px 4px',
      alignItems: 'center',
      marginTop: '8px',
      padding: '10px 0',
      background: 'none',
      outline: 'none',
      border: 'none',
      fontSize: '14px',
    },
    iconAdd: { color: '#2A41DE' },
  },
  { name: 'records-documents-selector' }
);

export { recordsDocumentsSelectorStyle };
