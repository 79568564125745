import { CircularProgress as CoreCircularProgress } from '@mui/material';

// because basePath and other possible props are causing React warnings
function CircularProgress({
  color,
  disableShrink,
  size,
  thickness,
  value,
  variant,
}) {
  return (
    <CoreCircularProgress
      color={color}
      disableShrink={disableShrink}
      size={size}
      thickness={thickness}
      value={value}
      variant={variant}
    />
  );
}

export default CircularProgress;

// for more info go to https://v4-0-2.material-ui.com/api/circular-progress/
CircularProgress.defaultProps = {
  color: 'primary',
  disableShrink: false,
  size: 40,
  thickness: 3.6,
  value: 0,
  variant: 'indeterminate',
};
