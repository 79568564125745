import { Fragment, useState } from 'react';
import IconAdd from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import {
  formatAuditObject,
  formatRequestedRecordsText,
  MEDICAL_RECORD_REQUEST_COMPLETE,
  MEDICAL_RECORD_REQUEST_INACTIVE,
  MEDICAL_RECORD_REQUEST_OPEN,
} from '@pumpkincare/medical-records';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import { parseAndFormatDate } from '../../../../shared/utils';
import MarkReceivedModal from '../mark-received-modal';
import RequestRecordsModal from '../request-records-modal';
import ViewMedicalRecordsRequest from '../view-medical-records-request';
import { medicalRecordsRequestAccordionStyles } from './medical-records-request-accordion-style';

const MODAL_VIEW = 'VIEW';
const MODAL_MARK = 'MARK';

const ACTION = {
  [MEDICAL_RECORD_REQUEST_OPEN]: 'Mark Received',
  [MEDICAL_RECORD_REQUEST_INACTIVE]: 'No Response',
  [MEDICAL_RECORD_REQUEST_COMPLETE]: (
    <>
      <CheckIcon /> Received
    </>
  ),
};

function MedicalRecordsRequestAccordion({
  medicalRecordsRequestsObject,
  isLoading,
  refetch,
  disableRequestCreation,
}) {
  const classes = medicalRecordsRequestAccordionStyles();

  const medicalRecordsRequestsData = isLoading
    ? []
    : Object.values(medicalRecordsRequestsObject).sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

  const [isRequestRecordsModalOpen, toggleRequestRecordsModal] =
    useBooleanInput(false);
  const [requestId, setRequestId] = useState('');
  const [modalType, setModalType] = useState('');

  function toggleModal({ requestId = '', modalType = '' } = {}) {
    setRequestId(requestId);
    setModalType(modalType);
  }

  return (
    <>
      <Accordion square defaultExpanded classes={{ root: classes.root }}>
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
          }}
          aria-controls='all-medical-record-requests'
          id='all-medical-record-requests'
          disableRipple
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        >
          <Typography variant='h6'>Medical Record Requests</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {!medicalRecordsRequestsData.length && isLoading ? (
            <CircularProgress className={classes.loader} />
          ) : (
            <>
              {!medicalRecordsRequestsData.length ? (
                <Typography variant='body2'>
                  There are no medical record requests associated with this claim.
                </Typography>
              ) : (
                <div className={classes.grid}>
                  <div className={classes.miniText}>REQ ID</div>
                  <div className={classes.miniText}>DATE SENT</div>
                  <div className={classes.miniText}>VET CLINIC</div>
                  <div className={classes.miniText}>REQUESTED RECORDS</div>
                  <div className={classes.miniText}>LATEST STATUS</div>
                  <div />

                  {medicalRecordsRequestsData.map((row, idx) => {
                    /*
                     RA quirk - loading ALL medical records returns only the latest audit, but
                     opening the view modal will load all the audits and override the previous state
                     */
                    const audit = formatAuditObject(row.audit[row.audit.length - 1]);
                    const isOpen = row.status === MEDICAL_RECORD_REQUEST_OPEN;

                    return (
                      <Fragment key={row.id + idx}>
                        <div className={classes.cell}>
                          <Typography variant='body2'>{row.id}</Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography variant='body2'>
                            {parseAndFormatDate(row.created_at, 'M/D/YYYY')}
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography
                            variant='body2'
                            className={classes.truncateOverflow}
                          >
                            {row.clinic_name}
                            {row.vet_practice ? (
                              <span className={classes.vetInfo}>
                                {row.vet_practice.normalized_addr}
                              </span>
                            ) : null}
                            <span className={classes.vetInfo}>
                              {row.contact_email}
                            </span>
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography
                            variant='body2'
                            className={classes.truncateOverflow}
                          >
                            {formatRequestedRecordsText(row.records).map(record => (
                              <span key={record.description || record.type}>
                                {record.text}
                              </span>
                            ))}
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography variant='body2'>
                            <span className={classes.truncateOverflow}>
                              {audit.event}
                            </span>
                            <span className={classes.miniText}>{audit.detail}</span>
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Button
                            className={classes.tableAction}
                            onClick={() =>
                              toggleModal({
                                requestId: row.id,
                                modalType: MODAL_VIEW,
                              })
                            }
                          >
                            <Typography variant='body2'>View Details</Typography>
                          </Button>
                          <Button
                            className={classes.tableAction}
                            onClick={() =>
                              toggleModal({
                                requestId: row.id,
                                modalType: MODAL_MARK,
                              })
                            }
                            disabled={!isOpen}
                          >
                            <Typography variant='body2'>
                              {ACTION[row.status]}
                            </Typography>
                          </Button>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              )}

              {isLoading ? (
                <CircularProgress className={classes.loader} size={20} />
              ) : null}

              <Button
                className={classes.newRequest}
                onClick={toggleRequestRecordsModal}
                variant='outlined'
                disabled={disableRequestCreation}
              >
                <IconAdd />
                New Request
              </Button>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      {isRequestRecordsModalOpen ? (
        <RequestRecordsModal
          onClose={toggleRequestRecordsModal}
          onSuccess={() => {
            toggleRequestRecordsModal();
            refetch();
          }}
        />
      ) : null}

      {requestId && modalType === MODAL_VIEW ? (
        <ViewMedicalRecordsRequest
          requestId={requestId}
          onClose={() => toggleModal()}
          onSuccess={() => refetch()}
        />
      ) : null}

      {requestId && modalType === MODAL_MARK ? (
        <MarkReceivedModal
          requestId={requestId}
          onClose={() => toggleModal()}
          onSuccess={() => refetch()}
        />
      ) : null}
    </>
  );
}

MedicalRecordsRequestAccordion.propTypes = {
  medicalRecordsRequestsObject: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
  disableRequestCreation: PropTypes.bool,
};

export default MedicalRecordsRequestAccordion;
