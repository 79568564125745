import makeStyles from '@mui/styles/makeStyles';

const claimCardStyle = makeStyles(
  {
    card: {
      backgroundColor: '#F8F8F6',
      marginRight: '12px',
      marginLeft: '12px',
    },
    cardDetail: {
      border: '1px solid #EEEFF1',
      background: '#FFFFFF',
      marginBottom: '12px',
      marginTop: '12px',
      padding: '12px',
    },
    cardTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    claimNumber: {
      color: '#007DFF',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
      letterSpacing: '0.1px',
      marginLeft: '4px',
      textDecoration: 'none',
    },
    claimInsurance: {
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '20px',
      color: '#000000',
      marginRight: '16px',
    },
    claimPrevent: {
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '20px',
      color: '#000000',
    },
    claimText: {
      fontSize: '12px',
      lineHeight: '20px',
      color: '#000000',
    },
    claimDaysInQueue: {
      fontSize: '11px',
      lineHeight: '20px',
      color: '#767676',
      textTransform: 'lowercase',
    },
    claimAccountIcon: {
      width: '24px',
      marginRight: '4px',
      color: '#767676',
    },
    claimStatus: {
      borderRadius: '4px',
      padding: '1.5px 8px 2.5px',
      fontSize: '11px',
      fontWeight: '700',
      lineHeight: '20px',
    },
  },
  { name: 'claim-card' }
);

export { claimCardStyle };
