import makeStyles from '@mui/styles/makeStyles';

const claimAttachmentTableStyle = makeStyles(
  {
    headerCell: {
      fontSize: '8px',
      fontWeight: '500',
      lineHeight: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
      padding: '0px 16px 0px 0px',
      borderBottom: '0px',
    },
    table: {
      borderBottom: '0px',
    },
    rowCell: {
      padding: '8px 24px 8px 0px',
    },
    fontStyle: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'normal',
      color: '#373F51',
    },
  },
  { name: 'claim-attachment-table' }
);

export { claimAttachmentTableStyle };
