import { useState } from 'react';
import {
  Edit,
  SimpleShowLayout,
  useCreate,
  useDataProvider,
  useEditController,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useCustomer, usePetPlans, usePolicyListByPetId } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import RoutePaths from '../../../routes';
import MessageModal from '../../../shared/components/message-modal';
import { petIsWellnessOnly } from '../../../shared/utils/petUtils';
import MidtermRequest from '../midterm-request';
import PetDetailsEdit from '../pet-edit-details/pet-edit-details';
import PolicyChangeRequest from '../policy-change-request';
import {
  PolicyChangeSideBar,
  PolicyChangeSideBarLegacy,
} from '../policy-change-sidebar';
import RenewalChangeRequest from '../renewal-change-request';
import {
  addIdToMidtermOptions,
  formatPetEditTitle,
  PET_CHANGE_OPTIONS,
  sortMidtermOptions,
} from '../utils/pet-utils';
import { petEditStyle } from './pet-edit-style';

const WA_START_DATE = new Date('2023-07-14');
const WA_END_DATE = new Date('2024-01-02');

function PetEdit(props) {
  const classes = petEditStyle();
  const notify = useNotify();
  const redirect = useRedirect();
  const [create, { isLoading: isCreateMidtermLoading }] = useCreate();
  const {
    fer4116ThrowErrorInAdminForMidtermChange,
    fer5187MidtermAndRenewalChangePageLayout,
  } = useFlags();
  const dataProvider = useDataProvider();
  const [createRenewalChange, { isLoading: isCreateRenewalChangeLoading }] =
    useCreate();

  const { record: petData } = useEditController(props);
  const [petChangeType, setPetChangeType] = useState();
  const [midtermSelectedData, setMidtermData] = useState();
  const [renewalChangeSelectedData, setRenewalChangeSelectedData] = useState();

  const [midtermFormattedData, setMidtermDataFormatted] = useState();
  const [renewalChangeFormattedData, setRenewalChangeDataFormatted] = useState();

  const [message, setMessage] = useState('');

  const [midtermChangeData, setMidtermChangeData] = useState();
  const [renewalChangeData, setRenewalChangeData] = useState();

  const [isMidtermChangeMessageModalOpen, toggleMidtermChangeMessageModal] =
    useBooleanInput();

  const [update, { isLoading }] = useUpdate();

  const { data: customerData, isLoading: isCustomerDataLoading } = useCustomer(
    petData?.user_id
  );

  const { data: petPolicies } = usePolicyListByPetId(petData?.id);

  const { data: petPlanData } = usePetPlans(petData?.id);

  if (petData) {
    petData.renewal_pending_policy =
      petPolicies &&
      petPolicies.filter(policy => policy.status == 'renew_pending')[0];
    if (petData.renewal_pending_policy)
      petData.renewal_pending_policy.version =
        petPolicies && petData.renewal_pending_policy.program_id.match(/\d\.\d/g)[0];

    petData.renewal_pending_pet_plan =
      petPlanData &&
      petPlanData.filter(pet_plan => pet_plan.status == 'renew_pending')[0];
  }

  const midtermDataOrdered = midtermChangeData
    ? addIdToMidtermOptions(sortMidtermOptions(midtermChangeData))
    : [];

  const renewalChangeDataOrdered = renewalChangeData
    ? addIdToMidtermOptions(sortMidtermOptions(renewalChangeData))
    : [];

  const isMidterm = petChangeType === 'midterm';
  const shouldShowPolicyChangeSideBar =
    petChangeType !== 'details' && (isMidterm || petChangeType === 'renewal');

  //Avoid useless warnings due to basePath is being passed into a div
  // eslint-disable-next-line no-unused-vars
  function SanitizedDiv({ basePath, ...props }) {
    return <div style={{ width: '100%' }} {...props} />;
  }

  function handleRatesChange(policy_change) {
    if (petChangeType == 'midterm') {
      setMidtermData(policy_change);
      setMidtermDataFormatted({
        ...petData,
        latest_pet_policy: {
          ...petData.latest_pet_policy,
          raw_rate: policy_change.raw_rate,
          filed_rate: policy_change.filed_rate,
          deductible: policy_change.deductible,
          coinsurance: policy_change.coinsurance,
          annual_limit: policy_change.annual_limit,
        },
      });
    } else {
      setRenewalChangeSelectedData(policy_change);
      setRenewalChangeDataFormatted({
        ...petData,
        renewal_pending_policy: {
          ...petData.renewal_pending_policy,
          raw_rate: policy_change.raw_rate,
          filed_rate: policy_change.filed_rate,
          capped_rate: policy_change.capped_rate,
          deductible: policy_change.deductible,
          coinsurance: policy_change.coinsurance,
          annual_limit: policy_change.annual_limit,
        },
      });
    }
  }

  function handleEditPetDetails(petData, pet_id, breedList) {
    const breed = breedList.find(breed => breed.code === petData?.breed_code);
    const payload = {
      name: petData.name,
      gender: petData?.gender,
      age: petData?.age,
      breed_name: breed.name,
      breed_type: breed.type,
      breed_code: breed.code,
    };

    update(
      RoutePaths.pets,
      { data: payload, id: pet_id },
      {
        onSuccess: () => {
          redirect(`/${RoutePaths.pets}/${pet_id}/show`);
        },
        onError: error => {
          notify(
            `There was an error while updating the pet details: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  function saveMidtermChanges() {
    create(
      `/${RoutePaths.policies}/${petData.latest_pet_policy.id}/midterm`,
      { data: midtermSelectedData },
      {
        onSuccess: () => {
          redirect(`/${RoutePaths.pets}/${petData.id}/show`);
        },
        onError: error => {
          setMessage(error.message);
          toggleMidtermChangeMessageModal();
        },
      }
    );
  }

  function saveRenewalChange() {
    createRenewalChange(
      `/${RoutePaths.policies}/${petData.renewal_pending_policy.id}/renewal`,
      { data: renewalChangeSelectedData },
      {
        onSuccess: () => {
          redirect(`/${RoutePaths.pets}/${petData.id}/show`);
        },
        onError: error => {
          setMessage(error.message);
          toggleMidtermChangeMessageModal();
        },
      }
    );
  }

  function handleConfirmChanges() {
    switch (petChangeType) {
      case 'midterm':
        saveMidtermChanges();
        break;
      case 'renewal':
        saveRenewalChange();
        break;
      default:
      // code block
    }
  }

  function onTypeChange(changeType) {
    if (changeType === 'renewal' && petData && !petData.renewal_pending_policy) {
      notify(
        'Renewal change can not be completed. This pet is not within the renewal window.',
        { type: 'error' }
      );
      return;
    }

    if (fer4116ThrowErrorInAdminForMidtermChange) {
      const effectiveDate = new Date(
        petData?.latest_pet_policy?.policy_effective_date
      );
      const isDateWA =
        effectiveDate >= WA_START_DATE && effectiveDate <= WA_END_DATE;

      if (
        changeType === 'midterm' &&
        petData?.latest_pet_policy?.program_id?.endsWith('WA') &&
        isDateWA
      ) {
        notify(
          'Program Version Rating Error: Reach out to an Ops team member or Helpliner to get the rates for this pet',
          { type: 'error' }
        );
        return;
      }
    }

    if (
      fer5187MidtermAndRenewalChangePageLayout &&
      isNoChangeOptionsAvailable(changeType)
    ) {
      notify(`No ${changeType} change options available for this pet.`, {
        type: 'error',
      });
      return;
    }

    setPetChangeType(changeType);

    if (changeType === 'details') {
      return;
    }

    const policyId =
      changeType === 'midterm'
        ? petData.latest_pet_policy?.id
        : petData.renewal_pending_policy?.id;

    dataProvider
      .getOne('policies', {
        id: `${policyId}/${changeType}`,
      })
      .then(({ data }) => {
        if (changeType === 'midterm') {
          setMidtermChangeData(data);
        } else {
          setRenewalChangeData(data);
        }
        setPetChangeType(changeType);
      })
      .catch(error => {
        notify(error.message, { type: 'error' });
      });
  }

  function isNoChangeOptionsAvailable(changeType) {
    return (
      (changeType === 'midterm' &&
        midtermDataOrdered.length &&
        !midtermDataOrdered.some(obj => obj.allowed)) ||
      (changeType === 'renewal' &&
        renewalChangeDataOrdered.length &&
        !renewalChangeDataOrdered.some(obj => obj.allowed))
    );
  }

  const midtermContent = fer5187MidtermAndRenewalChangePageLayout ? (
    <PolicyChangeRequest
      petData={petData}
      changeData={midtermDataOrdered}
      onRatesChange={handleRatesChange}
      changeSelectedData={midtermSelectedData}
      isMidterm={isMidterm}
    />
  ) : (
    <MidtermRequest
      petData={petData}
      midtermChangeData={midtermDataOrdered}
      onRatesChange={handleRatesChange}
      midtermRatesValue={midtermSelectedData?.id}
    />
  );

  const renewalContent = fer5187MidtermAndRenewalChangePageLayout ? (
    <PolicyChangeRequest
      petData={petData}
      changeData={renewalChangeDataOrdered}
      onRatesChange={handleRatesChange}
      changeSelectedData={renewalChangeSelectedData}
      isMidterm={isMidterm}
    />
  ) : (
    <RenewalChangeRequest
      petData={petData}
      renewalChangeData={renewalChangeDataOrdered}
      onRatesChange={handleRatesChange}
      renewalChangeRatesValue={renewalChangeSelectedData?.id}
    />
  );

  return (
    <Edit
      {...props}
      actions={null}
      title={formatPetEditTitle(petData, customerData)}
      classes={{
        root: classes.root,
        noActions: classes.noActions,
      }}
    >
      <SimpleShowLayout>
        <SanitizedDiv>
          <div className={classes.pageContainer}>
            <div className={classes.changeDetailMainContainer}>
              <Link
                to={`/pets/${petData?.id}/show`}
                className={classes.returnToShowPetLink}
              >
                <ArrowBackIosIcon className={classes.arrowBackIcon} />
                <Typography className={classes.returnText}>
                  Show Pet Information
                </Typography>
              </Link>
              {!isCustomerDataLoading ? (
                <>
                  <Typography className={classes.selectOptionLabel}>
                    Change Type
                  </Typography>
                  <Select
                    style={{ height: '40px', marginBottom: '32px' }}
                    variant='outlined'
                    data-testid='select-type'
                    fullWidth
                    value={petChangeType}
                    onChange={props => onTypeChange(props.target.value)}
                  >
                    {PET_CHANGE_OPTIONS.filter(change =>
                      petIsWellnessOnly(petData) ? change.id === 'details' : true
                    ).map(change => (
                      <MenuItem key={change.id} value={change.id}>
                        {change.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : null}

              {
                {
                  midterm: isLoading ? (
                    <CircularProgress />
                  ) : Array.isArray(midtermChangeData) ? (
                    midtermContent
                  ) : null,
                  renewal: isLoading ? (
                    <CircularProgress />
                  ) : Array.isArray(renewalChangeData) ? (
                    renewalContent
                  ) : null,
                  details: (
                    <PetDetailsEdit
                      petData={petData}
                      onEditPetDetails={handleEditPetDetails}
                      record={props}
                    />
                  ),
                }[petChangeType]
              }
            </div>
            <div className={classes.changeDetailSideBar}>
              {shouldShowPolicyChangeSideBar ? (
                petData ? (
                  <>
                    {fer5187MidtermAndRenewalChangePageLayout ? (
                      <PolicyChangeSideBar
                        petData={petData}
                        policyChangeData={
                          isMidterm ? midtermSelectedData : renewalChangeSelectedData
                        }
                        midtermFormattedData={
                          isMidterm
                            ? midtermFormattedData
                            : renewalChangeFormattedData
                        }
                        loading={
                          isMidterm
                            ? !!isCreateMidtermLoading
                            : !!isCreateRenewalChangeLoading
                        }
                        onConfirmChanges={handleConfirmChanges}
                        isMidterm={isMidterm}
                      />
                    ) : (
                      <PolicyChangeSideBarLegacy
                        petData={petData}
                        policyChangeData={
                          isMidterm ? midtermSelectedData : renewalChangeSelectedData
                        }
                        midtermFormattedData={
                          isMidterm
                            ? midtermFormattedData
                            : renewalChangeFormattedData
                        }
                        loading={
                          isMidterm
                            ? !!isCreateMidtermLoading
                            : !!isCreateRenewalChangeLoading
                        }
                        onConfirmChanges={handleConfirmChanges}
                      />
                    )}

                    <MessageModal
                      onClose={toggleMidtermChangeMessageModal}
                      isOpen={isMidtermChangeMessageModalOpen}
                      title='The change you are trying to make cannot be completed.'
                      type='error'
                      buttonTitle='Close'
                      message={message}
                      onClick={toggleMidtermChangeMessageModal}
                    />
                  </>
                ) : (
                  <CircularProgress />
                )
              ) : null}
            </div>
          </div>
        </SanitizedDiv>
      </SimpleShowLayout>
    </Edit>
  );
}

export default PetEdit;
