import makeStyles from '@mui/styles/makeStyles';

const reissueReimbursementModalStyle = makeStyles(
  {
    totalReimbursement: {
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '20px',
      color: '#000000',
      letterSpacing: '0.4px',
    },
    checkInfo: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#000000',
      marginRight: '16px',
      letterSpacing: '0.4px',
    },
    statusDiv: {
      display: 'flex',
      marginTop: '16px',
      alignItems: 'baseline',
    },
    modalContent: { width: '500px' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    updateButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      color: 'white',
      width: '100%',
    },
    reimbursementTypeButton: {
      borderRadius: '4px',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12);',
    },
    activeReimbursementTypeButton: {
      borderColor: '#007DFF',
    },
    informationDiv: {
      border: '1px solid #2196F3',
      borderRadius: '4px',
      padding: '10px',
      marginTop: '12px',
      marginBottom: '16px',
    },
    customerDetailTitle: {
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '16px',
      color: '#000000',
      marginTop: '18px',
      marginBottom: '8px',
      letterSpacing: '1.5px',
    },
    reimbursementDetailTitle: {
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '16px',
      color: '#000000',
      marginTop: '24px',
      marginBottom: '22px',
      letterSpacing: '1.5px',
    },
    customerDetail: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#000000',
      letterSpacing: '0.4px',
    },
    dialogTitleContainer: { padding: '16px 24px 24px', color: '#007DFF' },
  },
  { name: 'ReissueReimbursementModal' }
);

export { reissueReimbursementModalStyle };
