import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { cancelRequestRecordsModalStyle } from './cancel-request-records-modal-style';

function CancelRequestRecordsModal({ onClose, onReturn }) {
  const classes = cancelRequestRecordsModalStyle();

  return (
    <Dialog open onClose={onReturn} classes={{ paperWidthSm: classes.root }}>
      <DialogTitle className={classes.modalTitle}>
        <Typography className={classes.title}>
          Are you sure you want to cancel this record request?
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <Typography className={classes.body}>
          Please note that by canceling, you will lose any progress made on this
          request.
        </Typography>
      </DialogContent>

      <DialogActions className={classes.modalActions}>
        <Button
          className={classes.returnButton}
          onClick={onReturn}
          variant='outlined'
          color='secondary'
        >
          Return to Request
        </Button>
        <Button
          variant='contained'
          color='primary'
          className={classes.confirmButton}
          onClick={onClose}
        >
          Confirm Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelRequestRecordsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
};

export default CancelRequestRecordsModal;
