import makeStyles from '@mui/styles/makeStyles';

const petEditStyle = makeStyles(
  {
    root: { backgroundColor: 'rgb(0, 125, 255, 0.08)' },
    noActions: { marginTop: '0px' },
    pageContainer: {
      width: '100%',
      display: 'flex',
    },
    selectOptionLabel: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px',
      marginTop: '32px',
    },
    arrowBackIcon: {
      color: '#007DFF',
      width: '16px',
    },
    returnText: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
    },
    returnToShowPetLink: {
      textDecoration: 'none',
      display: 'flex',
      marginRight: '24px',
    },
    changeDetailSideBar: {
      width: '35%',
      backgroundColor: 'white',
      padding: '12px 16px',
    },
    changeDetailMainContainer: {
      width: '65%',
      marginRight: '24px',
      padding: '20px',
      backgroundColor: 'white',
    },
  },
  { name: 'pet-edit' }
);

export { petEditStyle };
