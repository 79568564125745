import { Button, SaveButton, Toolbar } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  CancelAndReturnModal,
  ChainIcon,
  useGetCurrentStep,
} from '@pumpkincare/shared';

import useBooleanInput from '../../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../../routes';

function WellnessQuoteToolbar({ edit, selectedPlans }) {
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const currentStep = useGetCurrentStep();
  const [isCancelModalOpen, toggleIsCancelModalOpen] = useBooleanInput(false);

  function handleCopyQuoteClick() {
    /*
    implement copy to clipboard and notify to swifter wellness flow once routes are done
    navigator.clipboard.writeText(`${WELLNESS_URL}/quote?${data.id}`);
    notify('Quote copied to clipboard', 'success');
     */
  }

  function handleNextClick({ data }) {
    navigate(
      edit
        ? `/wellness-quotes/${data.id}/${currentStep + 1}`
        : `/wellness-quotes/create/${currentStep + 1}`,
      { state: routerState }
    );
  }

  return (
    <Toolbar
      sx={{
        padding: '32px 24px',
        display: 'flex',
        gap: '40px',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton
        type='button'
        label='COPY QUOTE'
        variant='text'
        alwaysEnable
        sx={{ color: '#5368F5' }}
        icon={<ChainIcon />}
        mutationOptions={{ onSuccess: handleCopyQuoteClick }}
      />

      {currentStep === 1 ? (
        <Typography variant='body1' sx={{ fontWeight: 500 }}>
          TOTAL: $
          {Object.values(selectedPlans)
            .reduce((result, { price }) => result + parseFloat(price), 0)
            .toFixed(2)}{' '}
          / MO
        </Typography>
      ) : null}

      <div
        style={{
          display: 'flex',
          gap: '40px',
        }}
      >
        <Button
          label='CANCEL'
          variant='contained'
          color='error'
          sx={{ width: '180px' }}
          type='button'
          onClick={toggleIsCancelModalOpen}
        />

        <SaveButton
          label='NEXT'
          variant='contained'
          alwaysEnable
          color='primary'
          icon={null}
          sx={{ width: '180px' }}
          type='button'
          mutationOptions={{ onSuccess: handleNextClick }}
        />
      </div>

      <CancelAndReturnModal
        title='Are you sure you want to cancel creating a new Wellness quote?'
        onReturn={toggleIsCancelModalOpen}
        cancelTo={{ pathname: `/${RoutePaths.allQuotes}` }}
        open={isCancelModalOpen}
      />
    </Toolbar>
  );
}

WellnessQuoteToolbar.defaultProps = {
  edit: false,
  selectedPlans: {},
};

WellnessQuoteToolbar.propTypes = {
  edit: PropTypes.bool,
  selectedPlans: PropTypes.shape({
    price: PropTypes.number,
  }),
};

export default WellnessQuoteToolbar;
