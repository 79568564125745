import { useGetList } from 'react-admin';

export function useBankWithdrawal(reimbursementId) {
  return useGetList(
    'bank-withdrawal',
    {
      pagination: { page: 1, perPage: 50 },
      filter: { reimbursement_id: reimbursementId },
      sort: { field: 'id', order: 'DESC' },
    },
    { enabled: !!reimbursementId }
  );
}
