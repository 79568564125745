import { Container, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatCurrency, formatIsoToMMDDYYYY } from '../../../shared/utils';
import { getFullPercentage } from '../../../shared/utils/petUtils';
import { getRecordValue } from '../../../shared/utils/utils';
import { planSummaryStyle } from './plan-summary-style';
import {
  calculatePetTotal,
  calculatePlanTotal,
  formatBioString,
} from './plan-summary-utils';

const WELLNESS_KEYS = [
  'wellnessCare',
  'diseasePrevention',
  'diagnostics',
  'preventiveProcedures',
  'support',
];

function PlanSummary(props) {
  const classes = planSummaryStyle();
  const { pets, isChargedAnnually, isAddingInsurance, hasFeeShown, sx } = props;

  const frequency = isChargedAnnually ? '/ yr' : '/ mo';

  const planTotal = calculatePlanTotal(pets, isChargedAnnually);

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  return (
    <>
      {pets.map(pet => {
        const { policy = {}, wellness = {}, wellnessPlan = {} } = pet;
        const petTotal = calculatePetTotal(policy, wellnessPlan, isChargedAnnually);
        const policyMultiplier = isChargedAnnually ? 12 : 1;
        const wellnessMultiplier = isChargedAnnually ? 11 : 1;

        return (
          <Container
            key={pet.id}
            sx={{
              border: '1px solid #0000001F',
              borderRadius: '8px',
              padding: '16px !important',
              margin: '20px 0',
              width: '400px',
              ...sx,
            }}
          >
            <div>
              <Stack spacing={2}>
                <div className={classes.sectionRow}>
                  <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {`${pet?.name}’s Plan`}
                  </Typography>

                  <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {`${formatCurrency(petTotal)} ${frequency}`}
                  </Typography>
                </div>
                <Typography
                  variant='body2'
                  sx={{
                    marginBottom: '16px !important',
                    textTransform: 'capitalize',
                  }}
                >
                  {formatBioString(pet)}
                </Typography>
              </Stack>

              {Object.keys(policy).length > 0 ? (
                <Stack spacing={2}>
                  <div className={classes.sectionRow}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      Pumpkin Pet Insurance
                    </Typography>

                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      {`${formatCurrency(
                        parseFloat(policy?.premium * policyMultiplier) || 0
                      )} ${frequency}`}
                    </Typography>
                  </div>

                  <ul className={classes.listItems}>
                    <li>
                      <Typography variant='body2'>
                        {`${formatCurrency(policy?.deductible, {
                          hideZero: true,
                        })} Deductible`}
                      </Typography>
                    </li>
                    <li>
                      {' '}
                      <Typography variant='body2'>
                        {policy?.annual_limit?.includes('Unlimited')
                          ? 'Unlimited'
                          : `$${policy.annual_limit / 1000}K`}{' '}
                        Annual Limit
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        {`${getFullPercentage(
                          policy?.coinsurance
                        )} Reimbursement Rate`}
                      </Typography>
                    </li>
                  </ul>

                  <div className={classes.sectionColumn}>
                    <div className={classes.sectionRow}>
                      <Typography variant='body2'>Effective Date</Typography>

                      <Typography variant='body2'>
                        {policy?.policy_effective_date
                          ? formatIsoToMMDDYYYY(
                              getRecordValue(policy, 'policy_effective_date')
                            )
                          : formatIsoToMMDDYYYY(tomorrowDate)}
                      </Typography>
                    </div>
                    {pet?.next_charge_date ? (
                      <div className={classes.sectionRow}>
                        <Typography variant='body2'>Next Charge Date</Typography>

                        <Typography variant='body2'>
                          {formatIsoToMMDDYYYY(
                            getRecordValue(pet, 'next_charge_date')
                          )}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Stack>
              ) : null}

              {Object.keys(wellnessPlan).length > 0 ? (
                <Stack spacing={2} marginTop={'16px'}>
                  <div className={classes.sectionRow}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      Pumpkin Wellness
                    </Typography>

                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      {`${formatCurrency(
                        wellnessPlan.price * wellnessMultiplier
                      )} ${frequency}`}
                    </Typography>
                  </div>
                  <Typography
                    variant='body2'
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 'bold',
                      marginTop: '0 !important',
                    }}
                  >
                    {wellnessPlan.category}
                  </Typography>

                  <ul className={classes.listItems}>
                    {WELLNESS_KEYS.filter(key => wellnessPlan?.[key]?.length).map(
                      key =>
                        wellnessPlan[key].map(item => {
                          return (
                            <li key={item.label}>
                              <Typography variant='body2'>
                                {item.label} {' - '} {item.value}
                              </Typography>
                            </li>
                          );
                        })
                    )}
                  </ul>

                  <div className={classes.sectionColumn}>
                    <div className={classes.sectionRow}>
                      <Typography variant='body2'>Effective Date</Typography>

                      <Typography variant='body2'>
                        {wellness?.start_date
                          ? formatIsoToMMDDYYYY(wellness.start_date)
                          : formatIsoToMMDDYYYY(Date.now())}
                      </Typography>
                    </div>
                    {pet.wellness_next_charge_date ? (
                      <div className={classes.sectionRow}>
                        <Typography variant='body2'>Next Charge Date</Typography>

                        <Typography variant='body2'>
                          {formatIsoToMMDDYYYY(pet.wellness_next_charge_date)}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Stack>
              ) : null}
            </div>
          </Container>
        );
      })}
      <div className={classes.sectionRow}>
        <Typography variant='body2'>Subtotal:</Typography>
        <Typography variant='body2'>{formatCurrency(planTotal)}</Typography>
      </div>

      {hasFeeShown ? (
        <>
          <div className={classes.sectionRow}>
            <Typography variant='body2'>Transaction Fee:</Typography>
            <Typography variant='body2'>
              {formatCurrency(isAddingInsurance && !isChargedAnnually ? 2 : 0)}
            </Typography>
          </div>

          <div className={classes.sectionRow}>
            <Typography variant='body2'>Grand Total:</Typography>
            <Typography variant='body2'>
              {formatCurrency(
                isAddingInsurance && !isChargedAnnually ? planTotal + 2 : planTotal
              )}
            </Typography>
          </div>
        </>
      ) : null}
    </>
  );
}

PlanSummary.defaultProps = {
  isChargedAnnually: false,
  hasFeeShown: false,
  isAddingInsurance: false,
  sx: {},
};

PlanSummary.propTypes = {
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      policy: PropTypes.object,
      wellnessPlan: PropTypes.object,
      wellness: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string,
      }),
    })
  ).isRequired,
  isChargedAnnually: PropTypes.bool,
  hasFeeShown: PropTypes.bool,
  isAddingInsurance: PropTypes.bool,
  sx: PropTypes.object,
};

export default PlanSummary;
