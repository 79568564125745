import makeStyles from '@mui/styles/makeStyles';

const wellnessCreateStyle = makeStyles(
  {
    root: {
      padding: '16px 24px',
    },
    innerGrid: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      gap: '23px',
      padding: '16px 0',
      marginTop: '0',
      marginLeft: '0',
    },
    planItem: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '32px',
      marginBottom: '8px',
    },
    priceItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sectionRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    sectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    listItems: {
      marginTop: '8px !important',
      padding: '0 16px',
    },
  },
  { name: 'wellness-create-style' }
);

export { wellnessCreateStyle };
