import { useState } from 'react';
import { Button, Switch, Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TRANSACTION_FEE } from '../../../constants/policy';
import useBooleanInput from '../../../hooks/useBooleanInput';
import { formatCurrency } from '../../../shared/utils/currencyUtils';
import { isPetPlanInCancelledStatus } from '../../../shared/utils/PetPlanUtils';
import MidtermChangeConfirmationModal from '../midterm-change-confirmation-modal';
import MidtermSummary from '../midterm-summary';
import PetDetailHeader from '../pet-detail-header';
import RenewalChangeConfirmationModal from '../renewal-change-confirmation-modal';
import RenewalChangeSummary from '../renewal-change-summary';
import { policyChangeSidebarStyle } from './policy-change-sidebar-style-legacy';

function PolicyChangeSideBarLegacy(props) {
  const classes = policyChangeSidebarStyle();

  const {
    petData,
    policyChangeData,
    midtermFormattedData,
    loading,
    onConfirmChanges,
  } = props;
  const [policyData, setPolicyData] = useState(midtermFormattedData || petData);

  const isChargedAnnually = policyData?.renewal_pending_policy
    ? !!petData?.renewal_pending_policy?.is_annualized_policy
    : !!petData?.latest_pet_policy.is_annualized_policy;
  const months = isChargedAnnually ? 12 : 1;

  const { policyFiledRate, policyCappedRate } = calculatePolicyRates(
    policyData,
    petData,
    months
  );

  const petPlanCost = calculatePetPlanCost(petData, months);

  const transactionFee = isChargedAnnually ? 0 : TRANSACTION_FEE;
  const subtotal = policyCappedRate
    ? policyCappedRate + petPlanCost
    : policyFiledRate + petPlanCost;
  const grandTotal = subtotal + transactionFee;

  const subTotalFormatted = formatCurrency(subtotal, {
    areCents: false,
  });

  const grandTotalFormatted = formatCurrency(grandTotal, {
    areCents: false,
  });

  const [isPolicyChangeConfirmationModalOpen, togglePolicyChangeConfirmationModal] =
    useBooleanInput();

  function handlePolicyChange(isNewPolicy) {
    if (isNewPolicy) {
      setPolicyData(midtermFormattedData);
    } else {
      setPolicyData(petData);
    }
  }

  function calculatePolicyRates(policyData, petData, months) {
    const policyFiledRate = policyData?.renewal_pending_policy
      ? policyData?.renewal_pending_policy?.filed_rate * months
      : policyData?.latest_pet_policy?.filed_rate * months;

    const policyCappedRate =
      (parseFloat(policyData?.renewal_pending_policy?.capped_rate) <
      parseFloat(policyData?.renewal_pending_policy?.filed_rate)
        ? policyData?.renewal_pending_policy?.capped_rate
        : policyData?.renewal_pending_policy?.filed_rate) * months;

    return { policyFiledRate, policyCappedRate };
  }

  function calculatePetPlanCost(petData, months) {
    const planCost = petData?.renewal_pending_pet_plan
      ? petData?.renewal_pending_pet_plan?.cost
      : petData?.latest_pet_plan?.cost || 0;

    return !isPetPlanInCancelledStatus(petData?.latest_pet_plan?.status)
      ? planCost * months
      : 0;
  }

  return (
    <>
      <div className={classes.sidebarTitle}>
        <Typography className={classes.summary}>Summary</Typography>

        {policyChangeData ? (
          <div className={classes.switchRoot}>
            <Typography className={classes.switchLabelNormal}>
              Original Policy
            </Typography>
            {/*<Switch
              disableRipple
              data-testid='policy-switch'
              classes={{
                root: classes.switchMain,
                switchBase: classes.switchBase,
                thumb: classes.switchThumb,
                track: classes.switchTrack,
                checked: classes.switchChecked,
              }}
              defaultChecked
              onChange={event => handlePolicyChange(event.target.checked)}
            />*/}
            <Switch
              disableRipple
              data-testid='policy-switch'
              defaultChecked
              onChange={event => handlePolicyChange(event.target.checked)}
            />

            <Typography className={classes.switchLabelNormal}>
              Updated Policy
            </Typography>
          </div>
        ) : null}
      </div>

      <div
        className={classnames(classes.petPlanDetail, {
          [classes.insuranceAndPlan]: !!petData?.latest_pet_plan,
        })}
      >
        <div className={classes.petPlanHeaderContainer}>
          <Typography className={classes.petPlanTitle}>{`${
            petData.name || ''
          }'s Plan`}</Typography>
          <Typography className={classes.petPlanTotal}>{`${subTotalFormatted} ${
            isChargedAnnually ? '/ yr' : '/ mo'
          }`}</Typography>
        </div>

        <PetDetailHeader petData={petData} />
        {petData?.renewal_pending_policy ? (
          <RenewalChangeSummary
            petData={petData}
            renewalChangePolicyData={policyData}
            showSubTotal={false}
            showGrandTotal={false}
            showTransactionFee={false}
          />
        ) : (
          <MidtermSummary
            petData={petData}
            midtermPolicyData={policyData}
            showSubTotal={false}
            showGrandTotal={false}
            showTransactionFee={false}
          />
        )}
      </div>

      <div className={classes.petPlanHeaderContainer}>
        <Typography className={classes.totalValues}>{`${
          isChargedAnnually ? 'Annual' : 'Monthly'
        } Subtotal:`}</Typography>
        <Typography className={classes.totalValues}>{subTotalFormatted}</Typography>
      </div>

      <div className={classes.petPlanHeaderContainer}>
        <Typography className={classes.totalValues}>
          {`${isChargedAnnually ? 'Annual' : 'Monthly'} Transaction Fee:`}
        </Typography>
        <Typography className={classes.totalValues}>
          {formatCurrency(transactionFee, { areCents: false })}
        </Typography>
      </div>

      <div className={classes.petPlanHeaderContainer}>
        <Typography className={classes.totalValues}>{`${
          isChargedAnnually ? 'Annual' : 'Monthly'
        } Grand Total:`}</Typography>
        <Typography className={classes.totalValues}>
          {grandTotalFormatted}
        </Typography>
      </div>

      <Button
        fullWidth
        disabled={!policyChangeData || !!loading}
        onClick={() => {
          togglePolicyChangeConfirmationModal();
        }}
        className={classes.saveButton}
      >
        SAVE CHANGES
      </Button>

      <Button
        fullWidth
        disabled={!!loading}
        onClick={() => window.location.reload(true)}
        className={classes.resetButton}
      >
        RESET CHANGES
      </Button>
      {petData?.renewal_pending_policy ? (
        <RenewalChangeConfirmationModal
          onClose={togglePolicyChangeConfirmationModal}
          isOpen={isPolicyChangeConfirmationModalOpen}
          loading={!!loading}
          onConfirmChanges={onConfirmChanges}
          petData={petData}
          renewalChangeData={midtermFormattedData}
        />
      ) : (
        <MidtermChangeConfirmationModal
          onClose={togglePolicyChangeConfirmationModal}
          isOpen={isPolicyChangeConfirmationModalOpen}
          loading={!!loading}
          onConfirmChanges={onConfirmChanges}
          petData={petData}
          midtermData={midtermFormattedData}
        />
      )}
    </>
  );
}

PolicyChangeSideBarLegacy.propTypes = {
  petData: PropTypes.shape({
    renewal_pending_policy: PropTypes.shape({
      is_annualized_policy: PropTypes.bool,
      filed_rate: PropTypes.number,
      capped_rate: PropTypes.number,
    }),
    latest_pet_policy: PropTypes.shape({
      is_annualized_policy: PropTypes.bool,
      filed_rate: PropTypes.number,
    }),
    latest_pet_plan: PropTypes.shape({
      status: PropTypes.string,
      cost: PropTypes.number,
    }),
    renewal_pending_pet_plan: PropTypes.shape({
      cost: PropTypes.number,
    }),
    name: PropTypes.string,
  }),
  policyChangeData: PropTypes.object,
  midtermFormattedData: PropTypes.object,
  loading: PropTypes.bool,
  onConfirmChanges: PropTypes.func,
};

export default PolicyChangeSideBarLegacy;
