import makeStyles from '@mui/styles/makeStyles';

const createDocsModalStyle = makeStyles(
  {
    modalTitle: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      color: '#007DFF',
    },
    modalContentDetail: {
      border: '1px solid #CCCCCC',
      borderRadius: '8px',
      padding: '16px',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    headerTitle: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#007DFF',
    },
    documentToGenerate: {
      fontSize: '12px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0);',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      paddingLeft: '0px',
    },
    warnIcon: {
      float: 'left',
      paddingTop: '19px',
      paddingRight: '11px',
    },
    docGenerationWarning: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#373F51;',
      letterSpacing: '0.4px',
      lineHeight: '24px',
      paddingLeft: '0px',
      paddingTop: '19px',
      display: 'flex',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    saveButton: {
      color: 'white',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      maxWidth: '94%',
      margin: '16px 0px',
    },
    cancelButton: {
      color: 'white',
      backgroundColor: '#D4424E',
      '&:hover': {
        backgroundColor: '#D4424E',
      },
      maxWidth: '94%',
    },
  },
  { name: 'create-docs-modal' }
);

export { createDocsModalStyle };
