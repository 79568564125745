import { TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';

import PhoneInput from '../../lib/shared/ui/phone-input';

export default function CustomerFilter() {
  const { filterValues, setFilters } = useListContext();
  const form = useForm({
    defaultValues: filterValues,
  });

  function onSubmit(values) {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      setFilters({}, []);
    }
  }

  function onClearAll() {
    form.reset();
    setFilters({}, []);
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display='flex' alignItems='flex-end' mb={1}>
            <Box component='span' mr={2}>
              <TextInput helperText={false} source='first_name' label='First Name' />
            </Box>
            <Box component='span' mr={2}>
              <TextInput helperText={false} source='last_name' label='Last Name' />
            </Box>
            <Box component='span' mr={2}>
              <TextInput helperText={false} source='email' label='Email Address' />
            </Box>
            <Box component='span' mr={2}>
              <TextInput helperText={false} source='pumpkin_id' label='PKN Number' />
            </Box>
            <Box component='span' mr={2}>
              <PhoneInput
                helperText={false}
                source='phone'
                label='Phone Number'
                variant='filled'
              />
            </Box>

            <Box
              component='span'
              mr={2}
              mb={1.5}
              display='flex'
              alignItems='flex-end'
            >
              <Box component='span' mr={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  type='submit'
                  style={{
                    borderRadius: '4px',
                    width: '120px',
                  }}
                >
                  Filter
                </Button>
              </Box>
              <Box component='span' mr={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  type='button'
                  onClick={() => onClearAll()}
                  style={{
                    borderRadius: '4px',
                    width: '120px',
                  }}
                >
                  Clear All
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
}
