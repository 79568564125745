import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Dialog, Tab, Tabs } from '@mui/material';

import ClaimAuditLogTab from '../claim-audit-log-tab';
import ClaimNotesTab from '../claim-notes-tab';
import ClaimReimbursementTab from '../claim-reimbursement-tab';
import { claimSideModalStyle } from './claim-side-modal-style';

export default function ClaimSideModal(props) {
  const record = useRecordContext();
  const { onClose, open, onSendNoteClick, opsUserList = [] } = props;
  const classes = claimSideModalStyle();
  const [tabValue, setTabValue] = useState(0);

  function handleSubmit(props, refetchClaimNotes) {
    onSendNoteClick(record, props.note, refetchClaimNotes);
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.modal }}>
      <Tabs
        value={tabValue}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        onChange={handleTabChange}
      >
        <Tab label='Notes' data-testid='menu-notes' />
        <Tab label='Change Log' data-testid='menu-changelog' />
        <Tab label='Reimbursements' data-testid='menu-reimbursements' />
      </Tabs>
      <ClaimNotesTab tabValue={tabValue} handleSubmit={handleSubmit} />
      <ClaimAuditLogTab tabValue={tabValue} opsUserList={opsUserList} />
      <ClaimReimbursementTab tabValue={tabValue} />
    </Dialog>
  );
}
