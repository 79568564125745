import { TextField } from 'react-admin';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { usePet, usePlanByPetId } from '@pumpkincare/users';

function PetVetAssociatedRow({ record }) {
  const { data: petData } = usePet(record.id);
  const { data: petPlanData } = usePlanByPetId(petData?.latest_pet_plan?.plan_id);

  return petData?.associated_vets?.map((vet, index) => (
    <TableRow key={index}>
      <TableCell>
        <TextField source='pumpkin_id' label='Pet ID' record={record} />
      </TableCell>
      <TableCell>
        <TextField source='name' label='Name' record={record} />
      </TableCell>
      <TableCell>
        <TextField
          source='name'
          label='Latest Plan'
          record={{
            name: petPlanData
              ? `${petPlanData?.version_number} - ${petPlanData?.name}`
              : '',
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          source='visited_vet_this_year'
          label='Visited Vet This Year'
          record={record}
        />
      </TableCell>
      <TableCell>
        <TextField source='vet_name' label='Vet Clinic' record={vet} />
      </TableCell>
    </TableRow>
  ));
}

export default PetVetAssociatedRow;
