import { useState } from 'react';
import { CREATE } from 'react-admin';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dataProvider } from '../../lib/dataProvider/provider';
import RoutePaths from '../../routes';

const theme = createTheme();

const useStyles = makeStyles(
  {
    button: {
      display: 'block',
      marginTop: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 160,
    },
    emailWrapper: {
      marginLeft: -5,
    },
  },
  { name: 'email' }
);

const Email = props => {
  const [templateIndex, setTemplateIndex] = useState('');
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { id, settings } = props;
  const { templates, entity } = settings;
  const [sendingEmail, setSendingEmail] = useState(false);
  const [mailSuccess, setMailSuccess] = useState(false);
  const [errorSendingMail, setErrorSendingMail] = useState('');

  const templateOptions = templates.map((item, i) => (
    <MenuItem key={i} value={i}>
      {item.display_name}
    </MenuItem>
  ));

  const handleSendEmail = () => {
    const template = templates[templateIndex] ? templates[templateIndex].name : '';

    setSendingEmail(true);
    dataProvider(CREATE, RoutePaths.mgaEmails, {
      data: {
        entity,
        id,
        template,
      },
    })
      .then(() => {
        setSendingEmail(false);
        setMailSuccess(true);
        setTimeout(() => {
          setMailSuccess(false);
        }, 3000);
      })
      .catch(() => {
        setSendingEmail(false);
        setErrorSendingMail('there is an error when sending email');
        setTimeout(() => {
          setErrorSendingMail('');
        }, 3000);
      });
  };

  function handleChange(event) {
    setTemplateIndex(event.target.value);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <div className={classes.emailWrapper}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='demo-controlled-open-select'>
            Email Template
          </InputLabel>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={templateIndex}
            onChange={handleChange}
          >
            {templateOptions}
          </Select>
        </FormControl>
        <Button
          color='primary'
          className={classes.button}
          disabled={sendingEmail}
          onClick={handleSendEmail}
        >
          {sendingEmail ? 'Sending...' : 'Send Email'}
        </Button>
        {errorSendingMail ? <p>{errorSendingMail}</p> : null}
        {mailSuccess ? <p>Email sent successfully</p> : null}
      </div>
    </>
  );
};

export default Email;
