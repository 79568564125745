import makeStyles from '@mui/styles/makeStyles';

const claimsProcessingStyle = makeStyles(
  {
    root: { backgroundColor: 'rgb(0, 125, 255, 0.08)' },
    noActions: { marginTop: '0px' },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: '500',
      color: '#2196F3',
      marginRight: '12px',
    },
    attachmentsTitle: {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
      fontWeight: 'normal',
      color: '#000000',
      marginBottom: '8px',
    },
    claimWrapper: {
      width: 'auto',
      backgroundColor: 'white',
      padding: '20px',
    },
    claimContent: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '42px',
      justifyContent: 'space-between',
    },
    hideClaimContent: { marginBottom: '0px' },
    nextButton: {
      marginLeft: '12px',
    },
    newNextButton: {
      backgroundColor: '#5368F5',
      width: '110px',
      '&:hover': {
        backgroundColor: '#2A41DE',
      },
      borderRadius: '4px',
      color: 'white',
      marginLeft: '12px',
    },
    backButton: {
      marginLeft: '12px',
      marginRight: '8px',
      color: '#5368F5',
    },
    table: {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    headerCell: {
      fontWeight: '600',
    },
    rowEven: { backgroundColor: 'rgba(0, 125, 255, 0.08)' },
    rowCell: { padding: '12px 24px 12px 16px' },
    subtitle: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000000',
    },
    description: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
    link: {
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#2196F3',
      background: 'transparent',
      margin: 'auto',
      cursor: 'pointer',
      fontFamily: 'Poppins, sans-serif',
    },
    checkCircle: {
      color: '#3EC28F',
      height: '20px',
      width: '20px',
    },
    alert: {
      backgroundColor: '#FF9800',
    },
  },
  { name: 'claims-processing' }
);

const claimsProcessingSx = {
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  headerCell: {
    fontWeight: '600',
  },
  rowEven: { backgroundColor: 'rgba(0, 125, 255, 0.08)' },
  rowCell: { padding: '12px 24px 12px 16px' },
};

export { claimsProcessingStyle, claimsProcessingSx };
