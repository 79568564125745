import makeStyles from '@mui/styles/makeStyles';

const petEditDetailsStyle = makeStyles(
  {
    petDetailForm: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '250px',
    },

    addButton: {
      borderRadius: '4px',
      maxWidth: '200px',
      backgroundColor: '#007DFF',
      color: 'white',
      width: '100%',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
    },
  },
  { name: 'pet-edit-details' }
);

export { petEditDetailsStyle };
