import { TextInput } from 'react-admin';
import { Grid, InputLabel } from '@mui/material';

import { OrganizationsCreateStyles } from '../../organizations-create/organizations-create.styles';

export function SalesforceIdField() {
  const classes = OrganizationsCreateStyles();
  return (
    <Grid item xs={12} md={2}>
      <InputLabel shrink>Salesforce ID</InputLabel>
      <TextInput
        variant='outlined'
        source='salesforceid'
        label={false}
        fullWidth
        className={classes.defaultHeight}
      />
    </Grid>
  );
}
