import { GENDER_MAP } from '@pumpkincare/shared';

export function calculatePlanTotal(pets, isAnnual) {
  return pets.reduce((result, pet) => {
    result =
      result +
      (parseFloat(pet.policy?.premium * (isAnnual ? 12 : 1)) || 0) +
      (parseFloat(pet.wellnessPlan?.price * (isAnnual ? 11 : 1)) || 0);

    return result;
  }, 0);
}

export function calculatePetTotal(policy, wellnessPlan, isAnnual) {
  return (
    (parseFloat(policy?.premium * (isAnnual ? 12 : 1)) || 0) +
    (parseFloat(wellnessPlan?.price * (isAnnual ? 11 : 1)) || 0)
  );
}

export function formatBioString(pet) {
  const bios = [];

  if (pet.species) bios.push(`Species: ${pet.species}`);
  if (pet.breed_name) bios.push(`Breed: ${pet.breed_name}`);
  if (pet.age) bios.push(`Age ${pet.age}`);
  if (pet.gender) bios.push(`Gender: ${GENDER_MAP[pet.gender]}`);

  return bios.join(' | ');
}
