import { useState } from 'react';
import { Popper, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { autoCompleteInputStyle } from './autocomplete-input-style';

export default function AutocompleteInput(props) {
  const { id, value, api, field, options, label, onChange = () => {} } = props;
  const [inputValue, setInputValue] = useState('');

  function handleOnInputChange(event, newInputValue) {
    setInputValue(newInputValue);
  }

  const classes = autoCompleteInputStyle();

  function handleChange(event, newOption) {
    if (api) {
      api.setEditCellValue({ id, field, value: { ...newOption } }, event);
      // Check if the event is not from the keyboard
      // https://github.com/facebook/react/issues/7407
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
      }
    }

    onChange(newOption);
  }

  function handleRef(element) {
    if (element && api) {
      element.querySelector(`input[id="combo-box-demo"]`).focus();
    }
  }

  function highlight(name) {
    if (!inputValue) {
      return name;
    }

    function escapeRegex(string) {
      return string.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
    }

    const startIndex = name.search(new RegExp(escapeRegex(inputValue), 'i'));
    if (startIndex === -1) {
      return name;
    }

    const endIndex = startIndex + inputValue.length;

    return (
      <>
        {startIndex > 0 ? name.substring(0, startIndex) : null}
        <b>{name.substring(startIndex, endIndex)}</b>
        {endIndex < name.length ? name.substring(endIndex) : null}
      </>
    );
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 20,
  });

  return (
    <div>
      <Autocomplete
        id='combo-box-demo'
        filterOptions={filterOptions}
        ref={handleRef}
        options={options}
        disableClearable
        groupBy={option => option.category}
        getOptionLabel={option => option.name}
        fullWidth
        value={value}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        style={{ width: 300, marginLeft: '4px' }}
        renderOption={(props, option) => (
          <Typography {...props} key={option.id} className={classes.option}>
            {highlight(option.name)}
          </Typography>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            onClick={e => e.stopPropagation()}
            variant='outlined'
          />
        )}
        PopperComponent={params => (
          <Popper
            {...params}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
          />
        )}
      />
    </div>
  );
}
