export const MGA_DOCUMENT_TYPES_UPLOAD_CHOICES = [
  { id: 'application', name: 'Application' },
  { id: 'new_policy_cover_page', name: 'New Policy Cover Page' },
  { id: 'renewal_cover_page', name: 'Renewal Cover Page' },
  { id: 'non_renewal_notice', name: 'Non-Renewal Notice' },
  { id: 'jacket', name: 'Jacket' },
  { id: 'declarations_page', name: 'Declarations Page' },
  { id: 'illness_coverage', name: 'Illness Coverage' },
  { id: 'amendment_endorsements', name: 'Amendment Endorsements' },
  { id: 'prevent_customer_agreement', name: 'Prevent Customer Agreement' },
  { id: 'medical_record', name: 'Medical Record' },
  { id: 'cancellation_notice', name: 'Cancellation Notice' },
  { id: 'claim_adjustment_letter', name: 'Claim Adjustment Letter' },
  { id: 'claim_appeals_letter', name: 'Claim Appeals Letter' },
  { id: 'claim_summary', name: 'Claim Summary' },
  { id: 'claim_invoice', name: 'Claim Invoice' },
  { id: 'claim_form', name: 'Claim Form' },
  { id: 'insurance_policy', name: 'Insurance Policy' },
  {
    id: 'preventive_essentials_cover_page',
    name: 'Preventive Essentials Cover Page',
  },
  { id: 'generic', name: 'Generic' },
  {
    id: 'waiting_period_health_assessment',
    name: 'Waiting Period Health Assessment',
  },
  {
    id: 'wellness_cover_page',
    name: 'Wellness Cover Page',
  },
  {
    id: 'wellness_customer_agreement',
    name: 'Wellness Customer Agreement',
  },
  {
    id: 'pet_avatar',
    name: 'Pet Avatar',
  },
];

export const DOCUMENT_TYPES_REQUIRE_PET_FIELD = [
  'medical_record',
  'no_coverage_in_force',
  'waiting_period_health_assessment',
  'pet_avatar',
];

export const DOCUMENT_ALLOWED_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'image/tiff',
  '.doc',
  'application/msword',
];

export const DOCUMENT_MAX_SIZE = 9540000;
