import { useState } from 'react';
import { Create, useCreate, useNotify, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { GENDER_MAP } from '@pumpkincare/shared';
import { usePetAvailableWellnessPlans } from '@pumpkincare/users';
import { useWellnessProgram } from '@pumpkincare/wellness';

import { getPetSpecies } from '../../../pets/utils/pet-utils';
import PlanSummary from '../../../plans/plan-summary';
import WellnessPlansOptions from '../wellness-plans-options';
import { wellnessCreateStyle } from './wellness-create-style';

function WellnessCreate() {
  const classes = wellnessCreateStyle();
  const location = useLocation();
  const redirect = useRedirect();
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate();
  const [currentWellnessPlan, setCurrentWellnessPlan] = useState({});

  const { pet, user } = location?.state || {};

  const { id: petId, breed_name: breedName, age, gender, species, name } = pet || {};

  const {
    data: { program_options: programOptions },
  } =
    useWellnessProgram({
      program_id: 'saw-pumpkin-0',
      state: user.billing_address.state_province,
      species: getPetSpecies(pet),
      age: age,
    }) || {};

  const { data: options } = usePetAvailableWellnessPlans(petId) || {};

  function handleAddProduct() {
    const tier = `${currentWellnessPlan.tier}.${pet.species}`;

    const payload = {
      pet_id: pet.id,
      price: currentWellnessPlan.price,
      wellness_tier: tier,
      wellness_experiment: '0',
      wellness_partner: 'pumpkin',
    };

    create(
      `wellness-plans/add-wellness`,
      {
        data: payload,
      },
      {
        onSuccess: () => {
          redirect(`/customers/${user.id}/show`);
        },
        onError: error => {
          notify(`Error: ${error?.body?.message}`, 'error');
        },
      }
    );
  }

  return (
    <Create title={'Add Wellness'}>
      <div>
        <IconButton
          onClick={() => redirect(`/customers/${user.id}/show`)}
          size='large'
          sx={{ display: 'flex', color: '#007DFF' }}
        >
          <ArrowBackIosIcon />
          <Typography
            sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
          >
            Add Wellness
          </Typography>
        </IconButton>
      </div>
      <div style={{ width: '100%' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          className={classes.root}
          spacing={2}
        >
          <Grid item>
            <Container
              sx={{
                border: '1px solid #0000001F',
                borderRadius: '8px',
                padding: '24px 16px !important',
              }}
            >
              <Typography variant='h5' color='standard' sx={{ marginBottom: '8px' }}>
                {name}
              </Typography>

              <Typography variant='standard' sx={{ textTransform: 'capitalize' }}>
                {` ${species} • ${breedName} • Age ${age} • ${
                  GENDER_MAP[gender] || 'N/A'
                }`}
              </Typography>

              <Divider sx={{ marginTop: '24px' }} />

              <Container sx={{ padding: '0 0 !important' }}>
                <Typography
                  variant='h6'
                  sx={{ margin: '24px 0 8px', fontWeight: 'bold' }}
                >
                  Wellness Plan
                </Typography>

                <WellnessPlansOptions
                  wellnessPlans={options}
                  onChange={setCurrentWellnessPlan}
                  onRemove={() => setCurrentWellnessPlan({})}
                  isLoading={isCreateLoading}
                  programOptions={programOptions?.[species]}
                  species={species}
                />
              </Container>
            </Container>
          </Grid>
          <Grid item>
            <Typography
              variant='h6'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
            >
              Summary
            </Typography>

            <PlanSummary
              pets={[
                {
                  ...pet,
                  wellnessPlan: currentWellnessPlan,
                },
              ]}
              isChargedAnnually={user.is_charged_annually}
            />

            <Button
              sx={{
                width: '100%',
                marginTop: '16px',
                height: '40px',
              }}
              variant={'contained'}
              disabled={!currentWellnessPlan.tier || isCreateLoading}
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
      </div>
    </Create>
  );
}

export default WellnessCreate;
