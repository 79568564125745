import {
  ArrayField,
  Datagrid,
  DatagridBody,
  DateField,
  FunctionField,
  TextField,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { usePet, usePetByUser, usePlanByPetId } from '@pumpkincare/users';

import { PetVetAssociatedRow } from '../../PetPlans';

function PetPlanWrapper() {
  const record = useRecordContext();

  const { data: petPlanData } = usePlanByPetId(record?.latest_active_plan?.plan_id);
  return (
    <TextField
      source='name'
      label='Latest Plan'
      record={{
        name: petPlanData
          ? `${petPlanData?.version_number} - ${petPlanData?.name}`
          : '',
      }}
    />
  );
}

function PetVetAddress() {
  const pet = useRecordContext();
  const { data: petDetailedData } = usePet(pet.id);
  const notify = useNotify();

  return petDetailedData?.associated_vets ? (
    <CopyToClipboard
      text={petDetailedData.associated_vets[0]?.normalized_addr}
      onCopy={() => {
        notify('Address copied', { type: 'info' });
      }}
    >
      <span>{petDetailedData.associated_vets[0]?.normalized_addr}</span>
    </CopyToClipboard>
  ) : (
    'N/A'
  );
}

function PetVetName() {
  const pet = useRecordContext();
  const { data: petDetailedData } = usePet(pet.id);
  const notify = useNotify();
  return petDetailedData?.associated_vets ? (
    <CopyToClipboard
      text={petDetailedData.associated_vets[0]?.vet_name}
      onCopy={() => {
        notify('Vet clinic copied', { type: 'info' });
      }}
    >
      <span>{petDetailedData.associated_vets[0]?.vet_name}</span>
    </CopyToClipboard>
  ) : (
    'N/A'
  );
}

export default function CustomerPetVets(props) {
  const { allowMultiVetsPerPet } = useFlags();
  const user = useRecordContext();

  const { data: petData, isLoading } = usePetByUser(user?.id);

  return isLoading ? null : (
    <div>
      <Typography style={{ marginTop: '16px', marginBottom: '24px' }}>
        Vet Enrollment / Onboarding
      </Typography>
      <ArrayField
        source='pets_vets'
        record={{
          pets_vets: petData,
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='pumpkin_id' label='Pet ID' />
          <TextField source='name' label='Name' />
          <PetPlanWrapper label='Latest Plan' />
          <TextField source='visited_vet_this_year' label='Visited Vet This Year' />

          <PetVetName label='Vet Clinic' />

          <PetVetAddress label='Vet Address' />
          <DateField source='birth_date' label='Birth Date' />
          <TextField source='latest_weight' label='Weight' />
          <FunctionField
            label='Medical History'
            render={(record = {}) => {
              return record.has_medical_records ? (
                <a
                  style={{ textDecoration: 'none' }}
                  href={record.latest_medical_record_url}
                >
                  View
                </a>
              ) : null;
            }}
          />
        </Datagrid>
      </ArrayField>
      {allowMultiVetsPerPet ? (
        <>
          <Typography style={{ marginTop: '24px', marginBottom: '24px' }}>
            Vet Enrollment / Onboarding
          </Typography>
          <ArrayField
            source='pets_vets'
            record={{
              pets_vets: petData,
            }}
          >
            <Datagrid
              bulkActionButtons={false}
              body={<DatagridBody {...props} row={<PetVetAssociatedRow />} />}
            >
              <TextField label='Pet ID' />
              <TextField label='Name' />
              <TextField label='Latest Plan' />
              <TextField label='Visited Vet This Year' />
              <TextField label='Vet Clinic' />
            </Datagrid>
          </ArrayField>
        </>
      ) : null}
    </div>
  );
}
