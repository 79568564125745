import { isValidElement } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {
  RecordContextProvider,
  sanitizeListRestProps,
  useListContext,
} from 'ra-core';

import ClaimBulkActionPanel from '../../../../sections/claims/ClaimList/view/ClaimBulkActionPanel';
import SimpleListLoading from '../simple-list-loading';

const useStyles = makeStyles(
  {
    tertiary: { float: 'right' },
  },
  { name: 'RaSimpleList' }
);

function SimpleSelectList(props) {
  const {
    className,
    primaryText,
    secondaryText,
    tertiaryText,
    onToggleItem,
    selectedIds = [],
    unselectIds,
    bulkActionButtons,
    ...rest
  } = props;
  const classes = useStyles(props);
  const { data, isLoading, total } = useListContext(props);

  const hasBulkActions = !!bulkActionButtons !== false;

  if (isLoading === true) {
    return (
      <SimpleListLoading
        classes={classes}
        hasBulkActions={hasBulkActions}
        className={className}
        hasSecondaryText={!!secondaryText}
        hasTertiaryText={!!tertiaryText}
      />
    );
  }

  return (total == null && data?.length > 0) || total > 0 ? (
    <>
      {bulkActionButtons !== false && selectedIds.length > 0 ? (
        <ClaimBulkActionPanel selectedIds={selectedIds} unselectIds={unselectIds} />
      ) : null}
      <List className={className} {...sanitizeListRestProps(rest)}>
        {data.map(record => (
          <RecordContextProvider key={record.id} value={record}>
            <li
              onClick={event => {
                event.preventDefault();
                onToggleItem(record.id);
              }}
            >
              <ListItem
                component='div'
                style={
                  selectedIds.includes(record.id)
                    ? {
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        border: '2px solid #3397FF',
                        marginTop: '16px',
                      }
                    : {
                        backgroundColor: 'white',
                        marginTop: '16px',
                        cursor: 'pointer',
                      }
                }
              >
                <ListItemText
                  primary={
                    <div>
                      {isValidElement(primaryText)
                        ? primaryText
                        : primaryText(record, record.id)}

                      {!!tertiaryText ? (
                        isValidElement(tertiaryText) ? (
                          tertiaryText
                        ) : (
                          <span className={classes.tertiary}>
                            {tertiaryText(record, record.id)}
                          </span>
                        )
                      ) : null}
                    </div>
                  }
                  secondary={
                    !!secondaryText &&
                    (isValidElement(secondaryText)
                      ? secondaryText
                      : secondaryText(record, record.id))
                  }
                  secondaryTypographyProps={{ component: 'span' }}
                />
              </ListItem>
            </li>
          </RecordContextProvider>
        ))}
      </List>
    </>
  ) : null;
}

SimpleSelectList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  primaryText: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  secondaryText: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  tertiaryText: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  onToggleItem: PropTypes.func,
};

export default SimpleSelectList;
