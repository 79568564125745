import makeStyles from '@mui/styles/makeStyles';

const cancellationConfirmModalStyle = makeStyles(
  {
    modalSubTitle: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '24px',
      color: '#000000',
    },
    totalReimbursement: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#000000',
      letterSpacing: '0.4px',
    },
    checkInfo: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#000000',
      marginRight: '16px',
      letterSpacing: '0.4px',
    },
    statusDiv: {
      display: 'flex',
      marginTop: '16px',
      alignItems: 'baseline',
    },
    modalContent: { width: '500px' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    updateButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      color: 'white',
      width: '100%',
    },
    informationDiv: {
      border: '1px solid #EEEFF1',
      borderRadius: '4px',
      padding: '10px',
      marginTop: '12px',
      marginBottom: '16px',
    },
    detail: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#000000',
      letterSpacing: '0.4px',
    },
    warningContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '24px',
    },
    confirmationWarning: {
      fontWeight: '400',
      color: '#EE0004',
      fontSize: '12px',
      lineHeight: '16px',
    },
    warningIcon: { color: '#EE0004', marginRight: '8px', width: '16px' },
    dialogTitleContainer: {
      padding: '16px 24px 0 24px',
      color: '#007DFF',
      fontWeight: 500,
    },
  },
  { name: 'cancellation-confirm-modal' }
);

export { cancellationConfirmModalStyle };
