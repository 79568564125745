import { useGetList, useGetOne } from 'react-admin';

const PET_PLAN_QUERY = 'pet-plans';

export function usePetPlans(petId) {
  return useGetList(
    PET_PLAN_QUERY,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { pet_id: petId },
    },
    { enabled: !!petId }
  );
}

export function usePetPlanUtilization(petPlanId) {
  return useGetOne(
    PET_PLAN_QUERY,
    { id: `${petPlanId}/utilization` },
    {
      enabled: !!petPlanId,
      onSuccess: data => {
        data.entitlements.forEach(entitlement => {
          entitlement.total =
            entitlement.available + entitlement.pending + entitlement.utilized;
        });

        return data;
      },
    }
  );
}
