import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const theme = createTheme();

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: theme.palette.grey[300],
      display: 'flex',
    },
  },
  { name: 'RaPlaceholder' }
);

function Placeholder(props) {
  const classes = useStyles(props);
  return <span className={classnames(classes.root, props.className)}>&nbsp;</span>;
}

export default Placeholder;
