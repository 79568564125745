import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import _ from 'lodash';
import { stringify } from 'query-string';

import {
  AUTH_SETTINGS,
  CUSTOMER_POLICY_BASE_API,
  POLICY_BASE_API,
} from '@pumpkincare/config';

import RoutePaths from '../../routes';
import { getInvoiceCreationPostBody } from './adapters/invoices';
import { getRefundsCreationPostBody } from './adapters/refunds';

const getAccessToken = authData => {
  const lastAuthUserTokenName = `CognitoIdentityServiceProvider.${authData.userPoolWebClientId}.LastAuthUser`;

  const lastAuthUser = localStorage.getItem(lastAuthUserTokenName);

  const accessTokenName = `CognitoIdentityServiceProvider.${authData.userPoolWebClientId}.${lastAuthUser}.accessToken`;
  return localStorage.getItem(accessTokenName);
};

const oidcGetAccessToken = authData => {
  const accessTokenName = `oidc.user:${authData.oauth.domain}:${authData.userPoolWebClientId}`;
  return JSON.parse(localStorage.getItem(accessTokenName)).access_token;
};

const httpClient = (url, paramOptions = {}) => {
  const options = paramOptions;
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const parsedUrl = new URL(url);

  let accessToken;
  if (AUTH_SETTINGS.useOidcLib) {
    accessToken = oidcGetAccessToken(AUTH_SETTINGS);
  } else {
    accessToken = getAccessToken(AUTH_SETTINGS);
  }

  options.headers.set('Authorization', `Bearer ${accessToken}`);
  options.mode = 'cors';

  const fetchUrl = parsedUrl.toString();

  if (options.api === 'UTILS') {
    return fetchUtils.fetchJson(fetchUrl);
  } else {
    return fetchUtils.fetchJson(fetchUrl, options);
  }
};

const convertImageToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
  });

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST         => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE          => GET http://my.api.url/posts/123
 * GET_MANY         => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE           => PUT http://my.api.url/posts/123
 * CREATE           => POST http://my.api.url/posts
 * DELETE           => DELETE http://my.api.url/posts/123
 * GET_ONE_DETAILS  => GET http://my.api.url/posts/123/details
 */
const simpleRestProvider = () => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} requestResource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, requestResource, params) => {
    // map outgoing 'customers' calls to '/users' endpoints
    const { api, custom_url } = params;
    const options = {};
    let apiUrl = POLICY_BASE_API;

    if (api === 'CUSTOMER') {
      apiUrl = CUSTOMER_POLICY_BASE_API;
    } else if (api === 'UTILS') {
      apiUrl = custom_url;
      options.api = api;
    }
    let resource = requestResource;

    // we don't have a "Customer" resource on the backend,
    // but since that's how the backoffice refers to it
    // the easiest solution was aliasing it here
    if (resource === RoutePaths.customers) {
      resource = 'users';
    }

    // the default data provider does not support sub resources
    // so we added some code here to allow accessing them in some
    // special cases
    const { sub, subId, subPath } = params;
    // support sub resources like this:
    // /resource/:id/subresource
    // or
    // /resource/:id/subresource/:subId
    // or
    // /resource/:id/subresource/:subId/:subPath
    const subFiltered = [sub, subId, subPath].filter(Boolean).join('/');
    let url = '';
    let postBody = params.data;
    const putBody = { ...params };

    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          filter: JSON.stringify(params.filter),
        };

        if (page && perPage) {
          query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1]);
        }

        if (resource === RoutePaths.claims && params.filter) {
          let customer;

          if (
            params.filter['first_name'] ||
            params.filter['last_name'] ||
            params.filter['pkn_number']
          ) {
            customer = {
              first_name: params.filter['first_name'],
              last_name: params.filter['last_name'],
              external_id: params.filter['pkn_number'],
            };
          }

          const assignedIdFilter =
            params.filter['assigned_id'] === 'null'
              ? null
              : params.filter['assigned_id'];

          query.filter = JSON.stringify({
            claim_number: params.filter['claim_number'],
            claim_type: params.filter['claim_type'],
            status: params.filter['status'],
            is_unassigned: params.filter['is_unassigned'],
            assigned_id: assignedIdFilter,
            is_active: params.filter['is_active'],
            external_claim_id: params.filter['external_claim_id'],
            pet_name: params.filter['pet_name'],
            current_owner_email: params.filter['current_owner_email'],
            state: params.filter['state'],
            customer,
          });
        }

        if (resource === RoutePaths.invoices && params.filter) {
          const filters = {};
          if (params.filter['created_at_gte']) {
            filters.created_at_gte = params.filter['created_at_gte'];
            delete params.filter['created_at_gte'];
          }

          if (params.filter['pet_names']) {
            filters.pet_names = params.filter['pet_names'];
            delete params.filter['pet_names'];
          }

          query.filters = JSON.stringify(filters);
          query.filter = JSON.stringify(params.filter);
        }

        if (resource === 'dashboard') {
          resource = 'claims';
          if (params.filter) {
            const filters = {};
            if (params.filter['only_uncompleted']) {
              filters.only_uncompleted = params.filter['only_uncompleted'];
              delete params.filter['only_uncompleted'];
            }

            query.filters = JSON.stringify(filters);
            query.filter = JSON.stringify(params.filter);
          }
        }

        if (
          resource === RoutePaths.claimsLibrary &&
          Object.keys(params.filter).length > 0
        ) {
          resource = 'claims';
        }

        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = resource
          ? `${apiUrl}/${resource}/${params.id}`
          : `${apiUrl}/${params.id}`;

        url += subFiltered ? `/${subFiltered}` : '';

        if (params.query) {
          url += `?${stringify(params.query)}`;
        }

        break;
      case 'GET':
        url = `${apiUrl}/${resource}`;
        break;
      case GET_MANY: {
        let query;
        if (params.isQuote) {
          query = {
            filter: JSON.stringify({ email: params.email }),
          };
        } else if (params.isPet) {
          query = {
            filter: JSON.stringify({ user_id: params.userId }),
          };
        } else {
          query = { filter: JSON.stringify({ id: params.ids }) };
        }

        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };

        // Replace the resource is to align with our notes endpoint path
        if (resource === RoutePaths.notes && params.target === 'user_id') {
          resource = `users/${params.id}/notes`;
        } else if (resource === RoutePaths.cfClaims && params.target === 'user_id') {
          resource = `users/${params.id}/cf-claims`;
        } else if (
          resource === RoutePaths.plansSummary &&
          params.target === 'user_id'
        ) {
          resource = `users/${params.id}/${RoutePaths.plansSummary}`;
        }

        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case 'PATCH':
        url = `${apiUrl}/${resource}`;
        options.method = 'PATCH';

        if (params.id) {
          url += `/${params.id}`;
        }

        url += subFiltered ? `/${subFiltered}` : '';

        options.body = JSON.stringify(putBody.data || params);
        break;

      case UPDATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'PUT';

        if (params.id) {
          url += `/${params.id}`;
        }

        if (resource === RoutePaths.addresses) {
          putBody.data.state_province = params.data.state;
        }

        if (
          [RoutePaths.invoices, RoutePaths.medicalRecordsRequests].some(
            path => path === resource
          )
        ) {
          options.method = 'PATCH';
        }

        url += subFiltered ? `/${subFiltered}` : '';

        options.body = JSON.stringify(putBody.data);
        break;
      case CREATE:
        // Replace the resource is to align with our notes endpoint path
        if (resource === RoutePaths.notes) {
          resource = `users/${params.data.user_id}/notes`;
        }

        if (resource === RoutePaths.refunds) {
          resource = `invoices/${params.data.id}/refunds`;
          postBody = getRefundsCreationPostBody(params);
        }

        if (resource === RoutePaths.invoices) {
          postBody = getInvoiceCreationPostBody(params);
        }

        url = `${apiUrl}/${resource}`;

        options.method = 'POST';
        options.body = JSON.stringify(postBody);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data: json.body?.data ? json.body.data.filter(d => d) : [],
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
      case CREATE:
        if (json) {
          if (params.returnJson) {
            return {
              data:
                params.returnJson === 'BODY'
                  ? json.body
                  : params.returnJson === 'RAW'
                    ? json
                    : json.body.data,
            };
          } else {
            return {
              data: {
                ...params.data,
                id: json.body.data && json.body.data.id,
              },
            };
          }
        } else {
          return {
            data: {
              ...params.data,
              id: 0,
            },
          };
        }
      case DELETE:
        return {
          data: { id: null },
        };
      default:
        return {
          data:
            params.returnJson === 'BODY'
              ? json.body
              : params.returnJson === 'RAW'
                ? json
                : json.body
                  ? json.body.data
                  : json,
        };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return async (type, resource, requestParams) => {
    const params = _.cloneDeep(requestParams);
    const apiUrl = POLICY_BASE_API;

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }

    // this is a special case that we need to support file uploads through react admin. it's modeled after this example:
    // https://github.com/marmelab/react-admin/blob/master/docs/DataProviders.md#extending-a-data-provider-example-of-file-upload
    if (type === CREATE) {
      if (
        resource === RoutePaths.claimattachments ||
        resource === RoutePaths.claims
      ) {
        const { file } = params.data;

        if (file && file instanceof Array) {
          params.data.files = [];
          for (let index = 0; index < file.length; index++) {
            if (file[index].rawFile instanceof File) {
              let parsedFile = {};
              parsedFile.content = await convertImageToBase64(file[index].rawFile);
              parsedFile.name = file[index].rawFile.name;
              parsedFile.type = file[index].rawFile.type;
              params.data.files.push(parsedFile);
            }
          }
          delete params.data.file;
        } else {
          if (file && file.rawFile instanceof File) {
            const base64 = await convertImageToBase64(file.rawFile);
            params.data.name = file.rawFile.name;
            params.data.type = file.rawFile.type;
            params.data.content = base64;

            delete params.data.file;
          }
        }
      }
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    if (
      resource === RoutePaths.claimsLibrary &&
      Object.keys(params.filter).length === 0
    ) {
      return { data: [{ id: 0 }], total: 1 };
    } else {
      return httpClient(url, options).then(response =>
        convertHTTPResponse(response, type, resource, params)
      );
    }
  };
};

export const dataProvider = simpleRestProvider();
