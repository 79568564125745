import { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { formatAgeRange } from '../../shared/utils/planUtils';

const useStyles = makeStyles(
  {
    planItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      whiteSpace: 'normal',
      paddingTop: '18px',
      paddingBottom: '18px',
    },
    planVersion: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    planTitle: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '4px',
    },
    planDetails: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
      marginTop: '8px',
    },
    menu: {
      padding: '0px !important',
      maxWidth: '472px',
    },
  },
  { name: 'mga-plan-input' }
);

function MgaPlanInput(props) {
  const { formData, label, source, options, required } = props;

  const {
    field,
    fieldState: { isTouched, error },
  } = useInput({ ...props });
  const classes = useStyles();

  const [value, setValue] = useState('');

  useEffect(() => {
    const currentValue = _.get(formData, source);
    if (!value && options.length > 0 && currentValue) {
      const option = options.find(opt => opt.id === currentValue.id) || {
        ...currentValue,
      };
      setValue(option);
    }
  }, [formData, source, value, options]);

  function handleOnChange(event) {
    const newOption = event.target.value;
    setValue(newOption);
    _.set(formData, source, newOption);
    if (newOption) {
      field.onBlur();
    }
  }

  function renderValue(value) {
    if (value === '') {
      return 'None';
    }

    return `${value.name} ${formatAgeRange(
      value.min_age_months,
      value.max_age_months
    )}`;
  }

  function createItem(plan) {
    return (
      <MenuItem key={plan.id} value={plan} className={classes.planItem}>
        <Typography className={classes.planVersion}>
          PREVENT {plan.version_number.toFixed(1)}
        </Typography>
        <Typography className={classes.planTitle}>
          {plan.name} {formatAgeRange(plan.min_age_months, plan.max_age_months)}
        </Typography>
        <Typography className={classes.planDetails}>
          {plan.plan_definitions
            ?.map(def => `${def.quantity} ${def.details}`)
            .join(', ')}
        </Typography>
        <Typography className={classes.planDetails}>
          Cost: ${plan.cost?.toFixed(2)}
        </Typography>
      </MenuItem>
    );
  }

  return (
    <TextField
      id='plan'
      select
      label={label}
      value={value}
      onChange={handleOnChange}
      onBlur={field.onBlur}
      error={!!(isTouched && error)}
      helperText={isTouched && error ? error : null}
      variant='filled'
      margin='dense'
      required={required}
      fullWidth
      InputLabelProps={{ shrink: !!value }}
      SelectProps={{
        renderValue: renderValue,
        MenuProps: {
          classes: {
            list: classes.menu,
          },
        },
      }}
    >
      {options.map(createItem)}
    </TextField>
  );
}

MgaPlanInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      min_age_months: PropTypes.number.isRequired,
      max_age_months: PropTypes.number.isRequired,
      cost: PropTypes.number.isRequired,
      plan_definitions: PropTypes.array.isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
};

MgaPlanInput.defaultProps = {
  label: 'Plan',
  required: true,
};

export default MgaPlanInput;
