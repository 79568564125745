import { CREATE } from 'react-admin';

import {
  isStatusBlockedForUpdate,
  validateInsuranceRows,
  validatePreventRows,
} from '@pumpkincare/claims';

import { dataProvider } from '../../../lib/dataProvider/provider';
import RoutePaths from '../../../routes';
import { getDateAsFormattedUTC, parseAndFormatDate } from '../../../shared/utils';

function createSignedUrl(userId, filename, documentType) {
  return dataProvider(CREATE, `${RoutePaths.documentsV2}/signed-url`, {
    data: {
      user_id: userId,
      file_name: filename,
      type: documentType,
    },
    returnJson: 'RAW',
  }).then(response => response.data);
}

function uploadFileToS3(file, signedUrls) {
  const formData = new FormData();
  const signedUrlParams = signedUrls[file.name];

  file.key = signedUrlParams.fields.key;

  Object.keys(signedUrlParams.fields).forEach(key => {
    formData.append(key, signedUrlParams.fields[key]);
  });

  formData.append('file', file);

  return fetch(signedUrlParams.url, {
    method: 'POST',
    body: formData,
  }).then(() => {
    const bucket = new URL(signedUrlParams.url).hostname.split('.')[0];
    return `s3://${bucket}/${file.key}`;
  });
}

function uploadMultipleFilesToS3(file, signedUrls) {
  const formData = new FormData();
  const signedUrlParams = signedUrls.find(fileName => fileName[file.name]);
  file.key = signedUrlParams[file.name].fields.key;

  Object.keys(signedUrlParams[file.name].fields).forEach(key => {
    formData.append(key, signedUrlParams[file.name].fields[key]);
  });

  formData.append('file', file);

  return fetch(signedUrlParams[file.name].url, {
    method: 'POST',
    body: formData,
  }).then(() => {
    const bucket = new URL(signedUrlParams[file.name].url).hostname.split('.')[0];
    return `s3://${bucket}/${file.key}`;
  });
}

export function createSignedUrlPromise(userId, filename, documentType) {
  return dataProvider(CREATE, `${RoutePaths.documentsV2}/signed-url`, {
    data: {
      user_id: userId,
      file_name: filename,
      type: documentType,
    },
    returnJson: 'RAW',
  });
}

export function uploadMultipleFiles(promises, files) {
  return Promise.all(promises).then(invoiceValues => {
    const formattedResponse = invoiceValues.map(returnedValue => {
      return {
        [Object.keys(returnedValue.data)[0]]:
          returnedValue.data[Object.keys(returnedValue.data)[0]],
      };
    });
    return files.map(file =>
      uploadMultipleFilesToS3(file.rawFile, formattedResponse)
    );
  });
}

export function uploadFile(userId, file, documentType) {
  return createSignedUrl(userId, file.name, documentType).then(signedUrls => {
    return uploadFileToS3(file, signedUrls);
  });
}

export function addIncident(
  toggleSaving,
  create,
  notify,
  incident,
  toggleAddIncidentModal,
  refetchIncidentHistory
) {
  toggleSaving();
  const createIncidentApi = `${RoutePaths.pets}/${incident.pet_id}/incident-v2`;
  create(
    createIncidentApi,
    {
      data: {
        type: incident.incident_type,
        original_symptom_date: incident.symptom_date,
        end_date: incident.end_date,
        diagnosis_id: incident.diagnosis,
        sub_diagnosis_id: incident.subdiagnosis,
        notes: incident.notes,
        is_curable: incident.is_curable,
        pre_existing_condition: incident.pre_existing_condition,
        mark_as_cured: incident.is_curable ? incident.mark_as_cured : false,
        latest_occurrence_date: incident.latest_occurrence_date,
      },
    },
    {
      onSuccess: () => {
        refetchIncidentHistory();
        toggleSaving();
        toggleAddIncidentModal();
      },
      onError: error => {
        toggleSaving();
        toggleAddIncidentModal();
        notify(`There was an error while adding an incident: ${error.message}`, {
          type: 'warning',
        });
      },
    }
  );
}

export function updateIncident(
  toggleSaving,
  update,
  notify,
  incident,
  toggleEditIncidentModal,
  refetchIncidentHistory
) {
  toggleSaving();
  const editIncidentApi = `${RoutePaths.pets}/${incident.pet_id}/incident-v2`;
  update(
    editIncidentApi,
    {
      id: incident.id,
      data: {
        type: incident.incident_type,
        original_symptom_date: parseAndFormatDate(
          incident.symptom_date,
          'YYYY-MM-DD'
        ),
        end_date: incident.end_date
          ? parseAndFormatDate(incident.end_date, 'YYYY-MM-DD')
          : null,
        diagnosis_id: incident.diagnosis,
        sub_diagnosis_id: incident.subdiagnosis,
        notes: incident.notes,
        is_curable: incident.is_curable,
        mark_as_cured: incident.is_curable ? incident.mark_as_cured : false,
        latest_occurrence_date: parseAndFormatDate(
          incident.latest_occurrence_date,
          'YYYY-MM-DD'
        ),
        pre_existing_condition: incident.pre_existing_condition,
      },
    },
    {
      onSuccess: () => {
        toggleSaving();
        toggleEditIncidentModal();
        refetchIncidentHistory();
      },
      onError: error => {
        toggleSaving();
        toggleEditIncidentModal();
        notify(`There was an error while editing the incident: ${error.message}`, {
          type: 'error',
        });
      },
    }
  );
}

export function insertClaimNote(
  toggleSaving,
  create,
  claimRecord,
  notes,
  refetchClaimNotes,
  notify,
  currentOpsUserData
) {
  toggleSaving();
  create(
    `${RoutePaths.claims}/${claimRecord.id}/notes`,
    {
      id: 'notes',
      data: { ops_user_id: currentOpsUserData.id, note: notes },
    },
    {
      onSuccess: () => {
        toggleSaving();
        refetchClaimNotes();
      },
      onError: error => {
        toggleSaving();
        notify(`There was an error while adding notes: ${error.message}`, {
          type: 'warning',
        });
      },
    }
  );
}

export function updateClaimStatus(
  record,
  status,
  notify,
  toggleSaving,
  update,
  toggleUpdateStatusModal,
  refresh
) {
  if (!status) {
    notify('Please select a status', { type: 'warning' });
  } else {
    if (isStatusBlockedForUpdate(record.status)) {
      notify(
        `This claim cannot be updated because it is in ${record.status} status`,
        {
          type: 'warning',
        }
      );
      return;
    }
    toggleSaving();

    update(
      `${RoutePaths.claims}/${record.id}/status/${status}`,
      {
        meta: { method: 'PATCH' },
      },
      {
        onSuccess: () => {
          toggleSaving();
          toggleUpdateStatusModal();
          refresh();
        },
        onError: error => {
          toggleSaving();
          notify(`There was an error while updating the status: ${error.message}`, {
            type: 'error',
          });
        },
      }
    );
  }
}

export function createReimbursement(
  record,
  payload,
  toggleSaving,
  create,
  refresh,
  toggleReimbursementModal,
  notify
) {
  toggleSaving();

  create(
    `${RoutePaths.claims}/${record.id}/complete`,
    { data: payload },
    {
      onSuccess: () => {
        toggleSaving();
        refresh();
        toggleReimbursementModal();
      },
      onError: error => {
        toggleSaving();
        notify(`There was an error while trying to reimburse: ${error.message}`, {
          type: 'error',
        });
      },
    }
  );
}

export function updateInvoiceLineItems(
  toggleSaving,
  petData,
  invoiceLineItems,
  create,
  record,
  toggleConfirmationModal,
  refresh,
  notify,
  lineItems,
  update,
  areCents
) {
  toggleSaving();
  const lineItemsToBeAdded = [];

  const line_item_type =
    petData?.wellness && !petData?.latest_pet_policy ? 'wellness' : '';

  invoiceLineItems?.invoices?.forEach(invoice => {
    invoice.line_items.forEach(lineItem => {
      let lineItemFormatted = lineItem;
      if (!isNaN(lineItemFormatted.id)) {
        lineItemFormatted.id = null;
      }
      lineItemFormatted.claim_type =
        lineItemFormatted.line_item_type || line_item_type;

      lineItemFormatted.total_amount = areCents
        ? lineItemFormatted.total_amount
        : lineItemFormatted.total_amount * 100;
      lineItemFormatted.tax_amount = areCents
        ? lineItemFormatted.tax_amount
        : lineItemFormatted.tax_amount * 100;
      lineItemFormatted.cost_per_unit_amount = areCents
        ? lineItem.total_amount / lineItem.quantity / 100
        : lineItemFormatted.total_amount / lineItem.quantity;

      lineItemsToBeAdded.push(lineItemFormatted);
    });
  });

  create(
    `${RoutePaths.claims}/${record.id}/invoice-line-items`,
    {
      data: {
        claim_submission_id: record.id,
        operation: 'ADD',
        line_items: lineItemsToBeAdded,
      },
    },
    {
      onSuccess: () => {
        toggleConfirmationModal();
        toggleSaving();
        refresh();
      },
      onError: error => {
        toggleConfirmationModal();
        toggleSaving();
        notify(
          `There was an error while creating the line items: ${error.message}`,
          {
            type: 'error',
          }
        );
      },
    }
  );
}

export function deleteSubclaims(record, refresh, notify, deleteOne) {
  deleteOne(
    RoutePaths.claims,
    { id: `${record.id}/sub-claims` },
    {
      onSuccess: () => {
        refresh();
      },
      onError: error => {
        notify(`There was an error while deleting the subclaims: ${error}`, {
          type: 'error',
        });
      },
    }
  );
}

export function runTheRules({
  record,
  rows,
  pepRows,
  toggleSaving,
  refresh,
  notify,
  insuranceDenialReason,
  bundleItems,
  setBundleItems,
  removePendingLines,
  storeDecisionsPromise,
  calculateUpdateClaim,
}) {
  if (record.step === 2) {
    const insuranceError = rows.length && validateInsuranceRows(rows);
    const preventError = pepRows.length && validatePreventRows(pepRows);

    if (insuranceError) {
      notify(`Please check the insurance line items and fill in all fields`, {
        type: 'warning',
      });
      return;
    }

    if (preventError) {
      notify(`Please check the prevent line items and fill in all fields`, {
        type: 'warning',
      });
      return;
    }

    toggleSaving();

    const insuranceSubclaimId = record.subclaims.find(
      sub => sub.type === 'insurance'
    )?.id;
    const preventSubclaimId = record.subclaims.find(
      sub => sub.type === 'wellness' || sub.type === 'prevent'
    )?.id;

    const preventListBundle = bundleItems.map(bundle => {
      const bundleItemsFormatted = bundle.bundle_items.map((item, index) => ({
        ordinal: index + 1,
        prevent_product_id: item.item_type,
        quantity: parseInt(item.quantity),
        is_included: item.included_in_prevent === true ? '1.0' : '0.0',
        approved_amount: Math.round(
          parseFloat(item.approved_amount).toFixed(2) * 100
        ),
      }));
      return {
        id: bundle.id,
        decision: bundle.decision,
        subclaim_id: preventSubclaimId,
        reimbursible_amount_amount: bundle.approved_amount
          ? bundle.approved_amount * 100
          : 0,
        prevent_product_id: bundle.item_type.id,
        approved_quantity: bundle.approved_qty || bundle.approved_quantity || 0,
        denial_reason_id: bundle.denial_reason,
        bundle_items: bundleItemsFormatted,
      };
    });

    const insuranceList = rows.map(insuranceRow => {
      const denialReasons = insuranceRow.denial_reason
        ? insuranceRow.denial_reason.map(denial =>
            insuranceDenialReason.find(denialReason => denialReason.id === denial)
          )
        : [];
      return {
        id: insuranceRow.id,
        decision: insuranceRow.decision,
        subclaim_id: insuranceSubclaimId,
        invoice_line_item_id: insuranceRow.claim_invoice_line_item_id,
        claimed_quantity: insuranceRow.quantity,
        line_item_description: insuranceRow.description,
        loss_date: getDateAsFormattedUTC(new Date(insuranceRow.loss_date)),
        claimed_amount: insuranceRow.claimed_amount * 100,
        cost_per_unit_amount: insuranceRow.claimed_amount / insuranceRow.quantity,
        ordinal: insuranceRow.ordinal,
        incident_history_id: insuranceRow.incident,
        service_subcode_id: insuranceRow.service.id,
        denial_reason_items: denialReasons.map(denialReason => ({
          denial_reason_id: denialReason.id,
        })),
      };
    });

    const preventListWithoutBundle = pepRows.filter(
      pepRow => pepRow.item_type.name !== 'Bundle'
    );

    const preventList = preventListWithoutBundle.map(pepRow => {
      return {
        id: pepRow.id,
        decision: pepRow.decision,
        subclaim_id: preventSubclaimId,
        reimbursible_amount_amount: pepRow.approved_amount
          ? pepRow.approved_amount * 100
          : 0,
        prevent_product_id: pepRow.item_type.id,
        approved_quantity: pepRow.approved_qty || 0,
        denial_reason_items: pepRow.denial_reason
          ? [{ denial_reason_id: pepRow.denial_reason }]
          : [],
        line_item_description: pepRow.description,
        claimed_quantity: pepRow.quantity,
        claimed_amount: pepRow.claimed_amount * 100,
        invoice_line_item_id: pepRow.claim_invoice_line_item_id,
        ordinal: pepRow.ordinal,
        loss_date: getDateAsFormattedUTC(new Date(pepRow.loss_date)),
        bundle_items: [],
      };
    });

    const lineItemDecisions = insuranceList
      .concat(preventList)
      .concat(preventListBundle);

    processLineItemDecisions(
      record.id,
      lineItemDecisions,
      removePendingLines,
      storeDecisionsPromise,
      calculateUpdateClaim
    )
      .then(() => {
        setBundleItems([]);
        toggleSaving();
        refresh();
      })
      .catch(error => {
        toggleSaving();
        notify(`There was an error while adjudicating: ${error.message}`, {
          type: 'error',
        });
      });
  }
}

async function processLineItemDecisions(
  claimId,
  lineItems,
  removePendingLines,
  storeDecisionsPromise,
  calculateUpdateClaim
) {
  const updatePromises = [];
  const loopLength = Math.ceil(lineItems.length / 10);

  await removePendingLines({
    id: claimId,
    data: {
      updated_decisions: lineItems,
      skip_validate: false,
    },
  });

  for (let i = 0; i <= loopLength; i++) {
    // Batching in sets of 10
    const batchToUpdate = lineItems.slice(i, (i + 1) * 10);

    const updatePromise = storeDecisionsPromise({
      id: claimId,
      data: {
        updated_decisions: batchToUpdate,
        skip_validate: false,
      },
    });

    updatePromises.push(updatePromise);
  }

  await Promise.all(updatePromises);

  await calculateUpdateClaim({
    id: claimId,
    data: {
      updated_decisions: lineItems,
      skip_validate: false,
    },
  });
}
