import makeStyles from '@mui/styles/makeStyles';

const recordsVetSelectorStyle = makeStyles(
  {
    noVet: {
      fontSize: '16px',
      lineHeight: '24px',
      '& span': {
        color: '#2A41DE',
        cursor: 'pointer',
      },
    },
    emailLabel: {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        fontFamily: 'none',
        marginLeft: '4px',
      },
    },
    emailConfirmation: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '8px',
      '& svg': { width: '17px', marginRight: '4px' },
      '& p': { fontSize: '14px', lineHeight: '16px', fontWeight: 700 },
    },
    iconError: { color: '#FF9800' },
    unverifiedEmail: {
      color: '#FF9800',
    },
    iconCheckCircle: { color: '#3EC28F' },
    verifiedEmail: {
      color: '#3EC28F',
    },
  },
  { name: 'records-vet-selector' }
);

export { recordsVetSelectorStyle };
