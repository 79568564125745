import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  SingleFieldList,
  TextField,
  useListContext,
  useNotify,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CircularProgress } from '@mui/material';

import CustomerFilter from './customer-filter';

function DatagridWrapper(props) {
  const { isLoading } = useListContext();
  const notify = useNotify();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props} rowClick={() => 'show'}>
      <TextField source='first_name' sortable={false} />
      <TextField source='last_name' sortable={false} />
      <TextField source='email' sortable={false} />
      <ArrayField label='Pet Name' source='pets' sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source='name' />
        </SingleFieldList>
      </ArrayField>
      <DateField source='created_at' label='Enrollment Date' />
      <FunctionField
        label='PKN Number'
        render={(record = {}) => {
          return (
            <CopyToClipboard
              text={record.pumpkin_id}
              onCopy={() => {
                notify(`${record.pumpkin_id} copied`, {});
              }}
            >
              <span>{record.pumpkin_id}</span>
            </CopyToClipboard>
          );
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        sortable={false}
      />
      <TextField source='phone' label='Phone Number' sortable={false} />
    </Datagrid>
  );
}

export default function CustomerList() {
  return (
    <List
      exporter={false}
      actions={null}
      filters={<CustomerFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <DatagridWrapper />
    </List>
  );
}
