import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Tile from '../../../../shared/components/Tile';
import { formatCurrency } from '../../../../shared/utils/currencyUtils';

const useStyles = makeStyles(
  {
    root: {
      padding: '16px 16px 24px 16px',
    },
    title: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'black',
    },
    container: {
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
      marginTop: '20px',
      '& > *': {
        flex: '0 0 calc(25% - 3px)',
        margin: '0 4px 16px 0px',
      },
      '& > *:nth-child(4n)': {
        marginRight: '0',
      },
    },
  },
  { name: 'insurance-summary' }
);

export default function InsuranceSummary(props) {
  const {
    policy: { stats, utilization },
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Insurance Claim Summary</Typography>

      <div className={classes.container}>
        <Tile
          title={'Total claims'}
          content={`${stats ? stats.number_of_completed_claims : ''} completed / ${
            stats ? stats.number_of_pending_claims : ''
          } pending`}
        />
        <Tile
          title={'Total paid'}
          content={
            stats
              ? formatCurrency(stats.total_paid, {
                  areCents: true,
                  hideZero: true,
                })
              : ''
          }
        />

        {utilization.map((item, index) => {
          const total =
            item.available === -1
              ? 'Unlimited'
              : formatCurrency(item.available + item.pending + item.utilized, {
                  areCents: true,
                  hideZero: true,
                });
          return (
            <Tile
              key={index}
              title={item.entitlement}
              content={`${formatCurrency(item.utilized, {
                areCents: true,
                hideZero: true,
              })} of ${total}`}
            />
          );
        })}
      </div>
    </div>
  );
}
