import { useRedirect } from 'react-admin';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';

import { ClaimDetailCard } from '@pumpkincare/shared';

export default function ClaimCustomerInformation({ customerData = {} }) {
  const redirect = useRedirect();

  return (
    <ClaimDetailCard
      icon={
        <IconButton
          onClick={() => redirect(`/customers/${customerData.id}/show`)}
          style={{ padding: '0px' }}
          size='large'
        >
          <AccountCircleIcon
            style={{ width: '45px', height: '45px', color: '#007DFF' }}
          />
        </IconButton>
      }
      title={`${customerData.first_name} ${customerData.last_name}`}
      detail={customerData.pumpkin_id}
    />
  );
}
