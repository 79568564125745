import { useGetOne } from 'react-admin';

export const INSURANCE_UTILIZATION_QUERY = 'policies';

function addTotal(item) {
  item.total =
    item.available === -1 ? item.available : item.utilized + item.available;
  return item;
}

export function useInsuranceUtilization(policyId) {
  return useGetOne(
    INSURANCE_UTILIZATION_QUERY,
    { id: `${policyId}/utilization` },
    {
      enabled: !!policyId,
      onSuccess: response => {
        addTotal(response.deductible_utilization);
        addTotal(response.annual_limit_utilization);
        return response;
      },
    }
  );
}
