// Claims
export const PERMISSION_CREATE_CLAIM = 'createClaim';
export const PERMISSION_CREATE_INSURANCE_CLAIM = 'createInsuranceClaim';
export const PERMISSION_CREATE_PREVENT_CLAIM = 'createPreventClaim';
export const PERMISSION_DELETE_CLAIM_ATTACHMENT = 'deleteClaimAttachment';
export const PERMISSION_EDIT_CLAIM_ATTACHMENT = 'editClaimAttachment';
export const PERMISSION_EDIT_CLAIM = 'editClaim';
export const PERMISSION_EDIT_INSURANCE_CLAIM = 'editInsuranceClaim';
export const PERMISSION_EDIT_PREVENT_CLAIM = 'editPreventClaim';
export const PERMISSION_REIMBURSE_CLAIM = 'reimburseClaim';

export const PERMISSION_EDIT_PREVENT_CLAIM_LINE_ITEM = 'editPreventClaimLineItem';

export const PERMISSION_SUBMIT_CLAIM_TO_INSURANCE_PROVIDER =
  'submitClaimToInsuranceProvider';

export const PERMISSION_UPLOAD_CLAIM_ATTACHMENT = 'uploadClaimAttachment';

// Billing
export const PERMISSION_START_REFUND = 'startRefund';

// Invoices
export const PERMISSION_CREATE_CUSTOMER_INVOICE = 'createCustomerInvoice';

// Shipments
export const PERMISSION_CREATE_SHIPMENT = 'createShipment';

// Quotes
export const PERMISSION_CREATE_QUOTE = 'createQuote';
export const PERMISSION_EDIT_QUOTE = 'editQuote';

// Policies
export const PERMISSION_EDIT_POLICY = 'editPolicy';
