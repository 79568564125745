import makeStyles from '@mui/styles/makeStyles';

export const OrganizationsListStyles = makeStyles(
  {
    root: {
      backgroundColor: '#007DFF',
    },
  },
  { name: 'organizations-list' }
);
