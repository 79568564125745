import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const theme = createTheme();

const customerDialogStyle = makeStyles(
  {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    editDialog: {
      minWidth: 830,
      maxWidth: 830,
      width: 830,
      backgroundColor: 'yellow',
    },
    title: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000',
      textTransform: 'uppercase',
      marginBottom: '5px',
    },
    petUpdatesLabel: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000',
      textTransform: 'uppercase',
      marginTop: '28px',
      marginBottom: '14px',
    },
    petUpdatesValue: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '5px',
      columnGap: '15px',
    },
    customerInfo: {
      display: 'flex',
    },
    subTitle: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
    },
    fontStyle: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    element: {
      marginRight: '150px',
    },
    balanceItem: {
      border: '1px solid #EEEFF1',
      width: '48%',
    },
    balanceItemContent: {
      margin: '5px',
    },
    specieGender: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '10px',
      letterSpacing: '1.5px',
      lineHeight: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    petName: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '1.5px',
    },
    label: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    nextChargeDate: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    financialSummary: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid #EEEFF1',
      marginTop: '5px',
      paddingTop: '5px',
    },
    financialSummaryLabel: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000',
      textTransform: 'uppercase',
    },
    financialSummaryValue: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000',
    },
  },
  { name: 'customer-dialog' }
);

export { customerDialogStyle };
