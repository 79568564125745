import makeStyles from '@mui/styles/makeStyles';

const dashboardStyle = makeStyles(
  {
    dashboard: {
      backgroundColor: 'rgb(0, 125, 255, 0.08)',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    dashboardTitle: {
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: '400',
      color: '#007DFF',
    },
    dashboardSubtitle: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#007DFF',
    },
    dashboardLaneWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '16px',
    },
    dashboardLane: {
      border: '1px solid #EEEFF1',
      width: '370px',
      height: '500px',
      backgroundColor: '#F8F8F6',
    },
    laneStatusTitle: {
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      fontWeight: '500',
      marginRight: '8px',
    },
    laneClaimCount: {
      borderRadius: '50%',
      width: '16px',
      height: '15px',
      color: '#FFFFFF',
      fontSize: '9px',
      lineHeight: '14px',
      textAlign: 'center',
    },
    cardWrapper: {
      backgroundColor: '#F8F8F6',
      overflow: 'auto',
      height: '430px',
    },
    link: {
      textDecoration: 'none',
      color: '#2196F3',
      width: '16px',
    },
    laneTitle: {
      backgroundColor: 'white',
      padding: '12px',
      display: 'flex',
    },
  },
  { name: 'dashboard' }
);

export { dashboardStyle };
