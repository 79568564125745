import { useMemo } from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
} from 'react-admin';
import { Typography } from '@mui/material';

import { MoneyField } from '../../../../components/MoneyField';
import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import BundleItemList from '../bundle-item-list';
import {
  claimCompletedDatagridStyle,
  claimCompletedDatagridSx,
} from './claim-completed-datagrid-style';

export default function ClaimCompletedDatagrid(props) {
  const classes = claimCompletedDatagridStyle();

  const {
    rows,
    pepRows,
    insuranceLineItems,
    preventLineItems,
    preventProductsData,
    incidentHistoryOptions,
    isWhiteLabel,
  } = props;
  const { totalClaimed, totalAppliedToDeductible, totalPaid } = useMemo(
    () => ({
      totalClaimed: rows.reduce((sum, item) => sum + item.claimed_amount, 0),
      totalAppliedToDeductible: rows.reduce(
        (sum, item) =>
          sum + (isNaN(item.deductible_amount) ? 0 : item.deductible_amount),
        0
      ),
      totalPaid: rows.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
    }),
    [rows]
  );

  const { totalPepClaimed, totalPepApproved, totalPepDeclined } = useMemo(
    () => ({
      totalPepClaimed: pepRows.reduce((sum, item) => sum + item.claimed_amount, 0),
      totalPepApproved: pepRows.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
      totalPepDeclined: pepRows.reduce(
        (sum, item) =>
          sum + (isNaN(item.deductible_amount) ? 0 : item.deductible_amount),
        0
      ),
    }),
    [pepRows]
  );

  return (
    <div>
      {insuranceLineItems ? (
        <>
          <Typography style={{ marginBottom: '24px' }} className={classes.title}>
            {insuranceLineItems.id}
          </Typography>
          {isWhiteLabel ? (
            <ArrayField
              source='insurance_line_item'
              record={{
                insurance_line_item: rows,
              }}
            >
              <Datagrid
                bulkActionButtons={false}
                sx={{
                  '& .RaDatagrid-headerCell': claimCompletedDatagridSx.headerCell,
                  '& .RaDatagrid-table': claimCompletedDatagridSx.table,
                  '& .RaDatagrid-rowCell': claimCompletedDatagridSx.rowCell,
                  '& .RaDatagrid-rowEven': claimCompletedDatagridSx.rowEven,
                }}
              >
                <TextField label='C&F ID' source='cnf_claim_id' />
                <TextField label='Sent to C&F' source='created_at' />
                <TextField label='Completed by C&F' source='date_completed' />
                <MoneyField label='Claimed' source='claimed_amount' />
                <DateField label='Loss Date' source='loss_date' />
                <TextField
                  label='Decision'
                  style={{ textTransform: 'capitalize' }}
                  source='decision'
                />
                <MoneyField label='Deducted' source='deductible_amount' />
                <MoneyField label='Paid' source='reimbursible_amount' />
              </Datagrid>
            </ArrayField>
          ) : (
            <ArrayField
              source='insurance_line_item'
              record={{
                insurance_line_item: rows,
              }}
            >
              <Datagrid
                bulkActionButtons={false}
                sx={{
                  '& .RaDatagrid-headerCell': claimCompletedDatagridSx.headerCell,
                  '& .RaDatagrid-table': claimCompletedDatagridSx.table,
                  '& .RaDatagrid-rowCell': claimCompletedDatagridSx.rowCell,
                  '& .RaDatagrid-rowEven': claimCompletedDatagridSx.rowEven,
                }}
              >
                <TextField label='Line Item' source='description' />
                <TextField label='Qty' source='quantity' />
                <TextField source='service.name' label='Service' />
                <FunctionField
                  label='Incident'
                  render={(record = {}) => {
                    let incidentLabel;

                    incidentLabel = incidentHistoryOptions?.find(
                      incident => incident.value === record?.incident_history?.id
                    )?.toolTip;

                    return (
                      <TextField
                        record={{
                          incident_description: incidentLabel,
                        }}
                        source='incident_description'
                      />
                    );
                  }}
                />
                <MoneyField label='Amount' source='claimed_amount' />
                <TextField
                  label='Decision'
                  style={{ textTransform: 'capitalize' }}
                  source='decision'
                />
                <DateField label='Loss Date' source='loss_date' />
                <FunctionField
                  label='Denial Reason'
                  render={(record = {}) => {
                    return record.denial_reason_items?.length ? (
                      <ChipField
                        style={{ textTransform: 'capitalize' }}
                        record={{
                          denial_reason_description: record.denial_reason_items.map(
                            (item, index) => {
                              return item.denial_reason_item
                                ? item.denial_reason_item.code +
                                    (index + 1 === record.denial_reason_items.length
                                      ? ''
                                      : ', ')
                                : item.denial_reason?.code
                                  ? item.denial_reason.code +
                                    (index + 1 === record.denial_reason_items.length
                                      ? ''
                                      : ', ')
                                  : null;
                            }
                          ),
                        }}
                        source='denial_reason_description'
                      />
                    ) : null;
                  }}
                />

                <MoneyField
                  label='Applied to Deductible'
                  source='deductible_amount'
                />
                <MoneyField label='Paid' source='reimbursible_amount' />
              </Datagrid>
            </ArrayField>
          )}
          <div className={classes.totalDiv}>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Claimed:
              </Typography>
              <Typography>
                {formatCurrency(totalClaimed, { areCents: true })}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Applied to Deductible:
              </Typography>
              <Typography>
                {formatCurrency(totalAppliedToDeductible, { areCents: true })}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Paid:
              </Typography>
              <Typography>
                {formatCurrency(totalPaid, { areCents: true })}
              </Typography>
            </div>
          </div>
        </>
      ) : null}

      {preventLineItems ? (
        <>
          {isWhiteLabel ? (
            <>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '24px',
                  alignItems: 'center',
                }}
              >
                <Typography className={classes.title}>
                  {preventLineItems.id}
                </Typography>
              </div>
              <ArrayField
                source='prevent_line_item'
                record={{
                  prevent_line_item: pepRows,
                }}
              >
                <Datagrid
                  bulkActionButtons={false}
                  classes={{
                    table: classes.table,
                    headerCell: classes.headerCell,
                    rowEven: classes.rowEven,
                    rowCell: classes.rowCell,
                  }}
                  expand={({ record }) => (
                    <BundleItemList
                      bundleItems={record.bundle_items}
                      preventProductsData={preventProductsData}
                    />
                  )}
                  isRowExpandable={insuranceRow =>
                    insuranceRow.bundle_items.length > 0
                  }
                >
                  <TextField label='Item Name' source='description' />
                  <TextField label='Product' source='item_type.name' />
                  <TextField label='Qty' source='quantity' />
                  <MoneyField label='Claimed' source='claimed_amount' />
                  <MoneyField label='Approved' source='reimbursible_amount' />
                  <MoneyField label='Reimbursed' source='deductible_amount' />
                  <TextField
                    label='Decision'
                    style={{ textTransform: 'capitalize' }}
                    source='decision'
                  />
                  <FunctionField
                    label='Denial Reason'
                    render={(record = {}) => {
                      return (
                        <TextField
                          style={{ textTransform: 'capitalize' }}
                          record={{
                            prevent_denial_reason: record.denial_reason_items?.length
                              ? record.denial_reason_items[0].denial_reason.name
                              : '',
                          }}
                          source='prevent_denial_reason'
                        />
                      );
                    }}
                  />
                </Datagrid>
              </ArrayField>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '24px',
                  alignItems: 'center',
                }}
              >
                <Typography className={classes.title}>
                  {preventLineItems.id}
                </Typography>
              </div>
              <ArrayField
                source='prevent_line_item'
                record={{
                  prevent_line_item: pepRows,
                }}
              >
                <Datagrid
                  bulkActionButtons={false}
                  classes={{
                    table: classes.table,
                    headerCell: classes.headerCell,
                    rowEven: classes.rowEven,
                    rowCell: classes.rowCell,
                  }}
                  expand={({ record }) => (
                    <BundleItemList
                      bundleItems={record.bundle_items}
                      preventProductsData={preventProductsData}
                    />
                  )}
                  isRowExpandable={insuranceRow =>
                    insuranceRow.bundle_items.length > 0
                  }
                >
                  <TextField label='Line Item' source='description' />
                  <TextField label='Qty' source='quantity' />
                  <TextField label='Item Type' source='item_type.name' />
                  <MoneyField label='Amount' source='claimed_amount' />
                  <TextField
                    label='Decision'
                    style={{ textTransform: 'capitalize' }}
                    source='decision'
                  />
                  <DateField label='Loss Date' source='loss_date' />

                  <FunctionField
                    label='Denial Reason'
                    render={(record = {}) => {
                      return (
                        <TextField
                          style={{ textTransform: 'capitalize' }}
                          record={{
                            prevent_denial_reason: record.denial_reason_items?.length
                              ? record.denial_reason_items[0].denial_reason.name
                              : '',
                          }}
                          source='prevent_denial_reason'
                        />
                      );
                    }}
                  />
                  <MoneyField label='Approved Amount' source='reimbursible_amount' />
                  <TextField label='Approved Quantity' source='approved_quantity' />

                  <MoneyField label='Declined Amount' source='deductible_amount' />
                </Datagrid>
              </ArrayField>
            </>
          )}
          <div className={classes.totalDiv}>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Claimed:
              </Typography>
              <Typography>
                {formatCurrency(totalPepClaimed, { areCents: true })}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Approved:
              </Typography>
              <Typography>
                {formatCurrency(totalPepApproved, { areCents: true })}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                style={{ textAlign: 'left', fontWeight: 'bold', marginRight: '4px' }}
              >
                Total Declined:
              </Typography>
              <Typography>
                {formatCurrency(totalPepDeclined, { areCents: true })}
              </Typography>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
