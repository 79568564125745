export const stripeErrorStatus = {
  generic_decline: {
    error_description: 'Generic decline',
    error_code: 'card_declined',
  },
  insufficient_funds: {
    error_description: 'Insufficient funds decline',
    error_code: 'card_declined',
  },
  lost_card: {
    error_description: 'Lost card decline',
    error_code: 'card_declined',
  },
  stolen_card: {
    error_description: 'Stolen card decline',
    error_code: 'card_declined',
  },
  expired_card: {
    error_description: 'Expired card decline',
  },
  incorrect_cvc: {
    error_description: 'Incorrect CVC decline',
  },
  processing_error: {
    error_description: 'Processing error decline',
  },
  incorrect_number: {
    error_description: 'Incorrect number decline\t',
  },
};
