import { Children, cloneElement, isValidElement, useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

function UserMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { children, label, icon, logout } = props;

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Tooltip title={label}>
        <IconButton
          style={{ borderRadius: '10px', paddingTop: '0px', paddingBottom: '0px' }}
          aria-label={label}
          aria-owns={anchorEl ? 'menu-appbar' : null}
          aria-haspopup={true}
          color='inherit'
          onClick={event => handleMenu(event)}
          size='large'
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        {Children.map(children, menuItem =>
          isValidElement(menuItem) ? cloneElement(menuItem) : null
        )}
        {logout}
      </Menu>
    </div>
  );
}

UserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  logout: PropTypes.node,
  icon: PropTypes.node,
};

UserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
};

export default UserMenu;
