const tracksQueue = {
  events: [],
  isReady: false,
};

export function analyticsTrack(payload, customProperties) {
  if (tracksQueue.isReady) {
    const { event, ...rest } = payload;
    const properties = {
      ...customProperties,
      ...rest,
    };

    window.analytics.track(event, properties);
  } else {
    tracksQueue.events.push({ payload, customProperties });
  }
}

export function runQueuedTracks() {
  tracksQueue.isReady = true;

  tracksQueue.events.forEach(({ payload, customProperties }) => {
    analyticsTrack(payload, customProperties);
  });

  tracksQueue.complete = tracksQueue.events;
  tracksQueue.events = [];
}
