export const nonConversionReasons = [
  {
    id: 'Coverage Other than Pre-Existing',
    name: 'Coverage Other than Pre-Existing',
  },
  { id: 'Dental', name: 'Dental' },
  { id: 'Location Not Available', name: 'Location Not Available' },
  { id: 'Not A Sales Call', name: 'Not A Sales Call' },
  {
    id: 'Not in possession of pet at this time',
    name: 'Not in possession of pet at this time',
  },
  { id: 'Pet under 8 weeks', name: 'Pet under 8 weeks' },
  { id: 'Pre-Existing Condition', name: 'Pre-Existing Condition' },
  { id: 'Price', name: 'Price' },
  { id: 'Spay/Neutering', name: 'Spay/Neutering' },
  { id: 'Still Comparing', name: 'Still Comparing' },
  { id: 'Unknown', name: 'Unknown' },
];
