import { useState } from 'react';
import {
  FormDataConsumer,
  RadioButtonGroupInput,
  regex,
  required,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { CLOUDFLARE_WORKER_API } from '@pumpkincare/config';
import { useCheckEmail } from '@pumpkincare/users';

import VetInput from '../../../../shared/components/VetInput';
import {
  getEmbarkDiscountByState,
  postCustomerQuote,
  putCustomerQuote,
  validateGroupDiscountPasscode,
} from '../service/quoteFlowService';

const zipcodeRegex = /\b\d{5}\b/g;

function CustomerTab(props) {
  const { mutate: mutateZipcode } = useMutation(
    zipcode =>
      dataProvider.getOne('', {
        meta: {
          api: 'UTILS',
          custom_url: `${CLOUDFLARE_WORKER_API}/v1/zipcodes`,
        },
        id: zipcode,
      }),
    {
      onSuccess: ({ data }) => {
        getEmbarkDiscount(data.state);
        setZipcodeError('');
      },
      onError: () => {
        setZipcodeError('Zipcode not found');
        setEmbarkDiscount(null);
      },
    }
  );
  const dataProvider = useDataProvider();
  const { setDiscount, setQuote, quote, setShowNonConversionModal, setUser } = props;
  const [zipcodeError, setZipcodeError] = useState();
  const [embarkDiscount, setEmbarkDiscount] = useState();
  const [discountError, setDiscountError] = useState();
  const navigate = useNavigate();
  const notify = useNotify();

  function onEmailChange(event) {
    checkEmail(event.target.value);
  }

  const {
    isError: isEmailError,
    helperText: emailHelperText,
    isAssociatedQuote,
    toggleExistingCustomerModal,
    user,
    checkEmail,
    ExistingCustomerModal,
  } = useCheckEmail(quote.existingUserDetails || {});
  setUser(user);

  function getEmbarkDiscount(state) {
    getEmbarkDiscountByState(state)
      .then(response => setEmbarkDiscount(response.discount))
      .catch(() => setEmbarkDiscount(null));
  }

  function onDiscountChange(event) {
    validateDiscount(event.target.value);
  }

  const validateDiscount = _.debounce(passcode => {
    if (passcode.length > 0) {
      validateGroupDiscountPasscode(passcode)
        .then(response => {
          if (response.error) {
            setDiscountError('Invalid discount passcode');
            setDiscount('');
          } else {
            setDiscountError('');
            setDiscount(response);
          }
        })
        .catch(() => {
          setDiscountError('Invalid discount passcode');
        });
    } else {
      setDiscountError('');
    }
  }, 500);

  function handleAddPetClick() {
    const data = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      policy_zipcode: user.rating_address.zipcode,
      vet: user.vets.length > 0 ? user.vets[0] : null,
      vet_id: user.vets.length > 0 ? user.vets[0].id : null,
    };

    const payload = props.edit ? quote : data;
    const customerSubmitFunction = props.edit ? putCustomerQuote : postCustomerQuote;
    const redirectPath = props.edit ? `/quotes/${quote.id}/1` : '/quotes/create/1';

    customerSubmitFunction(payload).then(response => {
      setQuote({
        ...response.data,
        existingUserDetails: user,
      });
      toggleExistingCustomerModal();
      navigate(redirectPath, {
        state: {
          from: 'customer',
          is_add_a_pet: true,
          user,
        },
      });
    });
  }

  function validateOnClick(formData) {
    return (
      zipcodeError ||
      (isEmailError && !isAssociatedQuote) ||
      discountError ||
      !formData.first_name ||
      !formData.last_name ||
      !formData.policy_zipcode ||
      !formData.email ||
      !formData.has_vet ||
      (formData.has_vet === 'YES' && !formData.vet)
    );
  }

  function formatNonUserFields(formData) {
    return {
      vet_id: formData.has_vet === 'YES' ? formData.vet.id : null,
      partner: formData.partner === 'YES' ? 'embark' : null,
      partner_code: formData.partner_code === 'YES' ? 'petmeds' : null,
    };
  }

  function handleSubmitCustomerClick({ formData }) {
    if (!formData.partner && !quote.id) {
      notify(`The form contains errors`, { type: 'warning' });
      return;
    }
    if (formData.partner === 'YES' && formData.partner_code === 'YES') {
      notify('Choose only one partner, PetMeds or Embark', { type: 'error' });
      return;
    }

    if (validateOnClick(formData)) {
      notify(`The form contains errors`, { type: 'warning' });
    } else if (quote.id) {
      if (quote.existingUserDetails) {
        notify(`Customer already exists`, { type: 'warning' });
        return;
      }
      const customerUpdateJson = {
        ...quote,
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        policy_zipcode: formData.policy_zipcode,
        vet: formData.has_vet === 'YES' ? formData.vet : null,
        ...formatNonUserFields(formData),
      };

      putCustomerQuote(customerUpdateJson)
        .then(response => {
          setQuote({
            ...response.data,
            ...customerUpdateJson,
          });
          navigate(props.edit ? `/quotes/${quote.id}/1` : '1', {
            state: {
              from: 'customer',
            },
          });
        })
        .catch(error => {
          notify(error.message, { type: 'error' });
        });
    } else {
      const data = {
        ...formData,
        ...formatNonUserFields(formData),
      };

      postCustomerQuote(data)
        .then(response => {
          setQuote(response.data);
          navigate('1', {
            state: {
              from: 'customer',
            },
          });
        })
        .catch(error => {
          notify(error.message, { type: 'error' });
        });
    }
  }

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: '500',
          }}
        >
          Fill Out Customer Details Below:
        </Typography>
        <div>
          <IconButton
            onClick={() =>
              setShowNonConversionModal({ event: 'CLOSE', quote: quote })
            }
            style={{ padding: '0px', color: '#3F51B5' }}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '550px',
          justifyContent: 'space-between',
          paddingTop: '32px',
          paddingLeft: '32px',
        }}
      >
        <TextInput validate={required()} source='first_name' label='First Name' />
        <TextInput validate={required()} source='last_name' label='Last Name' />
      </div>
      <div
        style={{
          display: 'flex',
          width: '550px',
          justifyContent: 'space-between',
          paddingLeft: '32px',
        }}
      >
        <TextInput
          onChange={event =>
            zipcodeRegex.test(event.target.value)
              ? mutateZipcode(event.target.value)
              : setZipcodeError('Invalid Zip code')
          }
          source='policy_zipcode'
          label='Zip Code'
          validate={[required(), regex(/^\d{5}$/, 'Should be 5 characters')]}
          error={!!zipcodeError}
          helperText={zipcodeError}
        />
        <TextInput
          onChange={onEmailChange}
          validate={required()}
          error={isEmailError}
          helperText={emailHelperText}
          label='Owner Email'
          source='email'
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '550px',
          justifyContent: 'space-between',
          paddingLeft: '32px',
        }}
      >
        <Typography
          style={{
            fontSize: '0.8rem',
          }}
        >
          {isEmailError && isAssociatedQuote ? (
            <>
              <FlagOutlinedIcon
                style={{ verticalAlign: 'bottom', fill: '#0000EE' }}
              />
              A quote is associated with this account. Continue or{' '}
              <Link
                to={{ pathname: '/quotes/' }}
                className={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                Search for existing quote
              </Link>
            </>
          ) : null}
        </Typography>
      </div>

      <>
        <div
          style={{
            display: 'flex',
            paddingLeft: '32px',
          }}
        >
          <RadioButtonGroupInput
            style={{
              width: '286px',
            }}
            source='partner_code'
            validate={required()}
            label='DID PETMEDS REFER THEM?'
            choices={[
              { id: 'YES', name: 'YES' },
              { id: 'NO', name: 'NO' },
            ]}
          />
          <div>
            <RadioButtonGroupInput
              source='partner'
              validate={quote.id ? false : required()}
              label='DID EMBARK REFER THEM?'
              disabled={!!quote.id}
              choices={[
                { id: 'YES', name: 'YES' },
                { id: 'NO', name: 'NO' },
              ]}
            />
            <FormDataConsumer>
              {formDataProps => {
                return formDataProps.formData.partner === 'YES' && embarkDiscount ? (
                  <div
                    style={{
                      display: 'flex',
                      color: '#4A6BFF',
                      margin: '-16px 0 16px',
                    }}
                  >
                    <FlagOutlinedIcon style={{ fill: '#4A6BFF' }} />
                    <Typography>
                      {embarkDiscount}% Discount applicable at checkout
                    </Typography>
                  </div>
                ) : null;
              }}
            </FormDataConsumer>
          </div>
        </div>

        <RadioButtonGroupInput
          sx={{
            paddingLeft: '32px',
            '& .MuiFormGroup-root': { paddingLeft: '0px' },
          }}
          source='has_vet'
          validate={required()}
          label='DO THEY HAVE A PRIMARY VET?'
          choices={[
            { id: 'YES', name: 'YES' },
            { id: 'NO', name: 'NO' },
          ]}
        />
      </>

      <FormDataConsumer>
        {formDataProps =>
          formDataProps.formData.has_vet === 'YES' ? (
            <div
              style={{ paddingLeft: '32px', width: '550px', marginBottom: '20px' }}
            >
              <VetInput
                {...formDataProps}
                source='vet'
                zipcodeSource={'policy_zipcode'}
              />
            </div>
          ) : null
        }
      </FormDataConsumer>
      <div
        style={{
          display: 'flex',
          width: '550px',
          justifyContent: 'space-between',
          paddingLeft: '32px',
        }}
      >
        <Typography style={{ paddingTop: '24px' }}>
          Insert team discount code if applicable:
        </Typography>
        <TextInput
          source='discount'
          label='Discount Code'
          onChange={onDiscountChange}
          error={!!discountError}
          helperText={discountError}
        />
      </div>

      <FormDataConsumer>
        {formDataProps => (
          <div style={{ paddingBottom: '32px', textAlign: 'right', width: '582px' }}>
            <Button
              variant='outlined'
              onClick={() => handleSubmitCustomerClick(formDataProps)}
              color='primary'
            >
              Next
            </Button>
          </div>
        )}
      </FormDataConsumer>

      <ExistingCustomerModal onAddInsurance={handleAddPetClick} />
    </>
  );
}

CustomerTab.propTypes = {
  setDiscount: PropTypes.func.isRequired,
  setQuote: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setShowNonConversionModal: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.string,
    existingUserDetails: PropTypes.object,
  }).isRequired,
  edit: PropTypes.bool,
};

export default CustomerTab;
