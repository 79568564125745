import { useEffect, useState } from 'react';
import { Form, RichTextField, useNotify } from 'react-admin';
import { Button, Card, Divider } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { RichTextInput } from 'ra-input-rich-text';

import {
  getCancellationNotes,
  getCurrentOpsUser,
  postCancellationNotes,
} from '../../shared/services/netServices';
import { parseAndFormatDate } from '../../shared/utils';
import useStyles from './CancellationStyles';

function CancellationNotes({ userId }) {
  const classes = useStyles();

  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');
  const notify = useNotify();

  function handleAddNoteClick() {
    getCurrentOpsUser()
      .then(response => {
        if (response?.id) {
          return postCancellationNotes(
            { ops_user_id: response.id, text: note },
            userId
          );
        } else {
          notify('Log in as an Ops User to add notes.', 'warning');
        }
      })
      .then(() => {
        setNote('');
        return getCancellationNotes(userId);
      })
      .then(response => {
        setNotes(response);
      });
  }

  useEffect(() => {
    if (userId) {
      getCancellationNotes(userId).then(response => {
        setNotes(response);
      });
    }
  }, [userId]);

  return (
    <div>
      <Typography className={classes.titleStyle}>Notes ({notes.length})</Typography>
      <Card>
        <CardContent>
          {notes.length > 0
            ? notes.map((note, index) => {
                return (
                  <div key={index} style={{ marginBottom: '32px' }}>
                    <Typography className={classes.noteDate}>
                      {parseAndFormatDate(
                        note.created_at,
                        'DD MMMM YYYY [AT] hh:mm A'
                      )}
                    </Typography>
                    <Typography className={classes.noteAuthorName}>
                      {note.ops_user?.name}
                    </Typography>
                    <RichTextField
                      style={{ display: 'block', wordBreak: 'break-word' }}
                      record={note}
                      source='text'
                    />
                  </div>
                );
              })
            : null}
          <Divider style={{ marginBottom: '16px' }} />
          <Form
            onSubmit={handleAddNoteClick}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <RichTextInput
              label=''
              onChange={event => setNote(event)}
              source='note'
            />
            <Button
              aria-label='add note'
              type='submit'
              variant='contained'
              style={{
                marginLeft: 'auto',
              }}
            >
              Add Note
            </Button>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}

export default CancellationNotes;

CancellationNotes.propTypes = {
  userId: PropTypes.string.isRequired,
};
