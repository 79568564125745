import makeStyles from '@mui/styles/makeStyles';

const BUTTON = {
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  border: '1px solid #CDCFD3',
  padding: '10px',
};

const requestRecordsModalStyle = makeStyles(
  {
    // overall modal styles
    root: {
      backgroundColor: 'rgb(0, 125, 255, 0.08)',
    },
    paper: { backgroundColor: '#FAFAFA', maxWidth: '1200px', width: '83vw' },
    modalTitle: { padding: '32px' },
    modalContent: {
      padding: '0 32px',
      display: 'flex',
      flexDirection: 'row',
      gap: '0 48px',
    },
    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '32px',
    },
    documentsFormHelperText: {
      margin: '-8px 0 16px',
    },

    // shared text, input, spacing styles
    textLabel: {
      margin: 0,
      width: '100%',
      alignItems: 'flex-start',
      '& span': { fontSize: '14px' },
      '& > div > div': { background: 'white' }, // input only
    },
    formHelperText: { marginLeft: 0 },
    title: { fontSize: '34px', lineHeight: '42px', letterSpacing: '0.25px' },
    sectionTitle: {
      fontSize: '24px',
      lineHeight: '32px',
      marginBottom: '16px',
      '&:not(:first-of-type)': { marginTop: '40px' },
    },
    marginBottom: { marginBottom: '16px' },

    // content flexbox styles
    leftSection: { flex: 1 },
    rightSection: {
      flex: '0 0 30vw',
      maxWidth: '435px',
      padding: '16px',
      borderRadius: '8px',
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      alignSelf: 'flex-start',
      boxSizing: 'border-box',
    },

    // pet info & parent (/additional) styles
    grid: {
      marginTop: '8px',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      gap: '8px 0',
      '& > *:nth-child(2n)': { textAlign: 'right' },
    },
    additionalOwner: {
      paddingTop: '16px',
      marginTop: '16px',
      borderTop: '1px solid #E0E0E0',
    },
    additionalOwnerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '16px',
      '& svg': { width: '16px', margin: '-1px 2px 0 0' },
      '& button': {
        color: '#2A41DE',
        background: 'none',
        outline: 'none',
        border: 'none',
        padding: 0,
        textTransform: 'unset',
      },
    },
    addAdditionalOwner: {
      marginTop: '32px',
      textAlign: 'center',
      '& svg': { width: '16px', margin: '-1px 2px 0 0' },
      '& button': {
        color: '#2A41DE',
        background: 'none',
        outline: 'none',
        border: 'none',
        padding: 0,
        textTransform: 'unset',
      },
    },

    // modal CTA styles
    cancelButton: { ...BUTTON, color: '#C21B44' },
    returnButton: { ...BUTTON, color: '#5368F5' },
    confirmButton: {
      ...BUTTON,
      color: 'white',
      backgroundColor: '#5368F5',
      borderColor: '#5368F5',
      '&:disabled': {
        borderColor: '#CDCFD3',
        backgroundColor: '#CDCFD3',
        color: 'white',
      },
    },
  },
  {
    name: 'records-request-modal',
  }
);

export { requestRecordsModalStyle };
