import { Typography } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';

import { paymentFormStyle } from './PaymentFormStyle';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  showIcon: true,
  style: {
    base: {
      color: '#373F51',
      fontSize: '16px',
      fontFamily: 'Nunito Sans',
      lineHeight: '37px',
      '::placeholder': { color: '#A9B7C4' },
      ':focus::placeholder': { color: 'transparent' },
    },
  },
};

const CARD_OPTIONS_50 = {
  ...CARD_OPTIONS,
  style: {
    ...CARD_OPTIONS.style,
    base: {
      ...CARD_OPTIONS.style.base,
      width: '50%',
    },
  },
};

function PaymentForm({ onNameCardChange, onErrorsChange, errors }) {
  const styles = paymentFormStyle();

  const paymentInputClassName = classNames(styles.inputGroup, styles.border);

  function handleFieldChangeFactory(field) {
    return ({ error, complete }) => {
      if (error && error.message && !complete) {
        onErrorsChange({ ...errors, [field]: false });
      } else if (complete) {
        onErrorsChange({ ...errors, [field]: true });
      }
    };
  }

  return (
    <div style={{ marginTop: '32px' }}>
      <Typography style={{ marginBottom: '8px' }}>
        <label htmlFor={'nameShownOnCard'}>
          Name on Card<sup className={styles.asterisk}>*</sup>
        </label>
      </Typography>

      <input
        id={'nameShownOnCard'}
        className={classNames(styles.textInput, styles.border)}
        type={'text'}
        onChange={e => onNameCardChange(e.target.value)}
      />

      <Typography style={{ margin: '24px 0 8px' }}>
        Card Information<sup className={styles.asterisk}>*</sup>
      </Typography>

      <div className={paymentInputClassName}>
        <CardNumberElement
          id='cardNumber'
          className={classNames(styles.textInput, styles.borderBottom)}
          onChange={handleFieldChangeFactory('cardNumber')}
          options={CARD_OPTIONS}
        />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CardExpiryElement
            id={'expirationDate'}
            className={classNames(styles.textInput, styles.borderRight)}
            onChange={handleFieldChangeFactory('expirationDate')}
            options={CARD_OPTIONS_50}
          />
          <CardCvcElement
            id={'cvc'}
            className={styles.textInput}
            onChange={handleFieldChangeFactory('cvc')}
            options={CARD_OPTIONS_50}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
