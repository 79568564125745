import {
  Button,
  Edit,
  FormDataConsumer,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { slugify } from '@pumpkincare/shared';
import { useDiscounts } from '@pumpkincare/users/discounts-query';

import RoutePaths from '../../../routes';
import { OrganizationsForm } from '../organizations-form';
import { OrganizationsEditStyles } from './organizations-edit.styles';

function OrganizationsEditToolbar({ ...props }) {
  const classes = OrganizationsEditStyles();
  const record = useRecordContext();

  const [update, { isLoading }] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();

  function handleUpdate(formData) {
    if (!formData?.name) {
      notify('Invalid Data', { type: 'warning' });
      return;
    }

    const data = {
      ...formData,
      pass_code: slugify(formData.name),
      association: undefined,
    };

    update(
      RoutePaths.organizations,
      { id: record.id, data: data },
      {
        onSuccess: () => {
          notify('Organization Updated', { type: 'success' });
          redirectTo('list', RoutePaths.organizations);
        },
        onError: error => {
          const errorTxt =
            typeof error === 'string'
              ? error
              : error?.message || 'ra.notification.http_error';
          notify(errorTxt, { type: 'warning' });
        },
      }
    );
  }

  return (
    <Toolbar {...props}>
      <FormDataConsumer>
        {formDataProps => (
          <Button
            disabled={isLoading}
            label='UPDATE Organization DETAILS'
            variant='contained'
            className={classes.submitBtn}
            onClick={() => handleUpdate(formDataProps.formData)}
          />
        )}
      </FormDataConsumer>
    </Toolbar>
  );
}

function OrganizationsEditForm() {
  const classes = OrganizationsEditStyles();
  const record = useRecordContext();
  const defaultValues = { ...record, discount_code: record?.discount?.code };
  delete defaultValues.discount;
  const { isLoading, error, data: discounts } = useDiscounts();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography className={classes.redText} component='p'>
        Error fetching discounts data
      </Typography>
    );
  }

  return (
    <SimpleForm toolbar={<OrganizationsEditToolbar />} defaultValues={defaultValues}>
      <OrganizationsForm discounts={discounts} />
    </SimpleForm>
  );
}

export function OrganizationsEdit(props) {
  const classes = OrganizationsEditStyles();

  return (
    <Box
      className={classes.defaultContainer}
      display='flex'
      flexDirection='column'
      height='100%'
    >
      <Typography component='h3' className={classes.pageTitle}>
        Update Organization
      </Typography>
      <Edit {...props}>
        <OrganizationsEditForm />
      </Edit>
    </Box>
  );
}
