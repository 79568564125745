import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { claimActionMenuStyle } from './claim-action-menu-style';

export default function ClaimActionMenu({
  toggleUpdateStatusModal,
  toggleRequestRecordsModal,
  onMenuIconClick,
  disableButton,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = claimActionMenuStyle();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.actionMenuDiv}>
      <IconButton
        disabled={disableButton}
        className={classes.menuIcon}
        onClick={onMenuIconClick}
        size='large'
        data-testid='claim-action-menu'
      >
        <MenuIcon />
      </IconButton>
      <Button
        aria-controls='claim-menu'
        aria-haspopup='true'
        variant='contained'
        className={classes.actionButton}
        onClick={handleClick}
        disabled={disableButton}
      >
        ACTION
        <ExpandMoreIcon />
      </Button>
      <Menu
        id='claim-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            toggleUpdateStatusModal();
          }}
        >
          Status Update
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            toggleRequestRecordsModal();
          }}
        >
          Request Records
        </MenuItem>

        <MenuItem>Move to Claim Queue</MenuItem>
      </Menu>
    </div>
  );
}
