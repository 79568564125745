import PropTypes from 'prop-types';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import {
  getCurrentPolicy,
  getLatestPlan,
} from '../../../../shared/utils/productUtils';

function PlanEffectiveDateField({ petRecord }) {
  const policy = getCurrentPolicy(petRecord?.policies);
  const plan = getLatestPlan(policy, petRecord);

  return plan ? (
    <DateFieldIgnoringZone
      source='plan_effective_date'
      record={plan}
      style={{
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '22px',
        letterspacing: '0.1px',
        color: '#000000',
      }}
    />
  ) : (
    ''
  );
}

PlanEffectiveDateField.propTypes = {
  petRecord: PropTypes.shape({
    policies: PropTypes.arrayOf(
      PropTypes.shape({
        plan_effective_date: PropTypes.string,
      })
    ),
  }),
};

export default PlanEffectiveDateField;
