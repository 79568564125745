import Typography from '@mui/material/Typography';

import { entitlementTableStyle } from './entitlement-table-style';

export default function EntitlementTable({ data }) {
  const classes = entitlementTableStyle();

  function createRow(entitlement, id) {
    return (
      <tr key={id} className={classes.row}>
        <td className={classes.cell}>
          <Typography className={classes.entitlementValue}>
            {entitlement.details}
          </Typography>
        </td>
        <td className={classes.cell}>
          <Typography className={classes.entitlementValue}>
            {entitlement.available}
          </Typography>
        </td>
        <td className={classes.cell}>
          <Typography className={classes.entitlementValue}>
            {entitlement.pending}
          </Typography>
        </td>
        <td className={classes.cell}>
          <Typography className={classes.entitlementValue}>
            {entitlement.utilized}
          </Typography>
        </td>
      </tr>
    );
  }

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.cell}>
            <Typography className={classes.entitlementHeader}>
              ENTITLEMENT
            </Typography>
          </th>
          <th className={classes.cell}>
            <Typography className={classes.entitlementHeader}>AVAILABLE</Typography>
          </th>
          <th className={classes.cell}>
            <Typography className={classes.entitlementHeader}>PENDING</Typography>
          </th>
          <th className={classes.cell}>
            <Typography className={classes.entitlementHeader}>UTILIZED</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(entitlement => createRow(entitlement, entitlement.id))}
      </tbody>
    </table>
  );
}
