import { useState } from 'react';
import {
  ArrayField,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  FunctionField,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { PERMISSION_START_REFUND } from '../../../auth/permissions';
import InvoiceItems from '../../../components/InvoiceItems';
import { MoneyField } from '../../../components/MoneyField';
import RestrictedAction from '../../../components/security/RestrictedAction';
import NumericInput from '../../../lib/shared/ui/numeric-input';
import RoutePaths from '../../../routes';
import { convertDollarsToCents } from '../../../shared/utils';

function CustomToolbar(props) {
  const { isDisabled, label, onCancelButtonClick } = props;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={isDisabled} submitOnEnter={false} label={label} />
      <Button
        variant='contained'
        color={'error'}
        style={{ color: '#FFF' }}
        onClick={onCancelButtonClick}
        sx={{
          padding: '8px !important',
          fontSize: '13px',
          lineHeight: '1.5',
          color: '#FFF',
          margin: '0 8px',
        }}
        disabled={isDisabled}
      >
        Cancel
      </Button>
    </Toolbar>
  );
}
CustomToolbar.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onCancelButtonClick: PropTypes.func,
};

function CustomInput({ sumRefunds }) {
  const record = useRecordContext();
  const source = `refund_id_${record.id}`;
  const inputDisable = record.total_refund_to_date === record.amount;
  return (
    <NumericInput
      id={source}
      source={source}
      key={source}
      label={''}
      onBlur={e => sumRefunds(e)}
      disabled={inputDisable}
      prefix='$'
      precision={2}
      allowDecimalValue
    />
  );
}
CustomInput.propTypes = {
  sumRefunds: PropTypes.func,
};

export function extractRefundList(formValue) {
  return Object.entries(formValue)
    .filter(([key]) => key.startsWith('refund_id_'))
    .map(([key, value]) => {
      const refundAmount = convertDollarsToCents(value);
      return refundAmount !== null && refundAmount !== '' && !isNaN(refundAmount)
        ? {
            invoice_item_id: key.substring(10),
            refund_amount: refundAmount,
            field_name: key,
          }
        : null;
    })
    .filter(item => item !== null); // Remove null entries
}

export function validateRefunds(formValue) {
  const refundList = extractRefundList(formValue);
  const { items } = formValue;
  const errors = {};
  refundList.forEach(refund => {
    const item = items.find(item => item.id === refund.invoice_item_id);

    if (!item) return;

    if (refund.refund_amount < 0) {
      errors[refund.field_name] = 'Refund cannot be negative';
    } else if (refund.refund_amount <= 0 && (item.totalValue || 0) <= 0) {
      errors[refund.field_name] = 'Refund cannot be 0 or lower';
    } else if (
      refund.refund_amount + (item.total_refund_to_date || 0) >
      item.amount
    ) {
      errors[refund.field_name] = 'Total refund cannot exceed line item amount';
    }
  });
  return errors;
}

function CustomerInvoiceShow(props) {
  const { punks1854RefundsComponent } = useFlags();
  const record = useRecordContext();
  const copyProps = { ...props };
  copyProps.record = record;
  const [create, { isLoading: isCreateLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const [inputValue, setInputValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [totalValue, setTotalValue] = useState(0);

  const refunds = [...record.refunds];

  const items = [...record.items];

  const showWarning = props.showWarning;

  const itemsArray = [];

  let refundListValue = [];
  let refundDateList = [];

  let totalRefundToDate = 0;

  items.forEach(item => {
    refundListValue = [];
    refundDateList = [];
    totalRefundToDate = 0;

    if (refunds && refunds.length > 0) {
      refunds.forEach((refund, refundIndex) => {
        if (refund.refund_items && refund.refund_items.length > 0) {
          refund.refund_items.forEach(refundItem => {
            if (refundItem.invoice_item_id === item.id) {
              refundListValue.push({
                value: refundItem.refund_amount,
                id: refundItem.invoice_item_id + refundIndex,
              });

              totalRefundToDate += refundItem.refund_amount;

              refundDateList.push({
                date: refund.created_at,
                id: refundItem.invoice_item_id + refundIndex,
              });
            }
          });
        }
      });
      itemsArray.push({
        ...item,
        refund_amount: refundListValue,
        refund_date: refundDateList,
        total_refund_to_date: totalRefundToDate,
      });
    }
  });

  copyProps.record.items = itemsArray.length > 0 ? itemsArray : items;

  function sumRefunds(obj) {
    const { name, value } = obj.target;

    setInputValue(() => {
      const newState = {
        ...inputValue,
        [name]: value,
      };

      // Calculate the sum with the updated state
      const sumValues = Object.values(newState).reduce((total, val) => {
        const parsedValue = parseFloat(val);
        return !Number.isNaN(parsedValue) ? total + parsedValue * 100 : total;
      }, 0);

      setTotalValue(sumValues);

      return newState;
    });
  }

  function refundSubmit(formValue) {
    create(
      RoutePaths.refunds,
      {
        data: formValue,
      },
      {
        onSuccess: () => {
          notify('Refund item(s) created successfully.', { type: 'success' });
          setIsSubmitting(false);
          refresh();
        },
        onError: error => {
          notify(
            `Something went wrong when creating refund: ${error?.body?.message}`,
            { type: 'error' }
          );
        },
      }
    );
  }

  return (
    <SimpleShowLayout {...copyProps}>
      <InvoiceItems source='items'>
        <Datagrid
          sx={{ '& .RaDatagrid-tableWrapper': { width: props?.sx || '100%' } }}
          bulkActionButtons={false}
        >
          <TextField source='type' />
          <TextField source='description' />

          <MoneyField source='amount' />

          <ArrayField source='refund_date'>
            <SingleFieldList linkType={null} style={{ display: 'grid' }}>
              <DateField showTime source='date' />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source='refund_amount'>
            <SingleFieldList linkType={null} style={{ display: 'grid' }}>
              <MoneyField style={{ color: 'red' }} source='value' />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </InvoiceItems>

      <div style={{ display: 'flex' }}>
        <RestrictedAction requiredPermissions={PERMISSION_START_REFUND}>
          <div
            style={{
              width: '100%',
              textAlign: 'end',
              paddingTop: '22px',
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            {showWarning ? (
              <div>
                <Typography style={{ paddingLeft: '16px', color: '#FF0000' }}>
                  If this refund is due to a cancellation, check the&nbsp;
                  <Link
                    to={{ pathname: `/${RoutePaths.cancellationsDashboard}` }}
                    className={{ cursor: 'pointer' }}
                    style={{
                      textDecoration: 'none',
                      paddingRight: '16px',
                      fontSize: '1rem',
                    }}
                  >
                    cancellations dashboard.
                  </Link>
                </Typography>
              </div>
            ) : null}

            {punks1854RefundsComponent && !isSubmitting ? (
              <Button
                sx={{
                  border: '1px solid',
                  padding: '4px 5px !important',
                  fontSize: '13px',
                  lineHeight: '1.5',
                }}
                color='primary'
                onClick={() => setIsSubmitting(true)}
              >
                Start Refunds
              </Button>
            ) : null}

            {punks1854RefundsComponent ? null : (
              <CreateButton
                label='Start Refunds'
                icon={null}
                resource={RoutePaths.refunds}
                state={{
                  ...copyProps,
                  refundsRedirect: copyProps.refundsRedirect,
                  showCancellationLink: props.showCancellationLink
                    ? true
                    : undefined,
                }}
                sx={{ border: '1px solid' }}
              />
            )}
          </div>
        </RestrictedAction>
      </div>

      {punks1854RefundsComponent && isSubmitting ? (
        <Box sx={{ backgroundColor: '#FAFAFA', padding: '0 64px 8px' }}>
          <SimpleForm
            sx={{ backgroundColor: 'white', padding: '12px 8px' }}
            toolbar={
              <CustomToolbar
                isDisabled={totalValue <= 0 || isCreateLoading}
                label={isCreateLoading ? 'Saving' : 'Submit Refund'}
                onCancelButtonClick={() => setIsSubmitting(false)}
              />
            }
            onSubmit={refundSubmit}
            validate={validateRefunds}
          >
            <DateTimeInput
              label='Date and Time of Refund'
              source='date_refund'
              defaultValue={new Date()}
              disabled
            />
            <TextInput multiline source='refund_reason' label='Reason for Refund' />
            <ArrayField source='items' label=''>
              <Datagrid bulkActionButtons={false}>
                <TextField source='type' />
                <TextField source='description' />
                <MoneyField source='amount' />
                <MoneyField
                  style={{ color: 'red' }}
                  label='Refunded to Date'
                  source='total_refund_to_date'
                />

                <CustomInput label='New Refund' sumRefunds={sumRefunds} />
              </Datagrid>
              <FunctionField
                render={() => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '307px',
                        marginTop: '8px',
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: '700',
                          marginRight: '30px',
                        }}
                      >
                        Refund Total
                      </Typography>
                      <MoneyField
                        style={{
                          color: 'red',
                          marginRight: '98px',
                        }}
                        record={{ total_refund: record.total_refund }}
                        source='record.total_refund'
                      />
                      <MoneyField
                        style={{
                          color: 'red',
                        }}
                        record={{ totalValue: totalValue }}
                        source='totalValue'
                      />
                    </div>
                  );
                }}
              />
            </ArrayField>
          </SimpleForm>
        </Box>
      ) : null}
    </SimpleShowLayout>
  );
}
CustomerInvoiceShow.propTypes = {
  showWarning: PropTypes.bool,
  showCancellationLink: PropTypes.bool,
  sx: PropTypes.string,
};
export default CustomerInvoiceShow;
