const dialogClasses = {
  fileInput: {
    '& .RaFileInput-root': {
      border: '1px solid #949494',
      borderRadius: '4px',
      width: '100%',
      background: 'red',
    },
    '& .RaFileInput-dropZone': {
      border: '1px dashed #949494',
      background: '#CDCFD361',
      width: '100%',
      padding: '32px 0',
    },
  },
  actions: {
    '& .MuiDialogActions': { paddingTop: 0 },
    '& .MuiDialogActions-root': { paddingTop: 0 },
    '& .MuiDialogActions-spacing': { paddingTop: 0 },
  },

  confirmButton: {
    minHeight: '42px',
    borderRadius: '4px',
    color: '#1976d2',
    background: 'unset',
    border: '',
    '&:hover': {
      backgroundColor: '',
    },
  },

  deleteButton: {
    minHeight: '42px',
    borderRadius: '4px',
    color: '#FFFFFF',
    background: '#D32F2F',
    border: '1px solid #D32F2F',
    '&:hover': {
      backgroundColor: '#007DFF',
    },
  },
  cancelButton: {
    borderRadius: '4px',
    minHeight: '42px',
  },

  context: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  dialog: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '80%',
      maxWidth: '444px',
      minHeight: '228px',
    },
  },

  form: { paddingBottom: '0 !important' },
};

export { dialogClasses };
