import { useGetOne } from 'react-admin';

export const CLAIM_NOTES_QUERY = 'claims';

export function useClaimNotes(claimId) {
  return useGetOne(
    CLAIM_NOTES_QUERY,
    { id: `${claimId}/notes` },
    { enabled: !!claimId }
  );
}
