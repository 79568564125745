import { useListContext } from 'react-admin';
import ContentFilter from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';

import useBooleanInput from '../../../../../hooks/useBooleanInput';

export default function ClaimFilterButton() {
  const { showFilter, hideFilter } = useListContext();
  const [isFilterVisible, toggleFilterVisibility] = useBooleanInput(false);

  return (
    <Button
      size='small'
      color='primary'
      onClick={() => {
        isFilterVisible ? hideFilter('main') : showFilter('main');
        toggleFilterVisibility();
      }}
      startIcon={<ContentFilter />}
    >
      Filter
    </Button>
  );
}
