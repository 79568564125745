import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import { CLAIM_POLICY_PLAN_STATUS_COLOR } from '../../../claims/claim-utils';
import { planPolicyDetailCardStyle } from './plan-policy-detail-card-style';

function PlanPolicyDetailCard(props) {
  const {
    title,
    coverageStartDate,
    enrollmentDate,
    status,
    effectiveDatePeriod,
    utilization,
    icon,
    accidentStartDate,
    isPolicy,
  } = props;
  const classes = planPolicyDetailCardStyle();

  return (
    <div className={classes.card}>
      {icon}
      <div>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>{title}</Typography>
          {status ? (
            <Chip
              className={classes.status}
              style={{
                backgroundColor: CLAIM_POLICY_PLAN_STATUS_COLOR[status] || '#FFFFFF',
              }}
              label={status?.replace('_', ' ').toUpperCase()}
            />
          ) : null}
        </div>

        <Typography className={classes.detail} style={{ marginBottom: '4px' }}>
          {effectiveDatePeriod}
        </Typography>

        {isPolicy ? (
          <>
            <div className={classes.dateWrapper}>
              <Typography
                className={classes.detail}
              >{`Accident Start: ${accidentStartDate} | Illness Start: ${coverageStartDate}`}</Typography>
            </div>

            <div className={classes.dateWrapper}>
              <Typography
                className={classes.detail}
              >{`Enrollment Date: ${enrollmentDate}`}</Typography>
            </div>
          </>
        ) : (
          <div className={classes.dateWrapper}>
            <Typography
              className={classes.detail}
            >{`Enrollment Date: ${enrollmentDate} ${
              coverageStartDate ? `Coverage Start Date: ${coverageStartDate}` : ''
            }`}</Typography>
          </div>
        )}

        {utilization ? (
          <div className={classes.utilizationWrapper}>
            <Typography className={classes.detail} style={{ fontWeight: '600' }}>
              {`Utilization: ${formatCurrency(
                utilization.annual_limit_utilization.utilized,
                { areCents: true }
              )} of ${
                utilization.annual_limit_utilization.available >= 0
                  ? formatCurrency(
                      utilization.annual_limit_utilization.utilized +
                        utilization.annual_limit_utilization.available,
                      { areCents: true }
                    )
                  : 'Unlimited'
              }`}
            </Typography>

            <Typography className={classes.detail} style={{ fontWeight: '600' }}>
              &nbsp;
              {`| ${formatCurrency(utilization.deductible_utilization.utilized, {
                areCents: true,
              })} of ${formatCurrency(
                utilization.deductible_utilization.utilized +
                  utilization.deductible_utilization.available,
                { areCents: true }
              )}`}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
}

PlanPolicyDetailCard.propTypes = {
  title: PropTypes.string,
  coverageStartDate: PropTypes.string,
  enrollmentDate: PropTypes.string,
  status: PropTypes.oneOf([
    'active',
    'pending',
    'renew_pending',
    'lapsed',
    'cancel_pending',
    'cancelled',
    'expired',
    'declined',
  ]),
  effectiveDatePeriod: PropTypes.string,
  icon: PropTypes.node,
};

export default PlanPolicyDetailCard;
