import { Form, SelectInput, TextInput, useRecordContext } from 'react-admin';
import { Button } from '@mui/material';

import { usePetBreedsBySpecies } from '@pumpkincare/pets';

import { PET_AGE_OPTIONS } from '../../../constants/quoteFlow';
import { petEditDetailsStyle } from './pet-edit-details-style';

function PetEditDetails({ petData = {}, onEditPetDetails }) {
  const record = useRecordContext();
  const classes = petEditDetailsStyle();
  const { data: petBreedList } = usePetBreedsBySpecies(`${petData.species}s`);

  if (petBreedList?.length === 0) {
    return <div>Loading</div>;
  }

  return (
    <Form
      onSubmit={values => onEditPetDetails(values, record.id, petBreedList)}
      className={classes.petDetailForm}
    >
      <TextInput
        source='name'
        label='Name'
        required={true}
        className={classes.inputWidth}
      />

      <SelectInput
        source='gender'
        label='Gender'
        choices={[
          { id: 'M', name: 'Male' },
          { id: 'F', name: 'Female' },
        ]}
        className={classes.inputWidth}
        required={true}
      />

      <SelectInput
        source='age'
        label='Age'
        choices={PET_AGE_OPTIONS}
        optionText='label'
        optionValue='value'
        className={classes.inputWidth}
        required={true}
      />

      <SelectInput
        source='breed_code'
        label={'Breed'}
        choices={petBreedList}
        className={classes.inputWidth}
        optionText='name'
        optionValue='code'
        required={true}
      />
      <Button className={classes.addButton} type='submit'>
        UPDATE PET INFO
      </Button>
    </Form>
  );
}

export default PetEditDetails;
