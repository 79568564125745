import { Typography } from '@mui/material';

import {
  completedDashboardStatuses,
  inProgressDashboardStatuses,
  toDoDashboardStatuses,
  useDashboard,
} from '@pumpkincare/claims';
import { ClaimCard } from '@pumpkincare/shared';

import { dashboardStyle } from './DashboardStyle';

function Dashboard() {
  const classes = dashboardStyle();
  const { data = [], isLoading } = useDashboard();

  const toDoList =
    !Array.isArray(data) && data?.addedFakeId
      ? []
      : data.filter(claim =>
          toDoDashboardStatuses.some(status => claim.status === status.id)
        );
  const inProgressList =
    !Array.isArray(data) && data?.addedFakeId
      ? []
      : data.filter(claim =>
          inProgressDashboardStatuses.some(status => claim.status === status.id)
        );
  const completedList =
    !Array.isArray(data) && data?.addedFakeId
      ? []
      : data
          .filter(claim =>
            completedDashboardStatuses.some(status => claim.status === status.id)
          )
          .sort((l, r) => {
            if (!l || !l.status_last_updated) {
              return 0;
            }

            return -l.status_last_updated.localeCompare(r.status_last_updated);
          });

  const completedListSliced = completedList.slice(0, 10);

  return isLoading ? null : (
    <div className={classes.dashboard}>
      <Typography className={classes.dashboardTitle}>
        Welcome back{data[0] ? ` ${data[0].current_assignee_name}!` : '!'}
      </Typography>
      <Typography className={classes.dashboardSubtitle}>
        Here what's happening in your account today
      </Typography>
      <div className={classes.dashboardLaneWrapper}>
        <div className={classes.dashboardLane}>
          <div className={classes.laneTitle}>
            <Typography
              className={classes.laneStatusTitle}
              style={{
                color: '#4CAF50',
              }}
            >
              TO DO
            </Typography>
            <Typography
              className={classes.laneClaimCount}
              style={{
                background: '#4CAF50',
              }}
            >
              {toDoList.length}
            </Typography>
          </div>

          <div className={classes.cardWrapper}>
            {toDoList.map(claim => (
              <ClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        </div>
        <div className={classes.dashboardLane}>
          <div className={classes.laneTitle}>
            <Typography
              style={{
                color: '#FF9800',
              }}
              className={classes.laneStatusTitle}
            >
              IN PROGRESS
            </Typography>
            <Typography
              style={{
                background: '#FF9800',
              }}
              className={classes.laneClaimCount}
            >
              {inProgressList.length}
            </Typography>
          </div>

          <div className={classes.cardWrapper}>
            {inProgressList.map(claim => (
              <ClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        </div>
        <div className={classes.dashboardLane}>
          <div
            style={{
              justifyContent: 'space-between',
            }}
            className={classes.laneTitle}
          >
            <div style={{ display: 'flex' }}>
              <Typography
                style={{
                  color: '#3397FF',
                }}
                className={classes.laneStatusTitle}
              >
                RECENTLY COMPLETED
              </Typography>
              <Typography
                style={{
                  background: '#3397FF',
                }}
                className={classes.laneClaimCount}
              >
                {completedListSliced.length}
              </Typography>
            </div>
          </div>

          <div className={classes.cardWrapper}>
            {completedListSliced.map(claim => (
              <ClaimCard key={claim.id} claim={claim} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
