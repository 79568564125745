import makeStyles from '@mui/styles/makeStyles';

const petCancelModalStyle = makeStyles(
  {
    modalTitle: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '32px',
      color: '#2196F3',
    },
    modalSubTitle: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#000000',
    },
    information: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000000',
    },
    informationDiv: {
      marginBottom: '16px',
    },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    updateButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      color: 'white',
      width: '100%',
    },
  },
  { name: 'pet-cancel-modal' }
);

export { petCancelModalStyle };
