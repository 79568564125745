import makeStyles from '@mui/styles/makeStyles';

export const OrganizationsEditStyles = makeStyles(
  {
    defaultContainer: {
      background: '#2196F320',
      marginRight: '-2rem',
      padding: '1rem',
    },
    submitBtn: { backgroundColor: '#007DFF', padding: '.5rem 1rem' },
    pageTitle: { textTransform: 'uppercase' },
    redText: {
      color: 'red',
    },
  },
  { name: 'organizations-edit' }
);
