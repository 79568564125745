import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { usePet } from '@pumpkincare/users';

import { claimBulkEditDeleteSection } from './claim-bulk-edit-delete-section-style';

function ClaimBulkEditDeleteSection({
  onBulkEditCancelClick,
  bulkChanges,
  updateBulkChanges,
  onBulkEditUpdateClick,
  onBulkDeleteClick,
}) {
  const classes = claimBulkEditDeleteSection();

  const record = useRecordContext();
  const { data: petData } = usePet(record?.pet_id);

  const [lineItemType, setLineItemType] = useState('');

  function handleTypeChange(event, lineItemType) {
    setLineItemType(lineItemType);
    updateBulkChanges({
      ...bulkChanges,
      line_item_type: lineItemType,
    });
  }

  return (
    <div
      style={{
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '12px',
        marginBottom: '16px',
        backgroundColor: 'white',
        boxShadow:
          '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      }}
    >
      <div
        style={{
          width: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          color='#5368F5'
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          Edit Selected Items
        </Typography>
        <Button
          onClick={onBulkDeleteClick}
          color='error'
          endIcon={<DeleteIcon color='error' />}
        >
          Delete
        </Button>
      </div>
      <Grid
        sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '16px' }}
        container
        spacing={2}
      >
        <Grid item xs={1.5}>
          <TextField
            variant='standard'
            fullWidth
            onClick={e => e.stopPropagation()}
            type='date'
            InputLabelProps={{
              shrink: true,
              sx: {
                '&.Mui-focused': {
                  color: '#5368F5',
                },
              },
            }}
            InputProps={{
              sx: {
                ':after': {
                  borderBottom: '2px solid #5368F5',
                },
              },
            }}
            sx={{ marginBottom: '0px' }}
            value={bulkChanges?.loss_date}
            label='Loss Date'
            onChange={event =>
              updateBulkChanges({
                ...bulkChanges,
                loss_date: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant='standard'
            fullWidth
            onClick={e => e.stopPropagation()}
            type='text'
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#5368F5',
                },
              },
            }}
            InputProps={{
              sx: {
                ':after': {
                  borderBottom: '2px solid #5368F5',
                },
              },
            }}
            sx={{ marginBottom: '0px' }}
            value={bulkChanges?.description}
            label='Description'
            data-testid='description-input'
            onChange={event =>
              updateBulkChanges({
                ...bulkChanges,
                description: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={1.3}>
          <ToggleButtonGroup
            value={lineItemType}
            exclusive
            sx={{ height: '30px' }}
            onChange={handleTypeChange}
            aria-label='Line Item Type'
          >
            {petData?.latest_pet_policy ? (
              <ToggleButton
                classes={{
                  selected: classes.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='insurance'
              >
                INS
              </ToggleButton>
            ) : null}

            {petData?.wellness ? (
              <ToggleButton
                classes={{
                  selected: classes.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='wellness'
              >
                WELL
              </ToggleButton>
            ) : null}

            {petData?.latest_pet_plan ? (
              <ToggleButton
                classes={{
                  selected: classes.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='prevent'
              >
                PEP
              </ToggleButton>
            ) : null}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={1}>
          <TextField
            variant='standard'
            fullWidth
            onClick={e => e.stopPropagation()}
            type='number'
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#5368F5',
                },
              },
            }}
            InputProps={{
              sx: {
                ':after': {
                  borderBottom: '2px solid #5368F5',
                },
              },
            }}
            sx={{ marginBottom: '0px' }}
            value={bulkChanges?.quantity}
            label='Quantity'
            data-testid='quantity-input'
            onChange={event =>
              updateBulkChanges({
                ...bulkChanges,
                quantity: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant='standard'
            fullWidth
            onClick={e => e.stopPropagation()}
            type='number'
            value={bulkChanges?.total_amount}
            label='Amount'
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#5368F5',
                },
              },
            }}
            InputProps={{
              sx: {
                ':after': {
                  borderBottom: '2px solid #5368F5',
                },
              },
            }}
            sx={{ marginBottom: '0px' }}
            data-testid='total-amount-input'
            onChange={event =>
              updateBulkChanges({
                ...bulkChanges,
                total_amount: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'baseline' }} container spacing={2}>
        <Grid item xs={6}>
          <Button
            className={classes.updateLineItemButton}
            fullWidth
            onClick={onBulkEditUpdateClick}
          >
            UPDATE
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            className={classes.cancelUpdateLineItemButton}
            onClick={onBulkEditCancelClick}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

ClaimBulkEditDeleteSection.propTypes = {
  onBulkEditCancelClick: PropTypes.func,
  bulkChanges: PropTypes.object,
  updateBulkChanges: PropTypes.func,
  onBulkEditUpdateClick: PropTypes.func,
  onBulkDeleteClick: PropTypes.func,
};

export default ClaimBulkEditDeleteSection;
