import { UPDATE } from 'react-admin';

import { dataProvider } from '../../lib/dataProvider/provider';
import RoutePaths from '../../routes';

export function linkVet(petId, vetId) {
  return dataProvider(UPDATE, RoutePaths.pets, {
    id: `${petId}/vets/${vetId}`,
    returnJson: 'RAW',
  });
}
