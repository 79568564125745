export const CLAIM_REIMBURSEMENT_STATUS_COLOR = {
  created: '#4DB5FF',
  open: '#4DB5FF',
  'in local area': 'rgba(62, 194, 143, 0.4)',
  cancelled: '#949494',
  deleted: '#949494',
  failed: '#EA0404',
  're-routed': '#EA0404',
  completed: '#3EC28F',
  closed: '#3EC28F',
  mailed: '#3EC28F',
  'processed for delivery': 'rgba(62, 194, 143, 0.8)',
  'in transit': 'rgba(62, 194, 143, 0.6)',
};
