import { useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { PetPlanPolicyStatus } from '@pumpkincare/shared';

import { MGA_DOCUMENT_TYPES_UPLOAD_CHOICES } from '../../../../constants/documents';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import DocumentUploadV2 from '../document-upload-v2';
import CustomerDocumentsV2Filter from './customer-documents-v2-filter';

const useStyles = makeStyles(
  {
    root: {
      padding: '0px !important',
    },
    refFields: {
      '& > div.MuiFormControl-root': {
        width: '100%',
      },
    },
    topContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '20px',
    },
  },
  { name: 'customer-documents-v2' }
);

const sxStyle = {
  headerCell: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingBottom: '16px',
  },
};

function CustomerDocumentsV2(props) {
  const classes = useStyles();
  const record = useRecordContext();
  const [filters, setFilters] = useState({});
  const [isUploadModalOpen, toggleUploadModal] = useBooleanInput(false);
  const refresh = useRefresh();

  return (
    <SimpleShowLayout {...props}>
      <div className={classes.topContainer}>
        <CustomerDocumentsV2Filter filters={filters} onChange={setFilters} />
        <Button
          variant='contained'
          color='primary'
          label='Upload'
          onClick={toggleUploadModal}
          startIcon={<AddIcon />}
        >
          Upload
        </Button>
      </div>

      <ReferenceManyField
        reference={RoutePaths.documentsV2}
        target='user_id'
        label=''
        className={classes.refFields}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />}
        filter={filters}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={{
            '& .RaDatagrid-headerCell': sxStyle.headerCell,
          }}
        >
          <DateField showTime={true} source='created_at' label='Created on' />
          <TextField
            source='source_file_name'
            label='DOCUMENT NAME'
            emptyText='N/A'
          />
          <FunctionField
            sortBy='type'
            label='Document Type'
            render={record => {
              let label = MGA_DOCUMENT_TYPES_UPLOAD_CHOICES.filter(
                el => el.id === record.type
              );
              label = label && Boolean(label.length) ? label[0]?.name : '';
              if (label && record.version) {
                label += ` (${record.version})`;
              }
              let status = null;
              if (record.policy) {
                status = <PetPlanPolicyStatus status={record.policy.status} />;
              } else if (record.pet_plan) {
                status = <PetPlanPolicyStatus status={record.pet_plan.status} />;
              }

              let img = null;
              if (record.type === 'pet_avatar') {
                img = (
                  <>
                    <br />
                    <img
                      alt='pet avatar'
                      src={record.document_signed_url}
                      style={{
                        height: '200px',
                        width: '200px',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </>
                );
              }

              return (
                <>
                  {label} {status} {img}
                </>
              );
            }}
          />
          <TextField source='claim_id' label='Claim ID' emptyText='N/A' />
          <TextField source='pet_name' label='Pet' />
          <TextField source='ops_user_email' label='Uploaded by' />
          <FunctionField
            label=''
            textAlign='right'
            render={record => {
              return record.document_signed_url ? (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                    window.open(record.document_signed_url);
                  }}
                >
                  DOWNLOAD
                </Button>
              ) : null;
            }}
          />
        </Datagrid>
      </ReferenceManyField>

      {isUploadModalOpen ? (
        <DocumentUploadV2
          open={true}
          toggleDialog={toggleUploadModal}
          userId={record.id}
          onSuccess={() => {
            toggleUploadModal();
            refresh();
          }}
        />
      ) : null}
    </SimpleShowLayout>
  );
}

export default CustomerDocumentsV2;
