import { useMemo } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import NumericField from '../numeric-field';
export default function ClaimLineItemGrid(props) {
  const {
    onLineItemDelete = () => {},
    onLineItemChange = () => {},
    onLineItemAdd = () => {},
    lineItems = [],
  } = props;

  const { totalClaimed } = useMemo(
    () => ({
      totalClaimed: lineItems.reduce(
        (sum, item) => sum + parseFloat(item.claimed_amount),
        0
      ),
    }),
    [lineItems]
  );
  return (
    <div style={{ width: null, maxWidth: '1120px' }}>
      {lineItems.map((lineItem, index) => (
        <div
          key={lineItem.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <IconButton
            onClick={() => onLineItemDelete(index)}
            style={{ color: '#EE0004' }}
            size='large'
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <TextField
            style={{ maxWidth: '160px' }}
            variant='outlined'
            fullWidth
            type='date'
            value={lineItem.loss_date}
            label='Loss Date'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={event =>
              onLineItemChange(event.target.value, index, 'loss_date')
            }
          />

          <TextField
            onChange={event => onLineItemChange(event.target.value, index, 'name')}
            variant='outlined'
            style={{ width: '270px' }}
            label='Line Item Name'
            value={lineItem.name}
          />

          <NumericField
            onChange={event =>
              onLineItemChange(event.target.value, index, 'quantity')
            }
            label='Qty'
            value={lineItem.quantity}
            style={{ width: '70px' }}
          />

          <NumericField
            onChange={event =>
              onLineItemChange(event.target.value, index, 'claimed_amount')
            }
            label='Claimed Amount'
            value={lineItem.claimed_amount}
            style={{ width: '150px' }}
            prefix='$'
            allowDecimalValue
            precision={2}
          />
          <TextField
            style={{ width: '250px' }}
            variant='outlined'
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: '10px',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              },
            }}
            fullWidth
            select
            label='Coverage Type'
            value={lineItem.coverage_type}
            onChange={event =>
              onLineItemChange(event.target.value, index, 'coverage_type')
            }
          >
            <MenuItem key={'insurance'} value={'insurance'}>
              Insurance
            </MenuItem>
            <MenuItem key={'prevent'} value={'prevent'}>
              Preventive Essentials
            </MenuItem>
          </TextField>

          <div style={{ width: '48px' }}>
            {lineItems.length === index + 1 ? (
              <IconButton
                onClick={onLineItemAdd}
                style={{ color: '#3EC28F' }}
                size='large'
              >
                <AddCircleOutlineIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      ))}
      <Typography
        style={{
          width: '582px',
          fontWeight: 'bold',
          fontSize: '24px',
          textAlign: 'right',
        }}
      >
        {`Total: ${formatCurrency(totalClaimed, { areCents: false })}`}
      </Typography>
    </div>
  );
}

ClaimLineItemGrid.propTypes = {
  onLineItemDelete: PropTypes.func,
  onLineItemChange: PropTypes.func,
  onLineItemAdd: PropTypes.func,
  lineItems: PropTypes.array,
};
