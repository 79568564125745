import makeStyles from '@mui/styles/makeStyles';

const autoCompleteInputStyle = makeStyles(
  {
    optionContainer: {
      padding: '8px 0 8px 20px',
    },
    option: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'black',
    },
    group: {
      paddingBottom: '8px',
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: '#5C6BC0',
    },
    groupContainer: {
      margin: '0 16px',
      padding: '8px 0',
      '&:nth-of-type(n+2)': {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '16px 0 8px',
      },
    },
    firstGroup: {
      color: '#2196F3',
    },
  },
  { name: 'auto-complete-input' }
);

export { autoCompleteInputStyle };
