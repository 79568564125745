export function formatAgeRange(minMonths, maxMonths) {
  let lower = minMonths < 12 ? `${minMonths}mo` : minMonths / 12;
  let upper = maxMonths < 12 ? `${maxMonths}mo` : maxMonths / 12;

  if (minMonths === 0) {
    lower = 0;
  }

  if (maxMonths === 999) {
    upper = '';
  }

  if (typeof lower === 'number' && lower % 1 !== 0) {
    lower = `${Math.trunc(minMonths / 12)}y ${minMonths % 12}mo`;
  }

  if (typeof upper === 'number' && upper % 1 !== 0) {
    upper = `${Math.trunc(maxMonths / 12)}y ${maxMonths % 12}mo`;
  }

  return `(Age ${lower} - ${upper})`;
}

export function getPlanDetails(plan) {
  if (plan.version_number < 2) {
    return ['6 Medicines', plan.target === 'cat' ? '1 Vaccine' : '3 Vaccines'];
  } else {
    return JSON.parse(plan.web_description).pickerModule.planDetails;
  }
}

export function transformPlan(plan) {
  return { ...plan, planDetails: getPlanDetails(plan) };
}
