import {
  Datagrid,
  DateField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import AddNoteButton from '../../../components/AddNoteButton';
import RoutePaths from '../../../routes';

const CustomerNotes = props => (
  <SimpleShowLayout {...props}>
    <ReferenceManyField
      title=''
      reference={RoutePaths.notes}
      target='user_id'
      label=''
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField
          source='created_at'
          label='Date'
          showTime={true}
          sortable={false}
        />
        <TextField source='agent_email' label='Agent Identifier' sortable={false} />
        <TextField source='note' label='Notes' sortable={false} />
      </Datagrid>
    </ReferenceManyField>
    <AddNoteButton />
  </SimpleShowLayout>
);

export default CustomerNotes;
