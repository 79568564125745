import { useRecordContext } from 'react-admin';
import { Form, TextField, TextInput } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, IconButton, Typography } from '@mui/material';

import { useClaimNotes } from '@pumpkincare/claims';
import { TabPanel } from '@pumpkincare/shared';

import { getDateAsFormattedLocalTZ } from '../../../shared/utils';
import { claimNotesTabStyle } from './claim-notes-tab-style';

export default function ClaimNotesTab({ tabValue, handleSubmit }) {
  const classes = claimNotesTabStyle();
  const claimRecord = useRecordContext();
  const {
    data: claimNotesData = [],
    isLoading,
    refetch: refetchClaimNotes,
  } = useClaimNotes(claimRecord?.id);
  return (
    <TabPanel value={tabValue} index={0}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        claimNotesData?.list?.map((note, index) => {
          return (
            <div key={index} style={{ marginBottom: '32px' }}>
              <Typography className={classes.noteDate}>
                {getDateAsFormattedLocalTZ(
                  note.created_at,
                  'MMMM Do YYYY, h:mm a (Z)'
                )}
              </Typography>
              <Typography className={classes.noteAuthorName}>
                {note.ops_user_name}
              </Typography>
              <TextField
                className={classes.notesDescription}
                record={note}
                source='note'
              />
            </div>
          );
        })
      )}

      <Form onSubmit={props => handleSubmit(props, refetchClaimNotes)}>
        <TextInput
          variant='outlined'
          label='WRITE NOTE'
          source='note'
          multiline
          minRows={2}
        />
        <IconButton style={{ color: '#007DFF' }} type='submit' size='large'>
          <SendIcon />
        </IconButton>
      </Form>
    </TabPanel>
  );
}
