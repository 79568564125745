import makeStyles from '@mui/styles/makeStyles';

const claimReimbursementModalStyles = makeStyles(
  {
    modalTitle: { padding: '32px' },
    modalContent: { width: '500px', padding: '32px' },
    modalActions: { justifyContent: 'space-between', padding: '0 32px 32px' },
    cancelButton: {
      width: '100%',
    },
    reimburseButton: {
      width: '100%',
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
      marginBottom: '16px',
    },
    content: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#0057B2',
    },
    amountTitle: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      marginBottom: '8px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    amountValue: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    directDeposit: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      margin: '16px 0 8px',
      letterSpacing: '1px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    status: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
  { name: 'claim-reimbursement-modal' }
);

export { claimReimbursementModalStyles };
