export function convertCentsToDollars(value) {
  return Number.isNaN(value) ? value : value / 100;
}

export function convertDollarsToCents(value) {
  return Number.isNaN(value) ? value : Math.round(value * 100);
}

export function formatCurrency(
  value,
  { hideZero = false, areCents = false, ifNaN = '' } = {}
) {
  const float = parseFloat(value);
  if (Number.isNaN(float)) {
    return ifNaN;
  }

  const number = areCents ? float / 100 : float;
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hideZero && Number.isInteger(float) ? 0 : 2,
  });

  return numberFormat.format(number);
}

export function getNumbersFromString(value) {
  const regex = /\d+/g;
  return regex.exec(value);
}

export function isValidInteger(value) {
  const re = /^\d+$/;
  return re.test(String(value).toLowerCase());
}

export function isValidDecimal(value) {
  const re = /^\d*\.?\d+$/;
  return re.test(String(value).toLowerCase());
}
