import { useGetOne } from 'react-admin';

export const CLAIM_DOCUMENTS_v2_SUMMARY_QUERY = 'documents-v2/claim-summary';

export function useClaimDocumentSummary(claimId) {
  return useGetOne(
    CLAIM_DOCUMENTS_v2_SUMMARY_QUERY,
    { id: claimId },
    { enabled: !!claimId }
  );
}
