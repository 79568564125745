import { Link } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Typography from '@mui/material/Typography';

import { claimMGAStatuses, getClaimStatusColor } from '@pumpkincare/claims';

function ClaimPrimarySession(props) {
  const { record } = props;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', marginRight: '24px' }}>
        <Link
          onClick={e => e.stopPropagation()}
          to={`/claims/${record.id}/show`}
          style={{
            textDecoration: 'none',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AssignmentIcon style={{ color: '#007DFF', width: '14px' }} />
            <Typography
              style={{
                color: '#007DFF',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '22px',
                textDecoration: 'none',
                marginLeft: '8px',
              }}
            >
              {record.id}
            </Typography>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          style={{
            borderRadius: '4px',
            padding: '1.5px 8px 2.5px',
            fontSize: '11px',
            fontWeight: '700',
            lineHeight: '20px',
            marginRight: '12px',
            backgroundColor: getClaimStatusColor(record.status),
          }}
        >
          {claimMGAStatuses[record.status]}
        </Typography>
        {record.is_under_financial_review ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FlagOutlinedIcon
              style={{ color: '#D4424E', width: '16px', marginRight: '4px' }}
            />
            <Typography
              style={{
                color: '#D4424E',
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '16px',
                letterSpacing: '1.5px',
              }}
            >
              UNDER FINANCIAL REVIEW
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ClaimPrimarySession;
