import { useGetOne } from 'react-admin';

export const PREVENT_PRODUCT_QUERY = 'claims';

export function usePreventProduct(claimId, enabled) {
  return useGetOne(
    PREVENT_PRODUCT_QUERY,
    { id: `${claimId}/available-prevent-products` },
    { enabled: !!claimId && !!enabled }
  );
}
