import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    root: { textAlign: 'right', width: '100%' },
    closeButton: { padding: '0px', color: '#3F51B5' },
    form: {
      '& li': {
        opacity: '1 !important',
        transform: 'none !important',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    petName: { width: '200px' },
    petAge: { width: '200px' },
    breedCode: { width: '300px' },
    nextButtonDiv: { paddingBottom: '32px', textAlign: 'right' },
  },
  { name: 'pet-tab' }
);
