import { Admin, defaultTheme, localStorageStore, Login } from 'react-admin';
import { QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as Sentry from '@sentry/browser';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import { createResources } from './auth/resources/PumpkinResources';
import { PumpkinLayout } from './components/PumpkinLayout';
import { providerV2 } from './lib/dataProvider/provider-v2';
import { AuthProvider, LoginForm } from './auth';

function LoginPage() {
  return (
    <Login>
      <LoginForm />
    </Login>
  );
}

function captureException(error) {
  Sentry.captureException(error);
}

export function App() {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
    allowMissing: true,
    onMissingKey: key => key,
  });

  const theme = {
    ...defaultTheme,
    sidebar: {
      closedWidth: '80px', // The default value is 240
    },
  };

  const store = localStorageStore();
  store.setItem('sidebar.open', false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        onError: captureException,
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Admin
          requireAuth
          theme={theme}
          dataProvider={providerV2}
          store={store}
          queryClient={queryClient}
          authProvider={AuthProvider}
          loginPage={LoginPage}
          layout={PumpkinLayout}
          disableTelemetry
          i18nProvider={i18nProvider}
        >
          {createResources().defaultResources}
        </Admin>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}
