import { useEffect, useState } from 'react';
import {
  Create,
  SaveContextProvider,
  TabbedForm,
  Title,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { CustomTabbedForm, useGetCurrentStep } from '@pumpkincare/shared';
import { useCheckEmail } from '@pumpkincare/users';

import { stripeErrorStatus } from '../../../../../constants/stripe-error-status';
import RoutePaths from '../../../../../routes';
import {
  HDYHAU_OPTIONS,
  MY_VET_CLINIC,
} from '../../../../quotes/quoteFlow/constants/HDYHAUQuestions';
import { postIdentityHdyhau } from '../../../../quotes/quoteFlow/service/quoteFlowService';
import {
  formatWellnessQuotePayload,
  validateSAWQuoteForm,
} from '../wellness-quote-form-validation';
import WellnessQuoteTabCheckout from '../wellness-quote-tab-checkout';
import WellnessQuoteTabPlanSelection from '../wellness-quote-tab-plan-selection';
import WellnessQuoteTabUserPets from '../wellness-quote-tab-user-pets';
import WellnessQuoteToolbar from '../wellness-quote-toolbar';

function WellnessQuoteCreate() {
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const notify = useNotify();
  const stripe = useStripe();
  const elements = useElements();
  const currentStep = useGetCurrentStep();

  function finalizeWellness(data) {
    if (stripe) {
      return stripe
        .createToken(elements.getElement(CardNumberElement))
        .then(response => {
          if (response.error) {
            const message =
              response.error.message || 'Please review the Payment Information';
            notify(`[SOMETHING WENT WRONG]: ${message}`, {
              type: 'error',
            });
            return Promise.reject();
          } else {
            return dataProvider
              .create(`${RoutePaths.wellnessQuote}/${data.id}/finalize`, {
                data: {
                  ...data,
                  stripe_token: response.token.id,
                },
                returnJson: 'DATA',
              })
              .then(({ data: user }) => {
                navigate(`/${RoutePaths.customers}/${user.id}/show`);
                return user;
              })
              .catch(e => {
                const errorMsg =
                  stripeErrorStatus[e?.message]?.error_description || e.message;
                notify(`Unable to complete transaction: ${errorMsg}`, {
                  type: 'warning',
                });
              });
          }
        });
    } else {
      notify('Stripe is not initialized', 'warning');
      return Promise.reject();
    }
  }

  function finalizeWellnessAAP(data) {
    return dataProvider
      .create(`${RoutePaths.wellnessQuote}/${quoteId}/finalize/add-a-pet`, {
        data,
        returnJson: 'DATA',
      })
      .then(() => {
        navigate(`/${RoutePaths.customers}/${user.id}/show`);
        return user;
      })
      .catch(e => {
        notify(`Unable to complete transaction: ${e.message}`, {
          type: 'warning',
        });
      });
  }

  const {
    data: createdQuote,
    mutateAsync: createQuote,
    isLoading: isCreatingQuote,
  } = useMutation(data =>
    dataProvider
      .create(RoutePaths.wellnessQuote, { data })
      .then(response => response.data)
  );

  const {
    mutateAsync: editQuote,
    data: editedQuote,
    isLoading: isEditingQuote,
  } = useMutation(data =>
    dataProvider
      .update(RoutePaths.wellnessQuote, { id: createdQuote.id, data })
      .then(response => response.data)
  );

  const quote = editedQuote || createdQuote || {};
  const quoteId = quote?.id;

  const {
    isError: isEmailError,
    helperText: emailHelperText,
    user,
    isAddAPet,
  } = useCheckEmail();

  const [selectedPlans, setSelectedPlans] = useState({});

  // redirect to first page of flow if refreshing a create quote - do not do this for edit flow
  useEffect(() => {
    if (currentStep !== 0 && !quoteId) {
      navigate(`/${RoutePaths.wellnessQuote}/create`);
    }
  }, [quoteId, currentStep]);

  function save(formData, { onSuccess, onError } = {}) {
    const quoteAction = quoteId ? editQuote : createQuote;
    const finalizeAction = isAddAPet ? finalizeWellnessAAP : finalizeWellness;

    const action = currentStep === 2 ? finalizeAction : quoteAction;
    const payload = formatWellnessQuotePayload({
      formData,
      dbUser: isAddAPet ? user : {},
      quote,
      selectedPlans,
      currentStep,
    });

    if (!isAddAPet && currentStep === 2) {
      postIdentityHdyhau(quote.identity_id, {
        wellness_quote_id: quote.id,
        order: -1,
        option_identifier: formData.referral,
        option_value: HDYHAU_OPTIONS.find(
          option => option.value === formData.referral
        ).label,
        vet_id: formData.referral === MY_VET_CLINIC ? formData.vet?.id : undefined,
      });
    }

    return action(payload)
      .then(response => {
        onSuccess?.(response);
      })
      .catch(err => {
        onError?.(err);
      });
  }

  function validateForm(values) {
    return validateSAWQuoteForm(
      values,
      quote,
      isAddAPet ? user : {},
      currentStep,
      selectedPlans,
      {
        isEmailError,
        emailHelperText,
      }
    );
  }

  function handleEditClick() {
    navigate(`/${RoutePaths.wellnessQuote}/create`, { state: routerState });
  }

  return (
    <Create
      className='wellness-quote-create-root'
      title={<Title title='Create a Wellness Quote' />}
    >
      <SaveContextProvider
        value={{
          save,
          saving: isCreatingQuote || isEditingQuote,
          mutationMode: 'pessimistic',
        }}
      >
        <CustomTabbedForm
          toolbar={
            currentStep <= 1 ? (
              <WellnessQuoteToolbar edit={false} selectedPlans={selectedPlans} />
            ) : null
          }
          validate={validateForm}
          sx={{
            '& .MuiTabs-root': {
              display: 'none',
            },
          }}
          defaultValues={
            isAddAPet
              ? {
                  email: user.email,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  billed_annually: user.is_charged_annually,
                }
              : {}
          }
        >
          <TabbedForm.Tab label='Details'>
            <WellnessQuoteTabUserPets disableAddRemovePets={!!quoteId} />
          </TabbedForm.Tab>

          <TabbedForm.Tab label='Plans'>
            <WellnessQuoteTabPlanSelection
              quote={quote}
              onSelectPlan={setSelectedPlans}
              onEditClick={handleEditClick}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab label='Checkout'>
            <WellnessQuoteTabCheckout quote={quote} selectedPlans={selectedPlans} />
          </TabbedForm.Tab>
        </CustomTabbedForm>
      </SaveContextProvider>
    </Create>
  );
}

export default WellnessQuoteCreate;
