import { CreateButton } from 'react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { usePetByUser } from '@pumpkincare/users';

import { PERMISSION_CREATE_CLAIM } from '../../../../auth/permissions';
import RestrictedAction from '../../../../components/security/RestrictedAction';
import RoutePaths from '../../../../routes';
import ClaimsList from './ClaimsList';
import InsuranceSummary from './InsuranceSummary';
import PreventSummary from './PreventSummary';
import WellnessSummary from './WellnessSummary';

const useStyles = makeStyles(
  {
    root: {
      marginBottom: '16px',
      '&:before': {
        height: '0',
      },
    },
    headerRoot: {
      flexDirection: 'row-reverse',
    },
    headerExpandIcon: {
      transform: 'rotate(-90deg)',
    },
    headerContent: {
      marginLeft: '20px',
    },
    headerExpanded: {
      '& $headerExpandIcon': {
        transform: 'rotate(0deg)',
      },
      '& $headerContent': {
        marginLeft: '20px',
      },
    },
    title: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.18px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  { name: 'customer-summary' }
);

function Summary(props) {
  const { userId, description, claims, pet_plan, policy, wellness } = props;
  const classes = useStyles();

  const { data: petData } = usePetByUser(userId);

  const pet = claims[0]?.pet_id
    ? petData.find(pet => pet.id === claims[0]?.pet_id)
    : null;
  const canFileEstimate = pet?.policies?.length;

  function renderClaimButton(petId, isEstimate) {
    return (
      <RestrictedAction requiredPermissions={PERMISSION_CREATE_CLAIM}>
        <CreateButton
          label={`CREATE ${isEstimate ? 'ESTIMATE' : 'CLAIM'}`}
          icon={null}
          sx={{
            border: '1px solid rgba(83, 104, 245, 1)',
            padding: '10px 16px',
            marginLeft: '16px',
            '&.MuiButton-sizeSmall': { lineHeight: '1' },
          }}
          resource={RoutePaths.claims}
          state={{
            customerId: userId,
            petId,
            isEstimate,
          }}
        />
      </RestrictedAction>
    );
  }

  return (
    <Accordion elevation={0} classes={{ root: classes.root }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: classes.headerRoot,
          content: classes.headerContent,
          expandIcon: classes.headerExpandIcon,
          expanded: classes.headerExpanded,
        }}
      >
        <Typography className={classes.title}>{description}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column', padding: '0 56px' }}>
        {policy ? <InsuranceSummary policy={policy} /> : null}

        {pet_plan ? <PreventSummary petPlan={pet_plan} /> : null}

        {wellness ? <WellnessSummary wellness={wellness} /> : null}

        {claims.length > 0 ? <ClaimsList claims={claims} /> : null}

        <div>
          {renderClaimButton(claims[0]?.pet_id, false)}
          {canFileEstimate ? renderClaimButton(claims[0]?.pet_id, true) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

Summary.propTypes = {
  userId: PropTypes.string,
  description: PropTypes.string,
  claims: PropTypes.array,
  pet_plan: PropTypes.object,
  policy: PropTypes.object,
  wellness: PropTypes.object,
};

export default Summary;
