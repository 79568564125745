export function calcQuoteTotalPrice(quote, monthlyFee) {
  return quote.quote_pets
    ? quote.quote_pets.reduce((total, pet) => total + pet.totalPrice, 0) + monthlyFee
    : 0;
}

export function calculateQuoteStates(
  quote,
  monthlyFee,
  billingOption,
  skusToIgnore
) {
  const addonMonthlyTotal = calcTotalPerInstallments(quote, 12, skusToIgnore);
  const addonAnnualTotal = calcTotalPerInstallments(quote, 1, skusToIgnore);
  let billingDisabled = false;
  let radioButtonBillingOption;
  let totalPrice;
  if (quote.existingUserDetails) {
    billingDisabled = true;
    if (quote.existingUserDetails.is_charged_annually) {
      radioButtonBillingOption = 'annually';
    }
  }
  if (
    billingOption === 'annually' ||
    quote.existingUserDetails?.is_charged_annually
  ) {
    totalPrice = calcQuoteTotalPrice(quote, 0) * 12 + addonMonthlyTotal;
  } else {
    totalPrice = calcQuoteTotalPrice(quote, monthlyFee) + addonMonthlyTotal;
  }
  return { addonAnnualTotal, billingDisabled, radioButtonBillingOption, totalPrice };
}

export function calcTotalPerInstallments(quote, installments, skusToIgnore) {
  let total = 0;
  if (quote.quote_pets) {
    quote.quote_pets.forEach(pet => {
      if (pet.addons?.length) {
        pet.addons.forEach(addon => {
          if (
            addon.installments === installments &&
            skusToIgnore.indexOf(addon.addon.sku) < 0
          ) {
            if (addon.cost === undefined) {
              // editing a quote
              addon.cost = addon.addon.payment_options.find(
                payment => payment.installments === addon.installments
              ).cost;
            }
            total = total + addon.cost;
          }
        });
      }
    });
  }
  return total;
}

export function countryToFlag(isoCode) {
  return isoCode
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));
}

export function calcTotalPerInstallmentsPerPet(
  quotePet,
  installments,
  skusToIgnore
) {
  let total = 0;
  if (quotePet.addons?.length) {
    quotePet.addons.forEach(addon => {
      if (
        addon.installments === installments &&
        skusToIgnore.indexOf(addon.addon.sku) < 0
      ) {
        total = total + addon.cost;
      }
    });
  }
  return total;
}

export function formatPlanSummary(quotePet) {
  return `Deductible $${
    quotePet.deductible ? quotePet.deductible : '--.--'
  } Limit $${quotePet.annual_limit ? quotePet.annual_limit : '--.--'}${
    quotePet.has_prevent ? ' with Prevent' : ''
  }`;
}

export function formatPlanDescription(quotePet) {
  return quotePet.has_prevent
    ? 'Pumpkin Pet Insurance + Preventive Essentials'
    : 'Pumpkin Pet Insurance';
}

export function formatPlanShortDescription(quotePet) {
  return quotePet.has_prevent
    ? 'Pet Insurance + Preventive Essentials'
    : 'Pet Insurance';
}

export function calcQuotePetDiscount(quotePet, isAnnualBilling) {
  return quotePet.discount
    ? quotePet.discount.reduce((totalDiscount, discount) => {
        return (
          totalDiscount +
          (isAnnualBilling
            ? parseFloat(discount.amount) * 12
            : parseFloat(discount.amount))
        );
      }, 0)
    : 0;
}

export function calcQuotePetPrice(quotePet, isAnnualBilling) {
  if (!quotePet.annual_limit || !quotePet.deductible || !quotePet._options) {
    return 0;
  }
  let insPrice;
  const coinsurance = quotePet.coinsurance || '10';
  if (quotePet.annual_limit === 'Unlimited') {
    try {
      insPrice =
        quotePet._options.prices[coinsurance][quotePet.annual_limit][
          quotePet.deductible
        ];
    } catch (e) {
      insPrice =
        quotePet._options.prices[coinsurance]['1000000'][quotePet.deductible];
    }
  } else {
    insPrice =
      quotePet._options.prices[coinsurance][quotePet.annual_limit][
        quotePet.deductible
      ];
  }
  return isAnnualBilling ? insPrice * 12 : insPrice;
}

export function calcQuoteTotalPriceWithoutMonthlyFee(quote) {
  return ((quote && quote.quote_pets) || []).reduce((sum, pet) => {
    return sum + (pet.totalPrice || 0);
  }, 0);
}

export function formatDiscountName(discount, { partner } = {}) {
  if (partner === 'embark' && discount.type === 'PriorityCode') {
    return `${discount.name.replace(/\D/g, '')}% Embark Discount Applied`;
  }

  if (discount.name.match(/priority/i)) {
    return `Priority Code ${discount.percentage}% off`;
  }

  return discount.name;
}
