import makeStyles from '@mui/styles/makeStyles';

const annualCapUtilizationStyle = makeStyles(
  {
    container: {
      display: 'flex',
      width: 'calc(100% - 16px)',

      margin: '16px auto 2px',
      justifyContent: 'space-between',
    },
    infoTitle: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#007DFF',
    },
    infoValue: {
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '12px',
      color: '#000000',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  { name: 'annual-cap-utilization' }
);

export { annualCapUtilizationStyle };
