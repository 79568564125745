import makeStyles from '@mui/styles/makeStyles';

const policyChangeSidebarStyle = makeStyles(
  {
    sidebarTitle: {
      display: 'flex',
      marginBottom: '24px',
    },
    summary: {
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
      marginRight: '102px',
    },
    petPlanDetail: {
      borderRadius: '8px',
      padding: '25px',
      marginBottom: '20px',
      backgroundColor: '#EEF0FE',
    },
    petPlanDescription: {
      fontSize: '14px',
      color: '#373F51',
      fontWeight: '400',
    },
    insuranceAndPlan: {
      marginBottom: '160px',
    },
    petPlanTitle: {
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#373F51',
    },
    petPlanCurrent: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#373F51',
    },

    switchRoot: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      cursor: 'pointer',
      width: 0,
    },
    switchLabelNormal: {
      fontSize: '12px',
      zIndex: 1,
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontWeight: '400',
      color: 'black',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    leftLabel: {
      left: '10px',
      width: '84px',
    },
    rightLabel: {
      left: '102px',
      width: '88px',
    },
    petPlanHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    planHeader: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'black',
      marginTop: '24px',
    },
    planBenefitsContainer: { display: 'flex', justifyContent: 'space-between' },
    planBenefitsList: {
      paddingLeft: '16px',
      color: 'rgba(0, 0, 0, 0.54);',
    },
    planBenefits: {
      fontSize: '12px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.54);',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      paddingLeft: '0px',
    },
    saveButton: {
      color: 'primary',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      margin: '16px 0px',
    },
    resetButton: {
      color: '#5368F5',
      backgroundColor: '#D4424E',
      '&:hover': {
        backgroundColor: '#D4424E',
      },
    },
    totalValues: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      letterSpacing: '0.25px',
      color: 'black',
    },
    finalTotalValues: {
      fontSize: '18px',
      lineHeight: '20px',
      fontWeight: '600',
      letterSpacing: '0.25px',
      color: 'black',
    },
    changeMessage: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#373F51',
    },
    changeTitle: {
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      marginLeft: '5px',
      paddingTop: '3px',
    },
    titleWrapper: {
      display: 'flex',
      marginTop: '10px',
    },
    totalSummary: {
      padding: '20px',
      border: 'solid 1px #E8E8E8',
      borderRadius: '8px',
    },
    margin20v: {
      margin: '20px 0px',
    },
    margin10v: {
      margin: '10px 0px',
    },
  },
  { name: 'policy-change-sidebar' }
);

export { policyChangeSidebarStyle };
