import { ArrayField, Datagrid, FunctionField, Labeled } from 'react-admin';
import { Card, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ResourceGrid from '../../../../components/ResourceGrid';
import SimpleTableLayout from '../../../../components/SimpleTableLayout';
import { customerBillingStyle } from './CustomerBillingStyle';

export default function CardDetails({ paymentDetails }) {
  const { punks1457AdminCustomerBilling } = useFlags();
  const classes = customerBillingStyle();
  const record = {
    payment_details: [paymentDetails],
  };

  const labelStyle = {
    '& .RaLabeled-label': { fontSize: '12px', marginBottom: '12px' },
  };
  const fontStyle = {
    fontWeight: '400',
    fontSize: '14px',
  };

  return paymentDetails ? (
    <Card
      className={classes.billingDetailsCard}
      variant={punks1457AdminCustomerBilling ? '' : 'outlined'}
    >
      <ResourceGrid record={record}>
        <ArrayField source='payment_details' label=''>
          {punks1457AdminCustomerBilling ? (
            <SimpleTableLayout
              className={classes.tableSize}
              labelClassName={classes.label}
              title={`Test`}
            >
              <Labeled sx={labelStyle} label='CARD'>
                <FunctionField
                  style={fontStyle}
                  render={(record = {}) => {
                    return (
                      <>
                        <div className={classes.cardDetails}>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: '400',
                              textTransform: 'capitalize',
                            }}
                          >
                            {record.payment_details.brand}
                          </Typography>
                          <Typography
                            style={{ fontSize: '14px', fontWeight: '400' }}
                          >
                            {' '}
                            ending in {record.payment_details.last4}
                          </Typography>
                        </div>
                        <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                          {record.payment_details.funding}
                        </Typography>
                      </>
                    );
                  }}
                />
              </Labeled>

              <Labeled sx={labelStyle} label='EXP'>
                <FunctionField
                  style={fontStyle}
                  render={(record = {}) => {
                    return (
                      <Typography style={{ fontSize: '14px', fontWeight: '400' }}>
                        {record.payment_details.exp_month}/
                        {record.payment_details.exp_year}
                      </Typography>
                    );
                  }}
                />
              </Labeled>
            </SimpleTableLayout>
          ) : (
            <Datagrid
              sx={{
                '& .RaDatagrid-headerCell': {
                  borderBottom: '0px',
                },
                '& .RaDatagrid-table': {
                  borderBottom: '0px',
                },
                '& .RaDatagrid-rowCell': {
                  borderBottom: '0px',
                },
              }}
              bulkActionButtons={false}
            >
              <FunctionField
                label={<Typography className={classes.label}>CARD</Typography>}
                render={paymentDetails => (
                  <div>
                    <div className={classes.cardBrand}>{paymentDetails.brand}</div>
                    <div>ending in {paymentDetails.last4}</div>
                    <div>{paymentDetails.funding}</div>
                  </div>
                )}
              />
              <FunctionField
                label={<Typography className={classes.label}>EXP.</Typography>}
                render={paymentDetails =>
                  `${paymentDetails.exp_month}/${paymentDetails.exp_year}`
                }
              />
            </Datagrid>
          )}
        </ArrayField>
      </ResourceGrid>
    </Card>
  ) : null;
}
