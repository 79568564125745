import { Button, TopToolbar } from 'react-admin';
import Add from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import QuoteFilter from './quote-filter';

function QuoteListCustomActions({ onClick }) {
  return (
    <Box width='100%'>
      <TopToolbar style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <QuoteFilter />
        <Button variant='contained' size='medium' onClick={() => onClick()}>
          <div
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              fontSize: '14px',
            }}
          >
            <Add /> Create
          </div>
        </Button>
      </TopToolbar>
    </Box>
  );
}

QuoteListCustomActions.propTypes = {
  onClick: PropTypes.func,
};

export default QuoteListCustomActions;
