import makeStyles from '@mui/styles/makeStyles';

const editIncidentModal = makeStyles(
  {
    modalContent: {
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftPanel: {
      minWidth: '371px',
      marginRight: '20px',
    },
    rightPanel: {
      width: '90%',
      height: '100%',
      padding: '0 20px 0 20px',
    },
    diagnosisDiv: {
      marginBottom: '30px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    datePickerDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    cancelButton: {
      borderRadius: '4px',
      width: '100%',
      minHeight: '42px',
    },
    addButton: {
      minHeight: '42px',
      borderRadius: '4px',
      width: '100%',
    },
    chipSelect: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '25px',
        backgroundColor: 'blue',
      },
      '& .MuiInputBase-root-MuiFilledInput-root': {
        borderBottom: '10px !important',
      },
    },

    daysPastAlert: {
      backgroundColor: '#CBD2FC',
      marginTop: '32px',
      '& .MuiAlert-icon': {
        color: '#323232',
      },
    },
    preExistingCheck: {
      width: '181.5px',
      alignContent: 'flex-end',
      '& .MuiButtonBase-root.Mui-checked': {
        color: 'red',
      },
      '& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track': {
        backgroundColor: 'red',
      },
      '& .MuiFormHelperText-root': {
        lineHeight: 0,
        order: 3,
      },
      '& .MuiFormControlLabel-label': {
        marginRight: '12px',
      },
      '& .MuiSwitch-root': {
        order: 2,
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
        order: 1,
      },
    },
    markAsCuredCheck: {
      width: '181.5px',
      alignContent: 'flex-end',
      '& .MuiButtonBase-root.Mui-checked': {
        color: 'green',
      },
      '& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track': {
        backgroundColor: 'green',
      },
      '& .MuiFormControlLabel-label': {
        marginRight: '12px',
      },
      '& .MuiFormHelperText-root': {
        lineHeight: 0,
        order: 3,
      },
      '& .MuiSwitch-root': {
        order: 2,
      },
      '& .MuiFormControlLabel-root': {
        order: 1,
        marginRight: 0,
      },
    },

    incidentTypeSelect: {
      width: '181.5px',
      borderRadius: '25px',
      height: '30px',
      '& .MuiInputBase-root': {
        margin: 0,
        color: 'white',
        '&:before': {
          borderBottom: 'none !important',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
        },
      },
      '& .MuiSelect-select': {
        minHeight: '1.6em',
        paddingLeft: '10px',
        fontWeight: 500,
        fontSize: '13px',
        paddingTop: '3px',
      },
      '& .MuiInput-input:focus': {
        backgroundColor: 'unset',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
      '& .MuiSvgIcon-root': {
        top: '2px',
        right: '5px',
        width: '0.8em',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 800,
        color: 'grey',
        overflow: 'unset',
        top: '-11px',
        fontSize: '13px',
        paddingLeft: '10px',
      },
      '& .MuiFormLabel-root > span > span': {
        display: 'none',
      },
    },

    curabilitySelect: {
      width: '181.5px',
      borderRadius: '25px',
      height: '30px',
      marginLeft: '5px',
      '& .MuiFormControl-root': {},
      '& .MuiInputBase-root': {
        margin: 0,
        color: 'white',
        '&:before': {
          borderBottom: 'none !important',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
        },
      },
      '& .MuiFormLabel-root': {
        color: 'grey',
        fontWeight: 800,
        overflow: 'unset',
        top: '-11px',
        fontSize: '13px',
        paddingLeft: '10px',
      },
      '& .MuiFormLabel-root > span > span': {
        display: 'none',
      },
      '& .MuiSvgIcon-root': {
        top: '2px',
        right: '5px',
        width: '0.8em',
      },
      '& .MuiSelect-select': {
        minHeight: '1.6em',
        paddingLeft: '10px',
        fontWeight: 500,
        paddingTop: '3px',
        fontSize: '13px',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },

    notesTextInput: {
      '& .MuiInputBase-root': {
        alignItems: 'normal',
        minHeight: '462px',
        backgroundColor: '#0000000F',
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },

    datePickerMargin: {
      width: '181.5px',
      '& .MuiFormHelperText-root': {
        lineHeight: 0,
        margin: 0,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },
      '& .MuiFormLabel-root > span > span': {
        display: 'none',
      },
    },

    autoComplete: {
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none',
      },
      '& .MuiFormLabel-root > span > span': {
        display: 'none',
      },
    },

    dialogContainer: {
      '& .MuiPaper-root.MuiDialog-paper': {
        width: '80%',
        maxWidth: '1022px',
        minHeight: '550px',
      },
    },
  },
  { name: 'edit-incident-modal' }
);

export { editIncidentModal };
