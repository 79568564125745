import { parseAndFormatDate } from '../shared/utils';

export function addonOptionsWithStatusForSelectedPet(pet) {
  const petAddons = pet.addons || [];
  return petAddons.map(petAddon => {
    const formattedStartDate = parseAndFormatDate(petAddon.start_date);
    return {
      id: petAddon.id,
      name: `${petAddon.addon.name} - ${formattedStartDate} - ${petAddon.status}`,
      formattedStartDate,
    };
  });
}
