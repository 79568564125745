import { TextInput } from 'react-admin';
import { Grid, InputLabel } from '@mui/material';

import { OrganizationsCreateStyles } from '../../organizations-create/organizations-create.styles';

export function NumberOfEmployeesField() {
  const classes = OrganizationsCreateStyles();
  return (
    <Grid item xs={12} md={2}>
      <InputLabel shrink># of Employees</InputLabel>
      <TextInput
        variant='outlined'
        source='num_of_employees'
        label={false}
        fullWidth
        className={classes.defaultHeight}
      />
    </Grid>
  );
}
