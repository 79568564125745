import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
} from 'react-admin';
import PropTypes from 'prop-types';

import { parseDeductible } from '../../../../shared/utils/productUtils';
import { policyExpandableDatagridStyle } from './policy-expandable-datagrid-style';

function formatDiscount(diff, percentage) {
  if (diff === null) {
    return null;
  }

  if (percentage === 0) {
    return '0 (0%)';
  }

  return `${diff?.from} → ${diff?.to} (${100 - percentage * 100}%)`;
}

function PolicyExpandableDatagrid({ policyChanges }) {
  const classes = policyExpandableDatagridStyle();

  return (
    <ArrayField
      source='policy_changes'
      record={{
        policy_changes: policyChanges.reverse(),
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='change_type' headerClassName={classes.colHeader} />
        <DateField
          showTime={true}
          label='Processed at'
          source='processed_at'
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Coinsurance'
          render={(record = {}) =>
            record.diff?.coinsurance
              ? `${record.diff?.coinsurance?.from} → ${record.diff?.coinsurance?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />

        <FunctionField
          label='Deductible'
          render={(record = {}) =>
            record.diff?.deductible
              ? `${parseDeductible(
                  record.diff?.deductible?.from
                )} → ${parseDeductible(record.diff?.deductible?.to)}`
              : null
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Annual Limit'
          render={(record = {}) =>
            record.diff?.annual_limit
              ? `${record.diff?.annual_limit?.from} → ${record.diff?.annual_limit?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Premium'
          render={(record = {}) =>
            record.diff?.premium
              ? `${record.diff?.premium?.from} → ${record.diff?.premium?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Raw Rate'
          render={(record = {}) =>
            record.diff?.raw_rate
              ? `${record.diff?.raw_rate?.from} → ${record.diff?.raw_rate?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Multi-Pet Discount'
          render={(record = {}) =>
            record.multiple_pet_discount_percentage === null
              ? null
              : formatDiscount(
                  record.diff?.multiple_pet_discount,
                  record.multiple_pet_discount_percentage
                )
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Group Discount'
          render={(record = {}) =>
            record.group_discount_percentage === null
              ? null
              : formatDiscount(
                  record.diff?.group_discount,
                  record.group_discount_percentage
                )
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Filed Rate'
          render={(record = {}) =>
            record.diff?.filed_rate
              ? `${record.diff?.filed_rate?.from} → ${record.diff?.filed_rate?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />
        <FunctionField
          label='Capped Rate'
          render={(record = {}) =>
            record.diff?.capped_rate
              ? `${record.diff?.capped_rate?.from} → ${record.diff?.capped_rate?.to}`
              : null
          }
          headerClassName={classes.colHeader}
        />
      </Datagrid>
    </ArrayField>
  );
}

PolicyExpandableDatagrid.propTypes = {
  policyChanges: PropTypes.array,
};

export default PolicyExpandableDatagrid;
