import { useGetList, useGetOne } from 'react-admin';
import moment from 'moment';

import { getCurrentPolicy } from '../../shared/utils/productUtils';

export const INVOICE_QUERY = 'invoices';

export function useInvoicesByUserId(userId, select) {
  return useGetList(
    INVOICE_QUERY,
    {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'ASC' },
      filter: { user_id: userId },
    },
    { enabled: !!userId, select: select }
  );
}

export function useRefundFilterByUser(userId) {
  return useGetOne(
    INVOICE_QUERY,
    { id: `${userId}/refunds/filter-options` },
    { enabled: !!userId }
  );
}

export function useInvoicePetNamesByUserId(userId) {
  return useInvoicesByUserId(userId, invoiceData => {
    const petNames = [];
    invoiceData.data.forEach(invoice => {
      const invoicePetNames = invoice.pet_names ? invoice.pet_names.join(', ') : '';

      if (!petNames.some(e => e.id === invoicePetNames)) {
        petNames.push({ id: invoicePetNames, name: invoicePetNames });
      }
    });

    return { ...invoiceData, data: petNames };
  });
}

export function useInvoicePolicyYearsByUserId(userId) {
  return useInvoicesByUserId(userId, invoiceData => {
    const petYears = [];
    const dataToBeReturned = { data: [], total: 0, loaded: true };

    if (invoiceData.data) {
      invoiceData.data.forEach(function (item) {
        const policy = getCurrentPolicy(item.policies);
        if (policy && policy.status === 'active') {
          petYears.push(moment(policy.policy_effective_date));
        }
      });

      const policyYear = moment.min(petYears);
      if (policyYear.isValid()) {
        dataToBeReturned.data = [
          { id: 0, policy_year: policyYear.format('YYYY-MM-DD') },
        ];
        dataToBeReturned.total = 1;
        dataToBeReturned.loaded = true;
      }
    }

    return dataToBeReturned;
  });
}
