import { useGetIdentity, useLogout } from 'react-admin';
import CompareArrows from '@mui/icons-material/CompareArrows';
import Done from '@mui/icons-material/Done';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Avatar, MenuItem, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { getUserGroups } from '../auth';
import UserMenu from './UserMenu';

const theme = createTheme();

const myCustomIconStyle = {
  avatar: {
    height: 30,
    width: 30,
  },
};

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  },
  { name: 'pumpkin-user-menu' }
);

const MyCustomIcon = withStyles(myCustomIconStyle)(({ classes, identity }) => (
  <>
    <Avatar className={classes.avatar} src='/assets/images/homepage-pug.png' />
    <Typography>{`${identity?.fullName}`}</Typography>
  </>
));

export default function PumpkinUserMenu(props) {
  const classes = useStyles();
  const logout = useLogout();
  const userAuthGroups = getUserGroups();
  const { identity } = useGetIdentity();
  const { currentCognitoGroup } =
    JSON.parse(localStorage.getItem('@pumpkincare/auth/groups')) || {};

  return (
    <UserMenu {...props} icon={<MyCustomIcon identity={identity} />}>
      {userAuthGroups.map(cognitoGroup => {
        if (cognitoGroup === currentCognitoGroup) {
          return (
            <MenuItem
              button
              className={classes.root}
              key={cognitoGroup}
              selected={true}
            >
              <ListItemIcon>
                <Done />
              </ListItemIcon>
              <ListItemText primary={cognitoGroup.split('_').pop()} />
            </MenuItem>
          );
        }
        return (
          <MenuItem
            button
            className={classes.root}
            key={cognitoGroup}
            onClick={() => {
              localStorage.setItem(
                '@pumpkincare/auth/groups',
                JSON.stringify({
                  cognitoGroups: userAuthGroups,
                  currentCognitoGroup: cognitoGroup,
                })
              );
              window.location.href = '/';
            }}
          >
            <ListItemIcon>
              <CompareArrows />
            </ListItemIcon>
            <ListItemText primary={cognitoGroup.split('_').pop()} />
          </MenuItem>
        );
      })}
      <MenuItem button className={classes.root} onClick={logout}>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary={'Log out'} />
      </MenuItem>
    </UserMenu>
  );
}
