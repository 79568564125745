import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';

/**
 * If you pass in your components props into this function it will look for a bunch of special props (see below)
 * to run some conditional checks on the provided resource. If any of the checks fail, it will return false
 * otherwise the updated props will be returned.
 * @param record
 * @param ifPresent
 * @param ifNotPresent
 * @param ifEmpty
 * @param ifNotEmpty
 * @param rest
 * @returns {boolean|*|{record: *}}
 */
const conditionals = ({
  record,
  ifPresent,
  ifNotPresent,
  ifEmpty,
  ifNotEmpty,
  ...rest
}) => {
  if (ifPresent && (!record || !get(record, ifPresent))) {
    return false;
  }

  if (ifNotPresent && (!record || get(record, ifNotPresent))) {
    return false;
  }

  if (ifNotEmpty && (!record || isEmpty(get(record, ifNotEmpty)))) {
    return false;
  }

  if (ifEmpty && (!record || !isEmpty(get(record, ifNotEmpty)))) {
    return false;
  }

  return { record, ...rest };
};

export default conditionals;
