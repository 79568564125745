import makeStyles from '@mui/styles/makeStyles';

const reimbursementDetailStyle = makeStyles(
  {
    mainDiv: {
      minWidth: 'fit-content',
      minHeight: '100vh',
      width: '100%',
      padding: '16px',
      backgroundColor: 'rgb(0, 125, 255, 0.08)',
    },
    headerWrapper: {
      display: 'flex',
      marginTop: '24px',
      marginBottom: '16px',
      marginLeft: '8px',
    },
    link: {
      textDecoration: 'none',
      display: 'flex',
      marginRight: '24px',
    },
    arrowBackIcon: {
      color: '#2196F3',
      width: '12px',
    },
    claimNumber: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#2196F3',
    },
    status: {
      borderRadius: '4px',
      padding: '1.5px 8px 2.5px',
      fontSize: '11px',
      fontWeight: '700',
      lineHeight: '20px',
      marginRight: '24px',
      height: '20px',
    },

    headerCell: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#000000',
    },
    table: {
      backgroundColor: 'white',
    },
    reimbursementCell: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
    cancelButton: {
      backgroundColor: '#007DFF',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
    },
  },
  { name: 'reimbursement-detail' }
);

export { reimbursementDetailStyle };
