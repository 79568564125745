import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';

import { getTimeDifferenceFromCurrentDate } from '../../../../../shared/utils';

function ClaimTertiarySession(props) {
  const { record } = props;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          bottom: '26px',
        }}
      >
        <AccountCircleIcon style={{ width: '20px', marginRight: '4px' }} />
        <Typography
          style={{ fontSize: '11px', lineHeight: '20px', fontWeight: '400' }}
        >
          {record.assigned_to || 'Unassigned'}
        </Typography>
      </div>
      <div style={{ position: 'relative', top: '27px' }}>
        <Typography
          style={{
            fontSize: '11px',
            lineHeight: '20px',
            fontWeight: '400',
            color: '#767676',
            textAlign: 'right',
          }}
        >
          {record.status_last_updated
            ? `${getTimeDifferenceFromCurrentDate(
                record.status_last_updated,
                'days'
              )} Days in Queue`
            : null}
        </Typography>
      </div>
    </div>
  );
}

export default ClaimTertiarySession;
