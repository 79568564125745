import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../shared/utils/currencyUtils';
import PetDetailHeader from '../pet-detail-header';
import RenewalChangeSummary from '../renewal-change-summary';
import { renewalChangeConfirmationModalStyle } from './renewal-change-confirmation-modal-style';
function RenewalChangeConfirmationModal(props) {
  const {
    onClose,
    isOpen,
    onConfirmChanges,
    loading,
    petData = {},
    renewalChangeData = { annual_limit: '' },
  } = props;

  const classes = renewalChangeConfirmationModalStyle();

  const policyData = petData?.renewal_pending_policy;
  const annualLimit = formatCurrency(
    policyData?.annual_limit.slice(0, -3).replace(/,/g, ''),
    {
      areCents: false,
      hideZero: true,
      ifNaN: 'Unlimited',
    }
  );

  const renewalAnnualLimit = formatCurrency(
    renewalChangeData?.renewal_pending_policy?.annual_limit
      .slice(0, -3)
      .replace(/,/g, ''),
    {
      areCents: false,
      hideZero: true,
      ifNaN: 'Unlimited',
    }
  );

  const deductible = formatCurrency(policyData?.deductible, {
    areCents: false,
    hideZero: true,
    ifNaN: 'Unlimited',
  });

  const renewalDeductible = formatCurrency(
    renewalChangeData?.renewal_pending_policy?.deductible,
    {
      areCents: false,
      hideZero: true,
      ifNaN: 'Unlimited',
    }
  );

  const isChargedAnnually = !!petData?.renewal_pending_policy.is_annualized_policy;

  const changesMade = [
    {
      visible: false,
      current_value: `${formatCurrency(policyData?.filed_rate, {
        areCents: false,
      })} ${isChargedAnnually ? '/ yr' : '/ mo'}`,
      new_value: `${formatCurrency(renewalChangeData?.raw_rate, {
        areCents: false,
      })} ${isChargedAnnually ? '/ yr' : '/ mo'}`,
    },
    {
      visible: annualLimit !== renewalAnnualLimit,
      current_value: `${
        annualLimit === 'Unlimited' ? annualLimit : annualLimit + 'K'
      } Annual Limit`,
      new_value: `${
        renewalAnnualLimit === 'Unlimited'
          ? renewalAnnualLimit
          : renewalAnnualLimit + 'K'
      } Annual Limit`,
    },
    {
      visible: deductible !== renewalDeductible,
      current_value: `${deductible} Deductible`,
      new_value: `${renewalDeductible} Deductible`,
    },
  ];

  function RenderContentHeader({ title }) {
    return (
      <div className={classes.petPlanHeaderContainer}>
        <Typography className={classes.petTitle}>{title}</Typography>
      </div>
    );
  }

  function RenderChangesMade({ item }) {
    return (
      <>
        <Typography className={classes.changesMade}>{item.current_value}</Typography>
        <ArrowRightAltIcon className={classes.changesMadeIcon} />
        <Typography className={classes.changesMade}>{item.new_value}</Typography>
      </>
    );
  }

  return (
    <Dialog maxWidth='md' open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.modalTitle}>Confirm Changes:</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classnames(classes.modalContentDetail)}>
          <RenderContentHeader title={`${petData.name || ''}'s Plan`} />

          <PetDetailHeader petData={petData} showLine={true} />

          <RenderContentHeader title={'Changes Made:'} />

          {changesMade.map((item, idx) => {
            return item.visible ? (
              <div key={idx} className={classes.changesMadeContainer}>
                <RenderChangesMade item={item} />
              </div>
            ) : null;
          })}

          <RenewalChangeSummary
            petData={petData}
            renewalChangePolicyData={renewalChangeData}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            disabled={loading}
            type='submit'
            className={classes.saveButton}
            onClick={onConfirmChanges}
          >
            SAVE CHANGES
          </Button>
          <Button
            fullWidth
            disabled={loading}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

RenewalChangeConfirmationModal.propTypes = {
  onClose: PropTypes.func,
  onConfirmChanges: PropTypes.func,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  petData: PropTypes.object,
  renewalChangeData: PropTypes.object,
};

export default RenewalChangeConfirmationModal;
