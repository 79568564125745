import { Children, cloneElement, isValidElement } from 'react';
import Grid from '@mui/material/Grid';
import { Labeled } from 'ra-ui-materialui';

import conditionals from '../lib/conditional';

/**
 * This component acts as a Grid from material-ui, but will properly propagate the props required
 * for react admin fields to work.
 */
const ResourceGrid = props => {
  const validatedProps = conditionals(props);

  if (!validatedProps) return null;

  // prevent passing in unused / non DOM props down
  // eslint-disable-next-line no-unused-vars
  const { basePath, className, children, record, resource, version, ...rest } =
    validatedProps;

  return (
    <Grid {...rest}>
      {Children.map(children, child => {
        // respect childResource and childBasePath properties already set on the child
        const childResource = child.props.resource || resource;
        const childBasePath = child.props.basePath || basePath;

        // figure out if this is an element we might potentially add a label to
        if (child && isValidElement(child)) {
          if (child.props.addLabel) {
            return (
              <Labeled
                record={record}
                resource={childResource}
                label={child.props.label}
                source={child.props.source}
                disabled={false}
              >
                {child}
              </Labeled>
            );
          }
        }

        return cloneElement(child, {
          basePath: childBasePath,
          record,
          resource: childResource,
        });
      })}
    </Grid>
  );
};

export default ResourceGrid;
