import makeStyles from '@mui/styles/makeStyles';

export const useCancellationBillingStyles = makeStyles(
  {
    box: {
      width: '100%',
      marginBottom: '32px',
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
      marginBottom: '24px',
      color: '#2196F3',
    },
    datagrid: {
      border: 'none',
      boxShadow: 'initial',
      '& .MuiDataGrid-columnHeader': {
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
        '& .MuiDataGrid-iconButtonContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
    },
  },
  { name: 'cancellation-billing-details' }
);
