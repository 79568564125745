import makeStyles from '@mui/styles/makeStyles';

const claimSubmissionStyle = makeStyles(
  {
    fileInput: {
      border: '1px solid #949494',
      borderRadius: '4px',
    },
    fileInputDropzone: {
      background: 'white',
    },
    headerTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
    },
    customerHeader: {
      display: 'flex',
      marginLeft: '4px',
      color: '#007DFF',
    },
    claimTitle: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '500',
    },
    claimSubmission: {
      width: '100%',
      backgroundColor: 'white',
      padding: '50px 100px',
    },
    claimMainLabel: {
      fontSize: '26px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
      fontWeight: '500',
      color: '#000000',
    },
    claimLabel: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: '400',
      color: '#1A1A1A',
    },
    visits: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '500px',
      alignItems: 'center',
      marginTop: '16px',
    },
    grid: {
      width: '100%',
      maxWidth: '912px',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '28px',
    },
  },
  { name: 'claim-submission' }
);

export { claimSubmissionStyle };
