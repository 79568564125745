const claimIncidentHistoryStyle = {
  title: {
    marginRight: '16px',
  },
  dataGrid: {
    '& .column-diagnosis': { width: '25%' },
    '& .column-original_symptom_date': { width: '8%' },
    '& .column-latest_occurrence_date': { width: '8%' },
    '& .column-notes': { width: '50%' },
    '& .MuiTableCell-root': {
      verticalAlign: 'top',
      borderBottom: 'none',
      '& .RaDatagridHeaderCell-root ': {
        borderRight: '1px solid black',
      },
    },
    borderRadius: '4px',
    '& .RaDatagrid-table': {
      border: 'none',
    },

    '& .RaDatagrid-headerCell': {
      padding: '8px 12px',
      fontWeight: '600',
      backgroundColor: '#92A6FF',
      position: 'relative',
      color: 'white',
      '&:after': {
        content: '" "',
        background: 'black',
        position: 'absolute',
        bottom: '3px',
        left: 0,
        height: '80%',
        width: '1px',
        opacity: '12%',
      },

      '&:nth-of-type(1):after': {
        display: 'none',
      },
      '&:nth-of-type(5):after': {
        display: 'none',
      },
      '&:nth-of-type(6):after': {
        display: 'none',
      },
    },

    '& .RaDatagrid-rowCell': {
      padding: '14px 16px',
      '&:nth-of-type(5)': {
        padding: '14px 2px',
      },
      '&:nth-of-type(6)': {
        padding: '14px 6px',
      },
    },

    '& .RaDatagrid-rowOdd': { backgroundColor: 'rgba(0, 125, 255, 0.08)' },

    '& .MuiTableSortLabel-root': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },

    '& .MuiTableSortLabel-root.Mui-active': {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },

    '& .RaDatagridHeaderCell-root.MuiTableSortLabel-icon': {
      display: 'unset',
      opacity: 'unset',
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },

    '& .Mui-active .MuiTableSortLabel-icon': {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },

    '& .MuiTableSortLabel-icon': {
      display: 'unset',
      color: 'white',
      opacity: 'unset',
      '&:hover': {
        color: 'white',
      },
    },
  },

  onSetDate: {
    '& .MuiButtonBase-root-MuiTableSortLabel-root': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .MuiTableSortLabel-icon': {
      display: 'unset',
      opacity: 'unset',
    },
  },
  deleteIcon: {
    '&:hover': {
      backgroundColor: 'rgba(211, 47, 47, 0.04)',
      color: '#D32F2F',
    },
  },
  expandIcon: {
    '&:hover': {
      backgroundColor: 'rgba(83, 104, 245, 0.12)',
      color: '#5368F5',
    },
  },
};
export { claimIncidentHistoryStyle };
