import AdminUserPoolGroupPermissions from './AdminUserPoolGroupPermissions';
import CustomerSupportGroupPermissions from './CustomerSupportGroupPermissions';
import SwivelChairGroupPermissions from './SwivelChairGroupPermissions';

export const PumpkinOneLoginLocalGroup = 'pumpkin-onelogin-local';
export const PumpkinOneLoginDevelopmentGroup = 'pumpkin-onelogin-development';
export const PumpkinOneLoginStagingGroup = 'pumpkin-onelogin-staging';
export const PumpkinOneLoginProductionGroup = 'pumpkin-onelogin-production';
export const PumpkinOneLoginMGADevGroup = 'pumpkin-onelogin-mga-dev';
export const PumpkinOneLoginMGAQAGroup = 'pumpkin-onelogin-mga-qa';
export const PumpkinOneLoginMGATrainingGroup = 'pumpkin-onelogin-mga-training';

export const AdminUserPoolGroup = 'AdminUserPoolGroup';
export const SuperAdminUserPoolGroup = 'SuperAdminUserPoolGroup';
export const SwivelChairGroup = 'SwivelChairGroup';
export const CustomerSupportGroup = 'CustomerSupportGroup';

export const permissionsByGroup = {
  [AdminUserPoolGroup]: AdminUserPoolGroupPermissions,
  [SuperAdminUserPoolGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginLocalGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginDevelopmentGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginMGADevGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginMGAQAGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginMGATrainingGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginStagingGroup]: AdminUserPoolGroupPermissions,
  [PumpkinOneLoginProductionGroup]: AdminUserPoolGroupPermissions,
  [SwivelChairGroup]: SwivelChairGroupPermissions,
  [CustomerSupportGroup]: CustomerSupportGroupPermissions,
};
