import { useGetOne } from 'react-admin';

import { CLOUDFLARE_WORKER_API } from '../config';

export function useProgramResolver(usState, species, age) {
  return useGetOne(
    '',
    {
      id: '',
      meta: {
        api: 'UTILS',
        custom_url: `${CLOUDFLARE_WORKER_API}/v2/programs/resolve/${usState}/${species}/${age}`,
      },
    },
    { enabled: !!usState, placeholderData: [], staleTime: 1000 * 60 * 60 }
  );
}
