import { useGetOne } from 'react-admin';

export const INCIDENT_HISTORY_QUERY = 'pets';

export function useIncidentHistory(petId) {
  return useGetOne(
    `${INCIDENT_HISTORY_QUERY}/${petId}`,
    { id: 'incident' },
    {
      enabled: !!petId,
      select: response => {
        response.list.forEach(incident => {
          incident.original_symptom_date_as_date = new Date(
            incident.original_symptom_date
          );
        });
        return response;
      },
    }
  );
}
