import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { PlanPolicyDetailCard } from '@pumpkincare/shared';

import { parseAndFormatDate } from '../../../shared/utils';
import { isPetPlanInCancelledStatus } from '../../../shared/utils/PetPlanUtils';
import PolicyChangeRadioButtonLabel from '../policy-change-radio-button-label';
import { midtermRequestStyle } from './midterm-request-style';

function MidtermRequest({
  midtermChangeData = [],
  petData = {},
  onRatesChange,
  midtermRatesValue = '',
}) {
  const classes = midtermRequestStyle();

  const policyStartDate = petData.latest_pet_policy?.policy_effective_date
    ? parseAndFormatDate(petData.latest_pet_policy?.policy_effective_date)
    : 'N/A';
  const policyEndDate = petData.latest_pet_policy?.policy_end_date
    ? parseAndFormatDate(petData.latest_pet_policy?.policy_end_date)
    : 'N/A';
  const policyDates = `Policy Effective Dates: ${policyStartDate} - ${policyEndDate}`;

  const planStartDate = petData.latest_pet_plan?.plan_effective_date
    ? parseAndFormatDate(petData.latest_pet_plan.plan_effective_date)
    : 'N/A';
  const planEndDate = petData.latest_pet_plan?.plan_end_date
    ? parseAndFormatDate(petData.latest_pet_plan.plan_end_date)
    : 'N/A';
  const planDates = `Plan Effective Dates: ${planStartDate} - ${planEndDate}`;

  return (
    <div>
      <Typography className={classes.ratesLabel}>Policy & Plan Term:</Typography>
      <div style={{ display: 'flex', marginBottom: '24px' }}>
        <PlanPolicyDetailCard
          title={`INSURANCE ${petData.state} (V${petData.version})`}
          status={petData.latest_pet_policy?.status}
          effectiveDatePeriod={policyDates}
          enrollmentDate={
            petData.enrollment_date
              ? parseAndFormatDate(petData.enrollment_date)
              : 'N/A'
          }
          coverageStartDate={
            petData.coverage_start_date
              ? parseAndFormatDate(petData.coverage_start_date)
              : 'N/A'
          }
          utilization={petData.latest_pet_policy?.utilization}
        />
        {petData.latest_pet_plan &&
        !isPetPlanInCancelledStatus(petData.latest_pet_plan.status) ? (
          <PlanPolicyDetailCard
            title={`PREVENTIVE ESSENTIALS (${petData.pet_plan_version || ''})`}
            status={petData.latest_pet_plan.status}
            effectiveDatePeriod={planDates}
            enrollmentDate={
              petData.pet_plan_enrollment_date
                ? parseAndFormatDate(petData.pet_plan_enrollment_date)
                : 'N/A'
            }
            coverageStartDate={
              petData.pet_plan_coverage_start_date
                ? parseAndFormatDate(petData.pet_plan_coverage_start_date)
                : 'N/A'
            }
          />
        ) : null}
      </div>

      <Typography className={classes.ratesLabel}>Rates</Typography>
      <FormControl component='fieldset'>
        <Typography className={classes.ratesDescription}>
          Disabled rate options are not available due to the change type. If the
          customer wishes to make these changes, consider making these changes at
          renewal.
        </Typography>
        <RadioGroup
          className={classes.ratesRadioGroupButton}
          name='rates'
          value={midtermRatesValue}
          onChange={event => {
            onRatesChange(
              midtermChangeData.find(
                midtermRecord => event.target.value === midtermRecord.id
              )
            );
          }}
        >
          {midtermChangeData.map(midtermChangeOption => {
            return (
              <FormControlLabel
                key={midtermChangeOption.id}
                className={classes.radioButton}
                value={midtermChangeOption.id}
                control={<Radio />}
                disabled={!midtermChangeOption.allowed}
                label={<PolicyChangeRadioButtonLabel {...midtermChangeOption} />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default MidtermRequest;
