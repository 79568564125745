import makeStyles from '@mui/styles/makeStyles';

const claimDiagnosisStyle = makeStyles(
  {
    title: {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
      fontWeight: 'normal',
      color: '#2D2D2D',
    },
    detail: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    vetText: {
      color: '#3EC28F',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    flag: {
      color: '#3EC28F',
      marginRight: '8px',
    },
  },
  { name: 'claim-diagnosis' }
);

export { claimDiagnosisStyle };
