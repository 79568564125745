import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getRecordValue } from '../shared/utils/utils';

function DateFieldIgnoringZone({ record, source, style }) {
  const contextRecord = useRecordContext();

  const date = record
    ? getRecordValue(record, source)
    : contextRecord
      ? getRecordValue(contextRecord, source)
      : null;
  return date ? (
    <Typography style={style || { fontSize: '0.875rem' }}>
      {moment.parseZone(date).format('MM/DD/YYYY')}
    </Typography>
  ) : (
    ''
  );
}

DateFieldIgnoringZone.defaultProps = {
  record: null,
  source: '',
  style: {},
};

DateFieldIgnoringZone.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  style: PropTypes.object,
};
export default DateFieldIgnoringZone;
