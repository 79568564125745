export const REIMBURSEMENT_CANCELLATION_REASON = [
  { id: 'WRONG_MAILING_ADDR', name: 'Sent to wrong mailing address' },
  {
    id: 'CUSTOMER_NEVER_RECEIVED_REIMBURSEMENT',
    name: 'Customer never received reimbursement',
  },
  { id: 'INCORRECT_CUSTOMER_NAME', name: 'Incorrect customer name' },
  { id: 'INCORRECT_REIMBURSEMENT_AMOUNT', name: 'Incorrect reimbursement amount' },
  { id: 'DUPLICATE', name: 'Duplicate' },
  { id: 'GENERAL_REIMBURSEMENT_ERROR', name: 'General Reimbursement Error' },
  {
    id: 'CHANGE_REQUEST_FOR_REIMBURSEMENT',
    name: 'Change request for reimbursement method',
  },
  { id: 'CANCELLED_OR_EXPIRED_PLAN', name: 'Canceled or Expired Plan' },
  { id: 'OTHER', name: 'Other' },
];

export function formatReissueModalCustomerDetail(
  reimbursement,
  type,
  mailingAddress
) {
  if (type === 'ACH') {
    return `${reimbursement.funding_source?.account_type} account ending in ${
      reimbursement.funding_source?.account_number || ''
    }`;
  } else if (type === 'Check') {
    return `Mailing address: ${mailingAddress.street_1} ${
      mailingAddress.street_2 ? mailingAddress.street_2 + ' ' : ''
    }${mailingAddress.city}, ${mailingAddress.state_province} ${
      mailingAddress.zipcode
    }`;
  } else {
    return '';
  }
}
