import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import AddressInput from './AddressInput';
import { paymentFormStyle } from './PaymentFormStyle';

function BillingForm(props) {
  const { quote, onAddressChange, isBillingSameAsShipping, onSameAsShippingChange } =
    props;

  const styles = paymentFormStyle();

  const billingAddressDefault = quote.existingUserDetails
    ? quote.existingUserDetails.billing_address
    : { country: 'US' };

  return (
    <div>
      <Typography style={{ margin: '24px 0 0' }}>
        Billing address<sup className={styles.asterisk}>*</sup>
      </Typography>

      <FormControl component='fieldset'>
        <RadioGroup
          onChange={onSameAsShippingChange}
          value={isBillingSameAsShipping}
        >
          <FormControlLabel
            value={true}
            control={<Radio color='primary' />}
            label='Same as shipping address'
          />
          <FormControlLabel
            value={false}
            control={<Radio data-testid={'radio-button-false'} color='primary' />}
            label='Use a different billing address'
          />
        </RadioGroup>
      </FormControl>

      {isBillingSameAsShipping === false ? (
        <AddressInput
          quote={quote}
          onChange={onAddressChange}
          style={{ marginTop: '16px' }}
          defaultAddress={billingAddressDefault}
        />
      ) : null}
    </div>
  );
}

export default BillingForm;
