import { useState } from 'react';
import { Form, useCreate, useNotify, useRefresh } from 'react-admin';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CLAIM_REIMBURSEMENT_STATUS_COLOR } from '@pumpkincare/claims';
import { formatReissueModalCustomerDetail } from '@pumpkincare/reimbursements';

import { formatCurrency, parseAndFormatDate } from '../../../shared/utils';
import { reissueReimbursementModalStyle } from './reissue-reimbursement-modal-style';

const REIMBURSEMENT_MAP = {
  insurance: 'Insurance',
  prevent: 'Preventive Essentials',
  wellness: 'Wellness',
};

function ReissueReimbursementModal(props) {
  const {
    onClose,
    isOpen,
    reimbursement = {},
    mailingAddress = {},
    claimId,
  } = props;
  const classes = reissueReimbursementModalStyle();
  const [create, { isLoading }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [reimbursementType, setReimbursementType] = useState();

  function handleSubmitCancellationConfirm() {
    if (!reimbursementType) {
      notify(`Please select a reimbursement type.`, {
        type: 'warning',
      });
      return;
    }
    create(
      `reimbursements`,
      {
        data: {
          reimbursement_method: reimbursementType.toUpperCase(),
          subclaim_id: reimbursement.subclaim_id,
          claim_submission_id: claimId,
        },
      },
      {
        onSuccess: () => {
          onClose();
          refresh();
        },
        onError: error => {
          notify(
            `There was an error while creating the reimbursement: ${error.message}`,
            {
              type: 'warning',
            }
          );
        },
      }
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmitCancellationConfirm}>
        <DialogTitle variant='h6' className={classes.dialogTitleContainer}>
          Create Reimbursement
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div style={{ display: 'flex', gap: '22px' }}>
            <Button
              onClick={() => setReimbursementType('ACH')}
              className={classnames(classes.reimbursementTypeButton, {
                [classes.activeReimbursementTypeButton]: reimbursementType === 'ACH',
              })}
            >
              ACH
            </Button>
            <Button
              onClick={() => setReimbursementType('Check')}
              className={classnames(classes.reimbursementTypeButton, {
                [classes.activeReimbursementTypeButton]:
                  reimbursementType === 'Check',
              })}
            >
              PHYSICAL CHECK
            </Button>
          </div>
          <Typography className={classes.customerDetailTitle}>
            CUSTOMER DETAILS:
          </Typography>
          <Typography className={classes.customerDetail}>
            {formatReissueModalCustomerDetail(
              reimbursement,
              reimbursementType,
              mailingAddress
            )}
          </Typography>

          <Typography className={classes.reimbursementDetailTitle}>
            REIMBURSEMENT TO BE REISSUED:
          </Typography>

          <div className={classes.informationDiv}>
            <Typography className={classes.totalReimbursement}>
              {`${
                REIMBURSEMENT_MAP[reimbursement.reimbursement_type]
              } Reimbursement: ${formatCurrency(
                reimbursement.total_reimbursement_amount,
                { areCents: true }
              )}`}
            </Typography>
            <div className={classes.statusDiv}>
              <Typography className={classes.checkInfo}>
                {`Initiated on ${parseAndFormatDate(reimbursement.created_at)}`}
              </Typography>

              <Chip
                style={{
                  fontSize: '10px',
                  height: '20px',
                  color: '#000000',
                  backgroundColor:
                    CLAIM_REIMBURSEMENT_STATUS_COLOR[reimbursement.status] ||
                    '#FFFFFF',
                }}
                label={reimbursement.status?.toUpperCase()}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={isLoading}
            type='submit'
            className={classes.updateButton}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

ReissueReimbursementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  reimbursement: PropTypes.object,
  mailingAddress: PropTypes.object,
  claimId: PropTypes.string,
};

export default ReissueReimbursementModal;
