import { useState } from 'react';
import {
  FileField,
  FileInput,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  useDelete,
  useNotify,
  useRecordContext,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  DOCUMENT_ALLOWED_TYPES,
  DOCUMENT_MAX_SIZE,
} from '../../../../constants/documents';
import RoutePaths from '../../../../routes';
import { uploadFile } from '../../../claims/service/claimService';
import { addDocument } from '../../../customers/service/customerService';
import { dialogClasses } from './pet-photo-modal-style';

function PetPhotoModal({ open, onClose, onSave, onDelete, source, isDeleting }) {
  const [deleteOne, { isLoading: isLoadingDelete }] = useDelete();

  const notify = useNotify();

  const { user_id, id: petId } = useRecordContext();
  const { id: documentId } = source || {};

  const [isLoading, setIsLoading] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');

  const confirmButtonText = isDeleting ? 'Yes, Delete it' : 'SAVE';
  function onFileRejected() {
    notify(
      'Error: Attachment rejected. Make sure it is the proper size and type.',
      'error'
    );
  }

  function uploadPetPhoto(rawFile, docType) {
    return uploadFile(user_id, rawFile, docType)
      .then(s3Url => {
        const document = {
          location: s3Url,
          type: docType,
          user_id: user_id,
          pet_id: petId,
          source_file_name: rawFile.name,
        };
        addDocument(document, RoutePaths.documentsV2)
          .then(response => {
            onSave(response);
          })
          .catch(error => {
            notify(`ERROR: ${error.message}`, 'error');
          })
          .finally(() => {
            setIsLoading(false);
          })
          .catch(() => {
            notify(
              'There was an error when uploading file - please try again later.',
              'error'
            );
            setIsLoading(false);
          });
      })
      .catch(() => {
        notify(
          'There was an error when uploading file - please try again later.',
          'error'
        );
      });
  }

  function handleSavePhoto({ formData }) {
    const { file } = formData;

    if (file?.rawFile) {
      setIsLoading(true);
      const docType = 'pet_avatar';
      const { rawFile } = file;

      if (Object.keys(source).length) {
        deleteOne(
          RoutePaths.documentsV2,
          {
            id: documentId,
          },
          {
            onSuccess: () => {
              uploadPetPhoto(rawFile, docType);
            },
            onError: error => {
              notify(
                `There was an error while deleting the document: ${error.message}`,
                {
                  type: 'error',
                }
              );
            },
          }
        );
      } else {
        uploadPetPhoto(rawFile, docType);
      }
    } else {
      notify('You must select a file to upload before saving.', 'warning');
    }
  }

  function handleConfirm(formDataProps) {
    if (isDeleting) {
      if (deleteReason === '') {
        notify('Please select a reason for deleting the image', 'warning');
        return;
      }
      const params = {
        id: documentId,
        data: {
          delete_reason: deleteReason,
        },
        meta: { data: { delete_reason: deleteReason } },
      };

      deleteOne(RoutePaths.documentsV2, params, {
        onSuccess: () => {
          onDelete();
        },
        onError: error => {
          notify(
            `There was an error while deleting the document: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      });
    } else {
      handleSavePhoto(formDataProps);
    }
  }

  return (
    <SimpleForm toolbar={false} sx={dialogClasses.form}>
      <Dialog open={open} sx={dialogClasses.dialog}>
        <DialogTitle variant='h6' color='primary'>
          UPLOAD IMAGE
        </DialogTitle>

        <DialogContent sx={dialogClasses.context}>
          {isDeleting ? (
            <>
              <Typography>
                Are you sure you want to remove this image? This user will lose their
                pet’s profile photo.
              </Typography>
              <SelectInput
                variant='standard'
                fullWidth
                source='delete_reason'
                choices={[
                  { id: 'Inappropriate Content', name: 'Inappropriate Content' },
                  { id: 'Customer Requested', name: 'Customer Requested' },
                  { id: 'Other', name: 'Other' },
                ]}
                label='Reason'
                onChange={e => setDeleteReason(e.target.value)}
              />
            </>
          ) : (
            <FileInput
              validate={true}
              source='file'
              label=' '
              options={{
                onDropRejected: onFileRejected,
              }}
              multiple={false}
              maxSize={DOCUMENT_MAX_SIZE}
              accept={DOCUMENT_ALLOWED_TYPES.join()}
              sx={dialogClasses.fileInput}
            >
              <FileField target='_blank' source='src' title='title' />
            </FileInput>
          )}
        </DialogContent>

        <DialogActions sx={dialogClasses.actions}>
          <Button
            onClick={onClose}
            variant={isDeleting ? 'outlined' : 'text'}
            disabled={isLoading || isLoadingDelete}
            sx={dialogClasses.cancelButton}
          >
            CANCEL
          </Button>
          <FormDataConsumer>
            {formDataProps => (
              <Button
                disabled={isLoading}
                type='submit'
                color='primary'
                variant={isDeleting ? 'outlined' : 'text'}
                onClick={() => handleConfirm(formDataProps)}
                sx={
                  isDeleting
                    ? dialogClasses.deleteButton
                    : dialogClasses.confirmButton
                }
              >
                {isLoading || isLoadingDelete ? 'SAVING...' : confirmButtonText}
              </Button>
            )}
          </FormDataConsumer>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
}

PetPhotoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  source: PropTypes.object,
  isDeleting: PropTypes.bool,
};

PetPhotoModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
  onDelete: () => {},
  source: {},
  isDeleting: false,
};

export default PetPhotoModal;
