import { useGetList } from 'react-admin';

export const DIAGNOSIS_QUERY = 'diagnosis';

export function useDiagnosis() {
  return useGetList(DIAGNOSIS_QUERY, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });
}

export function useSubDiagnosis() {
  const result = useGetList(`${DIAGNOSIS_QUERY}/sub-diagnosis`, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });

  return result;
}
