import { SelectInput, TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';

import { orgTypeChoices } from '../../../constants/organizations-constants';

export function OrganizationsFilter() {
  const { filterValues, setFilters } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  function onSubmit(values) {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      setFilters({}, []);
    }
  }

  function onClearAll() {
    form.reset();
    setFilters({}, []);
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display='flex' alignItems='flex-end' mb={1}>
            <Box component='span' mr={2}>
              <TextInput helperText={false} label='Name' source='name' />
            </Box>
            <Box component='span' mr={2}>
              <TextInput
                helperText={false}
                label='Association'
                source='association'
              />
            </Box>
            <Box component='span' mr={2}>
              <TextInput helperText={false} label='Pass code' source='pass_code' />
            </Box>
            <Box component='span' mr={2}>
              <SelectInput
                helperText={false}
                label='Type'
                source='org_type'
                choices={orgTypeChoices}
              />
            </Box>
            <Box
              component='span'
              mr={2}
              mb={1.5}
              display='flex'
              alignItems='flex-end'
            >
              <Box component='span' mr={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  type='submit'
                  style={{
                    borderRadius: '4px',
                    width: '120px',
                  }}
                >
                  Filter
                </Button>
              </Box>
              <Box component='span' mr={2}>
                <Button
                  variant='outlined'
                  color='primary'
                  type='button'
                  onClick={() => onClearAll()}
                  style={{
                    borderRadius: '4px',
                    width: '120px',
                  }}
                >
                  Clear All
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
}
