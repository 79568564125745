import { useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import { PRODUCT_STATUS_COLORS } from '../../../../constants/product-status-colors';

const styles = {
  active: { backgroundColor: PRODUCT_STATUS_COLORS.ACTIVE.color },
  pending: { backgroundColor: PRODUCT_STATUS_COLORS.PENDING.color },
  renew_pending: { backgroundColor: PRODUCT_STATUS_COLORS.RENEW_PENDING.color },
  lapsed: { backgroundColor: PRODUCT_STATUS_COLORS.LAPSED.color },
  cancel_pending: { backgroundColor: PRODUCT_STATUS_COLORS.CANCEL_PENDING.color },
  cancelled: { backgroundColor: PRODUCT_STATUS_COLORS.CANCELLED.color },
  expired: { backgroundColor: PRODUCT_STATUS_COLORS.EXPIRED.color },
  declined: { backgroundColor: PRODUCT_STATUS_COLORS.DECLINED.color },
  voided: { backgroundColor: PRODUCT_STATUS_COLORS.VOIDED.color },
  updated: { backgroundColor: PRODUCT_STATUS_COLORS.UPDATED.color },
};

function FieldStatusChip({ classes }) {
  const record = useRecordContext();
  const status = record?.status || '';

  return (
    <Chip
      className={classnames({
        [classes.active]: status === 'active',
        [classes.pending]: status === 'pending',
        [classes.renew_pending]: status === 'renew_pending',
        [classes.lapsed]: status === 'lapsed',
        [classes.cancel_pending]: status === 'cancel_pending',
        [classes.cancelled]: status === 'cancelled',
        [classes.expired]: status === 'expired',
        [classes.declined]: status === 'declined',
        [classes.voided]: status === 'voided',
        [classes.updated]: status === 'updated',
      })}
      style={{
        color: '#000000',
        height: '20px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
      }}
      label={status ? status.toUpperCase().replace('_', ' ') : ''}
    />
  );
}

export default withStyles(styles)(FieldStatusChip);
