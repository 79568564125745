export function transformPolicyOptions({
  deductibles = [],
  limits = [],
  prices = {},
}) {
  return {
    deductibles: deductibles.map(transformOptions),
    limits: limits.map(transformOptions),
    prices: transformPricesObject(prices),
  };
}

function transformOptions(option) {
  return {
    id: option.id.includes('Unlimited') ? 'Unlimited' : option.id.replace(/\D/g, ''),
    title: option.title.split(' ', 1)[0],
  };
}

function transformPricesObject(pricesObject) {
  const transformedObject = {};

  for (const [copay, prices] of Object.entries(pricesObject)) {
    const copayValue = copay.replace(/\D/g, '');
    transformedObject[copayValue] = transformPrices(prices);
  }

  return transformedObject;
}

function transformPrices(prices) {
  const transformedPrices = {};
  for (const [limit, deductibles] of Object.entries(prices)) {
    const formatted_limit = limit.includes('Unlimited')
      ? 'Unlimited'
      : limit.replace(/\D/g, '');
    const transformedDeductibles = {};
    for (const [deductible, price] of Object.entries(deductibles)) {
      transformedDeductibles[deductible.replace(/\D/g, '')] = price;
    }
    transformedPrices[formatted_limit] = transformedDeductibles;
  }
  return transformedPrices;
}
