import { useRecordContext } from 'react-admin';

import DateFieldIgnoringZone from '../../components/DateFieldIgnoringZone';
import { getRecordValue } from '../../shared/utils/utils';

export default function CancellationDetailsField({ source }) {
  const record = useRecordContext();
  return (
    <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
      {record.cancellation_assignments.map(item => (
        <li style={{ marginBottom: '8px' }} key={item.id}>
          {source === 'created_at' ? (
            <DateFieldIgnoringZone record={item} source={source} />
          ) : (
            getRecordValue(item, source) || 'N/A'
          )}
        </li>
      ))}
    </ul>
  );
}
