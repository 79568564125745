import { AutocompleteInput, Form, SelectInput, useListContext } from 'react-admin';
import { Box } from '@mui/material';

import { claimMGAStatusesFilter } from '@pumpkincare/claims';
import { stateList } from '@pumpkincare/shared';

function ClaimFilter(props) {
  const { opsList, showStatus = true, showAssignee = true } = props;
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();

  function handleSubmit(values) {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter('main');
    }
  }

  function handleAssigneeChange(event) {
    setFilters({
      ...filterValues,
      current_owner_email: event,
      assigned_id: event ? '' : 'unassigned',
    });
  }

  return displayedFilters.main ? (
    <Form onSubmit={handleSubmit} defaultValues={filterValues}>
      <Box display='flex' alignItems='flex-end' mb={1}>
        <Box component='span' mr={2}>
          <SelectInput
            source='state'
            variant='outlined'
            choices={stateList}
            label='State'
            resettable
            onChange={event =>
              setFilters({
                ...filterValues,
                state: event?.target?.value,
              })
            }
            alwaysOn
          />
        </Box>
        {showStatus === true ? (
          <Box component='span' mr={2}>
            <SelectInput
              variant='outlined'
              source='status'
              choices={claimMGAStatusesFilter}
              label='Status'
              resettable
              onChange={event =>
                setFilters({
                  ...filterValues,
                  status: event?.target?.value,
                })
              }
              alwaysOn
            />
          </Box>
        ) : null}
        {showAssignee === true ? (
          <Box component='span' mr={2} autocomplete='off'>
            <AutocompleteInput
              label='Assignee'
              source='current_owner_email'
              choices={opsList}
              onChange={handleAssigneeChange}
              optionText='email'
              optionValue='email'
              resettable
              variant='outlined'
            />
          </Box>
        ) : null}
      </Box>
    </Form>
  ) : null;
}
export default ClaimFilter;
