import makeStyles from '@mui/styles/makeStyles';

const uploadClaimAttachmentStyle = makeStyles(
  {
    fileInput: {
      border: '1px solid #949494',
      borderRadius: '4px',
    },
    fileInputDropzone: {
      background: 'white',
    },
  },
  { name: 'upload-claim-attachment' }
);

export { uploadClaimAttachmentStyle };
