import makeStyles from '@mui/styles/makeStyles';

const updateClaimStatusModal = makeStyles(
  {
    modalTitle: {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '32px',
      color: '#2196F3',
    },
    modalContent: { width: '300px', height: '100px', textAlign: 'center' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    updateButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      color: 'white',
      width: '100%',
    },
  },
  { name: 'update-claim-status-modal' }
);

export { updateClaimStatusModal };
