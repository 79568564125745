import { getCurrentPolicy } from '../../shared/utils/productUtils';

export function sortPoliciesByStatusASC(response) {
  const responseSorted = response.data.sort((a, b) => {
    const activePolicyA = getCurrentPolicy(a.policies);
    const activePolicyB = getCurrentPolicy(b.policies);

    return activePolicyA?.status.localeCompare(activePolicyB?.status);
  });

  return responseSorted;
}
