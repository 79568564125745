import {
  FunctionField,
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import SimpleTableLayout from '../../components/SimpleTableLayout';
import useBooleanInput from '../../hooks/useBooleanInput';
import RoutePaths from '../../routes';
import { getRecordValue } from '../../shared/utils/utils';
import CancellationCards from './cancellation-cards';
import CancellationEmailDetailsCard from './CancellationEmailDetailsCard';
import CancellationNotes from './CancellationNotes';
import CancellationReasonsCard from './CancellationReasonsCard';
import useStyles from './CancellationStyles';
import CancellationUpdateFormDialog from './CancellationUpdateFormDialog';
import RefundsCard from './RefundsCard';

function CancellationTitle() {
  const record = useRecordContext();
  return record ? (
    <span>
      Cancellations - {record.user.first_name} {record.user.last_name} -{' '}
      {record.user.pumpkin_id}
    </span>
  ) : (
    ''
  );
}

function CustomUrlField({ source, record }) {
  const classes = useStyles();
  const link = getRecordValue(record, source);
  return link ? (
    <a href={link} target={'_blank'} rel='noreferrer'>
      Open in Gladly
    </a>
  ) : (
    <div className={classes.customerInfo}>N/A</div>
  );
}

CustomUrlField.defaultProps = {
  addLabel: true,
};

function CustomerInformation() {
  const classes = useStyles();
  const record = useRecordContext();

  const [open, setOpen] = useBooleanInput(false);
  function handleClick() {
    setOpen(true);
  }

  return record ? (
    <SimpleTableLayout
      className={classes.tableSize}
      labelClassName={classes.label}
      title={`${record.user.first_name} ${record.user.last_name}`}
    >
      <Labeled label='CUSTOMER NAME'>
        <FunctionField
          render={() => {
            return (
              <Link
                to={`/${RoutePaths.customers}/${record.user.id}/show`}
                className={classes.customerInfo}
              >
                {record.user.first_name} {record.user.last_name}
              </Link>
            );
          }}
          className={classes.customerInfo}
        />
      </Labeled>

      <Labeled label='PKN NUMBER'>
        <TextField source='user.pumpkin_id' className={classes.customerInfo} />
      </Labeled>

      <Labeled label='EMAIL'>
        <TextField source='user.email' className={classes.customerInfo} />
      </Labeled>

      <Labeled label='GLADLY LINK'>
        <CustomUrlField source='user.gladly_customer_url' record={record} />
      </Labeled>

      <Labeled label='STATUS'>
        <TextField
          source='cancellation_assignments[0].internal_cancellation_status'
          className={classes.customerInfo}
        />
      </Labeled>

      <Labeled label='ASSIGNED TO'>
        <FunctionField
          render={(record = {}) => {
            return (
              <Typography className={classes.iconCell}>
                <AccountCircleIcon />
                {record.cancellation_assignments[0].ops_user?.name || 'N/A'}
              </Typography>
            );
          }}
        />
      </Labeled>

      <Button onClick={handleClick} variant='contained'>
        Update
      </Button>

      <CancellationUpdateFormDialog
        selectedIds={[record.cancellation_assignments[0].user.id]}
        open={open}
        setOpen={setOpen}
        showCompleted={true}
        showReason={false}
      />
    </SimpleTableLayout>
  ) : null;
}

function CancellationsInfo({ classes }) {
  const record = useRecordContext();

  return record ? (
    <div style={{ display: 'flex', gap: '16px' }}>
      <CancellationCards record={record} classes={classes} />

      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '1200px' }}>
        <RefundsCard record={record} />
        <CancellationReasonsCard record={record} />
        <CancellationEmailDetailsCard record={record} />
        <CancellationNotes userId={record.id} />
      </div>
    </div>
  ) : null;
}

export default function CancellationShow() {
  const classes = useStyles();
  return (
    <Show title={<CancellationTitle />}>
      <SimpleShowLayout>
        <Typography className={classes.titleStyle} style={{ paddingBottom: '0px' }}>
          Customer Information
        </Typography>
        <CustomerInformation />
        <Divider style={{ marginBottom: '16px' }} />
        <CancellationsInfo classes={classes} />
      </SimpleShowLayout>
    </Show>
  );
}
