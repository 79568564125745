import { GET_LIST } from 'react-admin';

import { addonOptionsWithStatusForSelectedPet } from '../../lib/addonOptionsForPet';
import { dataProvider } from '../../lib/dataProvider/provider';
import { planOptionsWithStatusForSelectedPet } from '../../lib/plan-options-for-pet';
import { policyOptionsWithStatusForSelectedPet } from '../../lib/policy-options-for-pet';
import { wellnessOptionsWithStatusForSelectedPet } from '../../lib/wellness-options-for-pet';
import RoutePaths from '../../routes';

export function getPets(userId) {
  return dataProvider(GET_LIST, RoutePaths.pets, {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'asc' },
    filter: { user_id: userId },
  }).then(response => {
    return response.data.map(pet => {
      return {
        id: pet.id,
        name: pet.name,
        plans: planOptionsWithStatusForSelectedPet(pet),
        policies: policyOptionsWithStatusForSelectedPet(pet),
        addons: addonOptionsWithStatusForSelectedPet(pet),
        wellness: wellnessOptionsWithStatusForSelectedPet(pet),
      };
    });
  });
}
