import Oidc from 'oidc-client';

import { AUTH_SETTINGS } from '@pumpkincare/config';

let oidcLogin;
let oidcLogout;
let oidcAuthCheck;
let oidcGetIdentity;
let oidcGetPermissions;
let oidcGetCurrentUser;
let oidcUserPoolGroups;

if (AUTH_SETTINGS.useOidcLib) {
  const oidcAuthSettings = {
    response_type: 'token',
    scope: 'openid profile',
    filterProtocolClaims: true,
    loadUserInfo: false,
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    authority: `${AUTH_SETTINGS.oauth.domain}`,
    client_id: `${AUTH_SETTINGS.userPoolWebClientId}`,
    redirect_uri: `${AUTH_SETTINGS.oauth.redirectSignIn}`,
  };

  Oidc.Log.logger = console;
  Oidc.Log.level = Oidc.Log.INFO;

  const mgr = new Oidc.UserManager(oidcAuthSettings);

  const signinRedirect = () => {
    mgr
      .signinRedirect()
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
  };

  oidcGetCurrentUser = () => {
    return {
      email: 'local@pumpkin.care',
      sub: 'local_sub',
    };
  };

  oidcUserPoolGroups = () => {
    return [
      'SuperAdminUserPoolGroup',
      'AdminUserPoolGroup',
      'CustomerSupportGroup',
      'SwivelChairGroup',
    ];
  };

  oidcLogin = () => {
    mgr.getUser().then(user => {
      if (user === null) {
        signinRedirect(mgr);
      } else if (user.expired) {
        mgr.removeUser().then(() => {
          signinRedirect(mgr);
        });
      } else {
        console.log('valid user session in place');
        window.location.pathname = '/'; // TODO: do with pushstate instead
      }
    });
    return Promise.resolve();
  };

  oidcLogout = () => {
    mgr
      .removeUser()
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
    return Promise.resolve();
  };

  oidcAuthCheck = () => {
    return mgr.getUser().then(user => {
      if (user === null) {
        return Promise.reject(new Error('You need to sign in to access that page.'));
      }
      if (user.expired) {
        return mgr.removeUser().then(() => {
          return Promise.reject(
            new Error('You need to sign in to access that page.')
          );
        });
      }
      return Promise.resolve();
    });
  };

  oidcGetIdentity = () => {
    return Promise.resolve({ id: '123-456', fullName: 'Local User' });
  };

  oidcGetPermissions = () => {
    const groupsArray = oidcUserPoolGroups();
    if (groupsArray.length === 0) {
      return Promise.reject(new Error('No groups present'));
    }

    const { currentCognitoGroup } =
      JSON.parse(localStorage.getItem('@pumpkincare/auth/groups')) || {};
    let currentOidcGroup = groupsArray[0];
    if (
      currentCognitoGroup &&
      groupsArray.find(group => group === currentCognitoGroup)
    ) {
      currentOidcGroup = currentCognitoGroup;
    }

    localStorage.setItem(
      '@pumpkincare/auth/groups',
      JSON.stringify({
        cognitoGroups: groupsArray,
        currentCognitoGroup: currentOidcGroup,
      })
    );

    return Promise.resolve(currentOidcGroup);
  };

  const oidcRewriteAuthResponseURL = () => {
    const parsedUrl = new URL(window.location);
    const hashObject = parsedUrl.hash
      .substring(1)
      .split('&')
      .map(v => v.split('='))
      .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

    // window.localStorage.setItem('id_token', hashObject.id_token)
    delete hashObject.id_token;

    parsedUrl.hash = ''.concat(
      '#',
      Object.keys(hashObject)
        .reduce((result, key) => {
          result.push(`${key}=${hashObject[key]}`);
          return result;
        }, [])
        .join('&')
    );

    const pushStateUrl = parsedUrl.toString();
    window.history.pushState(null, null, pushStateUrl);
  };
  if (
    window.location.pathname.indexOf('/login') > -1 &&
    window.location.hash.indexOf('access_token') > -1
  ) {
    oidcRewriteAuthResponseURL();

    mgr
      .signinRedirectCallback()
      .then(() => {
        // window.history.pushState(null, null, '/');
        window.location.href = '/'; // TODO: do with pushstate instead
      })
      .catch(err => {
        console.log(err);
      });
  }

  if (window.location.pathname.indexOf('/login') > -1) {
    mgr.getUser().then(user => {
      if (user === null) {
        window.location.pathname = '/login';
      } else if (user.expired) {
        window.location.pathname = '/login';
      } else {
        // window.history.pushState(null, null, '/');
        window.location.pathname = '/'; // TODO: do with pushstate instead
      }
    });
  }
}

const oidcAuthProviderFunctionMap = {
  AUTH_LOGIN: oidcLogin,
  AUTH_LOGOUT: oidcLogout,
  AUTH_CHECK: oidcAuthCheck,
  AUTH_GET_PERMISSIONS: oidcGetPermissions,
  AUTH_GET_IDENTITY: oidcGetIdentity,
};

export {
  oidcLogin,
  oidcAuthProviderFunctionMap,
  oidcGetCurrentUser,
  oidcUserPoolGroups,
};
