import {
  Button,
  CreateButton,
  FunctionField,
  Labeled,
  TextField,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconAdd from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Container,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useProductsByState } from '@pumpkincare/quotes';
import { GENDER_MAP } from '@pumpkincare/shared';

import AddEditPetPlanButton from '../../../../components/AddEditPetPlanButton';
import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import { formatCurrency } from '../../../../shared/utils';
import {
  appendBreedName,
  checkCancellation,
  checkPolicyCancellation,
  checkWellnessCancellation,
  petHasValidPlanForAddingPrevent,
  petHasValidPolicy,
  shouldShowWellness,
} from '../../../../shared/utils/petUtils';
import {
  getCurrentPolicy,
  getCurrentWellness,
} from '../../../../shared/utils/productUtils';
import { getPolicyState } from '../../utils/customer-utils';
import PetPlanItem from '../pet-plan-item';
import PolicyStatusField from '../policy-status-field';
import EditPetModal from './edit-pet-modal';
import { petListStyle, sxPetListStyle } from './pet-list-style';

function PetPlanWrapper({ petRecord }) {
  const { punks1928WellnessEmployerBenefit } = useFlags();

  const { data: productData } = useProductsByState(
    getPolicyState(petRecord?.latest_active_policy, petRecord?.policies)
  );
  const hasValidPlan = petHasValidPlanForAddingPrevent(petRecord);
  const hasValidPolicy = petHasValidPolicy(petRecord);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const userRecord = useRecordContext();
  const [isEditModalOpen, toggleIsEditModalOpen] = useBooleanInput(false);
  const canAddPrevent =
    petRecord?.wellness?.status !== 'active' &&
    !checkCancellation(petRecord?.policies) &&
    productData?.can_upsell_prevent;

  const canAddWellness =
    (!petRecord.wellness ||
      (checkWellnessCancellation(petRecord?.wellness) && hasValidPolicy)) &&
    !hasValidPlan;

  const canAddInsurance =
    petRecord.wellness && checkPolicyCancellation(petRecord?.policies);

  function redirectToInsuranceCreate(data) {
    refresh();
    redirect('create', RoutePaths.insurance, null, null, {
      pet: data || petRecord,
      user: userRecord,
    });
  }

  function handleCreateButtonClick() {
    if (!petRecord.gender || !petRecord.breed_name) {
      toggleIsEditModalOpen(true);
    } else {
      redirectToInsuranceCreate();
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '18px 0 !important',
        justifyContent: 'flex-start',
        alignItems: 'start',
        margin: '0',
      }}
    >
      {canAddPrevent ? (
        <AddEditPetPlanButton hasValidPlan={hasValidPlan} petRecord={petRecord} />
      ) : null}

      {canAddWellness ? (
        <CreateButton
          label='ADD WELLNESS'
          resource={RoutePaths.wellness}
          state={{
            pet: petRecord,
            user: userRecord,
          }}
        />
      ) : null}

      {canAddInsurance ? (
        <>
          {punks1928WellnessEmployerBenefit ? (
            <>
              <Button onClick={handleCreateButtonClick}>
                <IconAdd />
                ADD INSURANCE
              </Button>
              {isEditModalOpen && (
                <EditPetModal
                  record={petRecord}
                  onClose={toggleIsEditModalOpen}
                  isOpen={isEditModalOpen}
                  onSuccess={redirectToInsuranceCreate}
                />
              )}
            </>
          ) : (
            <>
              <CreateButton
                label='ADD INSURANCE'
                disabled={!petRecord.breed_name || !petRecord.gender}
                resource={RoutePaths.insurance}
                state={{
                  pet: petRecord,
                  user: userRecord,
                }}
              />
              {(!petRecord.breed_name || !petRecord.gender) && (
                <Typography sx={{ color: '#d32f2f' }}>
                  Please update breed and gender before adding insurance.
                </Typography>
              )}
            </>
          )}
        </>
      ) : null}

      {!(productData?.can_upsell_prevent || hasValidPlan || canAddInsurance) ? (
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '22px',
            color: '#767676',
            margin: '18px 0px',
          }}
        >
          Prevent Plan not available in this state.
        </Typography>
      ) : null}
    </Container>
  );
}

PetPlanWrapper.defaultProps = {
  petRecord: {},
};

PetPlanWrapper.propTypes = {
  petRecord: PropTypes.shape({
    latest_active_policy: PropTypes.object,
    policies: PropTypes.array,
    wellness: PropTypes.object,
    breed_name: PropTypes.string,
    gender: PropTypes.string,
  }),
};

export default function PetList({ hasCancellations, petListData }) {
  const { punks1928WellnessEmployerBenefit } = useFlags();
  const classes = petListStyle();
  const redirect = useRedirect();
  const notify = useNotify();
  const userRecord = useRecordContext();
  const PET_HEADERS = [
    'PRODUCT',
    'STATUS',
    'COST',
    'VERSION',
    'EFFECTIVE DATE',
    'NEXT CHARGE DATE',
    'LAPSED DATE',
    'RENEWAL STATUS',
  ];

  const AVATAR_PLACEHOLDERS = {
    dog: '/assets/images/dog_placeholder.svg',
    cat: '/assets/images/cat_placeholder.svg',
  };

  const { id: user_id, is_charged_annually: isChargedAnnually } = userRecord;

  function renewalStatus(record) {
    const textStyle = classnames({
      [classes.renewalToday]: record.renew_status === 'Renewal Today',
      [classes.renewalUpcoming]: record.renew_status === 'Upcoming Renewal',
    });

    return <span className={textStyle}>{record.renew_status}</span>;
  }

  function formatLabelSize(label) {
    let size;

    switch (label) {
      case 'NEXT CHARGE DATE':
      case 'EFFECTIVE DATE':
        size = 1.8;
        break;
      case 'VERSION':
        size = 1.6;
        break;
      case 'PRODUCT':
        size = 0.9;
        break;
      case 'STATUS':
        size = 1.5;
        break;
      case 'RENEWAL STATUS':
        size = 2;
        break;
      default:
        size = 1.2;
    }

    return size;
  }

  function renderClaimButton(petId, isEstimate) {
    return (
      <CreateButton
        label={`CREATE ${isEstimate ? 'ESTIMATE' : 'CLAIM'}`}
        icon={null}
        sx={{
          border: '1px solid rgba(83, 104, 245, 1)',
          width: '100%',
          textAlign: 'center',
          marginBottom: isEstimate ? '16px' : 0,
        }}
        data-testid={`create-${isEstimate ? 'estimate' : 'claim'}-btn`}
        resource={RoutePaths.claims}
        state={{
          customerId: user_id,
          petId,
          isEstimate,
        }}
      />
    );
  }

  return petListData.map(petRecord => {
    const policy = getCurrentPolicy(petRecord?.policies);
    const managedBy =
      petRecord.enrollment_type === 'employer'
        ? 'plan(s) managed by employer'
        : 'managed by vendor';
    return (
      <Accordion
        sx={{
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          '&:before': {
            height: '0px',
            borderBottom: '1px',
          },
          '&.Mui-expanded': {
            margin: '0px',
            marginBottom: '12px',
          },
          marginBottom: '12px',
        }}
        key={petRecord.id}
        defaultExpanded={
          policy?.status === 'active' ||
          getCurrentWellness(petRecord)?.status === 'active'
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            alignItems: 'center',
            '&.Mui-expanded': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              padding: '0 16px',
            },
          }}
        >
          <Grid container direction='row' alignItems='center'>
            <Grid xs={2.5} item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <Avatar
                    sx={{
                      width: '80px',
                      height: '80px',
                    }}
                    alt={petRecord?.pet_photo?.location.split('/')[-1]}
                    src={
                      petRecord?.pet_photo?.location ||
                      AVATAR_PLACEHOLDERS[petRecord.species]
                    }
                  />
                </div>
                <div style={{ marginLeft: '8px' }}>
                  <Typography variant='h5'>{petRecord.name}</Typography>
                  <Typography
                    variant='subtitle2'
                    sx={{ color: '#5368F5' }}
                    onClick={() => redirect(`/pets/${petRecord.id}/show`)}
                  >
                    View Pet Details
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid xs={1.6} item>
              <Labeled
                sx={{
                  '& .RaLabeled-label': sxPetListStyle.labelCell,
                }}
                label='PET ID'
              >
                <FunctionField
                  label='ID'
                  variant='body1'
                  sx={{ color: 'black' }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  render={() => {
                    return (
                      <CopyToClipboard
                        text={petRecord.pumpkin_id}
                        onCopy={() => {
                          notify(`${petRecord.pumpkin_id} copied`, {});
                        }}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          {petRecord.pumpkin_id}
                        </span>
                      </CopyToClipboard>
                    );
                  }}
                />
              </Labeled>
            </Grid>
            <Grid xs={1} item>
              <Labeled
                sx={{
                  '& .RaLabeled-label': sxPetListStyle.labelCell,
                }}
                label='SPECIES'
              >
                <TextField
                  record={petRecord}
                  sx={{ color: 'black', textTransform: 'capitalize' }}
                  source='species'
                  variant='body1'
                />
              </Labeled>
            </Grid>
            <Grid xs={1} item>
              <Labeled
                sx={{
                  '& .RaLabeled-label': sxPetListStyle.labelCell,
                }}
                label='GENDER'
              >
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {GENDER_MAP[petRecord?.gender] || 'N/A'}
                </Typography>
              </Labeled>
            </Grid>
            <Grid xs={3} item>
              <Labeled
                sx={{
                  '& .RaLabeled-label': sxPetListStyle.labelCell,
                }}
                label='BREED'
              >
                <TextField
                  record={appendBreedName(petRecord)}
                  sx={{ color: 'black' }}
                  source='breed_name'
                  variant='body1'
                />
              </Labeled>
            </Grid>
            <Grid xs={1.4} item>
              <Labeled
                sx={{
                  '& .RaLabeled-label': sxPetListStyle.labelCell,
                }}
                label='AGE'
              >
                <TextField
                  record={petRecord}
                  sx={{ color: 'black' }}
                  source='age'
                  variant='body1'
                />
              </Labeled>
            </Grid>
            <Grid xs={1.5} item>
              {policy ? renderClaimButton(petRecord.id, true) : null}
              {renderClaimButton(petRecord.id, false)}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: '24px' }}>
          <Grid container direction='row' alignItems='center'>
            {PET_HEADERS.map(label => (
              <Grid xs={formatLabelSize(label)} item key={label}>
                <Typography
                  sx={{
                    color: '#6B717E',
                  }}
                  variant='overline'
                >
                  {label}
                </Typography>
              </Grid>
            ))}
          </Grid>

          {shouldShowWellness(petRecord) && petRecord.wellness ? (
            <Grid container direction='row' alignItems='baseline'>
              <Grid xs={0.9} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  Wellness
                </Typography>
                {punks1928WellnessEmployerBenefit &&
                petRecord.wellness.group_enrolled ? (
                  <Tooltip
                    placement='bottom'
                    title={`Wellness ${managedBy}: ${petRecord.third_party_entity_name}`}
                    className={classes.thirdParty}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        lineHeight: '20px',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                      fontSize='inherit'
                    />
                    <Typography
                      sx={{
                        lineHeight: '20px',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                      variant='subtitle2'
                    >
                      Third Party
                    </Typography>
                  </Tooltip>
                ) : null}
              </Grid>

              {/*STATUS*/}
              <Grid xs={1.5} item>
                <Typography
                  sx={{ color: '#000000', textTransform: 'capitalize' }}
                  variant='subtitle2'
                  className={
                    petRecord.wellness.status === 'cancelled'
                      ? classes.cancelledStatus
                      : null
                  }
                >
                  {petRecord.wellness.status}
                </Typography>
              </Grid>

              {/*COST*/}
              <Grid xs={1.2} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {formatCurrency(
                    isChargedAnnually
                      ? petRecord.wellness.price * 11
                      : petRecord.wellness.price,
                    { areCents: false }
                  )}
                </Typography>
              </Grid>

              {/*VERSION*/}
              <Grid xs={1.6} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {petRecord.wellness.tier}
                </Typography>
              </Grid>

              {/*EFFECTIVE DATE*/}
              <Grid xs={1.8} item>
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '0.875rem',
                  }}
                  variant='subtitle2'
                >
                  <DateFieldIgnoringZone
                    source='start_date'
                    record={petRecord.wellness}
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '22px',
                      letterspacing: '0.1px',
                    }}
                  />
                </Typography>
              </Grid>

              {/*NEXT CHARGE DATE*/}
              <Grid xs={1.8} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  <DateFieldIgnoringZone
                    source='wellness_next_charge_date'
                    record={petRecord}
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '22px',
                      letterspacing: '0.1px',
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {policy ? (
            <Grid container direction='row' alignItems='center'>
              <Grid xs={0.9} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  Insurance
                </Typography>
              </Grid>
              <Grid xs={1.5} item>
                <PolicyStatusField
                  hasCancellations={hasCancellations}
                  policy={policy}
                  userId={petRecord?.user_id}
                />
              </Grid>
              <Grid xs={1.2} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {formatCurrency(policy.premium, {
                    areCents: false,
                  })}
                </Typography>
              </Grid>
              <Grid xs={1.6} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {policy.program_id}
                </Typography>
              </Grid>
              <Grid xs={1.8} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  <DateFieldIgnoringZone
                    source='policy_effective_date'
                    record={policy}
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '22px',
                      letterspacing: '0.1px',
                    }}
                  />
                </Typography>
              </Grid>
              <Grid xs={1.8} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  <DateFieldIgnoringZone
                    source='next_charge_date'
                    record={petRecord}
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '22px',
                      letterspacing: '0.1px',
                    }}
                  />
                </Typography>
              </Grid>
              <Grid xs={1.2} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {policy.lapsed_since ? (
                    <DateFieldIgnoringZone
                      source='lapsed_since'
                      record={policy}
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '22px',
                        letterspacing: '0.1px',
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography
                  sx={{
                    color: '#000000',
                  }}
                  variant='subtitle2'
                >
                  {renewalStatus(petRecord)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <PetPlanItem petRecord={petRecord} />
          <PetPlanWrapper petRecord={petRecord} />
        </AccordionDetails>
      </Accordion>
    );
  });
}
