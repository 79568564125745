import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import RoutePaths from '../../../../routes';
import { customerDetailStyle } from '../customer-detail/customer-detail-styles';

function PolicyStatusField({ policy, hasCancellations, userId }) {
  const classes = customerDetailStyle();

  const isCancelled = policy?.status === 'cancelled';

  return policy ? (
    <div>
      <Typography
        sx={{ textTransform: 'capitalize' }}
        variant='subtitle2'
        className={isCancelled ? classes.cancelledStatus : null}
      >
        {policy.status}
      </Typography>
      {isCancelled && hasCancellations ? (
        <Link
          to={{ pathname: `/${RoutePaths.cancellationDetails}/${userId}/show` }}
          className={{ cursor: 'pointer' }}
          style={{
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: '500',
          }}
          onClick={e => e.stopPropagation()}
        >
          View Details
        </Link>
      ) : null}
    </div>
  ) : (
    ''
  );
}

PolicyStatusField.propTypes = {
  policy: PropTypes.shape({
    status: PropTypes.string,
  }),
  hasCancellations: PropTypes.bool,
  userId: PropTypes.string,
};

export default PolicyStatusField;
