import { useState } from 'react';
import {
  Form,
  RadioButtonGroupInput,
  required,
  TextInput,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { CLAIM_REIMBURSEMENT_STATUS_COLOR } from '@pumpkincare/claims';
import {
  REIMBURSEMENT_CANCELLATION_REASON,
  useCancellationReimbursement,
} from '@pumpkincare/reimbursements';

import { formatCurrency, parseAndFormatDate } from '../../../shared/utils';
import { cancellationConfirmModalStyle } from './cancellation-confirm-modal-style';

const REIMBURSEMENT_MAP = {
  insurance: 'Insurance',
  prevent: 'Preventive Essentials',
  wellness: 'Wellness',
};

function CancellationConfirmModal(props) {
  const { onClose, isOpen, reimbursement = {} } = props;
  const classes = cancellationConfirmModalStyle();
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isCancellationConfirmed, setConfirmCancellation] = useState();

  const cancellationId = reimbursement.cancellations?.find(
    cancellation => cancellation.status === 'open'
  )?.id;

  const { data: cancellationData = {} } = useCancellationReimbursement(
    reimbursement.id,
    cancellationId
  );

  function handleSubmitCancellationConfirm({ notes }) {
    const dataFormatted = { is_approved: isCancellationConfirmed };
    if (!isCancellationConfirmed) {
      dataFormatted.decision_note = notes;
    }

    update(
      'reimbursements',
      {
        id: `${reimbursement.id}/cancel/${cancellationId}`,
        data: dataFormatted,
      },
      {
        onSuccess: () => {
          onClose();
          refresh();
        },
        onError: error => {
          notify(
            `There was an error while confirming the cancellation request: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmitCancellationConfirm}>
        <DialogTitle variant='h6' className={classes.dialogTitleContainer}>
          Financial Review
        </DialogTitle>

        <DialogContent className={classes.modalContent}>
          <div className={classes.warningContainer}>
            <ErrorIcon className={classes.warningIcon} />
            <Typography className={classes.confirmationWarning}>
              Note: Only Finance Ops should confirm cancellations.
            </Typography>
          </div>

          <Typography className={classes.modalSubTitle}>DETAILS:</Typography>
          <Typography
            className={classes.detail}
          >{`Cancellation Requested By: ${cancellationData.requestor_ops_user_name}`}</Typography>
          <Typography
            className={classes.detail}
          >{`Request Date: ${parseAndFormatDate(
            cancellationData.created_at
          )}`}</Typography>
          <Typography className={classes.detail}>{`Reason: ${
            REIMBURSEMENT_CANCELLATION_REASON.find(
              reason => reason.id === cancellationData.reason
            )?.name
          }`}</Typography>

          <div className={classes.informationDiv}>
            <Typography className={classes.totalReimbursement}>
              {`${
                REIMBURSEMENT_MAP[cancellationData.reimbursement?.reimbursement_type]
              } Reimbursement: ${formatCurrency(
                cancellationData.reimbursement?.total_reimbursement_amount,
                { areCents: true }
              )}`}
            </Typography>
            <div className={classes.statusDiv}>
              <Typography className={classes.checkInfo}>
                {`Initiated on ${parseAndFormatDate(
                  cancellationData.reimbursement?.created_at
                )}`}
              </Typography>

              <Chip
                style={{
                  fontSize: '10px',
                  height: '20px',
                  color: '#000000',
                  backgroundColor:
                    CLAIM_REIMBURSEMENT_STATUS_COLOR[
                      cancellationData.reimbursement?.status
                    ] || '#FFFFFF',
                }}
                label={cancellationData.reimbursement?.status?.toUpperCase()}
              />
            </div>
            <Typography className={classes.checkInfo}>
              {`Check Number: ${cancellationData.reimbursement?.lob_check_number}`}
            </Typography>
          </div>
          <RadioButtonGroupInput
            source='confirm_cancellation'
            validate={required()}
            onChange={event => {
              setConfirmCancellation(event.target.value === 'true');
            }}
            style={{ marginTop: '0px' }}
            label='CONFIRM CANCELLATION:'
            choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
          />
          {isCancellationConfirmed === false ? (
            <TextInput
              multiline
              minRows={2}
              fullWidth
              variant='outlined'
              label='Notes'
              source='notes'
              resettable
            />
          ) : null}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={isLoading}
            type='submit'
            className={classes.updateButton}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

CancellationConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  reimbursement: PropTypes.object,
};

export default CancellationConfirmModal;
