import {
  AdminUserPoolGroup,
  CustomerSupportGroup,
  PumpkinOneLoginDevelopmentGroup,
  PumpkinOneLoginLocalGroup,
  PumpkinOneLoginMGADevGroup,
  PumpkinOneLoginMGAQAGroup,
  PumpkinOneLoginMGATrainingGroup,
  PumpkinOneLoginProductionGroup,
  PumpkinOneLoginStagingGroup,
  SuperAdminUserPoolGroup,
  SwivelChairGroup,
} from '../groups';
import { createMgaAdminResources } from './MgaAdminResources';

export function createResources() {
  const resources = createMgaAdminResources();
  return {
    defaultResources: resources,
    [PumpkinOneLoginLocalGroup]: resources,
    [PumpkinOneLoginDevelopmentGroup]: resources,
    [PumpkinOneLoginMGADevGroup]: resources,
    [PumpkinOneLoginMGAQAGroup]: resources,
    [PumpkinOneLoginMGATrainingGroup]: resources,
    [PumpkinOneLoginStagingGroup]: resources,
    [PumpkinOneLoginProductionGroup]: resources,
    [AdminUserPoolGroup]: resources,
    [SuperAdminUserPoolGroup]: resources,
    [SwivelChairGroup]: resources,
    [CustomerSupportGroup]: resources,
  };
}
