import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import { claimMGAStatuses, getClaimStatusColor } from '@pumpkincare/claims';

import { claimHeaderStyle } from './claim-header-style';

export default function ClaimHeader({
  opsData = [],
  isAssignOpsEditMode = false,
  onEditModeToggle = () => {},
  onOpsChange = () => {},
  disableComponents = false,
}) {
  const record = useRecordContext();
  const classes = claimHeaderStyle();

  return (
    <div className={classes.headerWrapper}>
      <Link
        to={`/customers/${record.customer_id}/show/claims`}
        className={classes.link}
      >
        <ArrowBackIosIcon className={classes.arrowBackIcon} />
        <Typography className={classes.claimNumber}>{record.id}</Typography>
      </Link>
      <Typography
        className={classes.status}
        style={{
          backgroundColor: getClaimStatusColor(record.status),
        }}
      >
        {claimMGAStatuses[record.status]}
      </Typography>
      <div className={classes.headerWrapper}>
        {isAssignOpsEditMode ? (
          <Autocomplete
            options={opsData}
            data-testid={'ops-autocomplete'}
            getOptionLabel={option => option.name}
            style={{ width: '250px', marginBottom: '8px' }}
            onChange={(event, values) => onOpsChange(values, record.id)}
            renderOption={(props, option) => (
              <div
                {...props}
                key={option.email}
                style={{ paddingLeft: '8px', display: 'block' }}
              >
                <div className={classes.opsName}>{option.name}</div>
                <div className={classes.opsEmail}>{option.email}</div>
              </div>
            )}
            renderInput={params => <TextField {...params} variant='outlined' />}
          />
        ) : (
          <Button onClick={() => onEditModeToggle()} disabled={disableComponents}>
            <AccountCircleIcon />
            <Typography className={classes.owner}>
              {record.current_assignee_name || 'Unassigned'}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
}

ClaimHeader.propTypes = {
  opsData: PropTypes.array,
  isAssignOpsEditMode: PropTypes.bool,
  onEditModeToggle: PropTypes.func,
  onOpsChange: PropTypes.func,
  disableComponents: PropTypes.bool,
};
