import { claimAttachmentTypes } from '../../constants/claimAttachmentDocumentTypes';
import { BOTH, INSURANCE, PREVENT } from '../../constants/claimTypes';
import {
  convertDollarsToCents,
  getPlanDetails,
  parseAndFormatDate,
} from '../../shared/utils';
import {
  APPROVED_PENDING_REIMBURSEMENT,
  APPROVED_PENDING_VET_REIMBURSEMENT,
  AUTHORITY_REQUEST,
  AUTHORITY_REQUEST_APPROVED,
  AUTHORITY_REQUEST_DENIED,
  DENIED,
  ESTIMATE_COMPLETE,
  HOLD_FOR_INFORMATION,
  HOLD_FOR_MEDICAL_RECORDS,
  HOLD_FOR_MEDICAL_RECORDS_2,
  HOLD_FOR_MEDICAL_RECORDS_3,
  INFORMATION_UPDATED,
  MEDICAL_RECORDS_UPDATED,
  MGA_APPROVED,
  MGA_DUPLICATE,
  MGA_IN_REVIEW,
  PENDING_AUTOMATION,
  PENDING_DENIAL,
  REIMBURSEMENT_REVIEW,
  UNVERIFIED,
  VERIFIED,
  WITHDRAWN,
} from './claim-status';
export const CLAIM_REIMBURSEMENT_STATUS_COLOR = {
  created: '#4DB5FF',
  open: '#4DB5FF',
  'in local area': 'rgba(62, 194, 143, 0.4)',
  cancelled: '#949494',
  deleted: '#949494',
  failed: '#EA0404',
  're-routed': '#EA0404',
  completed: '#3EC28F',
  closed: '#3EC28F',
  mailed: '#3EC28F',
  'processed for delivery': 'rgba(62, 194, 143, 0.8)',
  'in transit': 'rgba(62, 194, 143, 0.6)',
};

export const CLAIM_POLICY_PLAN_STATUS_COLOR = {
  active: '#3EC28F',
  pending: '#78D4B1',
  renew_pending: '#AEE5D0',
  lapsed: '#F2994A',
  cancel_pending: '#F1947F',
  cancelled: '#E84D2A',
  expired: '#949494',
  declined: '#F8CABF',
};

export function isClaimConsistent(record) {
  let errorMsg = '';
  let isConsistent = true;

  if (record.claim_type === PREVENT && record.pet_plan_id === null) {
    isConsistent = false;
    errorMsg =
      'Please update this claim with a Plan, then you will be able to create/edit Prevent claims';
  } else if (record.claim_type === INSURANCE && record.policy_id === null) {
    isConsistent = false;
    errorMsg =
      'Please update this claim with a Policy, then you will be able to create/edit Insurance claims';
  } else if (
    record.claim_type === BOTH &&
    (record.policy_id === null || record.pet_plan_id === null)
  ) {
    isConsistent = false;
    errorMsg =
      'Please update this claim with a Policy and a Plan, then you will be able to create/edit Insurance and Prevent claims';
  }

  return {
    isConsistent,
    errorMsg,
  };
}

export function isPreventClaimReimbursable(reimburseAmount = 0, approvedAmount = 0) {
  return approvedAmount > reimburseAmount;
}

export function isTotalReimbursementAmount(controllerProps) {
  //TO-DO DAWG-339 - Remove after the total reimbursement is deleted from the payload
  if (controllerProps.record && controllerProps.record.prevent_claim) {
    return (
      controllerProps.record.prevent_claim[0].total_reimbursement_amount !==
      undefined
    );
  }
  return false;
}

export function isFullyReimbursed(claimedAmount, approvedAmount) {
  return convertDollarsToCents(claimedAmount) === approvedAmount;
}

export function getACHStatus(fundingSource) {
  if (fundingSource.received && fundingSource.verified) {
    return 'Verified';
  }

  if (fundingSource.received && !fundingSource.verified) {
    return 'Failed';
  }

  if (!fundingSource.received && !fundingSource.verified) {
    return 'Unverified';
  }
}

export function getACHStatusColor(status) {
  let style = {};
  if (status === 'Verified') {
    style = { color: '#3EC28F', fontSize: '16px', fontWeight: '400' };
  } else if (status === 'Failed') {
    style = { color: '#FB5D3E', fontSize: '16px', fontWeight: '400' };
  } else {
    style = { color: '#F2994A', fontSize: '16px', fontWeight: '400' };
  }

  return style;
}

export function cbAppendClaimIdToState(state, record) {
  const newState = {
    record: { ...record },
    ...state,
  };
  return newState;
}

export function formatClaimAttachmentType(type) {
  return claimAttachmentTypes[type] || type;
}

export function transformReimbursementHistory(reimbursements) {
  return (
    reimbursements
      // create_at is an ISO8601 date and it is lexicographically ordered
      // and we want it from newest to oldest
      .sort((l, r) => -l.created_at.localeCompare(r.created_at))
      .map(item => {
        let line = item.reimbursement_type;
        if (item.reimbursement_type !== 'E-CHECK' && item.funding_source) {
          line += ` ending in ${item.funding_source.account_number}`;
        }
        return line;
      })
      .slice(0, 3)
  );
}

export function transformClaimsSummaries(summaries) {
  return summaries.map(summary => {
    if (summary.pet_plan) {
      summary.pet_plan.plan = {
        ...summary.pet_plan.plan,
        planDetails: getPlanDetails(summary.pet_plan.plan),
      };
    }

    return summary;
  });
}

export function transformPreventClaimProducts(products) {
  if (!products) {
    return [];
  }
  try {
    return products.flatMap(item => {
      return item.products.map(product => ({ category: item.category, ...product }));
    });
  } catch (e) {
    return [];
  }
}

export function transformDenialReasons(denialReasonData) {
  if (!denialReasonData) {
    return [];
  }
  return denialReasonData.flatMap(item => {
    return item.items.map(denialReasonItem => {
      return {
        category: item.title,
        ...denialReasonItem,
        name: `${denialReasonItem.code} - ${denialReasonItem.name}`,
      };
    });
  });
}

export function renderFakeLines(nbChildren, fn) {
  return Array.from({ length: nbChildren }, (_, key) => fn(key));
}

export function getPetPolicyPlanDetail(pet, planVersion) {
  const policy = pet.latest_pet_policy;
  const plan = pet.latest_pet_plan;

  let policyVersion = '';

  const programIdSplit = policy?.program_id ? policy.program_id.split('-') : [];
  if (programIdSplit.length === 3) {
    policyVersion = `${programIdSplit[2]} (${programIdSplit[0].toUpperCase()}-${
      programIdSplit[1]
    })`;
  } else {
    policyVersion = policy?.program_id;
  }

  const insuranceType =
    policy?.id && plan?.id
      ? `Insurance ${policyVersion} + Prevent ${planVersion}`
      : policy?.id
        ? `Insurance ${policyVersion}`
        : 'N/A';

  let policyIllnessEffectiveDate = policy?.policy_illness_coverage_start_date
    ? `(${parseAndFormatDate(policy.policy_illness_coverage_start_date)})`
    : '';

  return {
    status: policy?.status || 'N/A',
    detail: `${insuranceType} ${policyIllnessEffectiveDate}`,
  };
}

export function getClaimType(claim) {
  const isInsurance = claim.is_accident || claim.is_illness;
  const { is_prevent: isPrevent, is_wellness: isWellness } = claim;

  if (isInsurance && isPrevent) return 'Insurance + Prevent';
  if (isInsurance && isWellness) return 'Insurance + Wellness';
  if (isInsurance) return 'Insurance';
  if (isWellness) return 'Wellness';
  if (isPrevent) return 'Prevent';

  return 'Prevent';
}

export function getClaimStatusColor(status) {
  let color = '';

  switch (status) {
    case AUTHORITY_REQUEST:
    case AUTHORITY_REQUEST_APPROVED:
    case AUTHORITY_REQUEST_DENIED:
      color = '#F2994A';
      break;
    case HOLD_FOR_MEDICAL_RECORDS:
    case HOLD_FOR_MEDICAL_RECORDS_2:
    case HOLD_FOR_MEDICAL_RECORDS_3:
    case HOLD_FOR_INFORMATION:
      color = '#E34F2F';
      break;
    case MGA_DUPLICATE:
      color = '#FFD600';
      break;
    case MGA_IN_REVIEW:
      color = '#F8BC3B';
      break;
    case MEDICAL_RECORDS_UPDATED:
    case INFORMATION_UPDATED:
    case PENDING_DENIAL:
    case PENDING_AUTOMATION:
      color = '#FF8D94';
      break;
    case APPROVED_PENDING_VET_REIMBURSEMENT:
    case APPROVED_PENDING_REIMBURSEMENT:
    case MGA_APPROVED:
    case REIMBURSEMENT_REVIEW:
    case ESTIMATE_COMPLETE:
    case DENIED:
      color = '#4DB5FF';
      break;
    case UNVERIFIED:
      color = 'rgba(0, 0, 0, 0.25)';
      break;
    case VERIFIED:
      color = '#27CE56';
      break;
    case WITHDRAWN:
      color = '#FFF4BA';
      break;
    default:
      color = '#FFFFFF';
  }

  return color;
}

export function validateInsuranceRows(rows) {
  return rows.some(
    insLineItem =>
      !insLineItem.incident ||
      !insLineItem.service ||
      !insLineItem.decision ||
      insLineItem.decision === 'new' ||
      (insLineItem.decision === 'denied' &&
        (!insLineItem.denial_reason || insLineItem.denial_reason.length === 0))
  );
}

export function validatePreventRows(pepRows) {
  return pepRows.some(pepLineItem => {
    if (
      !pepLineItem.item_type ||
      !pepLineItem.decision ||
      pepLineItem.decision === 'new' ||
      (pepLineItem.decision === 'approved' &&
        (!pepLineItem.approved_qty || !pepLineItem.approved_amount)) ||
      (pepLineItem.decision === 'denied' &&
        (!pepLineItem.declined_amount || !pepLineItem.denial_reason))
    ) {
      return true;
    }
  });
}

export function getModifiedBy(user, opsUserList) {
  if (user && user.includes('ops:')) {
    const userId = user.substring(4);
    return (
      opsUserList.find(opsUser => opsUser.id === userId)?.name ||
      'Ops user not found'
    );
  } else {
    return user;
  }
}

export function isStatusBlockedForUpdate(currentStatus) {
  return [
    MGA_DUPLICATE,
    DENIED,
    WITHDRAWN,
    MGA_APPROVED,
    ESTIMATE_COMPLETE,
    APPROVED_PENDING_REIMBURSEMENT,
  ].some(status => status === currentStatus);
}

export function transformVisitsData(visitsData = []) {
  return visitsData.map(visit => ({
    ...visit,
    claimed_amount: `${Number(visit.claimed_amount) * 100}`,
  }));
}

export function calculateTotalPerInvoice(invoiceLineItems = []) {
  if (invoiceLineItems.length === 0) {
    return '$0.00';
  }

  const total = invoiceLineItems.reduce(
    (sum, item) => sum + (isNaN(item.total_amount) ? 0 : item.total_amount),
    0
  );

  return `$${total.toFixed(2)}`;
}

export function isClaimInAutomationStatus(
  status,
  fer4611CasFrontendChanges = false
) {
  return status === PENDING_AUTOMATION && fer4611CasFrontendChanges;
}
