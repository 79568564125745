import makeStyles from '@mui/styles/makeStyles';

const claimSideModalStyle = makeStyles(
  {
    modal: {
      position: 'absolute',
      top: '0px',
      minWidth: '500px',
      right: '0px',
      marginRight: '0px',
      marginTop: '0px',
      minHeight: '100%',
    },
  },
  { name: 'claim-side-modal' }
);

export { claimSideModalStyle };
