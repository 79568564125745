import { memo } from 'react';
import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import sanitizeRestProps from '../lib/sanitizeRestProps';
import { formatCurrency } from '../shared/utils/currencyUtils';
import { getRecordValue } from '../shared/utils/utils';

const CustomMoneyField = ({ className, source, record, ...rest }) => {
  const recordFromContext = useRecordContext();
  let recordAux = record
    ? getRecordValue(record, source)
    : getRecordValue(recordFromContext, source);

  return (
    <Typography
      component='span'
      body1='body1'
      className={className}
      {...sanitizeRestProps(rest)}
    >
      {formatCurrency(recordAux, { areCents: true })}
    </Typography>
  );
};

CustomMoneyField.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CustomMoneyField.defaultProps = {
  className: '',
};

export const MoneyField = memo(CustomMoneyField);
