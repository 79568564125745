import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../shared/utils';
import {
  getCurrentPolicy,
  getLatestPlan,
} from '../../../../shared/utils/productUtils';

function PlanCostField({ petRecord }) {
  const policy = getCurrentPolicy(petRecord?.policies);
  const plan = getLatestPlan(policy, petRecord);

  return plan ? (
    <Typography variant='subtitle2' sx={{ color: '#000000' }}>
      {formatCurrency(plan.cost, { areCents: false })}
    </Typography>
  ) : (
    ''
  );
}

PlanCostField.propTypes = {
  petRecord: PropTypes.shape({
    policies: PropTypes.arrayOf(
      PropTypes.shape({
        cost: PropTypes.string,
      })
    ),
  }),
};

export default PlanCostField;
