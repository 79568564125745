import { convertDollarsToCents } from '../../../shared/utils/currencyUtils';

export const getRefundsCreationPostBody = params => {
  const keys = Object.keys(params.data);
  const values = Object.values(params.data);
  const refund_list = [];

  const refundIdFieldPrefix = 'refund_id_';

  keys.forEach((key, index) => {
    const isRefundIdField = key.indexOf(refundIdFieldPrefix) === 0;

    if (isRefundIdField && values[index] > 0) {
      refund_list.push({
        invoice_item_id: key.substring(refundIdFieldPrefix.length, key.length),
        refund_amount: convertDollarsToCents(values[index]),
      });
    }
  });

  const postBody = {
    refund_items: refund_list,
    refund_reason: params.data.refund_reason || '',
  };

  return postBody;
};
