import { Typography } from '@mui/material';

import { claimDetailCardStyle } from './claim-detail-card-style';

export default function ClaimDetailCard(props) {
  const { title, detail, icon } = props;
  const classes = claimDetailCardStyle();

  return (
    <div className={classes.card}>
      {icon}
      <div className={classes.detailWrapper}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.detail}>{detail}</Typography>
      </div>
    </div>
  );
}
