import { useGetList } from 'react-admin';

export const SERVICES_QUERY = 'claims/service';

export function useServices() {
  return useGetList(SERVICES_QUERY, {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'name', order: 'ASC' },
  });
}
