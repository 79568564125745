import makeStyles from '@mui/styles/makeStyles';

const editPetModalStyle = makeStyles({
  // overall modal styles
  root: {
    backgroundColor: 'rgb(0, 125, 255, 0.08)',
  },
  paper: { backgroundColor: '#FAFAFA', maxWidth: '604px', width: '83vw' },
  modalTitle: {
    fontSize: '20px',
    lineHeight: '32px',
    color: '#2A41DE',
    fontWeight: '500',
  },

  modalContent: {
    padding: '16px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0 48px',
  },

  modalActions: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '0 16px',
    padding: '0px 32px 24px',
  },

  autoComplete: {
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    '& .MuiFormLabel-root > span > span': {
      display: 'none',
    },

    asterisk: {
      color: '#B00020',
    },
  },
});

export { editPetModalStyle };
