import { CreateButton, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import RoutePaths from '../routes';

const AddEditPetPlanButton = ({ petRecord, hasValidPlan, style }) => {
  const record = petRecord || useRecordContext();
  let petPlan = null;

  if (hasValidPlan) {
    petPlan = { ...record.latest_plan };
  }

  return hasValidPlan ? null : (
    <CreateButton
      sx={style}
      label='ADD PREVENT'
      resource={RoutePaths.petPlans}
      state={{
        record: petPlan,
        pet: record,
      }}
    />
  );
};

AddEditPetPlanButton.defaultProps = {
  petRecord: null,
  hasValidPlan: false,
  style: {},
};

AddEditPetPlanButton.propTypes = {
  petRecord: PropTypes.shape({
    latest_plan: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  hasValidPlan: PropTypes.bool,
  style: PropTypes.object,
};

export default AddEditPetPlanButton;
