import { AUTH_SETTINGS } from '@pumpkincare/config';

import {
  cognitoAuthProviderFunctionMap,
  cognitoGetCurrentUser,
  cognitoLogin,
  cognitoUserPoolGroups,
} from './auth.cognito';
import { buildAuthProvider, buildLoginForm } from './auth.common';
import {
  oidcAuthProviderFunctionMap,
  oidcGetCurrentUser,
  oidcLogin,
  oidcUserPoolGroups,
} from './auth.oidc';

const AuthProvider = AUTH_SETTINGS.useOidcLib
  ? buildAuthProvider(oidcAuthProviderFunctionMap)
  : buildAuthProvider(cognitoAuthProviderFunctionMap);
const LoginForm = AUTH_SETTINGS.useOidcLib
  ? buildLoginForm(oidcLogin)
  : buildLoginForm(cognitoLogin);

const getCurrentUser = AUTH_SETTINGS.useOidcLib
  ? oidcGetCurrentUser
  : cognitoGetCurrentUser;

const getUserGroups = AUTH_SETTINGS.useOidcLib
  ? oidcUserPoolGroups
  : cognitoUserPoolGroups;

export { AuthProvider, LoginForm, getCurrentUser, getUserGroups };
