import makeStyles from '@mui/styles/makeStyles';

const wellnessOptionsStyle = makeStyles(
  {
    innerGrid: {
      maxWidth: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      justifyContent: 'center',
      gap: '24px',
      padding: '16px 0',
      marginTop: '0',
      marginLeft: '0',
    },
    planItem: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '32px',
      marginBottom: '8px',
      '& span': {
        maxWidth: '186px',
        '&:last-child': {
          whiteSpace: 'nowrap',
        },
      },
    },
    priceItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  { name: 'wellness-options-style' }
);

export { wellnessOptionsStyle };
