import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { CardContentInner } from 'ra-ui-materialui';

const GridLayout = props => {
  const { children, ...rest } = props;

  return (
    <CardContentInner>
      {Children.map(children, child => cloneElement(child, rest))}
    </CardContentInner>
  );
};

GridLayout.propTypes = {
  children: PropTypes.node,
};

export default GridLayout;
