import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  Pagination,
  TextField,
  TopToolbar,
  useListContext,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { Box, Button, CircularProgress } from '@mui/material';

import useBooleanInput from '../../../hooks/useBooleanInput';
import OpsUserFilter from './ops-user-filter';

function ListActions() {
  return (
    <Box width='100%'>
      <TopToolbar style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <OpsUserFilter />
        <CreateButton />
      </TopToolbar>
    </Box>
  );
}

function DatagridWrapper(props) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { isLoading: isLoadingContext } = useListContext();

  const [isLoading, toggleLoading] = useBooleanInput();

  function handleOnActivateInactivateButtonClick(id, email) {
    toggleLoading();
    update(
      'ops-users',
      {
        id: id,
        data: {
          email: email,
        },
      },
      {
        onSuccess: () => {
          toggleLoading();
          refresh();
        },
        onError: error => {
          toggleLoading();
          notify(`${error.message}`, {
            type: 'error',
          });
        },
      }
    );
  }

  return isLoadingContext ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props}>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='email' />
      <FunctionField
        label='Status'
        render={(record = {}) => (!!record.active ? 'Active' : 'Inactive')}
      />
      <FunctionField
        label=''
        render={(record = {}) =>
          !!record.active ? (
            <Button
              disabled={isLoading}
              variant='contained'
              onClick={() =>
                handleOnActivateInactivateButtonClick(record.id, record.email)
              }
            >
              Disable
            </Button>
          ) : (
            <Button
              disabled={isLoading}
              variant='contained'
              onClick={() =>
                handleOnActivateInactivateButtonClick(record.id, record.email)
              }
            >
              Activate
            </Button>
          )
        }
      />
    </Datagrid>
  );
}

export default function OpsUserList() {
  return (
    <List
      actions={<ListActions />}
      exporter={false}
      sort={{ field: 'name', order: 'asc' }}
      filterDefaultValues={{ active: 1 }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <DatagridWrapper />
    </List>
  );
}
