import makeStyles from '@mui/styles/makeStyles';

const claimHeaderStyle = makeStyles(
  {
    headerWrapper: { display: 'flex', alignItems: 'center' },
    link: {
      textDecoration: 'none',
      display: 'flex',
      marginRight: '24px',
    },
    arrowBackIcon: {
      color: '#2196F3',
      width: '12px',
    },
    claimNumber: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#2196F3',
    },
    status: {
      borderRadius: '4px',
      padding: '1.5px 8px 2.5px',
      fontSize: '11px',
      fontWeight: '700',
      lineHeight: '20px',
      marginRight: '24px',
      height: '20px',
    },
    owner: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      marginLeft: '8px',
    },
    opsName: {
      color: '#000000',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    opsEmail: {
      color: '#767676',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
  { name: 'claim-header' }
);

export { claimHeaderStyle };
