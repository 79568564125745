import { FunctionField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CustomUrlField from '../CustomUrlField';
import SimpleTableLayout from '../SimpleTableLayout';

const useStyles = makeStyles(
  {
    tableSize: {
      width: '1200px',
      paddingLeft: '0px',
    },
    label: {
      fontSize: '10px',
      fontWeight: '500',
    },
    gladlyLinkStyle: {
      fontWeight: '700',
      fontSize: '14px',
      color: '#0B33E3',
      lineHeight: '1.5',
    },
  },
  { name: 'user-info-card' }
);

export default function UserInfoCard(props) {
  const classes = useStyles();

  const userRecord = useRecordContext();

  const labelStyle = { '& .RaLabeled-label': { fontSize: '8px' } };
  const fontStyle = { fontWeight: '700', fontSize: '14px' };

  return (
    <SimpleTableLayout
      className={classes.tableSize}
      labelClassName={classes.label}
      title={`${userRecord.first_name} ${userRecord.last_name}`}
      {...props}
    >
      <Labeled sx={labelStyle} label='FIRST NAME'>
        <TextField source='first_name' style={fontStyle} />
      </Labeled>

      <Labeled sx={labelStyle} label='LAST NAME'>
        <TextField source='last_name' style={fontStyle} />
      </Labeled>

      <Labeled sx={labelStyle} label='PHONE NUMBER'>
        <TextField source='phone' style={fontStyle} />
      </Labeled>
      <Labeled sx={labelStyle} label='EMAIL'>
        <TextField source='email' style={fontStyle} />
      </Labeled>

      <Labeled sx={labelStyle} label='PKN NUMBER'>
        <TextField source='pumpkin_id' style={fontStyle} />
      </Labeled>

      <Labeled sx={labelStyle} label='ZTS SAP NUMBER'>
        <TextField source='zoetis_sap_id' style={fontStyle} />
      </Labeled>

      <Labeled sx={labelStyle} label='BILLING FREQUENCY'>
        <FunctionField
          style={fontStyle}
          render={(record = {}) => {
            return (
              <Typography style={{ fontSize: '14px', fontWeight: '700' }}>
                {record.is_charged_annually ? 'Annual' : 'Monthly'}
              </Typography>
            );
          }}
        />
      </Labeled>

      <Labeled sx={labelStyle} label='GLADLY LINK'>
        <CustomUrlField
          linkText='Open in Gladly'
          source='gladly_customer_url'
          target='_blank'
          rel='noopener noreferrer'
          className={classes.gladlyLinkStyle}
        />
      </Labeled>
    </SimpleTableLayout>
  );
}
