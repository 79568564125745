import IconAdd from '@mui/icons-material/Add';
import IconClear from '@mui/icons-material/Clear';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  ALL_MEDICAL_RECORDS_REQUEST,
  CLAIM_INVOICE_REQUEST,
  MEDICAL_RECORD_REQUEST,
} from '@pumpkincare/medical-records';

import { validateDateYYYYMMDD } from '../../../../../shared/utils';
import { recordsDocumentsSelectorStyle } from './records-documents-selector-style';

// this component was not built to be reusable, but just so that records-request-modal is readable
function RecordsDocumentsSelector({
  classes: propClasses,
  hasSubmittedForm,
  allMedicalRecords,
  onAllMedicalRecords,
  specificRecords,
  onSpecificRecords,
  invoice,
  onInvoice,
}) {
  // a cheap merge, not like real Material UI class merges
  const classes = {
    ...recordsDocumentsSelectorStyle(),
    ...propClasses,
  };

  function onSpecificRecordValue(rowIndex, field, value) {
    onSpecificRecords(prevState => {
      const newState = [...prevState];
      newState[rowIndex] = {
        ...newState[rowIndex],
        [field]: value,
      };
      return newState;
    });
  }

  function removeSpecificRecord(rowIndex) {
    onSpecificRecords(prevState => {
      const newState = [...prevState];
      newState.splice(rowIndex, 1);
      return newState;
    });
  }

  function addAnotherRecord() {
    onSpecificRecords(prevState => [
      ...prevState,
      { type: MEDICAL_RECORD_REQUEST, date: '', description: '' },
    ]);
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(allMedicalRecords.type)}
            onClick={() =>
              onAllMedicalRecords(prevState =>
                !prevState.type
                  ? { type: ALL_MEDICAL_RECORDS_REQUEST, date: '' }
                  : {}
              )
            }
          />
        }
        label='All Medical Records'
        className={classes.checkboxLabel}
      />
      {allMedicalRecords.type ? (
        <div className={classes.subCheckbox}>
          <FormControlLabel
            classes={{ root: classes.textLabel }}
            labelPlacement='top'
            label='Start Date (Optional)'
            control={
              <TextField
                type='date'
                id='all-medical-records-start-date'
                value={allMedicalRecords.date}
                onChange={e =>
                  onAllMedicalRecords(prevState => ({
                    ...prevState,
                    date: e.target.value,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                size='medium'
                fullWidth
              />
            }
          />
          <Typography variant='caption' className={classes.extraInfo}>
            To request all records since a pet's first visit, leave this field blank
          </Typography>
        </div>
      ) : null}

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(specificRecords.length)}
            onClick={() =>
              onSpecificRecords(prevState =>
                !prevState.length
                  ? [{ type: MEDICAL_RECORD_REQUEST, date: '', description: '' }]
                  : []
              )
            }
          />
        }
        label='Specific Record(s)'
        className={classes.checkboxLabel}
      />
      {specificRecords.length ? (
        <div className={classes.subCheckbox}>
          {specificRecords.map((record, index) => (
            <div key={`specific-record-${index}`} className={classes.specificRecord}>
              <FormControlLabel
                classes={{
                  root: classNames(classes.textLabel, classes.recordDate),
                }}
                labelPlacement='top'
                label='Date'
                control={
                  <TextField
                    type='date'
                    id={`specific-record-${index}-date`}
                    value={record.date}
                    onChange={e =>
                      onSpecificRecordValue(index, 'date', e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    FormHelperTextProps={{
                      classes: { root: classes.formHelperText },
                    }}
                    error={hasSubmittedForm && !validateDateYYYYMMDD(record.date)}
                    helperText={
                      hasSubmittedForm && !validateDateYYYYMMDD(record.date)
                        ? 'Enter a valid date (MM/DD/YYYY)'
                        : ''
                    }
                    variant='outlined'
                    size='medium'
                    fullWidth
                    required
                  />
                }
              />

              <FormControlLabel
                classes={{
                  root: classNames(classes.recordDescription, classes.textLabel),
                }}
                labelPlacement='top'
                label='Record Description'
                control={
                  <TextField
                    id={`specific-record-${index}-description`}
                    value={record.description}
                    onChange={e =>
                      onSpecificRecordValue(index, 'description', e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    FormHelperTextProps={{
                      classes: { root: classes.formHelperText },
                    }}
                    error={hasSubmittedForm && !record.description}
                    helperText={
                      hasSubmittedForm && !record.description
                        ? 'Enter a record description'
                        : ''
                    }
                    variant='outlined'
                    size='medium'
                    fullWidth
                    required
                  />
                }
              />

              {specificRecords.length > 1 ? (
                <Button
                  aria-label={`clear specific record - row ${index}`}
                  className={classes.buttonClear}
                  onClick={() => removeSpecificRecord(index)}
                >
                  <IconClear className={classes.iconClear} />
                </Button>
              ) : null}
            </div>
          ))}

          <button className={classes.addRecordButton} onClick={addAnotherRecord}>
            <IconAdd className={classes.iconAdd} />
            ADD ANOTHER
          </button>
        </div>
      ) : null}

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(invoice.type)}
            onClick={() =>
              onInvoice(prevState =>
                !prevState.type ? { type: CLAIM_INVOICE_REQUEST, date: '' } : {}
              )
            }
          />
        }
        label='Paid Invoice'
        className={classes.checkboxLabel}
      />
      {invoice.type ? (
        <div className={classes.subCheckbox}>
          <FormControlLabel
            classes={{ root: classes.textLabel }}
            labelPlacement='top'
            label='Invoice Date'
            control={
              <TextField
                type='date'
                id='invoice-start-date'
                value={invoice.date}
                onChange={e =>
                  onInvoice(prevState => ({
                    ...prevState,
                    date: e.target.value,
                  }))
                }
                InputLabelProps={{ shrink: true }}
                FormHelperTextProps={{
                  classes: { root: classes.formHelperText },
                }}
                error={hasSubmittedForm && !validateDateYYYYMMDD(invoice.date)}
                helperText={
                  hasSubmittedForm && !validateDateYYYYMMDD(invoice.date)
                    ? 'Enter a valid date (MM/DD/YYYY)'
                    : ''
                }
                variant='outlined'
                size='medium'
                fullWidth
                required
              />
            }
          />
        </div>
      ) : null}
    </>
  );
}

RecordsDocumentsSelector.propTypes = {
  classes: PropTypes.object,
  hasSubmittedForm: PropTypes.bool.isRequired,
  allMedicalRecords: PropTypes.object.isRequired,
  onAllMedicalRecords: PropTypes.func.isRequired,
  specificRecords: PropTypes.array.isRequired,
  onSpecificRecords: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  onInvoice: PropTypes.func.isRequired,
};

export default RecordsDocumentsSelector;
