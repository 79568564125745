import { useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { PlanPolicyDetailCard } from '@pumpkincare/shared';

import { parseAndFormatDate } from '../../../shared/utils';
import { isPetPlanInCancelledStatus } from '../../../shared/utils/PetPlanUtils';
import { getFullPercentage } from '../../../shared/utils/petUtils';
import PolicyChangeRadioButtonLabel from '../policy-change-radio-button-label';
import { groupMidtermData } from '../utils/pet-utils';
import { policyChangeRequestStyle } from './policy-change-request-style';

function getCoinsurancesArray(groupedChangeData) {
  return Object.keys(groupedChangeData)
    .map(key => ({
      value: key,
      display: getFullPercentage(key),
    }))
    .sort((a, b) => a.display.localeCompare(b.display));
}

function getPetDataByType(isMidterm, petData) {
  return isMidterm ? petData.latest_pet_policy : petData?.renewal_pending_policy;
}

function getPolicyDates(petDataByType) {
  const policyStartDate = petDataByType?.policy_effective_date
    ? parseAndFormatDate(petDataByType?.policy_effective_date)
    : 'N/A';
  const policyEndDate = petDataByType?.policy_end_date
    ? parseAndFormatDate(petDataByType?.policy_end_date)
    : 'N/A';
  return `Policy Effective Dates: ${policyStartDate} - ${policyEndDate}`;
}

function getPepDataByType(isMidterm, petData) {
  return isMidterm ? petData.latest_pet_plan : petData.renewal_pending_pet_plan;
}

function getPlanDates(pepDataByType) {
  const planStartDate = pepDataByType?.plan_effective_date
    ? parseAndFormatDate(pepDataByType.plan_effective_date)
    : 'N/A';
  const planEndDate = pepDataByType?.plan_end_date
    ? parseAndFormatDate(pepDataByType.plan_end_date)
    : 'N/A';
  return `Plan Effective Dates: ${planStartDate} - ${planEndDate}`;
}

function PolicyChangeRequest({
  changeData = [],
  petData = {},
  onRatesChange,
  changeSelectedData = {},
  isMidterm = false,
}) {
  const classes = policyChangeRequestStyle();

  const groupedChangeData = groupMidtermData(changeData);
  const coinsurancesArray = getCoinsurancesArray(groupedChangeData);

  const [coinsurance, setCoinsurance] = useState(
    changeSelectedData?.coinsurance ??
      coinsurancesArray[coinsurancesArray.length - 1].value
  );

  const petDataByType = getPetDataByType(isMidterm, petData);
  const policyDates = getPolicyDates(petDataByType);
  const pepDataByType = getPepDataByType(isMidterm, petData);
  const planDates = getPlanDates(pepDataByType);

  return (
    <div>
      <Typography className={classes.ratesLabel}>Policy & Plan Term:</Typography>
      <div style={{ display: 'flex', marginBottom: '24px' }}>
        <PlanPolicyDetailCard
          title={`INSURANCE ${petData.state} (V${
            isMidterm ? petData.version : petDataByType?.version
          })`}
          status={petDataByType?.status}
          effectiveDatePeriod={policyDates}
          enrollmentDate={
            petData.enrollment_date
              ? parseAndFormatDate(petData.enrollment_date)
              : 'N/A'
          }
          coverageStartDate={
            petData.coverage_start_date
              ? parseAndFormatDate(petData.coverage_start_date)
              : 'N/A'
          }
          utilization={petDataByType?.utilization}
        />
        {(pepDataByType && !isPetPlanInCancelledStatus(pepDataByType.status)) ||
        petData.renewal_pending_pet_plan ? (
          <PlanPolicyDetailCard
            title={`PREVENTIVE ESSENTIALS (${
              isMidterm
                ? petData.pet_plan_version
                : pepDataByType.plan?.version_number || ''
            })`}
            status={pepDataByType.status}
            effectiveDatePeriod={planDates}
            enrollmentDate={
              petData.pet_plan_enrollment_date
                ? parseAndFormatDate(petData.pet_plan_enrollment_date)
                : 'N/A'
            }
            coverageStartDate={
              petData.pet_plan_coverage_start_date
                ? parseAndFormatDate(petData.pet_plan_coverage_start_date)
                : 'N/A'
            }
          />
        ) : null}
      </div>

      <Typography className={classes.ratesLabel}>Rates</Typography>
      <FormControl sx={{ width: '100%' }} component='fieldset'>
        <div className={classes.displayFlex}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <Typography className={classes.weight500}>Reimbursement Rate</Typography>
          </Box>

          <RadioGroup
            name='response-type'
            row
            style={{ color: '#007DFF' }}
            value={coinsurance}
            onChange={e => {
              setCoinsurance(e.target.value);
            }}
          >
            {coinsurancesArray.map(coinsurance => (
              <FormControlLabel
                key={`coinsurance-${coinsurance.value}`}
                value={coinsurance.value}
                control={
                  <Radio
                    sx={{
                      color: '#007DFF',
                      '&.Mui-checked': {
                        color: '#007DFF',
                      },
                    }}
                  />
                }
                label={coinsurance.display}
              />
            ))}
          </RadioGroup>
        </div>
        <Divider
          sx={{ width: '100%', marginTop: '5px', marginBottom: '25px' }}
          flexItem
        />
        <RadioGroup
          className={classes.ratesRadioGroupButton}
          name='rates'
          value={changeSelectedData?.id}
          onChange={event => {
            onRatesChange(
              changeData.find(record => event.target.value === record.id)
            );
          }}
        >
          {coinsurance
            ? Object.keys(groupedChangeData[coinsurance]).map(deductible => (
                <div
                  key={`key-${coinsurance}-${deductible}`}
                  className={classes.ratesCard}
                >
                  <Typography className={classes.deductibleTitle}>
                    ${deductible} Deductible
                  </Typography>
                  {groupedChangeData[coinsurance][deductible].map(changeOption => {
                    return (
                      <FormControlLabel
                        key={changeOption.id}
                        className={classes.radioButton}
                        value={changeOption.id}
                        control={<Radio />}
                        disabled={!changeOption.allowed}
                        data-testid={`radio-${changeOption.id}`}
                        label={<PolicyChangeRadioButtonLabel {...changeOption} />}
                      />
                    );
                  })}
                </div>
              ))
            : null}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

PolicyChangeRequest.propTypes = {
  changeData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      coinsurance: PropTypes.string.isRequired,
      deductible: PropTypes.string.isRequired,
      allowed: PropTypes.bool.isRequired,
    })
  ),
  petData: PropTypes.shape({
    state: PropTypes.string,
    version: PropTypes.string,
    latest_pet_policy: PropTypes.object,
    renewal_pending_policy: PropTypes.object,
    latest_pet_plan: PropTypes.object,
    renewal_pending_pet_plan: PropTypes.object,
    enrollment_date: PropTypes.string,
    coverage_start_date: PropTypes.string,
    pet_plan_version: PropTypes.string,
    pet_plan_enrollment_date: PropTypes.string,
    pet_plan_coverage_start_date: PropTypes.string,
  }),
  onRatesChange: PropTypes.func,
  changeSelectedData: PropTypes.shape({
    id: PropTypes.string,
    coinsurance: PropTypes.string,
  }),
  isMidterm: PropTypes.bool,
};

export default PolicyChangeRequest;
