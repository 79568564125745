import { Children, cloneElement } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Button,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Add, Delete } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

import { usePetBreedsBySpecies } from '@pumpkincare/pets';
import { ProcessingStep, ToggleButtonInput } from '@pumpkincare/shared';
import { useCheckEmail } from '@pumpkincare/users';

import { PET_AGE_OPTIONS } from '../../../../../constants/quoteFlow';
import RoutePaths from '../../../../../routes';
import { postCustomerQuote } from '../../../../quotes/quoteFlow/service/quoteFlowService';

function ToolTipForDisabled({
  title,
  style = {},
  placement = 'top',
  disabled,
  children,
  ...rest
}) {
  return Children.map(children, child => {
    const clonedChild = cloneElement(child, {
      disabled,
      sx: {
        ...child.props.sx,
        ...style,
      },
      ...rest,
    });

    return disabled ? (
      <Tooltip title={title} placement={placement}>
        <span style={style}>{clonedChild}</span>
      </Tooltip>
    ) : (
      clonedChild
    );
  });
}

function WellnessQuoteTabUserPets({ disableAddRemovePets }) {
  const formContext = useFormContext();
  const navigate = useNavigate();

  const { data: dogBreeds } = usePetBreedsBySpecies('dogs');
  const { data: catBreeds } = usePetBreedsBySpecies('cats');

  const breedChoices = { dog: dogBreeds, cat: catBreeds };

  const {
    isError: isEmailError,
    helperText: emailHelperText,
    checkEmail,
    ExistingCustomerModal,
    isAddAPet,
    toggleExistingCustomerModal,
  } = useCheckEmail();

  function handleAddWellness(user) {
    formContext.setValue('first_name', user.first_name);
    formContext.setValue('last_name', user.last_name);
    formContext.setValue('email', user.email);
    formContext.setValue(
      'billed_annually',
      user.is_charged_annually ? 'true' : 'false'
    );

    toggleExistingCustomerModal();
  }

  function handleAddInsurance(user) {
    return postCustomerQuote({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      policy_zipcode: user.rating_address.zipcode,
      vet: user.vets.length > 0 ? user.vets[0] : null,
      vet_id: user.vets.length > 0 ? user.vets[0].id : null,
    }).then(response => {
      toggleExistingCustomerModal(false);
      return navigate(`/${RoutePaths.quotes}/create/1`, {
        state: {
          is_add_a_pet: true,
          user,
          quote: {
            ...response.data,
            existingUserDetails: user,
          },
        },
      });
    });
  }

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <div
            style={{
              background: 'white',
              padding: '40px 8px',
              width: '100%',
            }}
          >
            <ProcessingStep
              steps={['Details', 'Choose Plan', 'Checkout']}
              currentStep={1}
              style={{
                width: '63vw',
                minWidth: '500px',
                margin: '0 auto 68px',
              }}
            />

            <Typography variant='h5'>1. Customer Info</Typography>

            <Grid
              container
              justifyContent='space-between'
              sx={{ gap: '24px', marginTop: '24px' }}
            >
              <TextInput
                source='first_name'
                label='First Name'
                variant='outlined'
                size='medium'
                sx={{ flex: 1 }}
                disabled={isAddAPet}
              />
              <TextInput
                source='last_name'
                label='Last Name'
                variant='outlined'
                size='medium'
                sx={{ flex: 1 }}
                disabled={isAddAPet}
              />
              <TextInput
                onChange={checkEmail}
                source='email'
                label='Email'
                variant='outlined'
                size='medium'
                error={isEmailError}
                helperText={emailHelperText}
                sx={{ flex: 1 }}
                disabled={isAddAPet}
              />
            </Grid>

            <Typography variant='h5' sx={{ marginTop: '32px' }}>
              2. Pet Details
            </Typography>

            <Grid
              container
              justifyContent='space-between'
              sx={{ gap: '24px', marginTop: '24px' }}
            >
              <ArrayInput source='quote_pets' label='' fullWidth defaultValue={[{}]}>
                <SimpleFormIterator
                  getItemLabel={idx => `PET ${idx + 1}`}
                  disableReordering
                  disableClear
                  disableRemove={(formData.quote_pets?.length || 0) < 2}
                  sx={{
                    '& .RaSimpleFormIterator-line': {
                      flexWrap: 'wrap',
                      borderBottom: 'none',
                      '& > p.MuiTypography-root': {
                        flex: '100%',
                        marginBottom: '12px',
                        marginTop: '24px',
                      },
                      '& .RaSimpleFormIterator-form': {
                        width: 'calc(100% - 52px)',
                      },
                      '& .RaSimpleFormIterator-action': {
                        visibility: 'visible',
                        alignSelf: 'center',
                        marginTop: '-18px',
                        marginLeft: '4px',
                        '& button': {
                          padding: '12px',
                        },
                      },
                    },
                  }}
                  removeButton={
                    <ToolTipForDisabled
                      disabled={disableAddRemovePets}
                      style={{
                        paddingTop: '24px',
                      }}
                      title='To remove pets, you need to start a new quote'
                    >
                      <IconButton
                        size='large'
                        aria-label='remove-pet'
                        sx={{ color: '#D32F2F' }}
                      >
                        <Delete />
                      </IconButton>
                    </ToolTipForDisabled>
                  }
                  addButton={
                    <ToolTipForDisabled
                      disabled={disableAddRemovePets}
                      title='To add a new pet, you need to start a new quote'
                    >
                      <Button
                        sx={{
                          fontSize: '14px',
                          fontWeight: '700',
                          color: '#5368F5',
                          borderColor: '#5368F5',
                          width: '182px',
                          '&:hover': {
                            color: '#2A41DE',
                            borderColor: '#2A41DE',
                          },
                        }}
                        size='large'
                        color='primary'
                        variant='outlined'
                      >
                        <>
                          <Add sx={{ marginRight: '2px' }} />
                          ADD A PET
                        </>
                      </Button>
                    </ToolTipForDisabled>
                  }
                >
                  <FormDataConsumer>
                    {({ getSource, scopedFormData: pet }) => {
                      const id = uuidV4();
                      const breed =
                        breedChoices[pet?.species]?.find(
                          choice => choice.code === pet.breed_code
                        ) || {};

                      return (
                        <Grid container gap='20px 16px' wrap='nowrap'>
                          <TextInput
                            source={getSource('id')}
                            defaultValue={id}
                            sx={{ display: 'none' }}
                          />
                          <TextInput
                            source={getSource('pet_name')}
                            label='Pet Name'
                            variant='outlined'
                            size='medium'
                            sx={{ flex: '0 0 150px' }}
                          />

                          <ToggleButtonInput
                            inputOptions={{
                              source: getSource('species'),
                            }}
                            toggleOptions={[
                              { value: 'cat', label: 'CAT' },
                              { value: 'dog', label: 'DOG' },
                            ]}
                          />

                          <AutocompleteInput
                            source={getSource('pet_age')}
                            label='Age'
                            variant='outlined'
                            size='medium'
                            sx={{ flex: '100%' }}
                            choices={PET_AGE_OPTIONS}
                            optionText='label'
                            optionValue='value'
                          />

                          <ToolTipForDisabled
                            title='Please select a species first'
                            disabled={!pet?.species}
                            style={{ flex: '100%' }}
                          >
                            <AutocompleteInput
                              source={getSource('breed_code')}
                              label='Breed'
                              choices={breedChoices[pet?.species]}
                              optionText='name'
                              optionValue='code'
                              translateChoice={false}
                              fullWidth
                              variant='outlined'
                              size='medium'
                            />
                          </ToolTipForDisabled>

                          <TextInput
                            source={getSource('breed_name')}
                            label='Breed Name'
                            defaultValue={breed.name || ''}
                            sx={{ display: 'none' }}
                          />

                          <TextInput
                            source={getSource('breed_type')}
                            label='Breed Type'
                            defaultValue={breed.type || ''}
                            sx={{ display: 'none' }}
                          />

                          <ToggleButtonInput
                            inputOptions={{
                              source: getSource('pet_gender'),
                            }}
                            toggleOptions={[
                              { value: 'male', label: 'MALE' },
                              { value: 'female', label: 'FEMALE' },
                            ]}
                          />
                        </Grid>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </div>

          <ExistingCustomerModal
            onAddWellness={handleAddWellness}
            onAddInsurance={handleAddInsurance}
          />
        </>
      )}
    </FormDataConsumer>
  );
}

WellnessQuoteTabUserPets.propTypes = {
  disableAddRemovePets: PropTypes.bool,
};

export default WellnessQuoteTabUserPets;
