export function getCurrentPolicy(policies) {
  if (policies && policies.length > 0) {
    for (let idx = policies.length - 1; idx >= 0; idx--) {
      if (
        policies[idx].status === 'renew_pending' ||
        policies[idx].status === 'declined' ||
        policies[idx].status === 'voided'
      ) {
        continue;
      }
      return policies[idx];
    }
  }
  return null;
}

export function parseDeductible(deductible) {
  if (deductible === null) {
    return null;
  }

  const withoutPrefix = String(deductible).replace(/deductible/gi, '');
  return Number.isNaN(withoutPrefix)
    ? deductible
    : parseFloat(withoutPrefix).toFixed(2);
}

export function getLatestPlan(policy, petRecord) {
  if (policy !== null) {
    return petRecord?.latest_plan?.status !== 'voided'
      ? petRecord?.latest_plan
      : null;
  } else {
    return null;
  }
}

export function getCurrentWellness(pet) {
  if (pet.wellness && Object.keys(pet.wellness).length > 0) {
    return pet.wellness;
  }
  return null;
}
