import makeStyles from '@mui/styles/makeStyles';

const petQuoteCardStyle = makeStyles(
  {
    checkbox: {
      padding: '0px 8px 0px 0px',
    },
    reimbursementRateContainer: {
      marginRight: '24px',
    },
    deductibleContainer33: {
      flex: '0 0 calc(33.3% - 12px)',
      marginRight: '24px',
    },
    annualLimitContainer33: {
      flex: '0 0 calc(33.3% - 12px)',
      marginRight: '0',
    },
    plansContainer: {
      marginTop: '8px',
    },
    buttonGroup: {
      borderColor: 'rgba(83, 104, 245, 0.5)',
      '& .MuiToggleButton-root': {
        borderColor: 'rgba(83, 104, 245, 0.5)',
        borderRadius: '1',
        margin: '20px 0 18px',
        '&.Mui-selected': {
          backgroundColor: 'rgba(83, 104, 245, 0.10)',
          color: 'rgba(83, 104, 245)',
        },
      },
    },
    toggleButton: {
      color: 'rgb(63, 81, 181)',
      width: '74.5px',
      height: '42px',
    },
    select: {
      margin: '8px 0 12px',
      padding: '8px',
      boxShadow:
        '0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      borderBottom: '0',
      '&:before': {
        borderBottom: '0',
      },
    },
    label: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase',
    },
    petName: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    petDetails: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    planItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      whiteSpace: 'normal',
      maxWidth: '360px',
    },
    planVersion: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    planTitle: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '4px',
    },
    planDetails: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.6)',
      marginTop: '8px',
    },
  },
  { name: 'pet-quote-card' }
);

export { petQuoteCardStyle };
