import makeStyles from '@mui/styles/makeStyles';

const paperlessSettingsModalStyle = makeStyles(
  {
    modalTitle: {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '32px',
      color: 'black',
    },
    paper: { minWidth: '720px' },
    modalContent: { textAlign: 'center' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#FC5555',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    addButton: {
      borderRadius: '4px',
      backgroundColor: '#5368F5',
      '&:hover': {
        backgroundColor: '#5368F5',
      },
      color: 'white',
      width: '100%',
    },
    switchContainer: {
      display: 'flex',
      width: '290px',
      marginTop: '0px',
      alignItems: 'center',
      marginBottom: '14px',
      justifyContent: 'space-between',
    },
    switchRoot: {
      width: '50px',
      height: '24px',
      padding: '0px',
    },
    switchBase: {
      color: '#818181',
      padding: '1px',
      '&.Mui-checked': {
        '& + $track': {
          backgroundColor: '#23bf58',
        },
      },
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px',
    },
    track: {
      borderRadius: '20px',
      backgroundColor: '#9B9FA8',
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '16px',
        position: 'absolute',
        top: '3px',
      },
      '&:after': {
        content: "'✔'",
        left: '10px',
      },
      '&:before': {
        content: "'✕'",
        right: '10px',
      },
    },
    checked: {
      transform: 'translateX(26px) !important',
    },
    textContent: { textAlign: 'left', fontSize: '16px' },
    dialogButtonContainer: { justifyContent: 'space-between', width: '300px' },
    disclaimerContainer: {
      backgroundColor: '#F8F7F3',
      padding: '14px 50px',
      marginTop: '12px',
    },
  },
  {
    name: 'paperless-settings-modal',
  }
);

export { paperlessSettingsModalStyle };
