import {
  PERMISSION_CREATE_CLAIM,
  PERMISSION_CREATE_INSURANCE_CLAIM,
  PERMISSION_CREATE_PREVENT_CLAIM,
  PERMISSION_CREATE_SHIPMENT,
  PERMISSION_EDIT_CLAIM,
  PERMISSION_EDIT_INSURANCE_CLAIM,
  PERMISSION_EDIT_POLICY,
  PERMISSION_EDIT_PREVENT_CLAIM,
  PERMISSION_EDIT_PREVENT_CLAIM_LINE_ITEM,
  PERMISSION_REIMBURSE_CLAIM,
  PERMISSION_SUBMIT_CLAIM_TO_INSURANCE_PROVIDER,
  PERMISSION_UPLOAD_CLAIM_ATTACHMENT,
} from '../permissions';

const CustomerSupportGroupPermissions = {
  // Claims
  [PERMISSION_CREATE_CLAIM]: true,
  [PERMISSION_CREATE_INSURANCE_CLAIM]: true,
  [PERMISSION_CREATE_PREVENT_CLAIM]: true,
  [PERMISSION_EDIT_CLAIM]: true,
  [PERMISSION_EDIT_INSURANCE_CLAIM]: true,
  [PERMISSION_EDIT_PREVENT_CLAIM]: true,
  [PERMISSION_EDIT_PREVENT_CLAIM_LINE_ITEM]: true,
  [PERMISSION_SUBMIT_CLAIM_TO_INSURANCE_PROVIDER]: true,
  [PERMISSION_UPLOAD_CLAIM_ATTACHMENT]: true,
  [PERMISSION_REIMBURSE_CLAIM]: true,

  // Shipments
  [PERMISSION_CREATE_SHIPMENT]: true,

  // Policies
  [PERMISSION_EDIT_POLICY]: true,
};

export default CustomerSupportGroupPermissions;
