import { useGetList } from 'react-admin';

export const OPS_LIST_QUERY = 'ops-users';

export function useOpsList() {
  return useGetList(OPS_LIST_QUERY, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'ASC' },
  });
}
