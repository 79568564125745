import { formatISODate } from '../shared/utils';

export function planOptionsWithStatusForSelectedPet(pet) {
  const petPlans = pet.plans || [];
  return petPlans.map(petPlan => {
    const formattedEffectiveDate = formatISODate(petPlan.plan_effective_date);
    return {
      id: petPlan.id,
      name: `${formattedEffectiveDate} - ${petPlan.status}`,
      formattedEffectiveDate,
    };
  });
}
