import makeStyles from '@mui/styles/makeStyles';

const claimActionMenuStyle = makeStyles(
  {
    actionMenuDiv: { display: 'flex', alignItems: 'center' },
    menuIcon: {
      backgroundColor: 'rgba(0, 125, 255, 0.08)',
      color: '#007DFF',
      padding: '12px',
      marginRight: '36px',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    actionButton: {
      backgroundColor: '#007DFF',
      borderRadius: '4px',
      color: 'white',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
    },
  },
  { name: 'claim-action-menu' }
);

export { claimActionMenuStyle };
