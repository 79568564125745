import { GENDER_MAP } from '@pumpkincare/shared';
/*
  formData points to the user's input data
  quote points to the existing quote data
  selectedPlans points to the local react state for selected plans for each pet - too abstract to incorporate this into formData
  currentStep points to the current step in the form - 0 is the entry point, 1 is the plan selection, 2 is checkout
 */

export function formatWellnessQuotePayload({
  formData,
  dbUser,
  quote,
  selectedPlans,
  currentStep,
}) {
  const baseData = { saw_experiment: '0', saw_partner: 'pumpkin' };
  const baseUser = dbUser.id ? dbUser : formData;
  const user = {
    email: baseUser.email,
    first_name: baseUser.first_name,
    last_name: baseUser.last_name,
  };
  if (baseUser.identity_id) user.identity_id = baseUser.identity_id;

  // intro - basic user and pets info
  if (currentStep === 0) {
    return {
      ...baseData,
      ...user,
      quote_pets: formData.quote_pets.map((pet, idx) => {
        const newPet = { ...pet };
        const [, type, code] = pet.breed_code.split('~');
        newPet.breed_type = type;
        newPet.breed_name = decodeURIComponent(code);

        if (quote.id) {
          newPet.id = quote.quote_pets[idx].id;
        }

        return newPet;
      }),
    };
  }

  // plan selection
  if (currentStep === 1) {
    return {
      ...baseData,
      ...user,
      id: quote.id,
      identity_id: quote.identity_id,
      quote_pets: quote.quote_pets.map(pet => {
        const newPet = { ...pet };
        delete newPet?.breed_type;
        delete newPet?.breed_name;

        return {
          ...newPet,
          pet_name: newPet.name,
          pet_age: newPet.age,
          pet_gender: GENDER_MAP[newPet.gender].toLowerCase(),
          tier: selectedPlans[newPet.id].tier,
        };
      }),
    };
  }

  const mappedQuotePets = quote.quote_pets.map(pet => {
    return {
      id: pet.id,
      pet_age: pet.age,
      pet_gender: GENDER_MAP[pet.gender].toLowerCase(),
      pet_name: pet.name,
      breed_code: pet.breed_code,
      species: pet.species,
      tier: `${selectedPlans[pet.id].tier}-${pet.species}`,
    };
  });

  // checkout
  if (currentStep === 2 && !dbUser.id) {
    const billing_information =
      formData.isBillingSameAsShipping === 'true'
        ? {
            ...formData.shipping_information,
            country: 'US',
            name_on_card: formData.name_on_card,
          }
        : {
            ...formData.billing_information,
            name_on_card: formData.name_on_card,
          };

    return {
      ...baseData,
      ...user,
      id: quote.id,
      quote_pets: mappedQuotePets,
      zipcode: formData.shipping_information.zipcode, // needed for identity
      phone: formData.phone,
      billed_annually: formData.billed_annually === 'true',
      shipping_information: formData.shipping_information,
      billing_information,
    };
  }

  // checkout AAP
  if (currentStep === 2 && dbUser.id) {
    return {
      ...baseData,
      user_id: dbUser.id,
      billed_annually: dbUser.is_charged_annually,
      quote_pets: mappedQuotePets,
    };
  }
}

export function validateUserAndPets(
  values,
  dbUser,
  { isEmailError, emailHelperText } = {}
) {
  const { quote_pets, ...formUser } = values;
  const user = dbUser.id ? dbUser : formUser;

  const errors = {
    quote_pets: Array.from({ length: quote_pets.length }, () => ({})),
  };

  if (!user.email || isEmailError) {
    errors.email = emailHelperText || 'Please enter a valid email';
  }

  [
    { key: 'first_name', value: user.first_name },
    { key: 'last_name', value: user.last_name },
  ].forEach(({ key, value }) => {
    if (!value) errors[key] = 'Please enter a value';
  });

  const uniqueQuotePets = [];
  const existingPets = dbUser?.pets?.map(pet => {
    return {
      pet_name: pet.name.toLowerCase().replace(/ /g, ''),
      species: pet.species,
    };
  });
  quote_pets.forEach((pet, idx) => {
    [
      { key: 'pet_name', value: pet.pet_name },
      { key: 'species', value: pet.species },
      { key: 'pet_age', value: pet?.pet_age },
      { key: 'breed_code', value: pet?.breed_code },
      { key: 'pet_gender', value: pet?.pet_gender },
    ].forEach(({ key, value }) => {
      if (!value) errors.quote_pets[idx][key] = 'Please enter a value';
    });
    if (pet.pet_name) {
      const petName = pet.pet_name.toLowerCase().replace(/ /g, '');
      if (
        existingPets?.some(
          existingPet =>
            existingPet.pet_name === petName && existingPet.species === pet.species
        )
      ) {
        errors.quote_pets[idx]['pet_name'] =
          'Customer already has a pet with this name';
      } else if (
        uniqueQuotePets.some(
          uniquePet =>
            uniquePet.pet_name === petName && uniquePet.species === pet.species
        )
      ) {
        errors.quote_pets[idx]['pet_name'] = 'Please enter a different name';
      } else {
        uniqueQuotePets.push({ pet_name: petName, species: pet.species });
      }
    }
  });

  return errors;
}

export function validatePlanSelection(pets, plans) {
  return pets.every(pet => plans[pet.id]?.tier) ? {} : { field: 'true' };
}

export function validateCheckout(values, dbUser) {
  // if AAP, no form error
  if (dbUser.id) return {};

  const errors = { shipping_information: {}, billing_information: {} };

  [
    { key: 'phone', value: values.phone },
    { key: 'name_on_card', value: values.name_on_card },
    { key: 'billed_annually', value: values.billed_annually },
    { key: 'isBillingSameAsShipping', value: values.isBillingSameAsShipping },
  ].forEach(({ key, value }) => {
    if (!value) errors[key] = 'Please enter a value';
  });

  const addresses = [
    { key: 'shipping_information', value: values.shipping_information },
    values.isBillingSameAsShipping === 'false' && values.billing_information
      ? { key: 'billing_information', value: values.billing_information }
      : null,
  ];

  /*
    filter to remove billing_information as needed
    then add nested errors for missing required fields
   */
  addresses
    .filter(address => address)
    .forEach(({ key, value: address }) => {
      Object.keys(address).forEach(addressKey => {
        if (!address[addressKey] && addressKey !== 'street_2')
          errors[key][addressKey] = 'Please enter a value';
      });
    });

  return errors;
}

export function validateSAWQuoteForm(
  values,
  quote,
  dbUser,
  currentStep,
  selectedPlans,
  { isEmailError, emailHelperText } = {}
) {
  if (currentStep === 0) {
    return validateUserAndPets(values, dbUser, {
      isEmailError,
      emailHelperText,
    });
  }

  if (currentStep === 1) {
    return validatePlanSelection(quote.quote_pets, selectedPlans);
  }

  if (currentStep === 2) {
    return validateCheckout(values, dbUser);
  }
}
