import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { getACHStatus, getACHStatusColor } from '@pumpkincare/claims';

function ACHStatus(props) {
  const { fundingSource, ...rest } = props;

  const status = getACHStatus(fundingSource);
  const style = getACHStatusColor(status);

  return (
    <Typography style={style} {...rest}>
      {status}
    </Typography>
  );
}

ACHStatus.defaultProps = {
  fundingSource: {
    account_type: '',
    account_number: '',
  },
};

ACHStatus.propTypes = {
  fundingSource: PropTypes.shape({
    account_type: PropTypes.string,
    account_number: PropTypes.string,
  }),
};

export default ACHStatus;
