import { SaveButton, Toolbar } from 'react-admin';
import { Link as LinkRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import RoutePaths from '../../routes';

function InvoiceCreateToolbar(props) {
  const { showCancellationLink, customerId, ...rest } = props;
  return (
    <Toolbar {...rest}>
      <SaveButton label='Create Invoice' alwaysEnable={true} />
      {showCancellationLink ? (
        <Typography style={{ marginLeft: 'auto' }}>
          <LinkRouter
            to={{
              pathname: `/${RoutePaths.cancellationDetails}/${customerId}/show`,
            }}
            className={{ cursor: 'pointer' }}
            style={{
              textDecoration: 'none',
              paddingRight: '16px',
              fontSize: '1rem',
            }}
          >
            Return to cancellation screen
          </LinkRouter>
        </Typography>
      ) : null}
    </Toolbar>
  );
}

InvoiceCreateToolbar.propTypes = {
  showCancellationLink: PropTypes.bool,
  customerId: PropTypes.string,
};

export default InvoiceCreateToolbar;
