import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import useStyles from './CancellationStyles';

function getValueOrDefault(value, defaultValue) {
  return value !== null && value !== undefined ? value : defaultValue;
}

function CancellationEmailDetailsForm({
  cancellation,
  hasError,
  amounts,
  handleChange,
}) {
  const classes = useStyles();
  const { id, outstanding_balance, pet } = cancellation;

  function getInsuranceRefund() {
    return getValueOrDefault(
      amounts?.['insurance_refund'],
      cancellation.insurance_refund !== null
        ? cancellation.insurance_refund
        : cancellation.enrollment?.premium
    );
  }

  function getEmbarkOutstandingBalance() {
    return Math.abs(
      getValueOrDefault(
        amounts?.['embark_outstanding_balance'],
        cancellation.embark_outstanding_balance !== null
          ? cancellation.embark_outstanding_balance
          : pet.embark_outstanding_balance
      )
    );
  }

  function getOutstandingValue(value) {
    const outstandingBalance = amounts?.outstanding_balance ?? null;

    if (outstandingBalance !== null) {
      // user edited the field in the current visit
      return outstandingBalance;
    }

    if (value > 0) {
      return Math.abs(value);
    }

    return 0;
  }

  function getPreventValue(value, columnName) {
    const preventRefund = amounts?.[columnName.replaceAll('-', '_')] ?? null;

    if (preventRefund !== null) {
      return preventRefund;
    }

    if (value < 0) {
      return Math.abs(value);
    }

    return 0;
  }

  function getHelperText(id, columnName) {
    if (hasError[id]) {
      if (hasError[id][columnName]) {
        return 'Value must be a number with two decimal places.';
      }
    }
    return '';
  }

  function checkHasError(id, columnName) {
    return hasError[id] ? hasError[id][columnName] : false;
  }

  return (
    <form key={id} className={classes.emailDetailsFormItem}>
      <Typography variant='h6' style={{ fontWeight: 500 }}>
        {pet.name}:
      </Typography>

      <FormControl>
        {cancellation.cancellation_type === 'Policy' ? (
          <>
            <Typography variant='overline'>Insurance</Typography>
            <TextField
              id='insurance-refund'
              name={id}
              onChange={handleChange}
              variant='outlined'
              label='Refund'
              value={getInsuranceRefund()}
              className={classes.inputStyle}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              error={checkHasError(id, 'insurance-refund')}
              helperText={getHelperText(id, 'insurance-refund')}
            />
          </>
        ) : null}

        {cancellation.cancellation_type === 'Policy' &&
        pet.embark_frequency !== null ? (
          <TextField
            id='embark-outstanding-balance'
            label='Embark Outstanding Balance'
            name={id}
            value={getEmbarkOutstandingBalance()}
            error={checkHasError(id, 'embark-outstanding-balance')}
            helperText={getHelperText(id, 'embark-outstanding-balance')}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            }}
            onChange={handleChange}
            variant='outlined'
            style={{ marginRight: '16px' }}
          />
        ) : null}

        {/*
              response will return just policy type cancellation, but will include PEP data if there
              is a coupled PEP plan to cancel as well
             */}
        {cancellation.cancellation_type === 'Prevent' || cancellation.pet_plan ? (
          <>
            <Typography variant='overline'>Prevent</Typography>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                onChange={handleChange}
                id='outstanding-balance'
                name={id}
                value={getOutstandingValue(outstanding_balance)}
                variant='outlined'
                label='Outstanding Balance'
                className={classes.inputStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                error={checkHasError(id, 'outstanding-balance')}
                helperText={getHelperText(id, 'outstanding-balance')}
              />

              <TextField
                onChange={handleChange}
                id='prevent-refund'
                name={id}
                value={getPreventValue(outstanding_balance, 'prevent-refund')}
                variant='outlined'
                label='Refund'
                className={classes.inputStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                error={checkHasError(id, 'prevent-refund')}
                helperText={getHelperText(id, 'prevent-refund')}
              />
            </Box>
          </>
        ) : null}

        {/*
              if cancelling BOTH insurance and wellness, type will be Policy but endpoint will return
              wellness data as well. If the pet has wellness but is ONLY cancelling insurance,
              wellness data does not populate in the response
             */}
        {cancellation.cancellation_type === 'Wellness' || cancellation.wellness ? (
          <>
            <Typography variant='overline'>Wellness</Typography>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <TextField
                onChange={handleChange}
                id='outstanding-balance'
                name={id}
                value={getOutstandingValue(outstanding_balance)}
                variant='outlined'
                label='Outstanding Balance'
                className={classes.inputStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                error={checkHasError(id, 'outstanding-balance')}
                helperText={getHelperText(id, 'outstanding-balance')}
              />

              <TextField
                onChange={handleChange}
                id='wellness-refund'
                name={id}
                value={getPreventValue(outstanding_balance, 'wellness-refund')}
                variant='outlined'
                label='Refund'
                className={classes.inputStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                error={checkHasError(id, 'wellness-refund')}
                helperText={getHelperText(id, 'wellness-refund')}
              />
            </Box>
          </>
        ) : null}
      </FormControl>
    </form>
  );
}

CancellationEmailDetailsForm.propTypes = {
  cancellation: PropTypes.shape({
    id: PropTypes.string,
    pet: PropTypes.shape({
      name: PropTypes.string,
      embark_frequency: PropTypes.number,
      embark_outstanding_balance: PropTypes.number,
      prevent_cancellation_amount: PropTypes.number,
      prevent_cancellation_status: PropTypes.string,
    }),
    insurance_refund: PropTypes.number,
    outstanding_balance: PropTypes.number,
    embark_outstanding_balance: PropTypes.number,
    cancellation_type: PropTypes.string,
    enrollment: PropTypes.shape({
      premium: PropTypes.number,
    }),
    pet_plan: PropTypes.object,
    wellness: PropTypes.object,
  }),
  hasError: PropTypes.shape({
    id: PropTypes.shape({
      insurance_refund: PropTypes.bool,
      outstanding_balance: PropTypes.bool,
      prevent_refund: PropTypes.bool,
      embark_outstanding_balance: PropTypes.bool,
    }),
  }),
  amounts: PropTypes.shape({
    insurance_refund: PropTypes.number,
    outstanding_balance: PropTypes.number,
    prevent_refund: PropTypes.number,
    embark_outstanding_balance: PropTypes.number,
  }),
  handleChange: PropTypes.func,
};

CancellationEmailDetailsForm.defaultProps = {
  cancellation: {
    id: 0,
    pet: {
      name: '',
      embark_frequency: 0,
      embark_outstanding_balance: 0,
      prevent_cancellation_amount: 0,
      prevent_cancellation_status: '',
    },
    insurance_refund: 0,
    outstanding_balance: 0,
    embark_outstanding_balance: 0,
    cancellation_type: '',
    enrollment: {
      premium: 0,
    },
  },
  hasError: {
    id: {
      insurance_refund: false,
      outstanding_balance: false,
      prevent_refund: false,
      embark_outstanding_balance: false,
    },
  },
  amounts: {
    insurance_refund: 0,
    outstanding_balance: 0,
    prevent_refund: 0,
    embark_outstanding_balance: 0,
  },
  handleChange: () => {},
};
export default CancellationEmailDetailsForm;
