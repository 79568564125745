import { useGetList, useGetOne } from 'react-admin';

export const DENIAL_REASON_SERVICE = 'denial-reason';

export function useDenialReasons() {
  return useGetList(DENIAL_REASON_SERVICE, {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });
}

export function useDenialReasonsWithItems() {
  return useGetOne('', { id: `${DENIAL_REASON_SERVICE}/list` });
}
