import makeStyles from '@mui/styles/makeStyles';

const medicalRecordsRequestAccordionStyles = makeStyles(
  {
    root: {
      marginTop: '24px',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: '24px auto auto',
      },
    },

    summaryRoot: {
      backgroundColor: '#007DFF14',
      borderRadius: '4px',
      margin: 0,
      minHeight: '56px',
      '&.Mui-expanded': { minHeight: '56px' },
      '& h6': { lineHeight: '24px', color: '#5368F5' },
    },
    summaryContent: { margin: 0, '&.Mui-expanded': { margin: 0 } },
    expandIcon: { fill: '#5368F5' },

    details: { padding: '24px 16px 0', flexDirection: 'column' },
    loader: { margin: '16px auto 0' },

    grid: {
      display: 'grid',
      gridTemplateColumns: '100px 100px 1fr 1fr 1fr 120px',
    },
    cell: {
      borderBottom: '1px solid #0000001F',
      padding: '16px 16px 16px 0',
      '& span': { display: 'block' },
    },

    truncateOverflow: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    vetInfo: {
      color: '#6B717E',
      display: 'block',
    },
    miniText: {
      fontSize: '10px',
      lineHeight: '16px',
      color: '#6B717E',
      display: 'block',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    },
    tableAction: {
      color: '#2A41DE',
      textTransform: 'initial',
      display: 'block',
      padding: 0,
      '&:first-child': { marginBottom: '16px' },
      '& svg': { width: '20px', height: '20px' },
      '& p': { display: 'flex', gap: '0 4px' },
      '&:disabled': { color: '#6B717E' },
    },

    newRequest: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
      marginTop: '24px',
      padding: '6px 8px',
      border: '1px solid #CDCFD3',
      '& svg': { color: '#5368F5;' },
      '&:disabled': {
        '& svg': { color: '#00000042' },
      },
    },
  },
  {
    name: 'medical-records-request-accordion',
  }
);

export { medicalRecordsRequestAccordionStyles };
