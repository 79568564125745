import { Typography } from '@mui/material';
import classNames from 'classnames';

import AddressInput from './AddressInput';
import { paymentFormStyle } from './PaymentFormStyle';

function ShippingForm({ quote, onAddressChange, onPhoneNumberChange }) {
  const styles = paymentFormStyle();

  const mailingAddressDefault = quote.existingUserDetails
    ? quote.existingUserDetails.mailing_address
    : {};

  const textInputWithBottomBorderClassName = classNames(
    styles.textInput,
    styles.nameBorder
  );

  return (
    <div className={styles.shippingForm}>
      <Typography style={{ margin: '16px 0 8px' }}>
        Shipping address<sup className={styles.asterisk}>*</sup>
      </Typography>

      <input
        type={'text'}
        className={textInputWithBottomBorderClassName}
        disabled
        style={{ backgroundColor: '#EEEFF1' }}
        value={`${quote.first_name} ${quote.last_name}`}
        placeholder={'Name'}
      />

      <AddressInput
        defaultAddress={mailingAddressDefault}
        isShipping
        quote={quote}
        onChange={onAddressChange}
      />

      <Typography style={{ margin: '24px 0 8px' }}>
        Phone number<sup className={styles.asterisk}>*</sup>
      </Typography>

      <input
        id={'phoneNumber'}
        data-testid='phoneNumber'
        defaultValue={quote.existingUserDetails?.phone}
        className={classNames(styles.textInput, styles.border)}
        type={'text'}
        onChange={e => onPhoneNumberChange(e.target.value)}
      />
    </div>
  );
}

export default ShippingForm;
