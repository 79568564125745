import { useGetList, useGetOne } from 'react-admin';

export const MEDICAL_RECORDS_REQUESTS_QUERY = 'medical-records-requests';

export function useAllMedicalRecordsRequests({ petId, claimId }, options = {}) {
  const filter = { pet_id: petId };
  if (claimId) filter.claim_submission_id = claimId;

  return useGetList(
    MEDICAL_RECORDS_REQUESTS_QUERY,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'created_at', order: 'DESC' },
      filter: filter,
    },
    {
      ...options,
      enabled:
        typeof options.enabled === 'boolean' ? options.enabled && !!petId : !!petId,
    }
  );
}

export function useMedicalRecordsRequest(id, options = {}) {
  return useGetOne(
    MEDICAL_RECORDS_REQUESTS_QUERY,
    { id: id },
    {
      ...options,
      enabled: typeof options.enabled === 'boolean' ? options.enabled && !!id : !!id,
    }
  );
}
