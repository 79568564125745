import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Typography } from '@mui/material';

import { claimMGAStatuses, getClaimStatusColor } from '@pumpkincare/claims';

import RoutePaths from '../../../../routes';
import {
  formatCurrency,
  getDateAsFormattedUTC,
  getTimeDifferenceFromCurrentDate,
} from '../../../../shared/utils';
import { claimCardStyle } from './claim-card-style';

export default function ClaimCard({ claim = {} }) {
  const classes = claimCardStyle();

  const insuranceSubclaim = claim.subclaims.find(
    subclaim => subclaim.type === 'insurance'
  );
  const preventSubclaim = claim.subclaims.find(
    subclaim => subclaim.type === 'prevent'
  );
  const wellnessSubclaim = claim.subclaims.find(
    subclaim => subclaim.type === 'wellness'
  );

  const daysInQueue = getTimeDifferenceFromCurrentDate(
    claim.status_last_updated,
    'days'
  );

  return (
    <div className={classes.card}>
      <div key={claim.id} className={classes.cardDetail}>
        <div className={classes.cardTitle}>
          <Link
            to={linkToRecord(`/${RoutePaths.claims}`, claim.id, 'show')}
            style={{
              textDecoration: 'none',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AssignmentIcon style={{ color: '#007DFF', width: '14px' }} />
              <Typography className={classes.claimNumber}>{claim.id}</Typography>
            </div>
          </Link>
          <Typography
            className={classes.claimStatus}
            style={{
              backgroundColor: getClaimStatusColor(claim.status),
            }}
          >
            {claimMGAStatuses[claim.status]}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          {insuranceSubclaim ? (
            <Typography className={classes.claimInsurance}>
              INSURANCE: {insuranceSubclaim.id}
            </Typography>
          ) : null}

          {preventSubclaim ? (
            <Typography className={classes.claimPrevent}>
              PREVENT: {preventSubclaim.id}
            </Typography>
          ) : null}

          {wellnessSubclaim ? (
            <Typography className={classes.claimInsurance}>
              WELLNESS: {wellnessSubclaim.id}
            </Typography>
          ) : null}
        </div>
        <Typography className={classes.claimText}>
          {`Customer: ${claim.customer_name} - ${claim.customer_external_id}`}
        </Typography>
        <Typography className={classes.claimText}>
          {`Pet: ${claim.pet_name}`}
        </Typography>
        <Typography className={classes.claimText}>
          {`Amount Claimed: ${formatCurrency(claim.claimed_amount, {
            areCents: true,
          })}`}
        </Typography>
        <Typography className={classes.claimText}>State: {claim.state}</Typography>
        <Typography className={classes.claimText}>
          {`Loss Date: ${
            claim.loss_date
              ? getDateAsFormattedUTC(claim.loss_date, 'MM/DD/YYYY')
              : 'N/A'
          }`}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AccountCircleIcon className={classes.claimAccountIcon} />
          <Typography className={classes.claimDaysInQueue}>{`${daysInQueue} day${
            daysInQueue === 1 ? '' : 's'
          } in ${claimMGAStatuses[claim.status]}`}</Typography>
        </div>
      </div>
    </div>
  );
}
