import makeStyles from '@mui/styles/makeStyles';

const planSummaryStyle = makeStyles(
  {
    sectionRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },

    sectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    listItems: {
      marginTop: '8px !important',
      padding: '0 16px',
    },
  },
  { name: 'plan-summary-style' }
);

export { planSummaryStyle };
