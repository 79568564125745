import makeStyles from '@mui/styles/makeStyles';

const claimAdjudicationDatagridStyle = makeStyles(
  {
    insuranceAdjudicationDatagrid: {
      marginBottom: '40px',
      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0px',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
      '& .MuiDataGrid-renderingZone': {
        '& .MuiDataGrid-row': {
          '&:nth-child(2n-1)': {
            backgroundColor: 'rgba(0, 125, 255, 0.08)',
          },
        },
      },
    },
    preventAdjudicationDatagrid: {
      marginBottom: '40px',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0px',
      },
      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
      '& .MuiDataGrid-renderingZone': {
        '& .MuiDataGrid-row': {
          '&:nth-child(2n-1)': {
            backgroundColor: 'rgba(0, 125, 255, 0.08)',
          },
        },
      },
    },
    title: {
      color: '#007DFF',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: '14px',
      border: '1px solid black',
    },
  },
  {
    name: 'claim-adjudication-datagrid',
  }
);

export { claimAdjudicationDatagridStyle };
