import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { cancellationReasons } from '../../constants/cancellationReasons';
import { updateCancellations } from '../../shared/services/netServices';
import useStyles from './CancellationStyles';

const reasonsOptions = cancellationReasons.map(option => (
  <MenuItem key={option.id} value={option.name} disabled={option.disabled}>
    {option.name}
  </MenuItem>
));

export default function CancellationReasonsCard({ record }) {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const [reasons, setReasons] = useState({});

  function handleChange(event) {
    const name = event.target.name;
    setReasons({
      ...reasons,
      [name]: {
        cancellation_assignment_id: name,
        cancellation_reason: event.target.value,
      },
    });
  }

  function createReasonLine(cancellation, reasons, handleChange, handleUpdate) {
    return (
      <form key={cancellation.id} className={classes.formItem}>
        <Box sx={{ marginRight: '32px' }}>
          <Typography
            variant='h6'
            style={{
              paddingRight: '16px',
              width: '120px',
              fontWeight: 500,
              lineHeight: '24px',
              overflowWrap: 'break-word ',
            }}
          >
            {cancellation.pet.name}
          </Typography>
          <Typography
            variant='caption'
            style={{
              paddingRight: '16px',
              minWidth: '120px',
              fontWeight: 400,
              lineHeight: '0px',
            }}
          >
            Product: {cancellation.cancellation_type}
          </Typography>
        </Box>

        <FormControl
          className={classes.formControl}
          sx={{ flexDirection: 'row !important' }}
        >
          <InputLabel variant='outlined' id={'cancellation-select-label'}>
            Cancellation Reason
          </InputLabel>
          <Select
            labelId={'cancellation-select-label'}
            variant='outlined'
            id='reason-select'
            name={cancellation.id}
            label={'Cancellation Reason'}
            sx={{ height: '56px', minWidth: '376px' }}
            value={
              reasons[cancellation.id]
                ? reasons[cancellation.id]['cancellation_reason']
                : cancellation.cancellation_reason
            }
            onChange={handleChange}
          >
            <MenuItem key={'none'} value={''}>
              &nbsp;
            </MenuItem>
            {reasonsOptions}
          </Select>
          <Button
            onClick={handleUpdate}
            variant='contained'
            style={{
              marginLeft: '56px',
            }}
          >
            Update
          </Button>
        </FormControl>
      </form>
    );
  }

  function handleUpdate() {
    updateCancellations(Object.values(reasons))
      .then(() => {
        notify('Cancellation reasons updated', {});
        refresh();
      })
      .catch(() => {
        notify('Cancellation reasons not updated', 'warning');
      });
  }

  return (
    <div style={{ marginBottom: '32px' }}>
      <Typography variant='h5' sx={{ marginBottom: '12px' }}>
        2. Set Cancellation Reason
      </Typography>
      <Card>
        <CardContent>
          {record.cancellation_assignments.map(function (cancellation) {
            return createReasonLine(
              cancellation,
              reasons,
              handleChange,
              handleUpdate
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
}

CancellationReasonsCard.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    cancellation_assignments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        pet: PropTypes.shape({
          name: PropTypes.string,
        }),
        cancellation_reason: PropTypes.string,
      })
    ),
  }),
};

CancellationReasonsCard.defaultProps = {
  record: {
    id: 0,
    cancellation_assignments: [],
  },
};
