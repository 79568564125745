import { useState } from 'react';
import { useListContext } from 'react-admin';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';

function FieldSortButton({
  fields,
  ascLabel = 'Oldest to Newest',
  descLabel = 'Newest to Oldest',
}) {
  const { sort, setSort } = useListContext();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  function inverseOrder(sort) {
    return sort === 'ASC' ? 'DESC' : 'ASC';
  }

  function handleChangeSort(event) {
    const field = event.currentTarget.dataset.sort;
    setSort({
      field,
      order: field === sort.field ? inverseOrder(sort.order) : 'ASC',
    });
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        color='primary'
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size='small'
      >
        Sort By
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields.map(field => (
          <MenuItem onClick={handleChangeSort} data-sort={field} key={field}>
            {sort.order === 'ASC' ? descLabel : ascLabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default FieldSortButton;
