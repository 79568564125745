import { TopToolbar } from 'react-admin';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import FieldSortButton from '../../../../../shared/components/FieldSortButton';
import ClaimFilter from '../ClaimFilter';
import ClaimFilterButton from '../ClaimFilterButton';
import ClaimLibraryFilter from '../ClaimLibraryFilter';

function ClaimListActions(props) {
  const {
    opsList,
    showStatus,
    showAssignee,
    title = 'Claims Queue',
    filterOnClick = false,
    filterClaimsOn = ['created_at'],
  } = props;
  return (
    <Box width='100%'>
      <TopToolbar
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            color: '#007DFF',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
          }}
        >
          {title}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: filterOnClick ? 'auto' : '230px',
          }}
        >
          {filterOnClick === false ? (
            <div>
              <ClaimFilterButton />
            </div>
          ) : null}

          <div>
            <FieldSortButton fields={filterClaimsOn} />
          </div>
        </div>
      </TopToolbar>
      {filterOnClick === true ? (
        <ClaimLibraryFilter opsList={opsList} />
      ) : (
        <ClaimFilter
          showStatus={showStatus}
          showAssignee={showAssignee}
          opsList={opsList}
        />
      )}
    </Box>
  );
}

ClaimListActions.propTypes = {
  opsList: PropTypes.array.isRequired,
  showStatus: PropTypes.bool.isRequired,
  showAssignee: PropTypes.bool.isRequired,
  title: PropTypes.string,
  filterOnClick: PropTypes.bool,
  filterClaimsOn: PropTypes.array.isRequired,
};

export default ClaimListActions;
