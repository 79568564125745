import makeStyles from '@mui/styles/makeStyles';

const BUTTON = {
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  border: '1px solid #CDCFD3',
  padding: '10px',
};

const cancelRequestRecordsModalStyle = makeStyles(
  {
    // modal CTA styles
    returnButton: { ...BUTTON, color: '#5368F5' },
    confirmButton: {
      ...BUTTON,
      color: 'white',
      backgroundColor: '#5368F5',
      borderColor: '#5368F5',
    },

    // cancel request modal styles
    root: { maxWidth: '642px' },
    modalTitle: { padding: '24px 32px 8px' },
    modalContent: { padding: '0 32px' },
    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '24px 32px',
    },
    title: { fontSize: '24px', lineHeight: '32px', textAlign: 'center' },
    body: {
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#595959',
      letterSpacing: '0.15px',
    },
  },
  { name: 'cancel-records-request-modal' }
);

export { cancelRequestRecordsModalStyle };
