import {
  INVOICE_ITEM_TYPE_ADDON,
  INVOICE_ITEM_TYPE_OB,
  INVOICE_ITEM_TYPE_PLAN,
  INVOICE_ITEM_TYPE_POLICY,
  INVOICE_ITEM_TYPE_WELLNESS,
} from '../../../constants/invoiceItemTypes';

export const getInvoiceCreationPostBody = params => {
  const invoiceParams = params.data.invoice;

  const invoiceItemsPayload = invoiceParams.invoice_items.map(item => {
    const itemPayload = {
      type: item.type,
      description: item.description,
      amount: item.price_per_unit / 100,
      pet_id: item.pet_id,
    };

    if (item.type === INVOICE_ITEM_TYPE_PLAN) {
      itemPayload.pet_plan_id = item.pet_plan_id;
    } else if (item.type === INVOICE_ITEM_TYPE_ADDON) {
      itemPayload.pet_addon_id = item.pet_addon_id;
    } else if (item.type === INVOICE_ITEM_TYPE_OB) {
      if (item.pet_plan_id) {
        itemPayload.pet_plan_id = item.pet_plan_id;
      } else if (item.pet_addon_id) {
        itemPayload.pet_addon_id = item.pet_addon_id;
      } else if (item.wellness_id) {
        itemPayload.wellness_id = item.wellness_id;
      }
    } else if (item.type === INVOICE_ITEM_TYPE_WELLNESS) {
      itemPayload.wellness_id = item.wellness_id;
    } else if (item.type === INVOICE_ITEM_TYPE_POLICY) {
      itemPayload.policy_id = item.policy_id;
    }

    return itemPayload;
  });

  return {
    user_id: invoiceParams.customer.id,
    invoice_items: invoiceItemsPayload,
    notes: invoiceParams.notes,
  };
};
