import { useEffect } from 'react';
import { ArrayField, Datagrid, TextField, useNotify } from 'react-admin';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useBillingDetails } from '@pumpkincare/users';

import DateFieldIgnoringZone from '../../../components/DateFieldIgnoringZone';
import LoadingDataGrid from '../../../components/LoadingDataGrid';
import { useCancellationBillingStyles } from './cancellation-billing-details.styles';

function CancellationBillingDetails({ userId }) {
  const notify = useNotify();
  const classes = useCancellationBillingStyles();

  const { isLoading, error, data } = useBillingDetails(userId);

  useEffect(() => {
    if (error) {
      notify(`${JSON.stringify(error)}`, { type: 'error' });
    }
  }, [error]);

  if (isLoading) {
    return (
      <Box flex={1} display='flex' alignItems='center' justifyContent='center'>
        <LoadingDataGrid />
      </Box>
    );
  }

  if (error) {
    return (
      <Box flex={1} display='flex' alignItems='center' justifyContent='center'>
        <Typography>An unexpected error occurred</Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography className={classes.title}>Billing Details</Typography>
      <div className={classes.box}>
        <ArrayField label='' source='billing' record={{ billing: data }}>
          <Datagrid bulkActionButtons={false}>
            <TextField source='product_status' label='Product Status' />
            <DateFieldIgnoringZone
              source='next_charge_date'
              label='Next Charge Date'
            />
            <TextField source='pet_names' label='Pets' />
            <TextField source='invoice_type' label='Type' />
            <TextField source='invoice_external_number' label='Invoice ID' />
            <DateFieldIgnoringZone
              source='last_charge_date'
              label='Last Charge Date'
            />
            <DateFieldIgnoringZone source='lapsed_since' label='Lapsed Since' />
          </Datagrid>
        </ArrayField>
      </div>
    </>
  );
}

CancellationBillingDetails.propTypes = {
  userId: PropTypes.string,
};

export default CancellationBillingDetails;
