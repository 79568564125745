import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { COUNTRIES_LIST } from '@pumpkincare/shared';

const MARGIN_BOTTOM = { marginBottom: '8px' };

function AddressDetails({ existingUserDetails }) {
  const ratingAddress = existingUserDetails.rating_address;
  const billingAddress = existingUserDetails.billing_address;

  return (
    <div>
      <Typography variant='h5' sx={MARGIN_BOTTOM}>
        Contact Info
      </Typography>

      <Typography variant='subtitle2' sx={MARGIN_BOTTOM}>
        Rating Address
      </Typography>
      <Typography variant='body2'>
        {`${ratingAddress.street_1}${
          ratingAddress.street_2 ? ', ' + ratingAddress.street_2 : ''
        }`}
      </Typography>
      <Typography
        variant='body2'
        sx={{ marginBottom: '32px' }}
      >{`${ratingAddress.city}, ${ratingAddress.state_province} ${ratingAddress.zipcode}`}</Typography>

      <Typography variant='h5' sx={MARGIN_BOTTOM}>
        Payment Information
      </Typography>

      <Typography variant='subtitle2' sx={MARGIN_BOTTOM}>
        Card Information
      </Typography>
      <Typography
        variant='body2'
        sx={MARGIN_BOTTOM}
      >{`•••• •••• •••• ${existingUserDetails.payment_details.last4} - ${existingUserDetails.payment_details.brand}`}</Typography>

      <Typography variant='subtitle2' sx={MARGIN_BOTTOM}>
        Billing Address
      </Typography>

      <Typography variant='body2'>
        {
          COUNTRIES_LIST.find(country => country.id === billingAddress?.country)
            ?.name
        }
      </Typography>
      <Typography variant='body2'>{`${billingAddress?.street_1}${
        billingAddress?.street_2 ? ', ' + billingAddress?.street_2 : ''
      }`}</Typography>
      <Typography variant='body2'>{`${billingAddress?.city}, ${billingAddress?.state_province} ${billingAddress?.zipcode}`}</Typography>
    </div>
  );
}

AddressDetails.propTypes = {
  existingUserDetails: PropTypes.shape({
    // extracting this into a reusable address prop makes sonarcloud mad :shrug:
    billing_address: PropTypes.shape({
      street_1: PropTypes.string,
      street_2: PropTypes.string,
      city: PropTypes.string,
      state_province: PropTypes.string,
      zipcode: PropTypes.string,
      country: PropTypes.string,
    }),
    rating_address: PropTypes.shape({
      street_1: PropTypes.string,
      street_2: PropTypes.string,
      city: PropTypes.string,
      state_province: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    payment_details: PropTypes.shape({
      last4: PropTypes.string,
      brand: PropTypes.string,
    }),
  }).isRequired,
};

export default AddressDetails;
