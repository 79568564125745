import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../../../../constants/quoteFlow';
import { checkExistingEmail } from '../../../../shared/services/netServices';
import {
  getDiscountDetail,
  getPetsByUserId,
  getPlan,
  getPolicyOptions,
  getQuoteDetail,
  getQuoteLink,
  getTransactionFee,
  getUserDetail,
} from '../service/quoteFlowService';
import calculatePetTotalPrice from './calculatePetTotalPrice';
import { transformPolicyOptions } from './transformPolicyOptions';

export function getFullQuote(quoteId) {
  let quote = {};
  let quoteUrl = {};

  return getQuoteDetail(quoteId)
    .then(response => {
      quote = { ...quote, ...response.data };
    })
    .then(() => {
      return getTransactionFee(quoteId).then(response => {
        quote = { ...quote, ...response.data };
      });
    })
    .then(() => {
      return getQuoteLink(quote).then(response => {
        quoteUrl = response;
      });
    })
    .then(() => {
      return quote.quote_pets.forEach(quotePet => {
        delete quotePet['policy_options'];
        return getPolicyOptions(quote.id, quotePet.id)
          .then(policyOptionsResponse => {
            quotePet._options = transformPolicyOptions(policyOptionsResponse);
          })
          .then(() => {
            quotePet.deductible =
              quotePet.deductible || quotePet._options.deductibles[0].id;
            quotePet.annual_limit =
              quotePet.annual_limit || quotePet._options.limits[0].id;
            quotePet.coinsurance = quotePet.coinsurance || '10';

            const insurance_price =
              quotePet._options.prices[quotePet.coinsurance][quotePet.annual_limit][
                quotePet.deductible
              ];
            quotePet.totalPrice = calculatePetTotalPrice(quotePet, insurance_price);

            const petAgeInMonths =
              quotePet.age === UP_TO_5_MONTHS
                ? 5
                : quotePet.age === UP_TO_11_MONTHS
                  ? 11
                  : parseInt(quotePet.age) * 12;

            return getPlan(quotePet.species, petAgeInMonths, quote.vet).then(
              response => {
                quotePet.plan = response[0];
              }
            );
          });
      });
    })
    .then(() => {
      return quote.quote_pets.length > 0
        ? getDiscountDetail(quoteId).then(response => {
            response.forEach(discount => {
              quote.quote_pets.forEach(pet => {
                if (discount.id === pet.id && discount.discounts.length > 0) {
                  pet.totalPrice = calculatePetTotalPrice(
                    pet,
                    discount.price_after_discount
                  );
                  pet.discount = discount.discounts;
                }
              });
            });
          })
        : Promise.resolve();
    })
    .then(() => {
      return checkExistingEmail(quote.email).then(response => {
        if (response.exists === true) {
          return getUserDetail(response.user_id).then(userResponse => {
            return getPetsByUserId(userResponse.data.id).then(petResponse => {
              quote.existingUserDetails = {
                ...userResponse.data,
                pets: petResponse.data,
              };
            });
          });
        }
      });
    })
    .then(() => {
      return { quote, quoteUrl };
    });
}
