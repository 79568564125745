export const BLOCKED_PAGES = [
  '/cancellations',
  '/documents',
  '/pets',
  '/cancellation-assignments/details',
  '/reimbursements',
  '/addresses',
  '/products',
  '/diagnosis',
  '/practices',
  '/refunds',
  '/pet-plans',
  '/notes',
  '/policies',
  '/denial-reason',
  '/vets',
  '/ops-user',
];
