import { parseAndFormatDate } from './date-moment';

export function transformUtilization({ claim, user, pet, utilization }) {
  // utilization endpoint returns the utilization for each plan
  // and we show the one that relates to this claim
  const plan = utilization.prevent.find(
    prevent => prevent.plan_id === claim.pet_plan_id
  );

  const formattedEffectiveDate = parseAndFormatDate(plan.plan_effective_date);
  const formattedEndDate = parseAndFormatDate(plan.plan_end_date);

  const formattedEntitlements = plan.entitlements.map(item => {
    return {
      name: item.entitlement.entitlement,
      available: item.entitlement.available,
      pending: item.entitlement.pending,
      utilized: item.entitlement.utilized,
    };
  });

  const formattedPlan = {
    annualCap: plan.annual_cap,
    description: plan.description,
    dateRange: `${formattedEffectiveDate} - ${formattedEndDate}`,
    status: pet.plans.find(plan => plan.id === claim.pet_plan_id).status,
    entitlements: formattedEntitlements.sort((a, b) => a.name.localeCompare(b.name)),
    reachedLimit: formattedEntitlements.every(
      entitlement => entitlement.available <= 0
    ),
  };

  return {
    pet: { id: pet.id, name: pet.name, isLapsed: !!pet.lapsed_since },
    user: {
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    },
    plan: formattedPlan,
    modal: {
      pet: pet,
      petPlan: claim.pet_plan_id
        ? pet.plans.find(plan => plan.id === claim.pet_plan_id)
        : pet.latest_active_plan,
      policy: claim.policy_id
        ? pet.policies.find(policies => policies.id === claim.policy_id)
        : pet.latest_active_policy,
    },
  };
}

export function transformUtilizationMga(props) {
  const { pet_plan, annual_cap, entitlements, plan, identity, pet } = props;

  const formattedEffectiveDate = pet_plan.plan_effective_date
    ? parseAndFormatDate(pet_plan.plan_effective_date)
    : 'N/A';
  const formattedEndDate = pet_plan.plan_end_date
    ? parseAndFormatDate(pet_plan.plan_end_date)
    : 'N/A';

  const formattedPlan = {
    annualCap: annual_cap,
    description: plan.description,
    dateRange: `${formattedEffectiveDate} - ${formattedEndDate}`,
    status: pet_plan.status,
    entitlements: entitlements.sort((a, b) => a.name.localeCompare(b.name)),
    reachedLimit: entitlements.every(entitlement => entitlement.available <= 0),
  };

  return {
    pet: { id: pet.id, name: pet.name },
    user: {
      id: identity.id,
      name: `${identity.first_name} ${identity.last_name}`,
    },
    plan: formattedPlan,
  };
}
