import makeStyles from '@mui/styles/makeStyles';

const reimbursementCancellationStyle = makeStyles(
  {
    modalTitle: {
      color: '#007DFF',
      marginBottom: '8px',
    },
    modalSubTitle: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '24px',
      color: '#000000',
    },
    totalReimbursement: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#000000',
      letterSpacing: '0.4px',
    },
    initiatedOn: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#000000',
      marginRight: '16px',
      letterSpacing: '0.4px',
    },
    statusDiv: {
      display: 'flex',
      marginTop: '16px',
      alignItems: 'baseline',
    },
    modalContent: { width: '500px' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    updateButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      color: 'white',
      width: '100%',
    },
    informationDiv: {
      border: '1px solid #EEEFF1',
      borderRadius: '4px',
      padding: '10px',
      marginTop: '16px',
      marginBottom: '24px',
    },
  },
  { name: 'reimbursement-cancellation' }
);

export { reimbursementCancellationStyle };
