import makeStyles from '@mui/styles/makeStyles';

const utilizationMgaStyle = makeStyles(
  {
    card: {
      width: '345px',
    },
    cardHeader: {
      position: 'relative',
      marginBottom: '28px',
    },
    cardTitle: {
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
      margin: '16px 16px 4px',
    },
    typeInfo: {
      fontSize: '10px',
      lineHeight: '16px',
      margin: '22px 16px 0',
      display: 'flex',
      fontWeight: '700',
      letterSpacing: '1.5px',
      alignItems: 'center',
    },
    typeDetail: {
      fontSize: '12px',
      lineHeight: '16px',
      margin: '4px 16px 0',
      fontWeight: '400',
      letterSpacing: '0.4px',
      color: '#767676',
    },
    toggleView: {
      marginLeft: '8px',
      cursor: 'pointer',
      color: '#007DFF',
    },
    infoContainer: {
      display: 'flex',
    },
    reachedLimit: {
      backgroundColor: 'rgba(63, 81, 181, 0.85)',
      borderRadius: '12px',
      height: '14px',
      fontWeight: '700',
      fontSize: '8px',
      lineHeight: '16px',
      letterSpacing: '0.25px',
      color: '#FFFFFF',
      padding: '0 8px',
      marginLeft: '12px',
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '16px',
    },
  },
  { name: 'utilization-mga' }
);

export { utilizationMgaStyle };
