import makeStyles from '@mui/styles/makeStyles';

const claimAuditLogTabStyle = makeStyles(
  {
    auditLog: {
      borderRadius: '5px',
      position: 'relative',
      zIndex: '2',
      backgroundColor: '#fff',
    },
    dashLine: {
      position: 'absolute',
      zIndex: '1',
      top: '16px',
      bottom: '0',
      left: '7px',
      height: '100%',
      borderLeft: '1px dashed #949494',
    },
    circle: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      marginRight: '12px',
    },
    auditLogEvent: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: '#000000',
    },
    auditLogDetail: {
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#767676',
      margin: '14px 0px',
    },
  },
  { name: 'claim-audit-log-tab' }
);

export { claimAuditLogTabStyle };
