import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import { useAuditLog } from '@pumpkincare/users';

import Tile from '../../shared/components/Tile';

function AuditLogDetails({ id }) {
  const { data, isLoading } = useAuditLog(id);

  return isLoading ? (
    <CircularProgress color='inherit' size={20} />
  ) : (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '32px' }}>
      {data.details.map(({ title, value }, index) => (
        <Tile key={index} title={title} content={value} />
      ))}
    </div>
  );
}

AuditLogDetails.propTypes = {
  id: PropTypes.string,
};

export default AuditLogDetails;
