import Typography from '@mui/material/Typography';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import { annualCapUtilizationStyle } from './annual-cap-utilization-style';

export default function AnnualCapUtilization({ data }) {
  const classes = annualCapUtilizationStyle();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.infoTitle}>ENTITLEMENT</Typography>
        <Typography className={classes.infoValue}>
          {formatCurrency(data.entitlement, { areCents: true })}
        </Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>AVAILABLE</Typography>
        <Typography className={classes.infoValue}>
          {formatCurrency(data.available, { areCents: true })}
        </Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>PENDING</Typography>
        <Typography className={classes.infoValue}>
          {formatCurrency(data.pending, { areCents: true })}
        </Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>UTILIZED</Typography>
        <Typography className={classes.infoValue}>
          {formatCurrency(data.utilized, { areCents: true })}
        </Typography>
      </div>
    </div>
  );
}
