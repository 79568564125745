import {
  Create,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

export default function OpsUserCreate() {
  return (
    <Create>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label='Create Ops User' />
          </Toolbar>
        }
      >
        <TextInput source='email' validate={required()} />
      </SimpleForm>
    </Create>
  );
}
