import {
  Form,
  required,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';

import {
  MGA_CLAIM_ATTACHMENT_TYPES,
  MGA_DOCUMENT_TYPES,
} from '../../../constants/claimAttachmentDocumentTypes';
import useBooleanInput from '../../../hooks/useBooleanInput';
import RoutePaths from '../../../routes';
import { editClaimAttachmentStyle } from './edit-claim-attachment-style';

function FileTypeSelectInput() {
  return (
    <SelectInput
      validate={required()}
      label='File Type'
      source='type'
      variant='outlined'
      fullWidth
      choices={MGA_CLAIM_ATTACHMENT_TYPES}
    />
  );
}

function EditClaimAttachment(props) {
  const { onClose, open, claimAttachment = {}, currentOpsUserData = {} } = props;
  const [isSaving, toggleSaving] = useBooleanInput();

  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const classes = editClaimAttachmentStyle();

  function handleAddLineItemClick(props) {
    const { type, source_file_name } = props;
    toggleSaving();

    if (
      claimAttachment.type &&
      MGA_DOCUMENT_TYPES[claimAttachment.type] === MGA_DOCUMENT_TYPES.claim_invoice
    ) {
      analyticsTrack({
        event: 'clicked_edit_invoice_claims',
        label: 'claims edit invoice',
        category: 'claims admin actions',
        ops_user_id: currentOpsUserData?.id,
        claim_id: claimAttachment?.claim_id,
        file_name: source_file_name,
      });
    }

    update(
      RoutePaths.documentsV2,
      {
        id: '',
        data: {
          user_id: claimAttachment.id,
          type: type,
          source_file_name: source_file_name,
          id: claimAttachment.id,
          location: claimAttachment.location,
          pet_id: claimAttachment.pet_id,
          claim_id: claimAttachment.claim_id,
        },
      },
      {
        onSuccess: () => {
          toggleSaving();
          refresh();
          onClose();
        },
        onError: error => {
          toggleSaving();
          notify(`There was an error while editing the document: ${error.message}`, {
            type: 'error',
          });
        },
      }
    );
  }

  const validateForm = values => {
    const errors = {};
    const regex = /[&/#`\\%=|,+$~%":;@^?<>{}[\]]/gi;
    const source_file_name = values.source_file_name ?? '';

    if (
      !source_file_name ||
      String(source_file_name).replace(/\s+/g, '').length <= 0
    ) {
      errors.source_file_name = 'Required';
    }

    if (regex.test(source_file_name)) {
      errors.source_file_name = 'Special characters are not allowed';
    }

    return errors;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        defaultValues={{
          type: claimAttachment.type,
          source_file_name: claimAttachment.source_file_name,
        }}
        onSubmit={handleAddLineItemClick}
        validate={validateForm}
      >
        <DialogTitle>
          <Typography className={classes.modalTitle}>Update Document</Typography>
        </DialogTitle>

        <DialogContent className={classes.modalContent}>
          <FileTypeSelectInput />
          <TextInput
            variant='outlined'
            validate={required()}
            label='File Name'
            source='source_file_name'
            fullWidth
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isSaving}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button disabled={isSaving} type='submit' className={classes.addButton}>
            UPDATE
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

EditClaimAttachment.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  claimAttachment: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    location: PropTypes.string,
    pet_id: PropTypes.string,
    claim_id: PropTypes.string,
  }),
  currentOpsUserData: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default EditClaimAttachment;
