import makeStyles from '@mui/styles/makeStyles';

const BUTTON = {
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  border: '1px solid #CDCFD3',
  padding: '10px',
};

const viewMedicalRecordsRequestStyle = makeStyles(
  {
    paper: { maxWidth: '1000px', width: '64vw' },

    modalTitle: {
      padding: '32px',
      color: '#373F51',
    },

    modalContent: {
      padding: '0 32px',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 24px',
    },
    section: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 4px',
      paddingBottom: '8px',
      borderBottom: '1px solid #CDCFD3',
      marginBottom: '8px',
      '& p': {
        color: '#373F51',
        '& span': { color: '#6B717E' },
      },
    },

    audit: {
      border: '1px solid #CDCFD3',
      borderRadius: '8px',
      padding: '16px',
      alignSelf: 'flex-start',
    },
    loader: {
      margin: '0 auto',
      display: 'block',
    },

    // modal CTA styles
    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '32px',
    },
    unsetSpacing: {
      '& > :not(:first-child)': { marginLeft: '0' },
    },
    cancelButton: { ...BUTTON },
    confirmButton: {
      ...BUTTON,
      color: 'white',
      backgroundColor: '#5368F5',
      borderColor: '#5368F5',
      '&:disabled': {
        borderColor: '#CDCFD3',
        backgroundColor: '#CDCFD3',
        color: 'white',
      },
    },
  },
  { name: 'view-medical-records-request' }
);

export { viewMedicalRecordsRequestStyle };
