/* eslint no-unused-vars: 0 */
// Modified from SimpleShowLayout source code

import { Children, cloneElement } from 'react';
import { CardContentInner, Labeled } from 'react-admin';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  translate,
  ...rest
}) => rest;

const tableStyles = {
  tableWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const TableLayout = withStyles(tableStyles)(props => {
  const {
    basePath,
    className,
    children,
    record,
    resource,
    version,
    classes,
    style,
    labelClassName,
    ...rest
  } = props;
  return (
    <CardContentInner
      key={version}
      {...sanitizeRestProps(rest)}
      style={style}
      className={classnames(classes.tableWrapper, className)}
    >
      {Children.map(children, field => {
        if (!field) {
          return null;
        }

        // only append props to child if they exist
        const fullChildProps = { resource, record, basePath };
        const childProps = Object.entries(fullChildProps).reduce(
          (result, [key, value]) => {
            if (value) result[key] = value;
            return result;
          },
          {}
        );

        return (
          <div
            key={field.props.source}
            className={classnames(
              `ra-field ra-field-${field.props.source}`,
              field.props.className
            )}
          >
            {field.props.addLabel ? (
              <Labeled
                record={record}
                resource={resource}
                label={field.props.label}
                source={field.props.source}
                disabled={false}
              >
                {field}
              </Labeled>
            ) : null}

            {typeof field.type === 'string' && !field.props.addLabel
              ? { field }
              : null}

            {typeof field.type !== 'string' ? cloneElement(field, childProps) : null}
          </div>
        );
      })}
    </CardContentInner>
  );
});

TableLayout.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  record: PropTypes.object,
  resource: PropTypes.string,
  version: PropTypes.number,
};

export default TableLayout;
