import makeStyles from '@mui/styles/makeStyles';

const processingStepStyle = makeStyles(
  {
    claimStepWrapper: { display: 'flex', alignItems: 'center', width: '100%' },
    stepDescription: {
      color: '#0B79D0',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      marginRight: '24px',
    },
    numberIcon: {
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      textAlign: 'center',
      marginRight: '8px',
    },
    numberDetail: {
      fontSize: '12px',
      marginTop: '2px',
      fontWeight: '400',
      lineHeight: '20px',
      color: 'white',
    },
    horizontalLine: { flex: 1, height: '1px', margin: '0px 8px' },
  },
  { name: 'claim-processing-step' }
);

export { processingStepStyle };
