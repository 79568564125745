import { useNotify } from 'react-admin';
import Link from '@mui/material/Typography';

import { useClaimDocumentSummary } from '@pumpkincare/claims';

function DownloadClaimDocumentSummaryLink(props) {
  const notify = useNotify();
  const { preventClaimId, linkLabel, classes } = props;

  const {
    data: claimDocumentSummaryData,
    isLoading: isClaimDocumentLoading,
    error,
  } = useClaimDocumentSummary(preventClaimId);

  function handleClick() {
    if (claimDocumentSummaryData?.document_signed_url && !error) {
      window.open(claimDocumentSummaryData.document_signed_url);
    } else {
      notify(
        'Claim documents will be available for download soon, check back later',
        'warning'
      );
    }
  }
  return (
    <Link
      className={classes.link}
      onClick={handleClick}
      disabled={isClaimDocumentLoading}
    >
      {linkLabel || 'Claim Summary Document'}
    </Link>
  );
}

export default DownloadClaimDocumentSummaryLink;
