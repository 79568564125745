import { useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const red = '#E84D2A';
const green = '#3EC28F';
const amber = '#F2994A';

const styles = {
  paid: { backgroundColor: green },
  unpaid: { backgroundColor: red },
  pending: { backgroundColor: amber },
  refundPending: { backgroundColor: red },
  refunded: { backgroundColor: red },
  disputeWarning: { backgroundColor: amber },
  disputeReview: { backgroundColor: amber },
  disputeWon: { backgroundColor: green },
  disputeLost: { backgroundColor: red },
};

function StatusChip({ classes }) {
  const record = useRecordContext();

  let refundStatus = record.status;
  if (record.dispute_status !== null) {
    refundStatus = record.dispute_status;
  } else if (record.refunds.length > 0) {
    refundStatus = 'refunded';
    record.refunds.forEach(refund => {
      if (refund.status === 'pending') {
        refundStatus = 'refund pending';
      }
    });
  }

  return (
    <Chip
      className={classnames({
        [classes.paid]: refundStatus === 'paid',
        [classes.unpaid]: refundStatus === 'open',
        [classes.pending]: refundStatus === 'pending',
        [classes.refundPending]: refundStatus === 'refund pending',
        [classes.refunded]: refundStatus === 'refunded',
        [classes.disputeWarning]: refundStatus === 'dispute_warning',
        [classes.disputeReview]: refundStatus === 'dispute_review',
        [classes.disputeWon]: refundStatus === 'dispute_won',
        [classes.disputeLost]: refundStatus === 'dispute_lost',
      })}
      style={{ color: '#000000', fontSize: '10px', height: '20px' }}
      label={refundStatus.toUpperCase().replace('_', ' ')}
    />
  );
}

StatusChip.defaultProps = {
  classes: {},
};

StatusChip.propTypes = {
  classes: PropTypes.shape({
    paid: PropTypes.string,
    unpaid: PropTypes.string,
    pending: PropTypes.string,
    refundPending: PropTypes.string,
    refunded: PropTypes.string,
    disputeWarning: PropTypes.string,
    disputeReview: PropTypes.string,
    disputeWon: PropTypes.string,
    disputeLost: PropTypes.string,
  }),
};
export default withStyles(styles)(StatusChip);
