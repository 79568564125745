import { Datagrid, List, Pagination, TextField, useListContext } from 'react-admin';
import { CircularProgress } from '@mui/material';

import { useOpsList } from '@pumpkincare/users';

import RoutePaths from '../../routes';
import { orderUsersByEmail } from '../../shared/utils/orderUsersByEmail';
import CancellationFilter from './cancellation-filter';
import CancellationDetailsField from './CancellationDetailsField';
import CancellationUpdateButton from './CancellationUpdateButton';

function CancellationBulkActionButtons(props) {
  return <CancellationUpdateButton {...props} />;
}

function DatagridWrapper({ rowClick, ...props }) {
  const { isLoading } = useListContext();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid {...props} rowClick={rowClick}>
      <TextField label='First Name' source='user.first_name' sortable={false} />
      <TextField label='Last Name' source='user.last_name' sortable={false} />
      <TextField label='PKN Number' source='user.pumpkin_id' sortable={false} />
      <CancellationDetailsField label={'Requested Date'} source={'created_at'} />
      <CancellationDetailsField
        label={'Pets'}
        source={'pet.name'}
        sortable={false}
      />
      <CancellationDetailsField
        label={'Type'}
        source={'cancellation_type'}
        sortable={false}
      />
      <CancellationDetailsField
        label={'Status'}
        source={'internal_cancellation_status'}
        sortable={false}
      />
      <CancellationDetailsField
        label={'Reason'}
        source={'cancellation_reason'}
        sortable={false}
      />
      <CancellationDetailsField
        label={'Assigned To'}
        source={'ops_user.email'}
        sortable={false}
      />
    </Datagrid>
  );
}

function CancellationList() {
  const { data, isLoading } = useOpsList();

  function rowClick(id) {
    return `/${RoutePaths.cancellationDetails}/${id}/show`;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <List
      title={'Cancellations Dashboard'}
      actions={null}
      filters={<CancellationFilter opsData={orderUsersByEmail(data)} />}
      filterDefaultValues={{
        internal_cancellation_status: 'cancellation_received',
      }}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <DatagridWrapper
        bulkActionButtons={<CancellationBulkActionButtons />}
        rowClick={rowClick}
      />
    </List>
  );
}
export default CancellationList;
