import { useGetOne } from 'react-admin';

export function useReimbursementById(reimbursementId) {
  return useGetOne(
    'reimbursements',
    { id: reimbursementId },
    { enabled: !!reimbursementId }
  );
}

export function useCancellationReimbursement(reimbursementId, cancellationId) {
  return useGetOne(
    'reimbursements',
    { id: `${reimbursementId}/cancel/${cancellationId}` },
    { enabled: !!reimbursementId && !!cancellationId }
  );
}
