import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  SingleFieldList,
  TextField,
  useListContext,
} from 'react-admin';
import { CircularProgress } from '@mui/material';

import { MoneyField } from '../../components/MoneyField';
import rowClick from '../../lib/rowClick';
import InvoiceFilter from './invoice-filter';

function DatagridWrapper(props) {
  const { isLoading } = useListContext();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props} rowClick={rowClick}>
      <TextField label='First Name' source='user.first_name' sortable={false} />
      <TextField label='Last Name' source='user.last_name' sortable={false} />
      <TextField label='Email' source='user.email' sortable={false} />
      <ArrayField label='Pet Name' source='pet_names' sortable={false}>
        <SingleFieldList linkType={null}>
          <FunctionField
            render={record => <ChipField record={{ name: record }} source='name' />}
          />
        </SingleFieldList>
      </ArrayField>
      <MoneyField label='Total' source='total' />
      <MoneyField label='Refund' source='total_refund' />
      <DateField label='Date' source='created_at' />
      <TextField
        label='Invoice Number'
        source='invoice_number'
        sortBy={'external_number'}
      />
      <TextField label='Status' source='status' sortable={true} />
    </Datagrid>
  );
}

export default function InvoiceList(props) {
  return (
    <List
      {...props}
      actions={null}
      filters={<InvoiceFilter />}
      bulkActionButtons={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <DatagridWrapper />
    </List>
  );
}
