import makeStyles from '@mui/styles/makeStyles';

const checkInputStyles = makeStyles(
  {
    label: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      color: '#1A1A1A',
      marginTop: '16px',
      marginBottom: '4px',
    },
    input: {
      border: '1px solid #949494',
      borderRadius: '4px',
      height: '60px',
      width: '100%',
      boxSizing: 'border-box',
      padding: '8px',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    helper: {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '13px',
      color: '#1A1A1A',
      marginTop: '4px',
      '& span': {
        color: '#595959',
      },
    },
    error: {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '13px',
      color: '#EE0004',
      marginTop: '4px',
    },
  },
  { name: 'check-input' }
);

export { checkInputStyles };
