import { Children, cloneElement, isValidElement, useState } from 'react';
import { matchPath, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { CardContent, Divider, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form, useResourceContext } from 'ra-core';

function getTabbedFormTabFullPath(tab, index) {
  if (tab.props.path != null) return tab.props.path;

  return index > 0 ? index.toString() : '';
}

export function useGetCurrentStep() {
  const { pathname } = useLocation();
  const lastParam = pathname.split('/').pop();
  return parseInt(lastParam, 10) || 0;
}

function TabbedFormTabs({ children, syncWithLocation, value, ...tabsProps }) {
  const params = useParams();

  // params will include eventual parameters from the root pathname and * for the remaining part
  // which should match the tabs paths
  const tabValue = params['*'];

  return (
    <Tabs
      value={syncWithLocation ? tabValue : value}
      indicatorColor='primary'
      {...tabsProps}
    >
      {Children.map(children, (tab, index) => {
        if (!isValidElement(tab)) return null;

        // Builds the full tab which is the concatenation of the last matched route in the
        // TabbedShowLayout hierarchy (ex: '/posts/create', '/posts/12', , '/posts/12/show')
        // and the tab path.
        // This will be used as the Tab's value
        const tabPath = getTabbedFormTabFullPath(tab, index);

        return cloneElement(tab, {
          intent: 'header',
          value: syncWithLocation ? tabPath : index,
          syncWithLocation,
        });
      })}
    </Tabs>
  );
}

function useFormRootPath() {
  const location = useLocation();
  const createMatch = matchPath(':resource/create/*', location.pathname);
  const editMatch = matchPath(':resource/:id/*', location.pathname);

  if (createMatch) {
    return createMatch.pathnameBase;
  }

  if (editMatch) {
    return editMatch.pathnameBase;
  }

  return '';
}

const PREFIX = 'RaTabbedForm';
const TabbedFormClasses = {
  errorTabButton: `${PREFIX}-errorTabButton`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .MuiTab-root.${TabbedFormClasses.errorTabButton}`]: {
    color: theme.palette.error.main,
  },
}));

// eslint-disable-next-line no-unused-vars
function sanitizeRestProps({ record, resource, ...rest }) {
  return rest;
}

function CustomTabbedForm(props) {
  const {
    children,
    className,
    component: Component,
    syncWithLocation,
    tabs,
    toolbar,
    ...rest
  } = props;

  const formRootPathname = useFormRootPath();
  const location = useLocation();
  const resource = useResourceContext(props);

  const [tabValue, setTabValue] = useState(0);

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  function renderTabHeaders() {
    return cloneElement(
      tabs,
      {
        onChange: handleTabChange,
        syncWithLocation,
        value: tabValue,
      },
      children
    );
  }

  return (
    <Form formRootPathname={formRootPathname} {...props}>
      <Root className={clsx('tabbed-form', className)} {...sanitizeRestProps(rest)}>
        {syncWithLocation ? (
          <Routes>
            <Route path='/*' element={renderTabHeaders()} />
          </Routes>
        ) : (
          renderTabHeaders()
        )}
        <Divider />
        <Component>
          {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
          {Children.map(children, (tab, index) => {
            if (!tab) {
              return null;
            }

            const tabPath = getTabbedFormTabFullPath(tab, index);
            const locationPath = location.pathname.split('/')[3];
            const hidden = index !== (locationPath ? parseInt(locationPath) : 0);

            return isValidElement(tab)
              ? cloneElement(tab, {
                  intent: 'content',
                  resource,
                  hidden,
                  value: syncWithLocation ? tabPath : index,
                })
              : null;
          })}
        </Component>
        {toolbar !== false ? toolbar : null}
      </Root>
    </Form>
  );
}

const DefaultTabs = <TabbedFormTabs />;
const DefaultComponent = CardContent;

const DEFAULT_PROPS = {
  className: '',
  syncWithLocation: true,
};
TabbedFormTabs.defaultProps = DEFAULT_PROPS;
CustomTabbedForm.defaultProps = {
  ...DEFAULT_PROPS,
  component: DefaultComponent,
  tabs: DefaultTabs,
  toolbar: false,
};

const PROP_TYPES = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  syncWithLocation: PropTypes.bool,
  value: PropTypes.node,
};

TabbedFormTabs.propTypes = PROP_TYPES;
CustomTabbedForm.propTypes = {
  ...PROP_TYPES,
  component: PropTypes.object,
  tabs: PropTypes.node,
  toolbar: PropTypes.node,
};

export default CustomTabbedForm;
