import makeStyles from '@mui/styles/makeStyles';

const editClaimAttachmentStyle = makeStyles(
  {
    modalTitle: {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '32px',
      color: '#2196F3',
    },
    modalContent: { width: '500px' },
    cancelButton: {
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#007DFF',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    addButton: {
      borderRadius: '4px',
      backgroundColor: '#007DFF',
      color: 'white',
      width: '100%',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
    },
  },
  { name: 'edit-claim-attachment' }
);

export { editClaimAttachmentStyle };
