import moment from 'moment';

// i want to deprecate this functions but sonarcloud then brings up 100+ issues soooo i'll deprecate another time

export function parseAndFormatDate(value, format = 'MM/DD/YYYY') {
  return moment.parseZone(value).format(format);
}

export function formatIsoToMMDDYYYY(dateString) {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      // Date parsing failed
      return '';
    }
    return moment.utc(dateString).format('MM/DD/YYYY');
  } catch (error) {
    console.error('Error occurred while formatting date:', error);
    return '';
  }
}

export function getTimeDifferenceFromCurrentDate(date, type) {
  let dateDifference = moment.duration(
    moment
      .parseZone(moment(new Date()))
      .diff(moment.parseZone(moment(new Date(date))))
  );

  if (type === 'minutes') {
    return parseInt(dateDifference.asMinutes());
  }

  if (type === 'hours') {
    return parseInt(dateDifference.asHours());
  }

  if (type === 'days') {
    return parseInt(dateDifference.asDays());
  }

  return dateDifference;
}

export function calculateEventTimeFromCurrentDate(eventDate) {
  const differenceAsHours = getTimeDifferenceFromCurrentDate(eventDate, 'hours');
  const differenceAsMinutes = getTimeDifferenceFromCurrentDate(eventDate, 'minutes');

  if (differenceAsHours > 24)
    return moment.parseZone(eventDate).format('MMMM D, YYYY');

  if (differenceAsMinutes <= 59) return `${differenceAsMinutes} min ago`;

  return `${differenceAsHours} ${differenceAsHours === 1 ? 'hr' : 'hrs'} ago`;
}

export function getDateAsFormattedUTC(date, format = 'YYYY-MM-DD') {
  return moment.utc(date).format(format);
}

export function getDateAsFormattedLocalTZ(date, format = 'YYYY-MM-DD') {
  return moment.utc(date).local().format(format);
}

export function getDateForFormat(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}

export function dateIsAfter(date, afterDate) {
  return moment(date).isAfter(afterDate);
}

export function validateDateYYYYMMDD(dateStr) {
  // regex split via https://regexsplitter.ryadel.com/
  const regex = new RegExp(
    '^\\d{4}(-|\\/)([1-9]|((0)[1-9])|((1)[0-2]))(-|\\/)([1' + // NOSONAR
      '-9]|((0)[1-9])|([1-2][0-9])|((3)[0-1]))$' // NOSONAR
  );

  return regex.test(dateStr);
}

export function addYearsToDate(date, years = 0) {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

export function isCreditCardExpDateValid(expMonth, expYear) {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  if (parseInt(expYear) > year) {
    return true;
  } else if (parseInt(expYear) === year) {
    return parseInt(expMonth) >= month;
  }

  return false;
}

export function isDateWithinRange(dateStr, startDateStr, endDateStr) {
  const date = new Date(dateStr);
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  return date >= startDate && date <= endDate;
}
