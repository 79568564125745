import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    wrapper: {
      boxShadow: '-1px -2px 3px rgba(0, 0, 0, 0.08)',
      padding: '48px 26px',
      paddingTop: 0,
    },
    item: {
      paddingTop: '48px',
    },
    name: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
    },
    title: {
      marginTop: '15px',
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '16px',
    },
    price: {
      marginTop: '15px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
  },
  { name: 'cancellation-refund' }
);
