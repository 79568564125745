import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const theme = createTheme();

const useStyles = makeStyles(
  {
    inputStyle: {
      '& > div.MuiInputBase-root': {
        height: '56px',
        width: '234px',
        marginBottom: '20px',
      },
    },
    workInProgress: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
    },
    titleStyle: {
      fontSize: '18px',
      fontWeight: '600',
      paddingBottom: '16px',
    },
    tableSize: {
      width: '1200px',
      paddingLeft: '0px',
    },
    label: {
      fontSize: '10px',
      fontWeight: '500',
    },
    customerInfo: {
      fontWeight: '700',
      fontSize: '14px',
    },
    iconCell: {
      fontSize: '14px',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 400,
      display: 'flex',
      flexDirection: 'column',
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
    },
    emailDetailsFormItem: {
      display: 'grid',
    },
    emailDetailsForm: {
      margin: theme.spacing(1),
      minWidth: 400,
      display: 'flex',
      flexDirection: 'row',
    },
    noteDate: {
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    noteAuthorName: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    noteText: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    petName: {
      fontSize: '14px',
      color: '#3F51B5',
      marginBottom: '6px',
    },
    titleText: {
      display: 'flex',
      fontWeight: '600',
      fontSize: '10px',
      marginBottom: '5px',
    },
    infoText: {
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: '5px',
    },
    cardGridStyle: {
      display: 'flex',
      flexDirection: 'row',
      gap: '2px',
      margin: '12px 0',
    },
    cardTitleStyle: {
      fontSize: '20px',
      fontWeight: '600',
    },

    cardItemLabelStyle: {
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    cardItemValueStyle: {
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  { name: 'cancellation' }
);

export default useStyles;
