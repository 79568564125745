export const MY_VET_CLINIC = 'MY_VET_CLINIC';

export const HDYHAU_OPTIONS = [
  { value: MY_VET_CLINIC, label: 'My Vet Clinic' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: 'BREEDER_OR_SHELTER', label: 'Breeder/Shelter' },
  { value: 'GOOGLE_OR_AD', label: 'Google/Search Ad' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'TELEVISION', label: 'Television' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend/Family' },
  { value: 'SNAIL_MAIL', label: 'Snail Mail' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Review Site' },
  { value: 'OTHER', label: 'Other' },
];
