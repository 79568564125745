import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

import { getClaimType } from '@pumpkincare/claims';

import { formatCurrency, parseAndFormatDate } from '../../../../shared/utils';
import { claimSubmissionInformationStyle } from './claim-submission-information-style';

export default function ClaimSubmissionInformation() {
  const classes = claimSubmissionInformationStyle();
  const record = useRecordContext();
  return (
    <div className={classes.submissionDetail}>
      <div>
        <Typography className={classes.header}>Submission Details</Typography>
      </div>
      <div className={classes.titleWrapper}>
        <div>
          <Typography className={classes.title}>CLAIM ID</Typography>
          <Typography className={classes.detail}>{record.id}</Typography>
        </div>
        <div>
          <Typography className={classes.title}>TYPE</Typography>
          <Typography className={classes.detail}>{getClaimType(record)}</Typography>
        </div>
        <div>
          <Typography className={classes.title}>AMOUNT CLAIMED</Typography>
          <Typography className={classes.detail}>
            {formatCurrency(record.claimed_amount, { areCents: true })}
          </Typography>
        </div>
        <div>
          <Typography className={classes.title}>SUBMISSION DATE</Typography>
          <Typography className={classes.detail}>
            {parseAndFormatDate(record.date_submitted)}
          </Typography>
        </div>
        <div>
          <Typography className={classes.title}>INCIDENT DATE</Typography>
          <Typography className={classes.detail}>
            {parseAndFormatDate(record.loss_date)}
          </Typography>
        </div>
        <div>
          <Typography className={classes.title}>ESTIMATE</Typography>
          <Typography className={classes.detail}>
            {record.is_estimate === true ? 'Yes' : 'No'}
          </Typography>
        </div>
      </div>
    </div>
  );
}
