import makeStyles from '@mui/styles/makeStyles';

const BUTTON = {
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  border: '1px solid #CDCFD3',
  padding: '10px',
};

const markReceivedModalStyle = makeStyles(
  {
    paper: { maxWidth: '470px', width: '80vw' },

    modalTitle: {
      padding: '32px',
      color: '#373F51',
    },

    modalContent: {
      padding: '0 32px',
    },
    infoBox: {
      padding: '16px',
      border: '1px solid #EBECEE',
      borderRadius: '8px',
      marginBottom: '32px',
    },
    contact: {
      color: '#6B717E',
      paddingBottom: '10px',
      borderBottom: '1px solid #949494',
      margin: '10px auto',
    },

    question: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#373F51',
      '& > span': { color: '#C21B44' },
      '&.Mui-focused': { color: '#373F51' },
    },
    labelRoot: {
      marginLeft: 0,
    },
    labelText: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    required: { '& span': { color: '#C21B44', fontSize: '16px' } },
    textLabel: {
      margin: 0,
      width: '100%',
      alignItems: 'flex-start',
      '& span': { fontSize: '14px' },
      '& > div > div': { background: 'white' }, // input only
    },

    // modal CTA styles
    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '32px',
    },
    unsetSpacing: {
      '& > :not(:first-child)': { marginLeft: '0' },
    },
    cancelButton: { ...BUTTON },
    confirmButton: {
      ...BUTTON,
      color: 'white',
      backgroundColor: '#5368F5',
      borderColor: '#5368F5',
      '&:disabled': {
        borderColor: '#CDCFD3',
        backgroundColor: '#CDCFD3',
        color: 'white',
      },
    },
  },
  { name: 'mark-received-modal' }
);

export { markReceivedModalStyle };
