import { Typography } from '@mui/material';
import classnames from 'classnames';

import { OrganizationsCreateStyles } from '../../organizations-create/organizations-create.styles';

export function CreateTitle(props) {
  const classes = OrganizationsCreateStyles();

  return (
    <Typography
      variant='h6'
      gutterBottom
      className={classnames(classes.title, props.className)}
    >
      Create Discount
    </Typography>
  );
}
