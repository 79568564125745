import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
  {
    grid: {
      maxWidth: '65%',
    },
  },
  { name: 'invoice-show' }
);

export default useStyles;
