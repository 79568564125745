import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import RoutePaths from '../../../../routes';

function CreateQuoteModal(props) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} aria-label='Create New Quote'>
      <DialogTitle style={{ fontSize: '24px', paddingBottom: '27px' }}>
        What type of quote would you like to create?
      </DialogTitle>
      <DialogContent>
        <Button
          style={{ marginRight: '20px' }}
          variant='outlined'
          size='medium'
          label='Insurance'
          component={Link}
          to={{
            pathname: `/${RoutePaths.quotes}/create`,
          }}
        />
        <Button
          variant='outlined'
          size='medium'
          label='Wellness'
          component={Link}
          to={{
            pathname: `/${RoutePaths.wellnessQuote}/create`,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='text' size='medium' label='Cancel' onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}

CreateQuoteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateQuoteModal;
