import {
  DateInput,
  Edit,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { useCustomer, usePetAvailablePlans } from '@pumpkincare/users';

import { getPetPlanStatus } from '../../constants/petPlanStatus';
import RoutePaths from '../../routes';
import Tile from '../../shared/components/Tile';
import {
  getDateAsFormattedUTC,
  getDefaultPetPlanEffectiveDate,
  getDefaultPetPlanEndDate,
  getPetPlanByStatus,
  getPetPlansStatusOptions,
} from '../../shared/utils';
import MgaPlanInput from './mga-plan-input';
import PetVet from './PetVet';

function MgaPetPlanEdit(props) {
  const { record, pet } = props.location.state;
  const navigate = useNavigate();

  const petPlansStatus = getPetPlansStatusOptions(pet);
  const redirect = `/${RoutePaths.customers}/${pet.user_id}/show`;
  const { data: userData } = useCustomer(pet?.user_id);

  const {
    data: availablePlans = [],
    isLoading,
    refetch,
  } = usePetAvailablePlans(pet?.id);

  const address = userData ? userData.rating_address : {};

  function loadPlans() {
    refetch();
  }

  function changePlan(record) {
    const plan = getPetPlanByStatus(pet, record);
    navigate(`/${RoutePaths.petPlans}/${plan.id}`, {
      state: {
        record: plan,
        pet,
      },
    });
  }

  function PetPlanToolbar(props) {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  }

  return (
    <Edit
      {...props}
      mutationMode='undoable'
      transform={data => {
        return { ...data, plan_id: data.plan.id };
      }}
    >
      <SimpleForm
        toolbar={<PetPlanToolbar />}
        redirect={redirect}
        validateOnBlur={true}
      >
        <Tile
          title={'Customer Name'}
          content={`${userData?.first_name} ${userData?.last_name}`}
          style={{ width: '472px' }}
        />

        <Tile
          title={'Pet Name'}
          content={pet.name}
          style={{ width: '472px', marginTop: '36px' }}
        />

        <RadioButtonGroupInput
          source='status'
          choices={petPlansStatus}
          fullWidth={true}
          onChange={changePlan}
        />

        <div style={{ width: '472px', marginTop: '36px' }}>
          <PetVet petId={pet.id} onChange={loadPlans} address={address} />
        </div>

        <div
          style={{
            width: '472px',
            marginTop: '44px',
            minHeight: '84px',
          }}
        >
          {isLoading ? (
            <Typography style={{ padding: '8px', color: 'rgba(0, 0, 0, 0.6)' }}>
              Loading plans...
            </Typography>
          ) : (
            <FormDataConsumer>
              {formDataProps => (
                <MgaPlanInput
                  {...formDataProps}
                  source='plan'
                  options={availablePlans}
                  label={'Prevent Plan'}
                  validate={[required()]}
                  required={true}
                />
              )}
            </FormDataConsumer>
          )}
        </div>

        <SelectInput
          label='Status'
          source='plan_status'
          choices={getPetPlanStatus(record.status)}
          defaultValue={record.status}
          validate={[required()]}
          style={{ width: '472px' }}
        />

        <div style={{ width: '472px', display: 'flex' }}>
          <DateInput
            label='Effective Date'
            source='effective_date'
            defaultValue={getDateAsFormattedUTC(
              getDefaultPetPlanEffectiveDate(pet, record)
            )}
            validate={[required()]}
            fullWidth
            style={{ marginRight: '8px' }}
          />

          <DateInput
            label='End Date'
            source='end_date'
            defaultValue={getDateAsFormattedUTC(
              getDefaultPetPlanEndDate(pet, record)
            )}
            validate={[required()]}
            fullWidth
            style={{ marginLeft: '8px' }}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
}

export default MgaPetPlanEdit;
