import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TRANSACTION_FEE } from '../../../constants/policy';
import { formatCurrency } from '../../../shared/utils/currencyUtils';
import { isPetPlanInCancelledStatus } from '../../../shared/utils/PetPlanUtils';
import { getFullPercentage } from '../../../shared/utils/petUtils';
import { renewalChangeSummaryStyle } from './renewal-change-summary-style';

function RenewalChangeSummary(props) {
  const classes = renewalChangeSummaryStyle();

  const {
    showSubTotal = true,
    showGrandTotal = true,
    showTransactionFee = true,
    petData,
    renewalChangePolicyData = { renewal_pending_policy: { annual_limit: '' } },
  } = props;
  const annualLimit = formatCurrency(
    renewalChangePolicyData?.renewal_pending_policy.annual_limit
      .slice(0, -3)
      .replace(/,/g, ''),
    {
      areCents: false,
      hideZero: true,
      ifNaN: 'Unlimited',
    }
  );

  const isChargedAnnually = !!petData?.renewal_pending_policy?.is_annualized_policy;
  const months = isChargedAnnually ? 12 : 1;

  const policyFiledRate =
    (renewalChangePolicyData?.renewal_pending_policy?.filed_rate || 0) * months;
  const policyRawRate =
    (renewalChangePolicyData?.renewal_pending_policy?.raw_rate || 0) * months;

  const policyCappedRate =
    (parseFloat(renewalChangePolicyData?.renewal_pending_policy?.capped_rate) <
    parseFloat(renewalChangePolicyData?.renewal_pending_policy?.filed_rate)
      ? renewalChangePolicyData?.renewal_pending_policy?.capped_rate
      : 0) * months;

  const petPlanCost = !isPetPlanInCancelledStatus(
    petData?.renewal_pending_pet_plan?.status
  )
    ? (petData?.renewal_pending_pet_plan?.cost || 0) * months
    : 0;
  const transactionFee = isChargedAnnually ? 0 : TRANSACTION_FEE;
  const subtotal = !!policyCappedRate
    ? policyCappedRate + petPlanCost
    : policyFiledRate + petPlanCost;
  const grandTotal = subtotal + transactionFee;
  const hasDiscount =
    !!renewalChangePolicyData?.renewal_pending_policy
      ?.multiple_pet_discount_percentage ||
    !!renewalChangePolicyData?.renewal_pending_policy?.group_discount_percentage;
  const subTotalFormatted = formatCurrency(subtotal, {
    areCents: false,
  });

  const grandTotalFormatted = formatCurrency(grandTotal, {
    areCents: false,
  });

  return (
    <div>
      <Typography className={classes.planCappedRate}>
        {!!policyCappedRate &&
          `${formatCurrency(policyCappedRate, {
            areCents: false,
          })} ${isChargedAnnually ? '/ yr' : '/ mo'}`}
      </Typography>
      <div className={classes.planBenefitsContainer}>
        <Typography className={classes.planHeader}>Pumpkin Pet insurance</Typography>

        <Typography
          className={classnames(classes.planHeader, {
            [classes.discountColor]: hasDiscount === true,
            [classes.cappedRateStrike]: policyCappedRate,
          })}
        >
          {`${formatCurrency(policyFiledRate, {
            areCents: false,
          })} ${isChargedAnnually ? '/ yr' : '/ mo'}`}
        </Typography>
      </div>
      <div>
        <ul className={classes.planBenefitsList}>
          <li>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={classes.planBenefits}>
                {`${getFullPercentage(
                  renewalChangePolicyData?.renewal_pending_policy?.coinsurance
                )} Reimbursement Rate`}
              </Typography>
              {hasDiscount ? (
                <Typography className={classes.planBenefitsWithoutDiscount}>
                  {`${formatCurrency(policyRawRate, {
                    areCents: false,
                  })} ${isChargedAnnually ? '/ yr' : '/ mo'}`}
                </Typography>
              ) : null}
            </div>
          </li>
          <li>
            <Typography className={classes.planBenefits}>
              {`${formatCurrency(
                renewalChangePolicyData?.renewal_pending_policy?.deductible,
                {
                  areCents: false,
                  hideZero: true,
                  ifNaN: 'Unlimited',
                }
              )} Deductible`}
            </Typography>
          </li>
          <li>
            <Typography className={classes.planBenefits}>
              {`${
                annualLimit === 'Unlimited' ? annualLimit : annualLimit + 'K'
              } Annual Limit`}
            </Typography>
          </li>
        </ul>
        {!!renewalChangePolicyData?.renewal_pending_policy
          ?.multiple_pet_discount_percentage ? (
          <Typography className={classes.planDiscount}>
            Multi-pet discount{' '}
            {parseFloat(
              1 -
                renewalChangePolicyData.renewal_pending_policy
                  .multiple_pet_discount_percentage
            ).toFixed(1) * 100}
            %
          </Typography>
        ) : null}
        {!!renewalChangePolicyData?.renewal_pending_policy
          ?.group_discount_percentage ? (
          <Typography className={classes.planDiscount}>
            Priority Code{' '}
            {parseFloat(
              1 -
                renewalChangePolicyData.renewal_pending_policy
                  .group_discount_percentage
            ).toFixed(2) * 100}
            %
          </Typography>
        ) : null}
        {!!policyCappedRate ? (
          <Typography className={classes.planCappedRate}>
            *Capped Rate{' '}
            {`${formatCurrency(policyCappedRate, {
              areCents: false,
            })} ${isChargedAnnually ? '/ yr' : '/ mo'}`}
          </Typography>
        ) : null}
      </div>
      {petData?.renewal_pending_pet_plan &&
      !isPetPlanInCancelledStatus(petData.renewal_pending_pet_plan.status) ? (
        <>
          <div className={classes.planBenefitsContainer}>
            <Typography className={classes.planHeader}>
              Preventive Essentials
            </Typography>
            <Typography className={classes.planHeader}>
              {`${formatCurrency(petPlanCost, {
                areCents: false,
              })} ${isChargedAnnually ? '/ yr' : '/ mo'}`}
            </Typography>
          </div>
          <div>
            <ul className={classes.planBenefitsList}>
              <li>
                <Typography className={classes.planBenefits}>
                  {`Pumpkin Prevent ${
                    petData.renewal_pending_pet_plan
                      ? petData.renewal_pending_pet_plan.plan.version_number
                      : petData.version
                  }`}
                </Typography>
              </li>
            </ul>
          </div>
        </>
      ) : null}

      {showSubTotal ? (
        <div className={classes.petPlanHeaderContainer}>
          <Typography className={classes.totalValues}>{`${
            isChargedAnnually ? 'Annual' : 'Monthly'
          } Subtotal:`}</Typography>
          <Typography className={classes.totalValues}>
            {subTotalFormatted}
          </Typography>
        </div>
      ) : null}

      {showGrandTotal ? (
        <div className={classes.petPlanHeaderContainer}>
          <Typography className={classes.totalValues}>
            {`${isChargedAnnually ? 'Annual' : 'Monthly'} Transaction Fee:`}
          </Typography>
          <Typography className={classes.totalValues}>
            {formatCurrency(transactionFee, { areCents: false })}
          </Typography>
        </div>
      ) : null}

      {showTransactionFee ? (
        <div className={classes.petPlanHeaderContainer}>
          <Typography className={classes.petPlanTitle}>{`${
            isChargedAnnually ? 'Annual' : 'Monthly'
          } Grand Total:`}</Typography>
          <Typography className={classes.petPlanTitle}>
            {grandTotalFormatted}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

RenewalChangeSummary.propTypes = {
  showSubTotal: PropTypes.bool,
  showGrandTotal: PropTypes.bool,
  showTransactionFee: PropTypes.bool,
  petData: PropTypes.object.isRequired,
  renewalChangePolicyData: PropTypes.object.isRequired,
};

export default RenewalChangeSummary;
