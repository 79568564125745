import { useGetList } from 'react-admin';

export const FUNDING_SOURCE_QUERY = 'funding-sources';

export function useFundingSource(userId) {
  return useGetList(FUNDING_SOURCE_QUERY, {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
    filter: { user_id: userId, active: 1 },
  });
}
