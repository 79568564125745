import {
  Datagrid,
  DateField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';

import RoutePaths from '../../../../routes';
import { AuditLogDetails, AuditLogsLink } from '../../../audit-logs';

export function CustomerAuditLogs(props) {
  const record = useRecordContext();
  return (
    <SimpleShowLayout {...props}>
      <ReferenceManyField
        title=''
        reference={RoutePaths.auditLogs}
        target='user_id'
        label=''
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick='expand'
          expand={<AuditLogDetails />}
        >
          <DateField
            showTime={true}
            label='Date'
            source='created_at'
            sortable={false}
          />
          <TextField label='Context' source='context' sortable={false} />
          <TextField label='Description' source='description' sortable={false} />
          <TextField label='Modified by' source='modified_by' sortable={false} />
          <TextField label='Pet ID' source='pet_id' sortable={false} />
          <TextField label='Policy ID' source='policy_id' sortable={false} />
          <TextField label='Pet Plan ID' source='pet_plan_id' sortable={false} />
          <TextField label='Claim ID' source='claim_id' sortable={false} />
          <TextField label='Invoice ID' source='invoice_id' sortable={false} />
        </Datagrid>
      </ReferenceManyField>

      <AuditLogsLink buttonName={'See more'} filter={{ user_id: record.id }} />
    </SimpleShowLayout>
  );
}

export default CustomerAuditLogs;
