import { useGetList } from 'react-admin';

export function usePolicyByPetId(petId, options = {}) {
  return useGetList(
    'policies',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'policy_effective_date', order: 'ASC' },
      filter: { pet_id: petId },
    },
    { enabled: !!petId, ...options }
  );
}

export function usePolicyListByPetId(petId) {
  return useGetList(
    'policies',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { pet_id: petId },
    },
    { enabled: !!petId }
  );
}
