import makeStyles from '@mui/styles/makeStyles';

export const useBadgeStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  { name: 'custom-badge' }
);
