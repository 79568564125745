import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { getDateAsFormattedUTC } from '../../../shared/utils';

export const toggleButtonStyle = makeStyles(
  {
    toggleButtonSelected: {
      '&&': {
        backgroundColor: '#5368F5',
        color: 'white',
        '&:hover': {
          backgroundColor: '#5368F5',
          color: 'white',
        },
      },
    },
    toggleButtonRoot: {
      '&:hover': {
        backgroundColor: '#fff',
        color: '#red',
      },
    },
  },
  {
    name: 'toggle-button-style',
  }
);

export function Alerts({ data = [] }) {
  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        backgroundColor: '#FFE9CC',
        '&:before': {
          height: '0px',
          borderBottom: '1px',
        },
        '&.Mui-expanded': {
          margin: '0px',
          marginBottom: '12px',
        },
        marginBottom: '12px',
      }}
      defaultExpanded={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <WarningAmberIcon style={{ width: '22px', color: '#EF6C00' }} />
        <Typography
          sx={{
            marginLeft: '12px',
            color: '#663C00',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
            letterSpacing: '0.15px',
          }}
        >
          {data.length} Alerts
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0 28px 18px',
        }}
      >
        <ol style={{ color: '#663C00', margin: '0px' }}>
          {data.map(alert => (
            <li key={alert}>
              <Typography
                sx={{
                  color: '#663C00',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  letterSpacing: '0.17px',
                }}
              >
                {alert}
              </Typography>
            </li>
          ))}
        </ol>
      </AccordionDetails>
    </Accordion>
  );
}

export function AutomationStatus({ invoice }) {
  if (invoice.status === 'processing') {
    return (
      <Alert severity='info' sx={{ marginBottom: '12px' }}>
        Invoice was queued for automation... Please wait.
      </Alert>
    );
  } else if (invoice.status === 'ready') {
    return (
      <Alert severity='info' sx={{ marginBottom: '12px' }}>
        Automation in progress... Please wait.
      </Alert>
    );
  } else if (invoice.status === 'failed') {
    return (
      <Alert severity='error' sx={{ marginBottom: '12px' }}>
        Oops! We were unable to automate this invoice.
      </Alert>
    );
  } else if (invoice.status === 'completed' && invoice.alerts?.length) {
    return <Alerts data={invoice.alerts} />;
  } else {
    return null;
  }
}

export function createClaimLineItemAutomationColumns(
  isAutomationConfidenceColors,
  toggleButtonClasses,
  renderMoneyInput,
  handleLineItemDeleteClick,
  disableComponents
) {
  function resolveConfidenceClassName(confidence) {
    if (!isAutomationConfidenceColors || !confidence || confidence === '0.000') {
      return '';
    }

    const confidenceValue = parseFloat(confidence).toFixed(1);

    if (confidenceValue < 0.6) {
      return 'filledRed';
    }

    if (confidenceValue < 0.8) {
      return 'filledYellow';
    }

    return '';
  }

  return [
    {
      field: 'loss_date',
      headerName: 'Loss Date',
      type: 'date',
      flex: 0.5,
      editable: !disableComponents,
      headerAlign: 'left',
      align: 'left',
      cellClassName: params => {
        return resolveConfidenceClassName(params.row?.loss_date_ai_confidence);
      },
      valueGetter: params => {
        return params?.value
          ? new Date(getDateAsFormattedUTC(new Date(params.value), 'MM/DD/YYYY'))
          : null;
      },
      renderCell: params => {
        return (
          <span
            style={{ color: params?.row?.has_incorrect_policy_period && '#C62828' }}
          >
            {params?.value
              ? getDateAsFormattedUTC(new Date(params.value), 'MM/DD/YYYY')
              : 'mm/dd/yyyy'}{' '}
          </span>
        );
      },
      sortable: false,
      hide: false,
    },
    {
      field: 'description',
      filterable: false,
      headerName: 'Item Name / Description',
      flex: 3,
      editable: !disableComponents,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      cellClassName: params => {
        return resolveConfidenceClassName(params.row?.description_ai_confidence);
      },
      renderCell: params => {
        if (params.value?.length > 150) {
          return (
            <Tooltip
              sx={{
                backgroundColor: 'white',
                color: 'black',
                fontSize: '14px',
                border: '1px solid black',
              }}
              placement='top'
              title={params.value}
            >
              <span>
                {String(params.value).substring(0, 28)}
                ...
              </span>
            </Tooltip>
          );
        }

        if (!params.value) {
          return <span>{'Item Name / Description'}</span>;
        }

        return <span>{params.value}</span>;
      },
    },
    {
      field: 'line_item_type',
      filterable: false,
      headerName: 'Type',
      editable: !disableComponents,
      headerAlign: 'right',
      flex: 0.52,
      align: 'right',
      renderCell: params => {
        if (params.row?.line_item_type === 'prevent') {
          return <span>PEP</span>;
        }

        if (params.row?.line_item_type === 'insurance') {
          return <span>INS</span>;
        }

        if (params.row?.line_item_type === 'wellness') {
          return <span>WELL</span>;
        }

        return (
          <ToggleButtonGroup
            {...params}
            value={params.row?.line_item_type}
            exclusive
            sx={{
              height: '30px',
            }}
          >
            {params.row?.canFileIns ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='insurance'
              >
                INS
              </ToggleButton>
            ) : null}

            {params.row?.canFileWell ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='wellness'
              >
                WELL
              </ToggleButton>
            ) : null}

            {params.row?.canFilePep ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                }}
                value='prevent'
              >
                PEP
              </ToggleButton>
            ) : null}
          </ToggleButtonGroup>
        );
      },
      renderEditCell: params => {
        return (
          <ToggleButtonGroup
            {...params}
            value={params.row?.line_item_type}
            exclusive
            onChange={(event, newValue) => {
              params.api.setEditCellValue({
                id: params.id,
                field: 'line_item_type',
                value: newValue,
              });
              params.api.stopCellEditMode({
                id: params.id,
                field: 'line_item_type',
              });
            }}
            aria-label='Line Item Type'
            sx={{
              height: '30px',
              marginRight: '8px',
            }}
          >
            {params.row?.canFileIns ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                  '&:hover': {
                    backgroundColor: '#F8F7F5',
                  },
                }}
                value='insurance'
              >
                INS
              </ToggleButton>
            ) : null}

            {params.row?.canFileWell ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                  '&:hover': {
                    backgroundColor: '#F8F7F5',
                  },
                }}
                value='wellness'
              >
                WELL
              </ToggleButton>
            ) : null}

            {params.row?.canFilePep ? (
              <ToggleButton
                classes={{
                  selected: toggleButtonClasses.toggleButtonSelected,
                }}
                sx={{
                  color: '#5368F5',
                  borderColor: '#5368F5',
                  '&:hover': {
                    backgroundColor: '#F8F7F5',
                  },
                }}
                value='prevent'
              >
                PEP
              </ToggleButton>
            ) : null}
          </ToggleButtonGroup>
        );
      },
      sortable: false,
    },
    {
      field: 'quantity',
      filterable: false,
      headerName: 'Quantity',
      type: 'number',
      editable: !disableComponents,
      headerAlign: 'right',
      flex: 0.5,
      align: 'right',
      sortable: false,
      cellClassName: params => {
        return resolveConfidenceClassName(params.row?.quantity_ai_confidence);
      },
      renderCell: params => {
        return <span>{params.value ? params.value : 'Quantity'}</span>;
      },
    },
    {
      field: 'total_amount',
      filterable: false,
      headerName: 'Amount',
      type: 'number',
      headerAlign: 'right',
      editable: !disableComponents,
      flex: 0.5,
      align: 'right',
      sortable: false,
      renderCell: params => {
        return <span>{params.value ? renderMoneyInput(params) : 'Amount'}</span>;
      },
      cellClassName: params => {
        return resolveConfidenceClassName(params.row?.total_amount_ai_confidence);
      },
    },
    {
      field: 'delete',
      headerName: '',
      filterable: false,
      editable: false,
      headerAlign: 'center',
      width: 50,
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <IconButton
            sx={{ color: 'rgba(0, 0, 0, 0.56)' }}
            onClick={() => handleLineItemDeleteClick(params?.row?.id)}
            size='medium'
            disabled={disableComponents}
          >
            <DeleteIcon style={{ width: '20px' }} />
          </IconButton>
        );
      },
    },
  ];
}

Alerts.propTypes = {
  data: PropTypes.array,
};

AutomationStatus.propTypes = {
  invoice: PropTypes.any.isRequired,
};

createClaimLineItemAutomationColumns.PropTypes = {
  isAutomationConfidenceColors: PropTypes.bool.isRequired,
  toggleButtonClasses: PropTypes.object.isRequired,
  renderMoneyInput: PropTypes.func.isRequired,
  handleLineItemDeleteClick: PropTypes.func.isRequired,
  wellness: PropTypes.object,
  insurance: PropTypes.object,
};
