import makeStyles from '@mui/styles/makeStyles';

const reimbursementCancellationDetailStyle = makeStyles(
  {
    cancellationDiv: {
      margin: '16px 0px',
      borderBottom: '1px solid',
      paddingBottom: '12px',
    },
    information: {
      color: '#000000',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    cancellationDecision: {
      color: '#000000',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      marginTop: '24px',
    },
  },
  { name: 'reimbursement-cancellation-detail' }
);

export { reimbursementCancellationDetailStyle };
