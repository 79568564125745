import makeStyles from '@mui/styles/makeStyles';

const messageModalStyle = makeStyles(
  {
    container: {
      display: 'flex',
      flexFlow: 'column',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    errorIcon: {
      color: '#D32F2F',
      fontSize: '24px',
      marginBottom: '4px',
    },

    notificationIcon: {
      color: '#2196F3',
      fontSize: '24px',
      marginBottom: '4px',
    },

    title: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },

    message: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#00000099',
      marginTop: '10px',
    },

    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    notificationButton: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      margin: '16px 0px',
      backgroundColor: '#FFFFFF',
      color: '#007DFF',
    },

    closeButton: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      margin: '16px 0px',
      backgroundColor: '#FFFFFF',
      color: '#D32F2F',
    },
  },
  { name: 'message-modal' }
);

export { messageModalStyle };
