import { useInput } from 'react-admin';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';

function ToggleButtonInput({ inputOptions, toggleOptions, sx }) {
  const {
    field,
    fieldState: { error },
  } = useInput(inputOptions);

  return (
    <ToggleButtonGroup
      {...field}
      exclusive
      sx={{
        height: '36px',
        marginTop: '18px',
        '& .MuiButtonBase-root': {
          padding: '6px 16px',

          '&.Mui-selected': {
            backgroundColor: '#5368F5',
            color: 'white',
          },
          '&:hover, &.Mui-selected:hover': {
            backgroundColor: '#2A41DE',
            color: 'white',
          },
        },
        ...sx.group,
      }}
      tabIndex={0}
    >
      {toggleOptions.map(option => (
        <ToggleButton
          key={option.value}
          sx={{
            color: error ? '#D32F2F' : '#5368F5',
            borderColor: error ? '#D32F2F' : '#5368F5',
            ...sx.button,
          }}
          value={option.value}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

ToggleButtonInput.defaultProps = {
  sx: {},
};

ToggleButtonInput.propTypes = {
  sx: PropTypes.shape({
    group: PropTypes.object,
    button: PropTypes.object,
  }),

  // https://marmelab.com/react-admin/useInput.html#props
  inputOptions: PropTypes.shape({
    source: PropTypes.string.isRequired,
  }).isRequired,

  toggleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ToggleButtonInput;
