import PropTypes from 'prop-types';

import { formatCurrency, getDateAsFormattedUTC } from '../../../../../shared/utils';

function ClaimSecondarySession(props) {
  const { record } = props;
  return (
    <div style={{ marginTop: '28px' }}>
      <div
        style={{
          color: '#000000',
          fontWeight: '700',
          fontSize: '12px',
          lineHeight: '20px',
          display: 'flex',
          width: '250px',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        {record.subclaims
          ? record.subclaims.map(subclaim => {
              return <span key={subclaim.id}>{subclaim.id}</span>;
            })
          : null}
      </div>
      <div
        style={{
          width: '85%',
          display: 'flex',
          color: '#000000',
          fontSize: '12px',
          lineHeight: '20px',
          fontWeight: '400',
        }}
      >
        <span
          style={{ width: '295px', marginRight: '30px' }}
        >{`Customer: ${record.customer_name} - ${record.customer_external_id}`}</span>
        <span
          style={{ width: '120px', marginRight: '30px' }}
        >{`Pet: ${record.pet_name}`}</span>
        <span
          style={{ width: '190px', marginRight: '30px' }}
        >{`Amount Claimed: ${formatCurrency(record.claimed_amount, {
          areCents: true,
        })}`}</span>
        <span
          style={{ width: '70px', marginRight: '30px' }}
        >{`State: ${record.state}`}</span>
        <span style={{ width: '140px', marginRight: '30px' }}>{`Loss Date: ${
          record.loss_date
            ? getDateAsFormattedUTC(record.loss_date, 'MM/DD/YYYY')
            : 'N/A'
        }`}</span>
        <span style={{ width: '180px' }}>{`Submission Date: ${
          record.created_at
            ? getDateAsFormattedUTC(record.created_at, 'MM/DD/YYYY')
            : 'N/A'
        }`}</span>
      </div>
    </div>
  );
}

ClaimSecondarySession.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ClaimSecondarySession;
