import makeStyles from '@mui/styles/makeStyles';

const claimCompletedDatagridStyle = makeStyles(
  {
    title: {
      color: '#007DFF',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    totalDiv: {
      display: 'flex',
      marginTop: '16px',
      marginBottom: '24px',
      width: '700px',
      justifyContent: 'space-between',
    },
  },
  { name: 'claim-completed-datagrid' }
);

const claimCompletedDatagridSx = {
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  headerCell: {
    fontWeight: '600',
  },
  rowEven: { backgroundColor: 'rgba(0, 125, 255, 0.08)' },
  rowCell: { padding: '12px 24px 12px 16px' },
};

export { claimCompletedDatagridStyle, claimCompletedDatagridSx };
