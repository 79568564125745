import { formatCurrency } from '../../shared/utils';

/**
 * Parses wellness program data, transforming it into a array of key and value.
 *
 * @param {Object} data - The input data containing wellness program information e.g. {"puppy": {"basic":{}}}.
 * @returns {Object} - The transformed data with wellness programs in array of key and value.
 */
export function parseWellnessPrograms(data) {
  const { program_options: programOptions, ...rest } = data;

  const transformedObj = {};

  // Check if programOptions exist in the input data
  if (programOptions) {
    for (const key in programOptions) {
      transformedObj[key] = {};

      for (const innerKey in programOptions[key]) {
        transformedObj[key][innerKey] = [];

        for (const prop in programOptions[key][innerKey]) {
          transformedObj[key][innerKey].push({
            key: prop,
            value: programOptions[key][innerKey][prop],
          });
        }
      }
    }
  }

  return { ...rest, program_options: transformedObj };
}

const PROGRAM_OPTIONS_ITEMS_LABELS = {
  exams: 'Wellness Exams',
  vaccines: 'Vaccinations',
  bloodwork: 'Routine Bloodwork',
  fecal: 'Fecal Test',
  heartworm_meds: 'Tick/Flea/Heartworm Prevention',
  spay_neuter_OR_dental: 'Spay/Neuter Procedure or Routine Dental Cleaning',
  telehealth: 'PawSupport 24/7 Pet Health Helpline',
};

export const PROGRAM_OPTIONS_UTILIZATION_LABELS = {
  EXAMS: 'Wellness Exams',
  VACCINES: 'Vaccinations',
  BLOODWORK: 'Bloodwork',
  FECAL: 'Fecal Test',
  HEARTWORM_MEDS: 'Heartworm / Flea / Tick Medications',
  SPAY_NEUTER_OR_DENTAL: 'Spay/Neuter OR Dental Cleaning',
};

export function setProgramOptionsItems(option, type) {
  let keys = [];
  switch (type) {
    case 'wellnessCare':
      keys = ['exams'];
      break;
    case 'diseasePrevention':
      keys = ['vaccines', 'heartworm_meds'];
      break;
    case 'diagnostics':
      keys = ['fecal', 'bloodwork'];
      break;
    case 'preventiveProcedures':
      keys = ['spay_neuter_OR_dental'];
      break;
    case 'support':
      keys = ['telehealth'];
      break;
    default:
      break;
  }

  const items = option
    .filter(obj => keys.includes(obj.key) && obj.value !== '0')
    .map(item => {
      return {
        label: PROGRAM_OPTIONS_ITEMS_LABELS[item.key],
        value:
          item.value === 'unlimited' || item.value === '99999'
            ? 'Unlimited'
            : `Up to ${formatCurrency(item.value, {
                hideZero: true,
              })}`,
      };
    });

  return items;
}
