import IconCheckCircle from '@mui/icons-material/CheckCircle';
import IconError from '@mui/icons-material/Error';
import { FormControlLabel, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import OutlinedVetInput from '../../../../../shared/components/outlined-vet-input';
import { validateEmail } from '../../../../../shared/utils/utils';
import { recordsVetSelectorStyle } from './records-vet-selector-style';

// this component was not built to be reusable, but just so that records-request-modal is readable
function RecordsVetSelector({
  classes: propClasses,
  isManualOrg,
  manualOrg,
  onManualOrg,
  hasSubmittedForm,
  email,
  onEmail,
  vet,
  onVet,
  toggleIsOrg,
  zipcode,
}) {
  // a cheap merge, not like real Material UI class merges
  const classes = {
    ...recordsVetSelectorStyle(),
    ...propClasses,
  };

  return isManualOrg ? (
    <>
      <FormControlLabel
        classes={{ root: classes.textLabel }}
        labelPlacement='top'
        label='Vet Clinic or Organization Name'
        control={
          <TextField
            value={manualOrg}
            onChange={e => onManualOrg(e.target.value)}
            className={classes.marginBottom}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            error={hasSubmittedForm && !manualOrg}
            helperText={
              hasSubmittedForm && !manualOrg ? 'Enter a clinic name' : null
            }
            variant='outlined'
            fullWidth
            size='medium'
            required
          />
        }
      />

      <FormControlLabel
        classes={{ root: classes.textLabel }}
        labelPlacement='top'
        label='Email Address'
        control={
          <TextField
            value={email}
            onChange={e => onEmail(e.target.value)}
            className={classes.marginBottom}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              classes: { root: classes.formHelperText },
            }}
            error={hasSubmittedForm && !validateEmail(email)}
            helperText={
              hasSubmittedForm && !validateEmail(email)
                ? 'Enter a valid email address'
                : null
            }
            variant='outlined'
            fullWidth
            size='medium'
            required
          />
        }
      />
    </>
  ) : (
    <>
      <div className={classes.marginBottom}>
        <OutlinedVetInput
          formData={{}}
          zipcode={zipcode}
          label='Vet Clinic'
          source='vet'
          onChange={event => {
            onVet(event);
            onEmail(event?.claims_email ?? '');
          }}
          error={hasSubmittedForm && !vet.id}
          helperText={hasSubmittedForm && !vet.id ? 'Select a clinic' : null}
          required
        />
      </div>

      {vet.id ? (
        <FormControlLabel
          classes={{ root: classes.textLabel }}
          labelPlacement='top'
          label={
            <div className={classes.emailLabel}>
              Email Address<span>*</span>
              {!vet.is_claims_email_confirmed ? (
                <div className={classes.emailConfirmation}>
                  <IconError className={classes.iconError} />
                  <Typography className={classes.unverifiedEmail}>
                    Unverified: Cross-Check with Invoice or Web
                  </Typography>
                </div>
              ) : (
                <div className={classes.emailConfirmation}>
                  <IconCheckCircle className={classes.iconCheckCircle} />
                  <Typography className={classes.verifiedEmail}>Verified</Typography>
                </div>
              )}
            </div>
          }
          control={
            <TextField
              value={email}
              onChange={e => onEmail(e.target.value)}
              className={classes.marginBottom}
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                classes: { root: classes.formHelperText },
              }}
              variant='outlined'
              size='medium'
              error={hasSubmittedForm && !validateEmail(email)}
              helperText={
                hasSubmittedForm && !validateEmail(email)
                  ? 'Enter a valid email address'
                  : null
              }
              fullWidth
            />
          }
        />
      ) : null}

      {!vet.id ? (
        <Typography className={classes.noVet}>
          Can't find a clinic?{' '}
          <span role='button' onClick={toggleIsOrg}>
            Enter Details Manually
          </span>
        </Typography>
      ) : null}
    </>
  );
}

RecordsVetSelector.propTypes = {
  classes: PropTypes.object,
  isManualOrg: PropTypes.bool.isRequired,
  manualOrg: PropTypes.string.isRequired,
  onManualOrg: PropTypes.func.isRequired,
  hasSubmittedForm: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onEmail: PropTypes.func.isRequired,
  vet: PropTypes.object.isRequired,
  onVet: PropTypes.func.isRequired,
  toggleIsOrg: PropTypes.func.isRequired,
  zipcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RecordsVetSelector;
