import makeStyles from '@mui/styles/makeStyles';

const policyChangeSidebarStyle = makeStyles(
  {
    sidebarTitle: {
      display: 'flex',
      marginBottom: '24px',
    },
    summary: {
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#007DFF',
      marginRight: '102px',
    },
    petPlanDetail: {
      border: '1px solid #CCCCCC',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '250px',
    },
    insuranceAndPlan: {
      marginBottom: '160px',
    },
    petPlanTitle: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#007DFF',
    },
    petPlanTotal: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#2196F3',
    },

    switchRoot: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      cursor: 'pointer',
      width: 0,
    },
    switchLabelNormal: {
      fontSize: '12px',
      zIndex: 1,
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontWeight: '400',
      color: 'black',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    leftLabel: {
      left: '10px',
      width: '84px',
    },
    rightLabel: {
      left: '102px',
      width: '88px',
    },
    petPlanHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    planHeader: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'black',
      marginTop: '24px',
    },
    planBenefitsContainer: { display: 'flex', justifyContent: 'space-between' },
    planBenefitsList: {
      paddingLeft: '16px',
      color: 'rgba(0, 0, 0, 0.54);',
    },
    planBenefits: {
      fontSize: '12px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.54);',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      paddingLeft: '0px',
    },
    saveButton: {
      color: 'white',
      backgroundColor: '#007DFF',
      '&:hover': {
        backgroundColor: '#007DFF',
      },
      margin: '16px 0px',
    },
    resetButton: {
      color: 'white',
      backgroundColor: '#D4424E',
      '&:hover': {
        backgroundColor: '#D4424E',
      },
    },
    totalValues: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      letterSpacing: '0.25px',
      color: 'black',
    },
  },
  { name: 'policy-change-sidebar' }
);

export { policyChangeSidebarStyle };
