import cloneDeep from 'lodash/cloneDeep';

import { getCurrentUser } from '../../../auth';

export default function orderUsersByEmail(users) {
  const user = getCurrentUser();
  const sorted = cloneDeep(users).sort((a, b) => (a.email > b.email ? 1 : -1));
  const userIndexInData = sorted.findIndex(item => item.email === user?.email);

  if (userIndexInData > -1) {
    const currentUser = sorted.splice(userIndexInData, 1)[0];
    return [currentUser, ...sorted];
  }

  return sorted;
}
