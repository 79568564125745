import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { PROGRAM_OPTIONS_UTILIZATION_LABELS } from '@pumpkincare/wellness';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { formatCurrency } from '../../../shared/utils';

const sx = {
  title: { marginBottom: '16px' },
  subtitle: { color: '#373f51', fontWeight: '500', marginBottom: '10px' },
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#f8f7f3',
    padding: '2px 8px',
    marginBottom: '16px',
  },
  pepToggle: { color: '#373f51', fontWeight: '500', marginBottom: '10px' },
  compare: { color: 'rgba(0,0,0,0.56)', padding: '2px', marginLeft: '4px' },
  grid: { display: 'grid', gap: '0 16px', gridTemplateColumns: 'repeat(2, 1fr)' },
};

function ClaimUtilization({
  insUtilization,
  pepUtilization,
  wellnessUtilization,
  style = {},
}) {
  const [isAnnualCap, toggleIsAnnualCap] = useBooleanInput(false);

  return (
    <div style={style}>
      <Typography variant='h6' sx={sx.title}>
        Utilization
      </Typography>

      <Grid container justifyContent='space-between'>
        {insUtilization ? (
          <Grid item xs={3}>
            <Typography variant='subtitle1' sx={sx.subtitle}>
              Insurance
            </Typography>

            <Typography variant='body2' sx={sx.bar}>
              Deductible{' '}
              <span>
                {formatCurrency(insUtilization.deductible_utilization.utilized, {
                  areCents: true,
                  hideZero: true,
                })}{' '}
                of{' '}
                {formatCurrency(insUtilization.deductible_utilization.total, {
                  areCents: true,
                  hideZero: true,
                })}
              </span>
            </Typography>

            <Typography variant='body2' sx={sx.bar}>
              Annual Limit{' '}
              <span>
                {formatCurrency(insUtilization.annual_limit_utilization.utilized, {
                  areCents: true,
                  hideZero: true,
                })}{' '}
                of{' '}
                {insUtilization.annual_limit_utilization.total === -1
                  ? 'Unlimited'
                  : formatCurrency(insUtilization.annual_limit_utilization.total, {
                      areCents: true,
                      hideZero: true,
                    })}
              </span>
            </Typography>
          </Grid>
        ) : null}

        {pepUtilization ? (
          <Grid item xs={8}>
            <Typography variant='subtitle1' sx={sx.subtitle}>
              Preventive Essentials
            </Typography>

            <Typography variant='body2' sx={sx.pepToggle}>
              {isAnnualCap ? 'Bundle' : 'Line Item'} Utilization
              <IconButton onClick={toggleIsAnnualCap} sx={sx.compare}>
                <CompareArrowsIcon />
              </IconButton>
            </Typography>

            {isAnnualCap ? (
              <Typography variant='body2' sx={sx.bar}>
                Annual Cap{' '}
                <span>
                  {formatCurrency(pepUtilization.annual_cap.utilized, {
                    areCents: true,
                    hideZero: true,
                  })}{' '}
                  of{' '}
                  {formatCurrency(pepUtilization.annual_cap.entitlement, {
                    areCents: true,
                    hideZero: true,
                  })}
                </span>
              </Typography>
            ) : (
              <div style={sx.grid}>
                {pepUtilization.entitlements.map(entitlement => (
                  <Typography variant='body2' sx={sx.bar} key={entitlement.id}>
                    {entitlement.details}{' '}
                    <span>
                      {entitlement.utilized}
                      {entitlement.utilized > entitlement.total ? '*' : ''} of{' '}
                      {entitlement.total} (Pending: {entitlement.pending})
                    </span>
                  </Typography>
                ))}
              </div>
            )}
          </Grid>
        ) : null}

        {wellnessUtilization ? (
          <Grid item xs={8}>
            <Typography variant='subtitle1' sx={sx.subtitle}>
              Wellness
            </Typography>

            <div style={sx.grid}>
              {wellnessUtilization.entitlements
                .filter(
                  entitlement =>
                    PROGRAM_OPTIONS_UTILIZATION_LABELS[entitlement.entitlement]
                )
                .map(entitlement => (
                  <Typography variant='body2' sx={sx.bar} key={entitlement.id}>
                    {PROGRAM_OPTIONS_UTILIZATION_LABELS[entitlement.entitlement]}
                    <span>
                      {formatCurrency(entitlement.utilized_amount, {
                        hideZero: true,
                        areCents: true,
                      })}{' '}
                      of{' '}
                      {formatCurrency(entitlement.available_amount, {
                        hideZero: true,
                        areCents: true,
                      })}
                    </span>
                  </Typography>
                ))}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

ClaimUtilization.propTypes = {
  style: PropTypes.object,
  insUtilization: PropTypes.object,
  pepUtilization: PropTypes.object,
  wellnessUtilization: PropTypes.object,
};

export default ClaimUtilization;
