import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles(
  {
    chip: {
      borderRadius: '4px',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#000000',
      display: 'inline-block',
      padding: '2px 8px 3px 8px',
      textTransform: 'uppercase',
    },
    active: {
      backgroundColor: '#3EC28F',
    },
    cancelled: {
      backgroundColor: '#D4424E',
    },
    pending: {
      backgroundColor: '#AEE5D0',
    },
    expired: {
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
    },
    cancel_pending: {
      backgroundColor: '#DC9399',
    },
    lapsed: {
      backgroundColor: '#F87D23',
    },
  },
  { name: 'pet-plan-policy-status' }
);

function PetPlanPolicyStatus({ status }) {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.chip, {
        [classes.active]: status === 'active',
        [classes.pending]: ['pending', 'renew_pending', 'updated'].some(
          expStatus => expStatus === status
        ),
        [classes.expired]: ['expired', 'declined', 'voided'].some(
          expStatus => expStatus === status
        ),
        [classes.cancel_pending]: status === 'cancel_pending',
        [classes.cancelled]: status === 'cancelled',
        [classes.lapsed]: status === 'lapsed',
      })}
    >
      {status}
    </div>
  );
}

export default PetPlanPolicyStatus;
