import makeStyles from '@mui/styles/makeStyles';

const customerBillingStyle = makeStyles(
  {
    billingDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    billingDetailsRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    title: {
      fontSize: '18px',
      fontWeight: '600',
      paddingBottom: '16px',
      marginRight: '24px',
    },
    titleIndented: {
      paddingBottom: '0px',
    },
    titleToLeft: {
      marginLeft: '-8px',
    },
    titleBilling: {
      marginTop: '16px',
    },
    billingDetailsCard: {
      marginRight: '24px',
    },
    headerCell: {
      borderBottom: '0px',
    },
    table: {
      borderBottom: '0px',
    },
    rowCell: {
      borderBottom: '0px',
    },
    tableSize: {
      gap: '28px',
      padding: '0px !important',
    },
    cardDetails: {
      display: 'flex',
      gap: '4px',
    },
    upcomingPayments: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '16px',
    },
    label: {
      fontSize: '7.5px',
      color: 'rgba(0, 0, 0, 0.54)',
      textTransform: 'uppercase',
    },
    labelBilling: {
      fontSize: '12px',
      paddingLeft: '0x !important',
    },
    cardBrand: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    createInvoiceButton: {
      textTransform: 'none',
      margin: '16px',
    },
    invoices: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    redText: {
      color: 'red',
    },
    tableWidth: {
      width: `700px`,
    },
  },
  { name: 'customer-billing' }
);

export { customerBillingStyle };
