import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import useBooleanInput from '../../../hooks/useBooleanInput';
import {
  getPetsByUserId,
  getQuotesByEmail,
  getUserDetail,
} from '../../../sections/quotes/quoteFlow/service/quoteFlowService';
import { checkExistingEmail } from '../../../shared/services/netServices';
import { validateEmail } from '../../../shared/utils/utils';
import CustomerDetailModal from './customer-detail-modal';

const QUOTE_ERROR = 'Email already has a quote associated';
const VALIDATING = 'Validating ...';
const ALREADY_EXISTS = 'Customer already exists';
const MUST_BE_VALID = 'Email must be valid';

export default function useCheckEmail(inputUser = {}) {
  const { state: routerState } = useLocation();
  const initUser = routerState?.user || inputUser;

  const [helperText, setHelperText] = useState('');
  const [user, setUser] = useState(initUser);
  const [isExistingCustomerModalOpen, toggleExistingCustomerModal] = useBooleanInput(
    Boolean(!routerState?.is_add_a_pet && Object.keys(initUser).length)
  );

  const { mutateAsync: mutateCheckExistingEmail } = useMutation(email =>
    checkExistingEmail(email)
  );

  /*
   necessary bc of the reliance on state via react-router BETWEEN two experiences
   (INS quote and SAW quote)
   when the component unmounts OR session ends, remove react-router state
   */
  useEffect(() => {
    function removeRouterState() {
      window.history.replaceState(null, '');
    }

    window.addEventListener('beforeunload', removeRouterState);

    return () => {
      removeRouterState();
      window.removeEventListener('beforeunload', removeRouterState);
    };
  }, []);

  function validateExistingEmail(userId) {
    getUserDetail(userId).then(userResponse => {
      getPetsByUserId(userId).then(petResponse => {
        setHelperText(ALREADY_EXISTS);
        setUser({ ...userResponse.data, pets: petResponse.data });
        toggleExistingCustomerModal(true);
      });
    });
  }

  function validateExistingQuote(email) {
    getQuotesByEmail(email)
      .then(response => {
        setHelperText(response.data.length > 0 ? QUOTE_ERROR : '');
      })
      .catch(e => {
        setHelperText(e.message);
      });
  }

  function checkEmail(event) {
    const email = event?.target?.value || event;
    setHelperText(VALIDATING);

    if (validateEmail(email)) {
      return mutateCheckExistingEmail(email)
        .then(response => {
          return response.exists
            ? validateExistingEmail(response.user_id)
            : validateExistingQuote(email);
        })
        .catch(e => setHelperText(e.message));
    } else {
      setHelperText(MUST_BE_VALID);
    }
  }

  return {
    isError:
      (helperText &&
        ![VALIDATING, QUOTE_ERROR, ALREADY_EXISTS, MUST_BE_VALID].some(
          message => helperText === message
        )) ||
      false,
    isAssociatedQuote: helperText === QUOTE_ERROR,
    helperText:
      routerState?.is_add_a_pet && helperText === ALREADY_EXISTS ? '' : helperText,
    user: routerState?.user || user,
    toggleExistingCustomerModal,
    isAddAPet: routerState?.is_add_a_pet || false,
    checkEmail: useDebouncedCallback(checkEmail, 500),
    ExistingCustomerModal: props => (
      <CustomerDetailModal
        {...props}
        open={!routerState?.is_add_a_pet && isExistingCustomerModalOpen}
        user={user}
        onAddWellness={() => {
          props.onAddWellness?.(user); // NOSONAR
        }}
        onAddInsurance={() => {
          props.onAddInsurance?.(user); // NOSONAR
        }}
      />
    ),
  };
}
