import { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SimpleFormIterator,
  TextInput,
  useNotify,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import {
  PET_AGE_OPTIONS,
  UP_TO_5_MONTHS,
  UP_TO_11_MONTHS,
} from '../../../../constants/quoteFlow';
import {
  getDiscountDetail,
  getPetBreedList,
  getPlan,
  getPolicyOptions,
  getQuoteLink,
  getTransactionFee,
  patchPetQuote,
  postPetQuote,
  postRegisterQuote,
  putPetQuoteDetail,
} from '../service/quoteFlowService';
import calculatePetTotalPrice from '../utils/calculatePetTotalPrice';
import { transformPolicyOptions } from '../utils/transformPolicyOptions';
import { useStyles } from './PetTabStyle';

function PetTab(props) {
  const {
    quote,
    pets,
    setPets,
    discount,
    setQuote,
    setQuoteUrl,
    setShowNonConversionModal,
    user,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dogBreedList, setDogBreedList] = useState([]);
  const [catBreedList, setCatBreedList] = useState([]);

  const classes = useStyles();

  const navigate = useNavigate();

  const notify = useNotify();

  useEffect(() => {
    getPetBreedList('dogs').then(dogBreeds => setDogBreedList(dogBreeds));
    getPetBreedList('cats').then(catBreeds => setCatBreedList(catBreeds));
  }, []);

  const existingPets = user?.pets?.map(pet => {
    return {
      pet_name: pet.name.toLowerCase().replace(/ /g, ''),
      species: pet.species,
    };
  });

  function validateRequiredFields(pet) {
    if (
      !pet.pet_name ||
      !pet?.pet_gender ||
      !pet?.species ||
      !pet?.pet_age ||
      !pet?.breed_code
    ) {
      return { error: true, message: 'Form is missing required fields' };
    }
    return { error: false, message: '' };
  }

  function validatePetName(pet, uniqueQuotePets) {
    const petName = pet?.pet_name?.toLowerCase().replace(/ /g, '');
    if (
      existingPets?.some(
        existingPet =>
          existingPet.pet_name === petName && existingPet.species === pet.species
      )
    ) {
      return { error: true, message: 'Pet name already exists for this customer' };
    } else if (
      uniqueQuotePets.some(
        uniquePet =>
          uniquePet.pet_name === petName && uniquePet.species === pet.species
      )
    ) {
      return { error: true, message: 'Pets must have different names' };
    }
    return { error: false, message: '' };
  }

  function formatPet(formData) {
    let error = false;
    let message = '';
    let petArray = [];
    let uniqueQuotePets = [];
    formData.pets.forEach((pet, index) => {
      ({ error, message } = validateRequiredFields(pet));
      if (!error) {
        ({ error, message } = validatePetName(pet, uniqueQuotePets));
      }
      if (!error) {
        uniqueQuotePets.push({
          pet_name: pet?.pet_name?.toLowerCase().replace(/ /g, ''),
          species: pet.species,
        });
        const breedArray = pet.species === 'dog' ? dogBreedList : catBreedList;
        const breed = breedArray.find(item => item.code === pet?.breed_code);
        petArray.push({
          pet_name: pet.pet_name,
          pet_gender: pet?.pet_gender,
          species: pet.species,
          pet_age: pet?.pet_age,
          breed_code: pet?.breed_code,
          breed_type: breed.type,
          breed_name: breed.name,
          quote_pet_id: pets[index] ? pets[index].id : undefined,
        });
      }
    });

    return { error, message, petArray };
  }

  function handlePetSubmitClick({ formData }) {
    setIsLoading(true);

    const { error, message, petArray } = formatPet(formData);

    if (error === true) {
      notify(message, 'warning');
      setIsLoading(false);
    } else {
      const quoteToRegister = {
        first_name: quote.first_name,
        last_name: quote.last_name,
        email: quote.email,
        policy_zipcode: quote.policy_zipcode,
        discount_id: discount ? discount.id : undefined,
      };

      if (quote.partner === 'embark') {
        petArray.forEach(pet => {
          pet.partner = 'embark';
        });
      }

      const petFunction =
        quote.quote_pets.length || props.edit ? patchPetQuote : postPetQuote;

      petFunction(petArray, quote.id)
        .then(petsResponse => {
          setPets(petsResponse.data);

          postRegisterQuote(quoteToRegister, quote.id)
            .then(registerQuoteResponse => {
              let quoteRegisterResponse = registerQuoteResponse.data;

              quoteRegisterResponse.existingUserDetails = quote.existingUserDetails;

              getTransactionFee(quote.id).then(transactionFeeResponse => {
                quoteRegisterResponse.transaction_fee =
                  transactionFeeResponse.data.transaction_fee;
              });

              getQuoteLink(quoteRegisterResponse).then(quoteLinkResponse => {
                setQuoteUrl(quoteLinkResponse);
              });

              const quoteDetail = [];

              quoteRegisterResponse.quote_pets.forEach(quotePet => {
                if (quote.partner === 'embark') {
                  quotePet.partner = 'embark';
                }

                getPolicyOptions(quote.id, quotePet.id)
                  .then(policyOptionsResponse => {
                    quotePet._options =
                      transformPolicyOptions(policyOptionsResponse);
                  })
                  .then(() => {
                    quotePet.deductible =
                      quotePet.deductible || quotePet._options.deductibles[0].id;
                    quotePet.annual_limit =
                      quotePet.annual_limit || quotePet._options.limits[0].id;
                    quotePet.coinsurance = quotePet.coinsurance || '10';
                    let insPrice;
                    if (quotePet.annual_limit === 'Unlimited') {
                      try {
                        insPrice =
                          quotePet._options.prices[quotePet.coinsurance][
                            quotePet.annual_limit
                          ][quotePet.deductible];
                      } catch (e) {
                        insPrice =
                          quotePet._options.prices[quotePet.coinsurance]['1000000'][
                            quotePet.deductible
                          ];
                      }
                    } else {
                      insPrice =
                        quotePet._options.prices[quotePet.coinsurance][
                          quotePet.annual_limit
                        ][quotePet.deductible];
                    }
                    quotePet.totalPrice = calculatePetTotalPrice(quotePet, insPrice);
                  })
                  .then(() => {
                    let petAgeInMonths;
                    if (quotePet.age === UP_TO_5_MONTHS) {
                      petAgeInMonths = 5;
                    } else if (quotePet.age === UP_TO_11_MONTHS) {
                      petAgeInMonths = 11;
                    } else {
                      petAgeInMonths = parseInt(quotePet.age) * 12;
                    }

                    return getPlan(quotePet.species, petAgeInMonths, quote.vet);
                  })
                  .then(planResponse => {
                    quotePet.plan = planResponse[0];

                    quoteDetail.push({
                      quote_pet_id: quotePet.id,
                      deductible: quotePet.deductible,
                      annual_limit: quotePet.annual_limit,
                      coinsurance: quotePet.coinsurance,
                    });

                    if (
                      quoteDetail.length === quoteRegisterResponse.quote_pets.length
                    ) {
                      putPetQuoteDetail(quoteDetail, quoteRegisterResponse.id)
                        .then(() => getDiscountDetail(quoteRegisterResponse.id))
                        .then(discountDetailResponse => {
                          discountDetailResponse.forEach(discount => {
                            quoteRegisterResponse.quote_pets.forEach(pet => {
                              if (
                                discount.id === pet.id &&
                                discount.discounts.length > 0
                              ) {
                                if (pet.has_prevent === true) {
                                  pet.totalPrice = calculatePetTotalPrice(
                                    pet,
                                    discount.price_after_discount
                                  );
                                } else {
                                  pet.totalPrice = discount.price_after_discount;
                                }

                                pet.discount = discount.discounts;
                              }
                            });
                          });
                        })
                        .then(() => {
                          setQuote(quoteRegisterResponse);
                          setPets(quoteRegisterResponse.quote_pets);
                          setIsLoading(false);
                          navigate(
                            props.edit
                              ? `/quotes/${quote.id}/2`
                              : '/quotes/create/2',
                            {
                              state: {
                                quote,
                                pets,
                              },
                            }
                          );
                        });
                    }
                  });
              });
            })
            .catch(e => {
              setIsLoading(false);
              notify(
                'There was an error when registering a quote: ' + e.message,
                'warning'
              );
            });
        })
        .catch(e => {
          setIsLoading(false);
          notify('There was an error when creating pet: ' + e.message, 'warning');
        });
    }
  }

  return (
    <>
      <div className={classes.root}>
        <IconButton
          onClick={() => setShowNonConversionModal({ event: 'CLOSE', quote: quote })}
          className={classes.closeButton}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <ArrayInput source='pets' label=''>
        <SimpleFormIterator
          addButton={
            <Button startIcon={<AddIcon />} variant='contained' color='primary'>
              ADD A PET
            </Button>
          }
          disableReordering
          disableClear
          removeButton={<Button style={{ color: '#B00020' }}>REMOVE</Button>}
          className={classes.form}
          fullWidth
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              let species = [];
              if (scopedFormData && scopedFormData.species === 'dog') {
                species = dogBreedList;
              } else if (scopedFormData && scopedFormData.species === 'cat') {
                species = catBreedList;
              }

              return (
                <div className={classes.row}>
                  <TextInput
                    className={classes.petName}
                    source={getSource('pet_name')}
                    record={scopedFormData}
                    validate={required()}
                    label='Pet Name'
                  />

                  <RadioButtonGroupInput
                    source={getSource('pet_gender')}
                    record={scopedFormData}
                    validate={required()}
                    label='GENDER'
                    choices={[
                      { id: 'M', name: 'M' },
                      { id: 'F', name: 'F' },
                    ]}
                  />

                  <RadioButtonGroupInput
                    source={getSource('species')}
                    record={scopedFormData}
                    validate={required()}
                    label='SPECIES'
                    choices={[
                      { id: 'dog', name: 'Dog' },
                      { id: 'cat', name: 'Cat' },
                    ]}
                  />

                  <div className={classes.petAge}>
                    <AutocompleteInput
                      source={getSource('pet_age')}
                      record={scopedFormData}
                      label='Select Age'
                      choices={PET_AGE_OPTIONS}
                      optionText='label'
                      optionValue='value'
                      validate={required()}
                      translateChoice={false}
                      fullWidth
                      options={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>

                  <div className={classes.breedCode}>
                    <AutocompleteInput
                      source={getSource('breed_code')}
                      record={scopedFormData}
                      label='Search Breed'
                      choices={species}
                      optionText='name'
                      optionValue='code'
                      validate={required()}
                      translateChoice={false}
                      fullWidth
                      options={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <FormDataConsumer>
        {formDataProps => (
          <div className={classes.nextButtonDiv}>
            <Button
              variant='outlined'
              onClick={() => handlePetSubmitClick(formDataProps)}
              color='primary'
              link={undefined}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Next'}
            </Button>
          </div>
        )}
      </FormDataConsumer>
    </>
  );
}

PetTab.propTypes = {
  setQuote: PropTypes.func.isRequired,
  setQuoteUrl: PropTypes.func.isRequired,
  pets: PropTypes.object,
  setPets: PropTypes.func.isRequired,
  user: PropTypes.object,
  setShowNonConversionModal: PropTypes.func.isRequired,
  discount: PropTypes.object,
  quote: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    partner: PropTypes.string,
    policy_zipcode: PropTypes.string,
    quote_pets: PropTypes.array,
    existingUserDetails: PropTypes.object,
    vet: PropTypes.object,
  }).isRequired,
  edit: PropTypes.bool,
};

export default PetTab;
