import makeStyles from '@mui/styles/makeStyles';

const claimDetailCardStyle = makeStyles(
  {
    card: { display: 'flex', alignItems: 'center', marginRight: '24px' },
    title: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0.15px',
      color: '#000000',
      textTransform: 'capitalize',
    },
    detail: {
      fontSize: '9px',
      fontWeight: '400',
      lineHeight: '12px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    detailWrapper: { marginLeft: '8px' },
  },
  { name: 'claim-detail-card' }
);

export { claimDetailCardStyle };
