import { useState } from 'react';
import { useNotify, useUpdate } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PropTypes from 'prop-types';

import {
  formatRequestedRecordsText,
  MEDICAL_RECORD_REQUEST_COMPLETE,
  RESPONSE_DOCUMENTS,
  RESPONSE_REASON,
  RESPONSE_REASON_NO_PATIENT,
  RESPONSE_REASON_NO_RECORDS,
  RESPONSE_REASON_NOT_CURRENTLY_AVAILABLE,
  RESPONSE_REASON_OTHER,
  useMedicalRecordsRequest,
} from '@pumpkincare/medical-records';

import RoutePaths from '../../../../routes';
import { markReceivedModalStyle } from './mark-received-modal-style.js';

function MarkReceivedModal({ requestId, onClose, onSuccess }) {
  const classes = markReceivedModalStyle();
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();

  const { data: currentData } = useMedicalRecordsRequest(requestId);
  const {
    vet_practice: vetPractice,
    clinic_name: clinicName,
    records,
    contact_email: contactEmail,
  } = currentData;

  const [responseType, setResponseType] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');

  // isValid if documents, if non-Other reason, or if Other reason has notes
  const isValid =
    responseType === RESPONSE_DOCUMENTS ||
    (responseType === RESPONSE_REASON &&
      ([
        RESPONSE_REASON_NO_PATIENT,
        RESPONSE_REASON_NO_RECORDS,
        RESPONSE_REASON_NOT_CURRENTLY_AVAILABLE,
      ].some(responseReason => responseReason === reason) ||
        (reason === RESPONSE_REASON_OTHER && !!notes)));

  function handleSubmit() {
    const payload = {
      ...currentData,
      status: MEDICAL_RECORD_REQUEST_COMPLETE,
      closed_with: responseType === RESPONSE_DOCUMENTS ? RESPONSE_DOCUMENTS : reason,
    };
    if (notes) payload.reason_notes = notes;

    update(
      RoutePaths.medicalRecordsRequests,
      { id: requestId, data: payload },
      {
        onSuccess: () => {
          notify('Request marked complete', { type: 'success' });
          onSuccess();
          onClose();
        },
        onError: ({ message }) => {
          notify(
            message ||
              `There was an error while closing this medical record request`,
            'error'
          );
        },
      }
    );
  }

  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle variant='h6' className={classes.modalTitle}>
        Mark Received
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <div className={classes.infoBox}>
          <Typography variant='body2'>
            <b>{clinicName}</b>
          </Typography>

          <div className={classes.contact}>
            {vetPractice ? (
              <Typography variant='body2'>{vetPractice.normalized_addr}</Typography>
            ) : null}
            <Typography variant='body2'>{contactEmail}</Typography>
          </div>

          {formatRequestedRecordsText(records).map(({ text }) => (
            <Typography key={text} variant='body2'>
              {text}
            </Typography>
          ))}
        </div>

        <FormControl component='fieldset'>
          <FormLabel component='legend' classes={{ root: classes.question }}>
            Did the clinic or pet parent provide the requested records?<span>*</span>
          </FormLabel>

          <RadioGroup
            onChange={e => {
              setResponseType(e.target.value);
            }}
            value={responseType}
            name='response-type'
            row
          >
            <FormControlLabel
              value={RESPONSE_DOCUMENTS}
              control={<Radio color='primary' />}
              label='Yes'
              classes={{ root: classes.labelRoot, label: classes.labelText }}
              style={{ marginRight: '60px' }}
            />

            <FormControlLabel
              value={RESPONSE_REASON}
              control={<Radio color='primary' />}
              label='No'
              classes={{ root: classes.labelRoot, label: classes.labelText }}
            />
          </RadioGroup>
        </FormControl>

        {responseType === RESPONSE_REASON ? (
          <>
            <FormControl component='fieldset' style={{ marginTop: '24px' }}>
              <FormLabel component='legend' classes={{ root: classes.question }}>
                What was the reason provided for the missing records?<span>*</span>
              </FormLabel>

              <RadioGroup
                onChange={e => {
                  setReason(e.target.value);
                }}
                value={reason}
                name='reason'
              >
                <FormControlLabel
                  value={RESPONSE_REASON_NO_PATIENT}
                  control={<Radio color='primary' />}
                  label='Patient or owner not found in our system'
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                />

                <FormControlLabel
                  value={RESPONSE_REASON_NO_RECORDS}
                  control={<Radio color='primary' />}
                  label='Patient found, but requested record(s) not unavailable'
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                />

                <FormControlLabel
                  value={RESPONSE_REASON_NOT_CURRENTLY_AVAILABLE}
                  control={<Radio color='primary' />}
                  label="Still finalizing doctor's notes"
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                />

                <FormControlLabel
                  value={RESPONSE_REASON_OTHER}
                  control={<Radio color='primary' />}
                  label='Other (please specify)'
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                />
              </RadioGroup>
            </FormControl>

            {reason === RESPONSE_REASON_OTHER ? (
              <FormControlLabel
                classes={{ root: classes.textLabel }}
                labelPlacement='top'
                label={
                  <div className={classes.required}>
                    Briefly describe the reason<span>*</span>
                  </div>
                }
                control={
                  <TextField
                    id='reason-for-request'
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    minRows={2}
                    multiline
                    fullWidth
                  />
                }
                style={{ marginTop: '16px' }}
              />
            ) : null}
          </>
        ) : null}
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.modalActions,
          spacing: classes.unsetSpacing,
        }}
      >
        <Button
          onClick={onClose}
          className={classes.cancelButton}
          variant='outlined'
          color='primary'
        >
          Cancel
        </Button>
        <Button
          className={classes.confirmButton}
          variant='contained'
          color='primary'
          disabled={!isValid || isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress size={16} color='inherit' thickness={4} />
          ) : (
            'Confirm'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MarkReceivedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MarkReceivedModal;
