import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { getACHStatus, getACHStatusColor } from '@pumpkincare/claims';

import { formatCurrency } from '../../../shared/utils/currencyUtils';
import { claimReimbursementModalStyles } from './claim-reimbursement-modal-style';

export default function ClaimReimbursementModal(props) {
  const {
    onClose,
    open,
    onReimburseClick,
    fundingSourceData = [],
    disabledButton,
  } = props;
  const record = useRecordContext();

  const classes = claimReimbursementModalStyles();

  const account =
    fundingSourceData.length > 0
      ? fundingSourceData[0].account_type === 'checking'
        ? `CHECKING ACCOUNT ENDING IN ${fundingSourceData[0].account_number}`
        : `SAVINGS ACCOUNT ENDING IN ${fundingSourceData[0].account_number}`
      : 'PHYSICAL CHECK';

  const status =
    fundingSourceData.length > 0 ? getACHStatus(fundingSourceData[0]) : '';
  const statusStyle = status ? getACHStatusColor(status) : {};

  const insuranceId = record?.subclaims
    ? record.subclaims.find(subclaim => subclaim.type === 'insurance')?.id
    : '';
  const preventId = record?.subclaims
    ? record.subclaims.find(
        subclaim => subclaim.type === 'prevent' || subclaim.type === 'wellness'
      )?.id
    : '';

  const insuranceLineItems =
    record?.subclaims?.find(sub => sub.type === 'insurance')?.line_items_decision ||
    [];
  const preventLineItems =
    record?.subclaims?.find(sub => sub.type === 'prevent' || sub.type === 'wellness')
      ?.line_items_decision || [];

  const {
    insuranceTotalClaimed,
    insuranceTotalAppliedToDeductible,
    insuranceTotalPaid,
    preventTotalClaimed,
    preventTotalPaid,
  } = useMemo(
    () => ({
      insuranceTotalClaimed: insuranceLineItems.reduce(
        (sum, item) => sum + item.claimed_amount,
        0
      ),
      insuranceTotalAppliedToDeductible: insuranceLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.deductible_amount) ? 0 : item.deductible_amount),
        0
      ),
      insuranceTotalPaid: insuranceLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
      preventTotalClaimed: preventLineItems.reduce(
        (sum, item) => sum + item.claimed_amount,
        0
      ),
      preventTotalPaid: preventLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
    }),
    [insuranceLineItems, preventLineItems]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.modalTitle} variant='h4'>
        Reimburse
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <div
          style={{
            marginBottom: '38px',
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Typography variant='h5'>Reimbursement Summary</Typography>

          {insuranceId ? (
            <div>
              <Typography variant='h6'>{insuranceId}</Typography>
              <div
                style={{
                  display: 'flex',
                  marginTop: '16px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography className={classes.amountTitle}>
                    CLAIMED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalClaimed, { areCents: true })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPROVED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalPaid, { areCents: true })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPLIED TO DEDUCTIBLE
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalAppliedToDeductible, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
              </div>
            </div>
          ) : null}

          {preventId ? (
            <div>
              <Typography variant='h6'>{preventId}</Typography>
              <div
                style={{
                  display: 'flex',
                  marginTop: '16px',
                  width: '285px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography className={classes.amountTitle}>
                    CLAIMED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(preventTotalClaimed, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPROVED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(preventTotalPaid, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <Typography variant='h6'>Reimbursement Method</Typography>

        <Typography className={classes.directDeposit}>{account}</Typography>
        <Typography style={statusStyle} className={classes.status}>
          {status}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.modalActions}>
        <Button
          disabled={disabledButton}
          onClick={onClose}
          className={classes.cancelButton}
          variant='outlined'
        >
          CANCEL
        </Button>
        <Button
          onClick={() => onReimburseClick(record)}
          className={classes.reimburseButton}
          disabled={disabledButton}
          variant='contained'
        >
          REIMBURSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
