import { Button, ListSubheader, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import classnames from 'classnames';

import { claimBulkEditSection } from './claim-bulk-edit-section-style';

export default function ClaimBulkEditSection({
  onBulkEditCancelClick,
  denialReasonList = [],
  bulkChanges,
  updateBulkChanges,
  onBulkEditUpdateClick,
  subclaimType,
  preventProductsData = [],
  serviceData = [],
  incidentHistoryOptions = [],
}) {
  const classes = claimBulkEditSection();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 20,
  });

  return (
    <div
      style={{
        marginBottom: '18px',
      }}
    >
      <div
        className={classnames({
          [classes.insuranceFieldContainer]: subclaimType === 'insurance',
          [classes.preventFieldContainer]: subclaimType === 'prevent',
        })}
      >
        <TextField
          style={{ maxWidth: '160px' }}
          variant='outlined'
          fullWidth
          onClick={e => e.stopPropagation()}
          type='date'
          value={bulkChanges?.loss_date}
          label='Loss Date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={event =>
            updateBulkChanges({
              ...bulkChanges,
              loss_date: event.target.value,
            })
          }
        />
        {subclaimType === 'insurance' ? (
          <>
            <Autocomplete
              options={serviceData}
              label='Service'
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                updateBulkChanges({
                  ...bulkChanges,
                  service: { id: newValue.id, name: newValue.name },
                });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              style={{ width: 300, marginLeft: '4px' }}
              filterOptions={filterOptions}
              renderInput={params => (
                <TextField {...params} label='Service' variant='outlined' />
              )}
            />

            <TextField
              variant='outlined'
              style={{ width: '250px' }}
              value={bulkChanges.incident?.id}
              onChange={event =>
                updateBulkChanges({
                  ...bulkChanges,
                  incident: { id: event.target.value },
                })
              }
              select
              label='Incident'
            >
              <MenuItem key='' value=''>
                &nbsp;
              </MenuItem>
              {incidentHistoryOptions.map(incident => (
                <MenuItem key={incident.value} value={incident.value}>
                  {incident.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : null}

        {subclaimType === 'prevent' ? (
          <Autocomplete
            options={preventProductsData}
            label='Service'
            getOptionLabel={option => option.name}
            onChange={(event, newValue) =>
              updateBulkChanges({
                ...bulkChanges,
                item_type: preventProductsData.find(
                  product => product.id === newValue.id
                ),
              })
            }
            style={{ width: 300, marginLeft: '4px' }}
            filterOptions={filterOptions}
            renderInput={params => (
              <TextField {...params} label='Service' variant='outlined' />
            )}
          />
        ) : null}
        <TextField
          variant='outlined'
          style={{ width: '150px' }}
          select
          label='Decision'
          value={bulkChanges.decision}
          onChange={event =>
            updateBulkChanges({
              ...bulkChanges,
              decision: event.target.value,
              denial_reason:
                event.target.value === 'denied'
                  ? bulkChanges.denial_reason
                  : subclaimType === 'insurance'
                    ? []
                    : '',
            })
          }
        >
          <MenuItem key='' value=''>
            &nbsp;
          </MenuItem>
          <MenuItem key='approved' value='approved'>
            Approved
          </MenuItem>
          <MenuItem key='denied' value='denied'>
            Denied
          </MenuItem>
        </TextField>
        {subclaimType === 'insurance' ? (
          <TextField
            label='Denial Reason'
            disabled={bulkChanges.decision !== 'denied'}
            SelectProps={{
              multiple: true,
              value: bulkChanges.denial_reason,
              renderValue: selected => {
                return selected
                  .map(id => {
                    return denialReasonList.find(denial => denial.id === id).code;
                  })
                  .join(',');
              },
              onChange: event =>
                updateBulkChanges({
                  ...bulkChanges,
                  denial_reason: event.target.value,
                }),
            }}
            select
            variant='outlined'
            style={{ width: '250px' }}
          >
            <ListSubheader disableSticky>Standard Reasons</ListSubheader>

            {denialReasonList
              .filter(denialReason => denialReason.category === 'Standard Reasons')
              .map(denialReason => (
                <MenuItem key={denialReason.id} value={denialReason.id}>
                  {denialReason.name}
                </MenuItem>
              ))}
            <ListSubheader disableSticky>Exclusions</ListSubheader>
            {denialReasonList
              .filter(denialReason => denialReason.category === 'Exclusions')
              .map(denialReason => (
                <MenuItem key={denialReason.id} value={denialReason.id}>
                  {denialReason.name}
                </MenuItem>
              ))}
          </TextField>
        ) : null}
        {subclaimType === 'prevent' ? (
          <TextField
            label='Denial Reason'
            disabled={bulkChanges.decision !== 'denied'}
            SelectProps={{
              value: bulkChanges.denial_reason,
              onChange: event =>
                updateBulkChanges({
                  ...bulkChanges,
                  denial_reason: event.target.value,
                }),
            }}
            select
            variant='outlined'
            style={{ width: '250px' }}
          >
            {denialReasonList.map(denialReason => (
              <MenuItem key={denialReason.value} value={denialReason.value}>
                {denialReason.label}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </div>
      <div
        className={classnames({
          [classes.insuranceButtonContainer]: subclaimType === 'insurance',
          [classes.preventButtonContainer]: subclaimType === 'prevent',
        })}
      >
        <Button
          className={classes.updateLineItemButton}
          onClick={onBulkEditUpdateClick}
        >
          UPDATE
        </Button>
        <Button
          className={classes.cancelUpdateLineItemButton}
          onClick={onBulkEditCancelClick}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
