import { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { REIMBURSEMENT_RATE_90 } from '../../../../constants/quoteFlow';
import { formatCurrency } from '../../../../shared/utils';
import { formatDiscountName } from '../utils/quoteUtils';
import { planModalStyle } from './PlanModalStyle';

function PlanModal(props) {
  const {
    quotePet,
    annualCoverage,
    annualDeductible,
    showDialog,
    setShowDialog,
    billingOption,
    quote,
  } = props;

  const classes = planModalStyle();

  const isBilledAnnually =
    billingOption === 'annually' || quote.existingUserDetails?.is_charged_annually;

  const multiplier = isBilledAnnually ? 12 : 1;

  const productsDescription = quotePet.has_prevent
    ? 'Pumpkin Pet Insurance + Preventive Essentials'
    : 'Pumpkin Pet Insurance';

  const coinsurance = quotePet.coinsurance || '10';
  let price;
  if (annualCoverage === 'Unlimited') {
    try {
      price =
        annualCoverage && annualDeductible && quotePet._options
          ? quotePet._options.prices[coinsurance][annualCoverage][annualDeductible]
          : 0;
    } catch (e) {
      price =
        annualCoverage && annualDeductible && quotePet._options
          ? quotePet._options.prices[coinsurance]['1000000'][annualDeductible]
          : 0;
    }
  } else {
    price =
      annualCoverage && annualDeductible && quotePet._options
        ? quotePet._options.prices[coinsurance][annualCoverage][annualDeductible]
        : 0;
  }

  const planDetails = useMemo(
    () =>
      quotePet.plan
        ? JSON.parse(quotePet.plan.web_description).pickerModule.planDetails
        : [],
    [quotePet.plan]
  );

  const reimbursementRate = quotePet.coinsurance
    ? `${100 - Number(quotePet.coinsurance)}%`
    : `${100 - Number(REIMBURSEMENT_RATE_90)}%`;

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      aria-label='Show Pet Plan'
    >
      <div
        style={{
          width: '500px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px 0px 24px',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '500',
            color: 'black',
          }}
        >
          Pet's Plan
        </Typography>
        <IconButton
          style={{
            color: 'black',
          }}
          onClick={() => setShowDialog(false)}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent
        style={{ display: 'inline-grid', paddingLeft: '0', paddingRight: '0' }}
      >
        <Divider />

        <div style={{ padding: '16px 24px 0px' }}>
          <Typography className={classes.label}>Pet</Typography>

          <Typography className={classes.petSpeciesGender}>
            {quotePet.species} ({quotePet?.gender})
          </Typography>

          <Typography className={classes.petName}>{quotePet.name}</Typography>

          <Typography className={classes.petDetails}>
            {quotePet?.breed_name}
          </Typography>

          <Typography className={classes.petDetails}>
            {isNaN(quotePet?.age)
              ? quotePet?.age
              : quotePet?.age > 1
                ? `${quotePet?.age} years old`
                : `${quotePet?.age} year old`}
          </Typography>
        </div>

        <Divider style={{ marginTop: '20px' }} />

        <div style={{ padding: '16px 24px 32px' }}>
          <Typography className={classes.label}>Plan</Typography>

          <Typography className={classes.productsDescription}>
            {productsDescription}
          </Typography>

          <div className={classes.productHeader}>
            <Typography className={classes.productName}>
              PUMPKIN PET INSURANCE
            </Typography>
            <Typography className={classes.productPrice}>
              {formatCurrency(price * multiplier)}
            </Typography>
          </div>

          <Typography className={classes.productDetails}>
            Annual Coverage Limit:{' '}
            {annualCoverage.includes('Unlimited')
              ? 'Unlimited'
              : formatCurrency(annualCoverage)}
          </Typography>

          <Typography className={classes.productDetails}>
            Reimbursement Rate: {reimbursementRate}
          </Typography>

          <Typography className={classes.productDetails}>
            Annual Deductible: {formatCurrency(annualDeductible, { hideZero: true })}
          </Typography>

          <Typography className={classes.productDetails}>
            Coverage Start Date: 14 days after payment
          </Typography>

          <Typography className={classes.productDetails}>
            Policy Start Date: On day of payment
          </Typography>

          {quotePet.has_prevent && quotePet.plan ? (
            <>
              <div className={classes.productHeader} style={{ marginTop: '16px' }}>
                <Typography className={classes.productName}>
                  PUMPKIN PREVENTIVE ESSENTIALS
                </Typography>
                <Typography className={classes.productPrice}>
                  {formatCurrency(quotePet.plan.cost * multiplier)}
                </Typography>
              </div>

              {quotePet.plan.vet_name ? (
                <Typography className={classes.vetName}>
                  {quotePet.plan.vet_name}
                </Typography>
              ) : null}

              {planDetails.map((detail, index) => (
                <Typography
                  key={`detail-${index}`}
                  className={classes.productDetails}
                >
                  {detail}
                </Typography>
              ))}

              <Typography className={classes.productDetails}>
                Start Date: On day of payment
              </Typography>
            </>
          ) : null}
        </div>

        {quotePet.discount
          ? quotePet.discount.map((discount, index) => {
              return (
                <div key={`discount-container-${index}`}>
                  <Divider />

                  <div key={`discount-${index}`} className={classes.discountHeader}>
                    <Typography className={classes.discountName}>
                      {formatDiscountName(discount)}
                    </Typography>
                    <Typography className={classes.discountAmount}>
                      {formatCurrency(-(discount.amount * multiplier))}
                    </Typography>
                  </div>
                </div>
              );
            })
          : null}

        <Divider />

        <div style={{ padding: '16px 24px 24px', textAlign: 'right' }}>
          <Typography className={classes.totalLabel}>Monthly Rate</Typography>
          <Typography className={classes.totalPrice}>
            {formatCurrency(quotePet.totalPrice * multiplier)}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
}

PlanModal.propTypes = {
  quotePet: PropTypes.shape({
    species: PropTypes.string.isRequired,
    gender: PropTypes.string,
    name: PropTypes.string.isRequired,
    breed_name: PropTypes.string,
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPrice: PropTypes.number.isRequired,
    annual_limit: PropTypes.string,
    coinsurance: PropTypes.string,
    deductible: PropTypes.string,
    discount: PropTypes.array,
    has_prevent: PropTypes.bool,
    partner: PropTypes.string,
    preventDescription: PropTypes.string,
    plan: PropTypes.shape({
      web_description: PropTypes.string,
      cost: PropTypes.number,
      name: PropTypes.string,
      min_age_months: PropTypes.number,
      max_age_months: PropTypes.number,
      id: PropTypes.string,
      vet_name: PropTypes.string,
    }),
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        addon: PropTypes.shape({
          sku: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    _options: PropTypes.shape({
      prices: PropTypes.object,
    }),
  }).isRequired,
  annualCoverage: PropTypes.string.isRequired,
  annualDeductible: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  billingOption: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    existingUserDetails: PropTypes.shape({
      is_charged_annually: PropTypes.bool,
    }),
  }).isRequired,
};

export default PlanModal;
