import makeStyles from '@mui/styles/makeStyles';

export const useReimbursementCardDetailsStyles = makeStyles(
  {
    reimbursementTitle: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      color: '#2196F3',
      marginBottom: '8px',
    },
    reimbursementSubtitle: {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
    reimbursementSectionTitle: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      marginTop: '20px',
      marginBottom: '8px',
    },
    reimbursementDetail: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#767676',
      marginBottom: '4px',
    },
    reimbursementDetailAmountPaid: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#767676',
      marginBottom: '16px',
    },
    financialReviewText: {
      color: '#D4424E',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    financialReviewIcon: { color: '#D4424E', width: '16px', marginRight: '4px' },
    financialReviewContainer: { display: 'flex', alignItems: 'center' },
  },
  { name: 'reimbursement-card-details' }
);
