import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../../../constants/quoteFlow';

export const PET_CHANGE_OPTIONS = [
  { id: 'midterm', name: 'Mid-Term Change' },
  { id: 'renewal', name: 'Renewal Change' },
  { id: 'details', name: 'Edit Pet Details' },
];

export function formatPetEditTitle(petData, customerData) {
  return petData && customerData
    ? `${customerData.first_name} ${customerData.last_name} - ${customerData.pumpkin_id} - ${petData.name}`
    : ' ';
}

export function addIdToMidtermOptions(midtermChangeData) {
  return midtermChangeData.map((option, index) => {
    option.id = String(index + 1);
    return option;
  });
}

export function sortMidtermOptions(midtermChangeData) {
  return midtermChangeData.sort((a, b) =>
    // Sort by deductible first: 100, 250, 500, 1000
    // When sorting by deductible, we need to compare the int value, otherwise the result would be: 100, 1000, 250, 500;
    // Then we sort by annual limit: 7000, 10000, 15000, 20000, Unlimited
    // When annual_limit === UNLIMITED, we use the MAX INT to guarantee this options to be pushed to the end;
    a.deductible === b.deductible
      ? (a.annual_limit.toUpperCase() === 'UNLIMITED'
          ? Number.MAX_SAFE_INTEGER
          : parseInt(a.annual_limit)) -
        (b.annual_limit.toUpperCase() === 'UNLIMITED'
          ? Number.MAX_SAFE_INTEGER
          : parseInt(b.annual_limit))
      : parseInt(a.deductible) - parseInt(b.deductible)
  );
}

export function groupMidtermData(midtermChangeData) {
  return midtermChangeData
    .filter(item => item.allowed)
    .reduce((data, item) => {
      const { coinsurance, deductible } = item;

      if (!data[coinsurance]) {
        data[coinsurance] = {};
      }

      if (!data[coinsurance][deductible]) {
        data[coinsurance][deductible] = [];
      }

      data[coinsurance][deductible].push(item);

      return data;
    }, {});
}

export function getPetSpecies(pet) {
  const { species, age } = pet || {};

  if (species === 'cat' && (age === UP_TO_5_MONTHS || age === UP_TO_11_MONTHS)) {
    return 'kitten';
  }

  if (species === 'dog' && (age === UP_TO_5_MONTHS || age === UP_TO_11_MONTHS)) {
    return 'puppy';
  }

  return species;
}
