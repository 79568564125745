import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Form,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import {
  dateIsAfter,
  getDateAsFormattedUTC,
  getTimeDifferenceFromCurrentDate,
  validateDateYYYYMMDD,
} from '../../../../shared/utils';
import { editIncidentModal } from './edit-incident-modal-style';
export default function EditIncidentModal(props) {
  const {
    onClose,
    open,
    onEditIncident,
    incident = {},
    disableButton,
    coverageStartDate,
    accidentCoverageStartDate,
    wellnessCoverageStartDate,
    subDiagnosisData = [],
  } = props;
  const record = useRecordContext();
  const [diagnosis, setDiagnosis] = useState('');
  const [diagnosisId, setDiagnosisId] = useState(null);
  const [incidentType, setIncidentType] = useState('');
  const [symptomOnset, setSymptomOnset] = useState('');
  const [disableMarkAsCured, setDisableMarkAsCured] = useState(false);
  const [curability, setCurability] = useState(true);
  const [symptomSelected, setSymptomSelected] = useState(0);
  const classes = editIncidentModal();
  useEffect(() => {
    setDiagnosis(incident?.diagnosis?.name);
    setDiagnosisId(incident?.diagnosis?.id);
    setIncidentType(incident?.type);
    setSymptomOnset(incident?.original_symptom_date);
    setDisableMarkAsCured(incident?.is_curable === 'incurable' ? true : false);
    setSymptomSelected(0);
  }, [incident]);

  function handleAddLineItemClick(props) {
    onEditIncident({
      ...props,
      pet_id: record?.pet_id,
      diagnosis: diagnosisId,
      id: incident?.id,
    });
  }

  function handleOnClose(props) {
    onClose(props);
  }

  function handleOnSymptomBlur(symptomOnsetDate, setValue) {
    setSymptomOnset(symptomOnsetDate);
    const coverageStart =
      incidentType == 'accident'
        ? accidentCoverageStartDate
        : incidentType == 'illness'
          ? coverageStartDate
          : wellnessCoverageStartDate;
    if (incidentType && symptomOnsetDate < coverageStart) {
      setValue('pre_existing_condition', true);
    } else {
      setValue('pre_existing_condition', false);
    }
    if (validateDateYYYYMMDD(symptomOnsetDate) && symptomSelected == 0) {
      setValue('latest_occurrence_date', symptomOnsetDate);
      setSymptomSelected(symptomSelected + 1);
    }
  }

  function handleIncidentTypeChange(incidentType, setValue) {
    setIncidentType(incidentType);

    const coverageStart =
      incidentType == 'accident'
        ? accidentCoverageStartDate
        : incidentType == 'illness'
          ? coverageStartDate
          : wellnessCoverageStartDate;
    if (symptomOnset && symptomOnset < coverageStart) {
      setValue('pre_existing_condition', true);
    } else {
      setValue('pre_existing_condition', false);
    }
  }

  function handleOnChangeCurability(curability, setValue) {
    if (curability === 'incurable') {
      setValue('mark_as_cured', false);
      setDisableMarkAsCured(true);
    } else {
      setDisableMarkAsCured(false);
    }
    setCurability(curability);
  }

  const IncidentTypeSelectInput = () => {
    const { setValue } = useFormContext();
    return (
      <SelectInput
        className={classes.incidentTypeSelect}
        sx={{
          backgroundColor: incidentType ? '#002984' : 'inherit',
          border: incidentType ? 'none' : '1px solid black',
          '& .MuiFormLabel-root': {
            display: incidentType ? 'none' : 'inherit',
          },

          '& .MuiSvgIcon-root': {
            color: incidentType ? 'white' : 'black',
          },
        }}
        onChange={e => handleIncidentTypeChange(e.target.value, setValue)}
        fullWidth
        source='incident_type'
        label='TYPE'
        validate={required()}
        choices={[
          { id: 'accident', name: 'ACCIDENT' },
          { id: 'illness', name: 'ILLNESS' },
          { id: 'wellness', name: 'WELLNESS' },
        ]}
        inputProps={{ 'data-testid': 'incident' }}
        InputLabelProps={{
          shrink: false,
          'aria-label': 'Without label',
        }}
        variant='standard'
      />
    );
  };

  const LatestOccurrenceDateInput = () => {
    return (
      <DateInput
        fullWidth
        variant='outlined'
        label='Latest Occurrence'
        source='latest_occurrence_date'
        className={classes.datePickerMargin}
      />
    );
  };

  const SymptomDateInput = () => {
    const { setValue } = useFormContext();

    return (
      <DateInput
        fullWidth
        variant='outlined'
        label='Symptom Onset'
        source='symptom_date'
        onBlur={e => handleOnSymptomBlur(e.target.value, setValue)}
        className={classes.datePickerMargin}
      />
    );
  };

  const PreExistingInput = () => {
    const incident_type = useWatch({ name: 'incident_type' });

    const coverageStart =
      incident_type == 'accident'
        ? accidentCoverageStartDate
        : incident_type == 'illness'
          ? coverageStartDate
          : wellnessCoverageStartDate;
    return (
      <BooleanInput
        label={<Typography variant='subtitle2'>Mark as pre-existing</Typography>}
        source='pre_existing_condition'
        fullWidth
        helperText={`Cov. start ${getDateAsFormattedUTC(
          coverageStart,
          'MM/DD/YYYY'
        )}`}
        className={classes.preExistingCheck}
        size='small'
      />
    );
  };

  function CurabilitySelectInput() {
    const { setValue, getFieldState } = useFormContext();
    const { error: curabilityError } = getFieldState('is_curable');

    return (
      <SelectInput
        className={classes.curabilitySelect}
        sx={{
          backgroundColor: curability ? '#9e9e9e' : 'inherit',
          border: curabilityError
            ? '1px solid red'
            : curability
              ? 'none'
              : '1px solid black',
          '& .MuiFormLabel-root': {
            display: curability ? 'none' : 'inherit',
          },
          '& .MuiSvgIcon-root': {
            color: curabilityError ? 'red' : curability ? 'white' : 'black',
          },
        }}
        onChange={event => handleOnChangeCurability(event.target.value, setValue)}
        fullWidth
        inputProps={{
          'data-testid': 'is_curable',
          'aria-label': 'Without label',
        }}
        source='is_curable'
        label='CURABILITY'
        validate={required()}
        choices={[
          { id: 'curable', name: 'CURABLE' },
          { id: 'incurable', name: 'INCURABLE' },
          { id: 'unknown', name: 'UNKNOWN' },
        ]}
        InputLabelProps={{ shrink: false }}
        variant='standard'
      />
    );
  }

  const AlertDaysSinceLatestOccurrence = () => {
    const latest_occurrence_date = useWatch({ name: 'latest_occurrence_date' });
    const is_curable = useWatch({ name: 'is_curable' });
    const cured = useWatch({ name: 'mark_as_cured' });
    if (is_curable === 'curable' && cured == false)
      return (
        <Alert
          icon={<AccessTimeIcon fontSize='inherit' />}
          className={classes.daysPastAlert}
        >
          <span style={{ color: '#373F51', fontWeight: '600' }}>
            {getTimeDifferenceFromCurrentDate(latest_occurrence_date, 'days')} days
            past since latest occurrence
          </span>
        </Alert>
      );
  };

  const diagnosisChoices = subDiagnosisData.map(diagnosisValue => {
    return {
      id: diagnosisValue.id,
      name: diagnosisValue.name,
      diagnosisName: diagnosisValue.diagnosis_name.toUpperCase(),
      diagnosisId: diagnosisValue.diagnosis_id,
    };
  });

  const validateIncidentCreation = values => {
    const errors = {};

    if (!values.latest_occurrence_date) {
      errors.latest_occurrence_date = 'Incident Type is required';
    }

    if (!values.symptom_date) {
      errors.symptom_date = 'Symptom Onset is required';
    }

    if (dateIsAfter(values.symptom_date, values.latest_occurrence_date)) {
      errors.latest_occurrence_date =
        'Latest Occurrence Date can not predate Symptom Onset date';
    }

    if (dateIsAfter(values.latest_occurrence_date, Date.now())) {
      errors.latest_occurrence_date = 'Latest Occurrence annot be later than today';
    }

    if (dateIsAfter(values.symptom_date, Date.now())) {
      errors.latest_occurrence_date = 'Symtpom Onset cannot be later than today';
    }

    return errors;
  };

  return (
    <Dialog open={open} onClose={handleOnClose} className={classes.dialogContainer}>
      <Form
        defaultValues={{
          incident_type: incident.type,
          symptom_date: incident.original_symptom_date,
          latest_occurrence_date: incident.latest_occurrence_date,
          notes: incident.notes,
          subdiagnosis: incident.sub_diagnosis_id,
          is_curable: incident.is_curable,
          mark_as_cured: incident.end_date ? true : false,
          pre_existing_condition: incident.pre_existing_condition,
        }}
        validate={validateIncidentCreation}
        onSubmit={handleAddLineItemClick}
      >
        <DialogTitle variant='h6' color='primary'>
          EDIT INCIDENT
        </DialogTitle>

        <DialogContent className={classes.modalContent}>
          <div className={classes.leftPanel}>
            <AutocompleteInput
              fullWidth
              onChange={(event, value) => {
                setDiagnosis(value.diagnosisName);
                setDiagnosisId(value.diagnosisId);
              }}
              defaultValue={diagnosisChoices[1]}
              choices={diagnosisChoices.sort((a, b) =>
                a.diagnosisName.localeCompare(b.diagnosisName)
              )}
              groupBy={diag => diag.diagnosisName}
              source='subdiagnosis'
              variant='outlined'
              size={'big'}
              optionText='name'
              label='Diagnosis'
              helperText={
                diagnosis ? (
                  <>
                    <span>Category </span>
                    <span style={{ color: '#2196F3', textTransform: 'capitalize' }}>
                      {diagnosis.toLowerCase()}
                    </span>
                  </>
                ) : null
              }
              getOptionLabel={option => option.name}
            />
            <div className={classes.diagnosisDiv}>
              <IncidentTypeSelectInput />
              <CurabilitySelectInput />
            </div>
            <div className={classes.datePickerDiv}>
              <SymptomDateInput />
              <LatestOccurrenceDateInput />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PreExistingInput />
              <BooleanInput
                label={
                  <Typography variant='subtitle2'>Mark as cured date</Typography>
                }
                className={classes.markAsCuredCheck}
                source='mark_as_cured'
                size='small'
                fullWidth
                disabled={disableMarkAsCured}
              />
            </div>
            <AlertDaysSinceLatestOccurrence />
          </div>

          <div className={classes.rightPanel}>
            <TextInput
              multiline
              label='Medical Record Reference/Notes'
              source='notes'
              minRows={2}
              fullWidth
              variant={'filled'}
              size={'medium'}
              className={classes.notesTextInput}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            onClick={onClose}
            variant='outlined'
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={disableButton}
            type='submit'
            color='primary'
            variant='contained'
            className={classes.addButton}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
