import makeStyles from '@mui/styles/makeStyles';

const paymentFormStyle = makeStyles(
  {
    shippingForm: {
      display: 'flex',
      flexDirection: 'column',
    },
    border: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    borderBottom: {
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    borderRight: {
      borderTop: 'none',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderLeft: 'none',
      borderBottom: 'none',
    },
    nameBorder: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: 'none',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    asterisk: {
      color: '#B00020',
    },
    textInput: {
      background: 'transparent',
      color: '#373F51',
      fontSize: '16px',
      height: '37px',
      lineHeight: '37px',
      boxSizing: 'border-box',
      padding: '0 8px',
      width: '100%',
    },
    selectInput: {
      background: 'transparent',
      color: '#373F51',
      fontSize: '16px',
      height: '37px',
      lineHeight: '37px',
      boxSizing: 'border-box',
      paddingLeft: '4px',
      width: '100%',
      border: 'none',
    },
    countryOption: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  },
  { name: 'payment-form' }
);

export { paymentFormStyle };
