import { DateInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import { flattenObj } from '@pumpkincare/shared';

import { MGA_DOCUMENT_TYPES_UPLOAD_CHOICES } from '../../../../constants/documents';

const useStyles = makeStyles(
  {
    inlineBlock: {
      display: 'inline-flex',
      marginRight: '20px',
      width: '180px',
    },
  },
  { name: 'customer-documents-v2-filter' }
);

function CustomerDocumentsV2Filter({ filters, onChange }) {
  const classes = useStyles();

  function buildFilter(rawName, value) {
    // bc record on the page is user, created_at technically points to user.created_at not documents
    const bandaid = { created: 'created_at' };
    const name = bandaid[rawName] || rawName;

    if (value === '') {
      const key = name.includes('.') ? name.substring(0, name.indexOf('.')) : name;
      const copy = { ...filters };
      delete copy[key];

      onChange(copy);
      return;
    }

    const criteria = _.set({}, name, value);
    const payload = {
      ...filters,
      ...criteria,
    };

    onChange(flattenObj(payload));
  }

  const debounceBuildFilter = _.debounce((name, value) => {
    buildFilter(name, value.trim());
  }, 500);

  return (
    <SimpleForm toolbar={null} onSubmit={null}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
      >
        <Grid item>
          <SelectInput
            fullWidth
            label='Document Type'
            source='type'
            choices={MGA_DOCUMENT_TYPES_UPLOAD_CHOICES}
            onChange={event => {
              buildFilter(event.target.name, event.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextInput
            fullWidth
            label='Claim Id'
            source='claim_id'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <DateInput
            fullWidth
            label='Date Uploaded'
            source='created'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
            formClassName={classes.inlineBlock}
          />
        </Grid>
        <Grid item>
          <TextInput
            fullWidth
            label='Pet Name'
            source='pet_name'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
            formClassName={classes.inlineBlock}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
}

export default CustomerDocumentsV2Filter;
