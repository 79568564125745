import makeStyles from '@mui/styles/makeStyles';

const renewalChangeRequestStyle = makeStyles(
  {
    ratesRadioGroupButton: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    radioButton: {
      '& svg': {
        width: '28px',
        height: '28px',
      },
      padding: '8px 0px',
      marginRight: '88px',
    },
    ratesDescription: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.15px',
    },
    ratesLabel: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0.15px',
      paddingBottom: '8px',
    },
  },
  { name: 'renewal-change-request' }
);

export { renewalChangeRequestStyle };
