import {
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import CustomEditAction from '../../components/CustomEditAction';
import CustomerNavBar from '../../components/CustomerNavBar';
import Email from '../../components/Email';
import mailMetadata from '../../components/Email/metadata';
import InvoiceItems from '../../components/InvoiceItems';
import { MoneyField } from '../../components/MoneyField';
import useStyles from './InvoiceShow.styles';

function NavBarWrapper() {
  const record = useRecordContext();
  return <CustomerNavBar userId={record?.user_id} />;
}

function InvoiceShow() {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Show title=' ' actions={<TopToolbar />}>
      <SimpleShowLayout>
        <NavBarWrapper />
        <CustomEditAction />
        <TextField label='First Name' source='user.first_name' />
        <TextField label='Last Name' source='user.last_name' />
        <TextField label='Email' source='user.email' />
        <MoneyField label='Total' source='total' />
        <MoneyField label='Refund' source='total_refund' />
        <DateField label='Date' source='created_at' />

        <InvoiceItems source='items'>
          <Datagrid bulkActionButtons={false} className={classes.grid}>
            <TextField source='type' />
            <TextField source='description' />
            <MoneyField source='amount' />
          </Datagrid>
        </InvoiceItems>

        <TextField label='Notes' component='pre' source='notes' />

        <Email id={id} entity='invoice' settings={mailMetadata.invoice} />
      </SimpleShowLayout>
    </Show>
  );
}

export default InvoiceShow;
