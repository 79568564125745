import makeStyles from '@mui/styles/makeStyles';

const bundleItemListStyle = makeStyles(
  {
    bundleItemDiv: {
      display: 'flex',
      marginTop: '12px',
      marginLeft: '48px',
      marginBottom: '12px',
    },
    bundleLineItem: { marginRight: '24px', width: '180px' },
    bundleLineItemTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    bundleLineItemValue: { fontSize: '10px' },
  },
  { name: 'bundle-item-list' }
);

export { bundleItemListStyle };
