import makeStyles from '@mui/styles/makeStyles';

export const OrganizationsConfirmModalStyles = makeStyles(
  {
    label: {
      fontSize: '0.625rem',
    },
    upper: {
      textTransform: 'uppercase',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    submitBtn: {
      backgroundColor: '#007DFF',
      color: '#FFFFFF',
      '&:hover': {
        color: '#007DFF',
      },
    },
    cancelBtn: {
      paddingTop: '0.6875rem !important',
      '& span': {
        color: '#007DFF',
      },
    },
    pb0: {
      paddingBottom: '0 !important',
    },
    dialogParent: {
      '& .MuiPaper-root, & .MuiDialogContent-root': {
        overflowY: 'initial !important',
        minHeight: '30vh',
      },
      '& .MuiDialogContent-root': {
        paddingBottom: '1.5rem',
        minHeight: '30vh',
      },
    },
  },
  { name: 'organizations-confirm-modal' }
);
