import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Typography } from '@mui/material';

import { GENDER_MAP } from '@pumpkincare/shared';

import { appendBreedName } from '../../../../shared/utils/petUtils';
import PetPhoto from '../pet-photo';
import petShowStyle from '../pet-show-style';

function PetInfo({ label, value, component = null }) {
  const classes = petShowStyle();

  return (
    <div>
      <Typography className={classes.petDetailTitle}>{label}</Typography>

      {component ? (
        component
      ) : (
        <Typography className={classes.petDetailSubtitle}>{value}</Typography>
      )}
    </div>
  );
}

function PetDetailHeader({ auditLog }) {
  const record = appendBreedName(useRecordContext(), 'breed');
  const classes = petShowStyle();

  return (
    <>
      <Link
        to={`/customers/${record?.user_id}/show`}
        className={classes.returnToShowCustomerLink}
      >
        <ArrowBackIosIcon className={classes.arrowBackIcon} />
        <Typography className={classes.returnText}>Pet Details</Typography>
      </Link>

      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={{ columnGap: '20px' }}
      >
        <div>
          <PetPhoto
            source={record.pet_photo}
            species={record.species}
            showActionButtons
          />
        </div>

        <div className={classes.petDetailContainer}>
          <PetInfo label='Pet ID' value={record.pumpkin_id} />
          <PetInfo label='Pet Name' value={record.name} />
          <PetInfo label='Species' value={record.species} />
          <PetInfo label='Gender' value={GENDER_MAP[record?.gender] || 'N/A'} />
          <PetInfo label='Breed' value={record.breed} />
          <PetInfo label='Age' value={record?.age} />
          <div />

          {auditLog ? (
            <Grid item>
              <PetInfo label='Audit logs' component={auditLog} value={null} />
            </Grid>
          ) : null}
        </div>
      </Grid>
    </>
  );
}

export default PetDetailHeader;
